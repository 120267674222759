/* eslint-disable no-useless-escape */
import React, { useState } from "react";
import { Paper, Grid, Typography } from "@mui/material";
import { Button, Input, Spin, Form, Modal } from "antd";
import AntDesignContext from "../../../common/contexts/AntDesignContext";
import { useAddWebhookEndpoint } from "../hooks/useAddWebhookEndpoint";
import { useGetWebhook } from "../hooks/useGetWebhook";
import CopyButton from "./CopyButton";

export const TypographyStyles = {
  heading: {
    textAlign: "left",
    variant: "h6",
  },
  subheading: {
    textAlign: "left",
    sx: {
      opacity: 0.7,
      mb: 1,
    },
  },
};

const TextStyles = TypographyStyles.subheading;

const Webhooks = () => {
  const { loading, addWebhookEndpoint } = useAddWebhookEndpoint();
  const { getLoading, webhookDetails } = useGetWebhook({ loading });
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [endpoint, setEndpoint] = useState();
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = async () => {
    await addWebhookEndpoint(endpoint);
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const [form] = Form.useForm();

  const handleRegisterWebhook = async () => {
    await form
      .validateFields()
      .then(async (values) => {
        setEndpoint(values);
        showModal();
      })
      .catch((errorInfo) => {
        console.log("Validation Failed:", errorInfo);
      });
  };

  return (
    <div>
      <Paper elevation={3} sx={{ p: 2, flexGrow: 1 }}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography {...TypographyStyles.heading}>Webhooks</Typography>
          </Grid>
        </Grid>
        <div>
          <AntDesignContext>
            <Form
              form={form}
              name={`webhookEndpoint`}
              className="w-full pt-4"
              id="webhookEndpoint"
              layout="vertical"
              autoComplete="on"
              requiredMark={"optional"}
            >
              <Form.Item
                hasFeedback
                name={"endpoint"}
                validateFirst
                rules={[
                  {
                    required: true,
                    message: `Please input your endpoint!`,
                  },
                  {
                    pattern:
                      /^[-a-zA-Z0-9@:%.\+#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%\+.#?&//=]*)$/,
                    message: "Please enter a valid endpoint address!",
                  },
                ]}
              >
                <div className="flex items-center justify-between gap-4">
                  <Input
                    addonBefore={"https://"}
                    size="middle"
                    placeholder={"example.com/hook"}
                    className="text-white bg-[#121212] rounded-lg placeholder:text-white placeholder:opacity-50"
                    required={true}
                  />
                  <Button
                    type="primary"
                    loading={loading}
                    onClick={handleRegisterWebhook}
                  >
                    Register
                  </Button>
                </div>
              </Form.Item>
            </Form>
            <Modal
              title="Are you sure you want to register this endpoint?"
              open={isModalOpen}
              onOk={handleOk}
              okText={"Register"}
              onCancel={handleCancel}
              centered
              className="pb-0 border border-gray-600 rounded-lg"
            >
              {/* Endpoint: {endpoint} */}
            </Modal>
          </AntDesignContext>
        </div>
        {webhookDetails && (
          <Spin spinning={getLoading}>
            <div className="mt-4 ">
              <span className="text-lg">Details:</span>
              <Grid container spacing={2} sx={{ mt: 0 }}>
                <Grid item xs={2}>
                  <Typography {...TextStyles} noWrap>
                    Endpoint
                  </Typography>
                  <Typography {...TextStyles} noWrap>
                    Signing Key
                  </Typography>
                </Grid>
                <Grid item xs={9}>
                  <Typography {...TextStyles} noWrap>
                    {webhookDetails?.webHookUrl}
                  </Typography>
                  <Typography {...TextStyles} noWrap>
                    {webhookDetails?.eventSigner}
                  </Typography>
                </Grid>
                <Grid item xs={1} sx={{ opacity: 0.7 }}>
                  <CopyButton text={webhookDetails?.webHookUrl} />
                  <CopyButton text={webhookDetails?.eventSigner} />
                </Grid>
              </Grid>
            </div>
          </Spin>
        )}
      </Paper>
    </div>
  );
};

export default Webhooks;

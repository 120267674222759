import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Progress, Alert } from "antd";
import { Button as MuiButton } from "@mui/material";
import AntDesignContext from "../../../common/contexts/AntDesignContext";
import useGetOnboardingStatus from "../../../modules/onboarding/hooks/useGetOnboardingStatus";
import { useGlobalContext } from "../../../common/contexts/GlobalContext";

const twoColors = {
  "0%": "#108ee9",
  "100%": "#87d068",
};

const OnboardingCard = () => {
  const navigate = useNavigate();
  const getOnboardingStatus = useGetOnboardingStatus();
  const { onboardingStatus } = useGlobalContext();

  useEffect(() => {
    getOnboardingStatus();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const progress = onboardingStatus?.currentPage
    ? onboardingStatus.currentPage * 12.5
    : 0;

  const onClickAction = () => {
    if (progress > 0) navigate("/onboarding");
    else navigate("/onboarding/welcome");
  };

  return (
    <AntDesignContext>
      {(!onboardingStatus ||
        !onboardingStatus?.status ||
        onboardingStatus?.status === "AWAITING_SUBMISSION") && (
        <div className="flex gap-10 py-6 px-6 md:px-20 bg-[#121212] rounded-md justify-between mb-4">
          <div className="flex flex-col gap-4">
            <div className="flex justify-between text-lg md:text-xl">
              Activate payments on your account
              <div className="block md:hidden">
                <Progress
                  size={"small"}
                  status="active"
                  type="circle"
                  percent={progress}
                  strokeColor={twoColors}
                  trailColor="rgba(255, 255, 255, 0.2)"
                />
              </div>
            </div>
            <div className="text-white opacity-50 ">
              Fill out the business profile to accept payments. Any progress you
              make will be saved, so you can always finish later.
            </div>
            <MuiButton
              variant="contained"
              onClick={onClickAction}
              className="w-fit"
            >
              Complete KYB
            </MuiButton>
          </div>
          <div className="hidden md:block">
            <Progress
              size={"default"}
              status="active"
              type="circle"
              percent={progress}
              strokeColor={twoColors}
              trailColor="rgba(255, 255, 255, 0.2)"
            />
          </div>
        </div>
      )}
      {onboardingStatus?.status === "VERIFYING" && (
        <div className="mb-4">
          <Alert
            message="Thank you for completing your KYB!"
            description="Your merchant is under verification, this usually takes 2-3 business days"
            type="success"
            showIcon
            closable
          />
        </div>
      )}
    </AntDesignContext>
  );
};

export default OnboardingCard;

import React from "react";
import { getUserName } from "../../../../../utils/stateFunctions";
import FilterBox from "./FilterBox";

const GreetingMessage = () => {
  return (
    <div className="flex flex-col items-center justify-between w-full gap-2 p-4 mb-4 text-xl rounded-lg md:flex-row">
      <div>Hi, {getUserName()} 👋</div>
      <FilterBox />
    </div>
  );
};

export default GreetingMessage;

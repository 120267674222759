import { Breadcrumbs, Typography } from "@mui/material";
import MerchantDetails from "./MerchantDetails";
import XPayKeys from "./XPayKeys";
import Webhooks from "./Webhooks";

const AccountSettingsPage = () => {
  return (
    <>
      <Breadcrumbs>
        <Typography color="gray">Settings</Typography>
        <Typography>Account</Typography>
      </Breadcrumbs>
      <div className="grid grid-cols-2 gap-4 mt-2">
        <XPayKeys />
        <MerchantDetails />
        <Webhooks />
      </div>
    </>
  );
};

export default AccountSettingsPage;

// src/pages/Payouts.js
import React, { useState } from "react";
import {
  Table,
  Button,
  Space,
  Typography,
  notification,
  Popconfirm,
} from "antd";
import { FaCheckCircle, FaUpload } from "react-icons/fa";
import { axiosInstance } from "./../../common/hooks/useAxios";
import { getAdminAuthToken } from "../../../utils/stateFunctions";
import captureAxiosError from "../../common/utils/captureAxiosError";
import { Modal, DatePicker, Upload } from "antd";
import dayjs from "dayjs";
import toast from "react-hot-toast";
import PayoutDrawer from "./PayoutDrawer";
import { IoOpenOutline } from "react-icons/io5";

const Payouts = ({ payouts, setPayouts, merchantId, balance, setBalance }) => {
  const [shouldShowDrawer, setShouldShowDrawer] = useState(false);
  const [payoutId, setPayoutId] = useState();
  const [loading, setLoading] = useState();
  const [payoutDetails, setPayoutDetails] = useState();

  const [untilTime, setUntilTime] = useState(
    dayjs().subtract(1, "day").endOf("day").valueOf()
  );

  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const onChange = (_, dateStr) => {
    const dateObj = dayjs(dateStr);
    setUntilTime(dateObj.valueOf());
  };

  // Mark payout as success
  const markAsSuccess = async (payoutId) => {
    const updatedPayouts = payouts.map((payout) => {
      if (payout.payoutId === payoutId) {
        return { ...payout, status: "SUCCESS" };
      }
      return payout;
    });

    try {
      await axiosInstance.post(
        "/admin/mark-payout-success",
        {
          merchantId: merchantId,
          payoutId: payoutId,
        },
        {
          headers: {
            Authorization: `Internal ${getAdminAuthToken()}`,
          },
        }
      );

      setPayouts(updatedPayouts);
      notification.success({
        message: "Payout Marked as Success",
        description: `Payout ${payoutId} has been marked as success.`,
      });
    } catch (error) {
      captureAxiosError(error);
    }
  };

  // Create Payout
  const createPayout = async () => {
    toast.loading(`Creating Payout`, {
      id: "create-payout",
    });

    try {
      await axiosInstance.post(
        "/admin/create-payout",
        {
          merchantId: merchantId,
          untilTime: untilTime,
        },
        {
          headers: {
            Authorization: `Internal ${getAdminAuthToken()}`,
          },
        }
      );

      const { data: payouts } = await axiosInstance.post(
        `/admin/get-payouts`,
        { merchantId: merchantId },
        {
          headers: {
            Authorization: `Internal ${getAdminAuthToken()}`,
          },
        }
      );

      setPayouts(payouts?.entries);

      const { data: balance } = await axiosInstance.post(
        `/admin/get-balance`,
        { merchantId: merchantId },
        {
          headers: {
            Authorization: `Internal ${getAdminAuthToken()}`,
          },
        }
      );

      setBalance(balance);

      toast.success(`Payout Created Successfully`, {
        id: "create-payout",
      });

      handleCancel();
    } catch (error) {
      toast.dismiss("create-payout");
      captureAxiosError(error);
    }
  };

  // Upload Props
  const uploadProps = {
    accept: ".pdf",
    onChange(info) {
      toast.loading(`Uploading File...`, { id: "uploading" });
      if (info.file.status !== "uploading") {
        toast.loading(`Uploading File...`, { id: "uploading" });
      }
      if (info.file.status === "done") {
        toast.success(`${info.file.name} file uploaded successfully`, {
          id: "uploading",
        });
      } else if (info.file.status === "error") {
        toast.error(`${info.file.name} file upload failed.`, {
          id: "uploading",
        });
      }
    },
  };

  // Columns for the table
  const columns = [
    {
      title: "Payout ID",
      dataIndex: "payoutId",
      key: "payoutId",
    },
    {
      title: "Initiated At",
      dataIndex: "initiatedAt",
      key: "initiatedAt",
      render: (initiatedAt) => dayjs(initiatedAt).format("DD/MM/YYYY HH:mm"),
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
      render: (amount) => <Typography.Text>$ {amount / 100}</Typography.Text>,
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (status) => <Typography.Text>{status}</Typography.Text>,
    },
    {
      title: "Actions",
      key: "actions",
      render: (text, record) => (
        <Space size="middle">
          {record.status === "INITIATED" && (
            <Popconfirm
              title="Mark this payout as success?"
              description="Are you sure to mark this payout as success?"
              onConfirm={() => markAsSuccess(record.payoutId)}
              okText="Yes"
              cancelText="No"
            >
              <Button
                type="primary"
                className="text-white bg-green-500 hover:bg-green-600"
                icon={<FaCheckCircle />}
                // onClick={() => markAsSuccess(record.payoutId)}
              >
                Mark Success
              </Button>
            </Popconfirm>
          )}
          <Upload
            {...uploadProps}
            customRequest={({ onSuccess, onError, file, onProgress }) => {
              const formData = new FormData();
              formData.append("file", file);
              formData.append("payoutId", record.payoutId);
              formData.append("merchantId", merchantId);
              formData.append("docName", file.name);
              axiosInstance
                .post(`/admin/upload-payout-doc`, formData, {
                  headers: {
                    Authorization: `Internal ${getAdminAuthToken()}`,
                  },
                  onUploadProgress: ({ total, loaded }) =>
                    onProgress({ percent: Math.round((loaded / total) * 100) }),
                })
                .then((res) => {
                  onSuccess(res.data);
                })
                .catch((err) => {
                  onError({ err });
                });
            }}
          >
            <Button
              icon={<FaUpload />}
              className="text-white bg-blue-500 hover:bg-blue-600"
            >
              Upload Docs
            </Button>
          </Upload>
          <Button
            onClick={() => getPayoutDetails(record.payoutId)}
            icon={<IoOpenOutline />}
          ></Button>
        </Space>
      ),
    },
  ];

  const getPayoutDetails = async (payoutId) => {
    setPayoutId(payoutId);
    setShouldShowDrawer(true);
    setLoading(true);
    const payload = {
      merchantId: merchantId,
      payoutId: payoutId,
    };
    try {
      const { data } = await axiosInstance.post(
        "/admin/get-one-payout",
        payload,
        {
          headers: {
            Authorization: `Internal ${getAdminAuthToken()}`,
          },
        }
      );

      setPayoutDetails(data);
      setLoading(false);
    } catch (e) {
      captureAxiosError(e);
      setLoading(false);
    }
  };

  return (
    <div className="min-h-screen p-6 bg-gray-100" id="admin-payouts">
      {/* Header with Total Balance and Create Payout Button */}
      <div className="flex items-center justify-between mb-6">
        <div>
          <div className="flex items-center gap-4">
            <div>Total Balances: </div>
            <div className="text-xl">
              $
              {(balance?.balanceAvailable + balance?.balanceAvailableSoon) /
                100}
            </div>
          </div>
          <div className="flex items-center gap-4 text-sm text-gray-600">
            <div>Balances Available: </div>
            <div className="">${balance?.balanceAvailable / 100}</div>
          </div>
          <div className="flex items-center gap-4 text-sm text-gray-600 ">
            <div>Balances Available Soon: </div>
            <div className="">${balance?.balanceAvailableSoon / 100}</div>
          </div>
        </div>
        <Button
          type="primary"
          className="text-white bg-blue-500 hover:bg-blue-600"
          onClick={showModal}
        >
          Create Payout
        </Button>
        <Modal
          title="Create Payout"
          open={isModalOpen}
          onOk={createPayout}
          onCancel={handleCancel}
          centered
        >
          <p className="mt-4">
            Select until time:
            <DatePicker
              defaultValue={dayjs().subtract(1, "day").endOf("day")}
              showTime
              disabledDate={(current) => current > dayjs().endOf("day")}
              onChange={onChange}
              className="ml-4"
            />
          </p>
          <p className="mt-4 text-sm text-gray-500">
            Epoach Time in milliseconds: {untilTime}
          </p>
        </Modal>
      </div>

      {/* Payout Table */}
      <Table
        columns={columns}
        dataSource={payouts}
        rowKey="payoutId"
      />
      {payoutDetails && (
        <PayoutDrawer
          payoutId={payoutId}
          payoutDetails={payoutDetails}
          shouldShowDrawer={shouldShowDrawer}
          setShouldShowDrawer={setShouldShowDrawer}
          loading={loading}
        />
      )}
    </div>
  );
};

export default Payouts;

import * as Sentry from "@sentry/react";
import { useEffect } from "react";
import {
  Routes,
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from "react-router-dom";
import { xPaySubdomainRegex } from "../constants/regex";
import config from "../../../config";

const initSentry = () => {
  const dsn = config.sentry_dsn;
  const environment = process.env.REACT_APP_XPAY_ENV || "dev";
  if (!dsn) {
    return;
  }
  Sentry.init({
    dsn,
    integrations: [
      new Sentry.BrowserTracing({
        routingInstrumentation: Sentry.reactRouterV6Instrumentation(
          useEffect,
          useLocation,
          useNavigationType,
          createRoutesFromChildren,
          matchRoutes
        ),
      }),
    ],
    environment,
    tracesSampleRate: 1.0,
    tracePropagationTargets: ["localhost", xPaySubdomainRegex],
  });
};

export const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);

export default initSentry;

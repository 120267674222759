import { toast } from "react-toastify";
import { axiosInstance } from "../../../common/hooks/useAxios";
import captureAxiosError from "../../../common/utils/captureAxiosError";

const useRegister = (setError) => {
  const handleRegister = async ({
    uname,
    email,
    password,
    confirmPassword,
  }) => {
    if (uname && email && password && confirmPassword) {
      if (password !== confirmPassword) {
        setError({ password: "Passwords do not match" });
        return false;
      }
      setError(null);
      try {
        const promise = axiosInstance.post("/users/register", {
          userDisplayName: uname,
          email,
          password,
          confirmPassword,
        });
        await toast.promise(promise, {
          pending: "Registering...",
          success: "Registered successfully! Redirecting...",
        });
        return true;
      } catch (e) {
        captureAxiosError(e);
        return false;
      }
    }
    if (!uname) {
      setError((state) => ({ ...state, uname: "Name is required" }));
    }
    if (!email) {
      setError((state) => ({ ...state, email: "Email is required" }));
    }
    if (!password || !confirmPassword) {
      setError((state) => ({ ...state, password: "Password is required" }));
    }
    return false;
  };

  return handleRegister;
};

export default useRegister;

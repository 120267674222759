/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import SwitchToggle from "./SwitchToggle";
import Tags from "./Tags";
import CopyBtn from "./CopyBtn";
import InfoDisplay from "./InfoDisplay";
import { ReactComponent as XPayWatermark } from "../../../../../assets/icons/xPayWatermark.svg";

const VBACard = ({ vbaDetails }) => {
  const [copyVBADetalsText, setCopyVBADetailsText] = useState();
  const [isFedWire, setIsFedWire] = useState(true);

  useEffect(() => {
    const text = `
    1. Beneficiary Name: ${vbaDetails?.beneficiaryName}
    2. Account Number: ${vbaDetails?.accountNumber}
    3. Routing Number: ${isFedWire ? vbaDetails?.fedWire : vbaDetails?.ach}
    4. Bank: JPMorgan Chase Bank, N.A
    5. Bank Address: 383 Madison Ave, New York, NY 10179, USA
    6. Currency: ${vbaDetails?.currency}
    7. Account Type: ${vbaDetails?.accountType} 
    `;
    setCopyVBADetailsText(text);
  }, [vbaDetails]);

  return (
    <div className="rounded-[20px] border border-[#252525] bg-gradient-to-tl from-[#1E1E1E] to-[#222222] p-7 flex flex-col gap-4 relative w-[35vw]">
      <div className="flex items-center justify-between gap-2">
        <SwitchToggle isFedWire={isFedWire} setIsFedWire={setIsFedWire} />
        <div className="flex items-center gap-2">
          <Tags text={vbaDetails?.currency} />
          <CopyBtn text={copyVBADetalsText} />
        </div>
      </div>
      <InfoDisplay
        label={"Beneficiary Name"}
        value={vbaDetails?.beneficiaryName}
      />
      <div className="grid grid-cols-2 gap-4">
        <InfoDisplay
          label={"Account Number"}
          value={vbaDetails?.accountNumber}
        />
        <InfoDisplay
          label={"Routing Number"}
          value={isFedWire ? vbaDetails?.fedWire : vbaDetails?.ach}
        />
      </div>
      <InfoDisplay
        label={"Bank Information"}
        value={"JPMorgan Chase Bank, N.A"}
        description={"383 Madison Ave, New York, NY 10179, USA"}
      />

      <XPayWatermark className="absolute right-4 bottom-4" />
    </div>
  );
};

export default VBACard;

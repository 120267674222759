import { LandingPageProvider } from "./contexts/LandingPageContext";
import Main from "./components/Main";

const Landing = () => {
  return (
    <LandingPageProvider>
      <Main />
    </LandingPageProvider>
  );
};

export default Landing;

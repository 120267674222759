/* eslint-disable react-hooks/exhaustive-deps */
import { useState } from "react";
import { axiosInstance } from "./../../../common/hooks/useAxios";
import { getCurrentMerchantId, getStandardApiHeadersWithAuth } from "./../../../../utils/stateFunctions";
import captureAxiosError from "./../../../common/utils/captureAxiosError";
import toast from "react-hot-toast";

export const useRefundTransaction = ({
  transactionId,
  setTransactionDetails,
  setOpenDialog,
}) => {
  const [loadingCancel, setLoadingCancel] = useState();

  const refundTransaction = async () => {
    setLoadingCancel(true);
    try {
      await axiosInstance.post(
        `/payments/refund`,
        {
          merchantId: getCurrentMerchantId(),
          xIntentId: transactionId,
        },
        {
          headers: getStandardApiHeadersWithAuth(),
        }
      );

      const { data } = await axiosInstance.get(
        `/payments/dashboard/get-intent/${transactionId}`,
        {
          headers: getStandardApiHeadersWithAuth(),
        }
      );

      setTransactionDetails(data);

      toast.success("Transaction refunded successfully");
    } catch (error) {
      captureAxiosError(error);
    } finally {
      setLoadingCancel(false);
      setOpenDialog(false);
    }
  };

  return { refundTransaction, loadingCancel };
};

import { Input, Form } from "antd";
import React from "react";
import AntDesignContext from "../../../../common/contexts/AntDesignContext";
import { getValidationRules } from "../../utils/validation";

const TextAreaField = ({
  label,
  placeholder,
  name,
  required,
}) => {

  return (
    <AntDesignContext>
      <Form.Item
        hasFeedback
        label={label}
        name={name}
        validateFirst
        rules={getValidationRules(name, required, label)}
      >
        <Input.TextArea
          showCount
          autoSize={{ minRows: 2, maxRows: 2 }}
          maxLength={100}
          size="middle"
          placeholder={placeholder}
          name={name}
          className="text-white bg-[#121212] rounded-lg placeholder:text-white placeholder:opacity-50 mb-4"
          required={required}
        />
      </Form.Item>
    </AntDesignContext>
  );
};

export default TextAreaField;

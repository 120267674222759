import { axiosInstance } from "../../../common/hooks/useAxios";
import {
  getAuthToken,
  getCurrentMerchantId,
} from "../../../../utils/stateFunctions";
import { useOnboardingContext } from "../contexts/OnboardingContext";
import { formFields } from "../constants/OnboardingFields";

const useSaveOnboardingDetails = () => {
  const {
    onboardingFields,
    currentPage,
    currentBDPage,
    noOfPartners,
    partnerCards,
  } = useOnboardingContext();

  const formData = new FormData();
  if (currentPage === 0) {
    // Business Details
    formData.append("currentPage", 2);
    formData.append(
      formFields.businessType,
      onboardingFields[formFields.businessType]
    );
  } else if (currentPage === 1) {
    // Business Details
    if (currentBDPage === 0) {
      // Basic Details
      formData.append("currentPage", 3);
      formData.append(
        formFields.businessDetails.companyName,
        onboardingFields[formFields.businessDetails.companyName]
      );
      formData.append(
        formFields.businessDetails.companyEmail,
        onboardingFields[formFields.businessDetails.companyEmail]
      );
      formData.append(
        formFields.businessDetails.companyAddress.addressLine1,
        onboardingFields[formFields.businessDetails.companyAddress.addressLine1]
      );
      onboardingFields[formFields.businessDetails.companyAddress.addressLine2]
        ? formData.append(
            formFields.businessDetails.companyAddress.addressLine2,
            onboardingFields[
              formFields.businessDetails.companyAddress.addressLine2
            ]
          )
        : formData.append(
            formFields.businessDetails.companyAddress.addressLine2,
            ""
          );
      formData.append(
        formFields.businessDetails.companyAddress.country,
        onboardingFields[formFields.businessDetails.companyAddress.country]
      );
      onboardingFields[formFields.businessDetails.companyAddress.state]
        ? formData.append(
            formFields.businessDetails.companyAddress.state,
            onboardingFields[formFields.businessDetails.companyAddress.state]
          )
        : formData.append(formFields.businessDetails.companyAddress.state, "");
      onboardingFields[formFields.businessDetails.companyAddress.city]
        ? formData.append(
            formFields.businessDetails.companyAddress.city,
            onboardingFields[formFields.businessDetails.companyAddress.city]
          )
        : formData.append(formFields.businessDetails.companyAddress.city, "");
      formData.append(
        formFields.businessDetails.companyAddress.pinCode,
        onboardingFields[formFields.businessDetails.companyAddress.pinCode]
      );
    } else if (currentBDPage === 1) {
      // Description
      formData.append("currentPage", 4);
      formData.append(
        formFields.businessDetails.doingBusinessAs,
        onboardingFields[formFields.businessDetails.doingBusinessAs]
      );
      formData.append(
        formFields.businessDetails.website,
        onboardingFields[formFields.businessDetails.website]
      );
      formData.append(
        formFields.businessDetails.nameOfProduct,
        onboardingFields[formFields.businessDetails.nameOfProduct]
      );
      formData.append(
        formFields.businessDetails.productDesc,
        onboardingFields[formFields.businessDetails.productDesc]
      );
      formData.append(
        formFields.businessDetails.volume,
        onboardingFields[formFields.businessDetails.volume]
      );
      formData.append(
        formFields.businessDetails.exportPurposeCode,
        onboardingFields[formFields.businessDetails.exportPurposeCode]
      );

      const localCurrenciesNeeded =
        onboardingFields[formFields.businessDetails.localCurrenciesNeeded];

      if (localCurrenciesNeeded) {
        for (let i = 0; i < localCurrenciesNeeded.length; i++) {
          formData.append(
            formFields.businessDetails.localCurrenciesNeeded + `[${i}]`,
            localCurrenciesNeeded[i]
          );
        }
      }
    } else if (currentBDPage === 2) {
      // Registration Details
      formData.append("currentPage", 5);
      const pan = formFields.businessDetails.businessRegDoc.pan;
      const gst = formFields.businessDetails.businessRegDoc.gst;
      const tan = formFields.businessDetails.businessRegDoc.tan;
      const memoArticleAssociation =
        formFields.businessDetails.businessRegDoc.memoArticleAssociation;
      const registrationCert =
        formFields.businessDetails.businessRegDoc.registrationCert;
      const iec = formFields.businessDetails.businessRegDoc.iec;

      formData.append(pan, onboardingFields[pan]);
      formData.append(gst, onboardingFields[gst]);
      formData.append(tan, onboardingFields[tan]);
      formData.append(
        memoArticleAssociation,
        onboardingFields[memoArticleAssociation]
      );
      if (onboardingFields[registrationCert]) {
        formData.append(registrationCert, onboardingFields[registrationCert]);
      }
      if (onboardingFields[iec]) {
        formData.append(iec, onboardingFields[iec]);
      }
    } else if (currentBDPage === 3) {
      // Address Proof
      formData.append("currentPage", 6);
      const rentalAgreement =
        formFields.businessDetails.addressProof.rentalAgreement;
      const utilityBill = formFields.businessDetails.addressProof.utilityBills;

      formData.append(rentalAgreement, onboardingFields[rentalAgreement]);

      if (onboardingFields[utilityBill]) {
        formData.append(utilityBill, onboardingFields[utilityBill]);
      }
    } else if (currentBDPage === 4) {
      // Account Details
      formData.append("currentPage", 7);
      formData.append(
        formFields.businessDetails.settlementAccountDetails.accountName,
        onboardingFields[
          formFields.businessDetails.settlementAccountDetails.accountName
        ]
      );
      formData.append(
        formFields.businessDetails.settlementAccountDetails.accountNumber,
        onboardingFields[
          formFields.businessDetails.settlementAccountDetails.accountNumber
        ]
      );
      formData.append(
        formFields.businessDetails.settlementAccountDetails.ifscCode,
        onboardingFields[
          formFields.businessDetails.settlementAccountDetails.ifscCode
        ]
      );

      const bankStatement =
        formFields.businessDetails.settlementAccountDetails.bankStatement;
      const cancelledCheque =
        formFields.businessDetails.settlementAccountDetails.cancelledCheque;

      formData.append(bankStatement, onboardingFields[bankStatement]);
      formData.append(cancelledCheque, onboardingFields[cancelledCheque]);
    }
  } else if (currentPage === 2) {
    // Director Details
    formData.append("currentPage", 7);

    for (let i = 0; i < noOfPartners; i++) {
      formData.append(
        formFields.directorDetails.baseName +
          `[${i}].` +
          formFields.directorDetails.nameOfPartner,
        onboardingFields[
          formFields.directorDetails.baseName +
            `[${partnerCards[i]?.id}].` +
            formFields.directorDetails.nameOfPartner
        ]
      );
      formData.append(
        formFields.directorDetails.baseName +
          `[${i}].` +
          formFields.directorDetails.linkedinOfPartner,
        onboardingFields[
          formFields.directorDetails.baseName +
            `[${partnerCards[i]?.id}].` +
            formFields.directorDetails.linkedinOfPartner
        ]
      );

      const aadharCard =
        formFields.directorDetails.baseName +
        `[${partnerCards[i]?.id}].` +
        formFields.directorDetails.aadhaarCardOfPartner;
      const panCard =
        formFields.directorDetails.baseName +
        `[${partnerCards[i]?.id}].` +
        formFields.directorDetails.panOfPartner;

      formData.append(
        formFields.directorDetails.baseName +
          `[${i}].` +
          formFields.directorDetails.aadhaarCardOfPartner,
        onboardingFields[aadharCard]
      );

      formData.append(
        formFields.directorDetails.baseName +
          `[${i}].` +
          formFields.directorDetails.panOfPartner,
        onboardingFields[panCard]
      );
    }
  }

  const saveOnboardingDetails = async () => {
    await axiosInstance.post(
      `/onboarding/${getCurrentMerchantId()}`,
      formData,
      {
        headers: {
          Authorization: `Bearer ${getAuthToken()}`,
          "Content-Type": "multipart/form-data",
        },
      }
    );
  };

  return saveOnboardingDetails;
};

export default useSaveOnboardingDetails;

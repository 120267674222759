/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { axiosInstance } from "../../../common/hooks/useAxios";
import captureAxiosError from "../../../common/utils/captureAxiosError";
import { getStandardApiHeadersWithAuth } from "../../../../utils/stateFunctions";
import useGlobalStore from "../../../common/store/GlobalStore";

export const useGetWebhook = ({ loading }) => {
  const [getLoading, setGetLoading] = useState(false);
  const [webhookDetails, setWebhookDetails] = useState(null);
  const { mode } = useGlobalStore();

  useEffect(() => {
    const getWebhook = async () => {
      setGetLoading(true);
      try {
        const { data } = await axiosInstance.post(
          `/webhook/get`,
          {
            mode: mode,
          },
          {
            headers: getStandardApiHeadersWithAuth(),
          }
        );
        setWebhookDetails(data);
      } catch (e) {
        captureAxiosError(e);
      } finally {
        setGetLoading(false);
      }
    };

    getWebhook();
  }, [loading, mode]);

  return { getLoading, webhookDetails };
};

import { Navigate, useLocation } from "react-router-dom";
import {
  getCurrentMerchantId,
  getEmail,
  getUserId,
  getUserName,
  isLoggedIn,
} from "../../../utils/stateFunctions";
import { isEmpty } from "lodash";
import { setContext } from "@sentry/react";

const withAuthHOC = (WrappedComponent) => {
  const ComponentWithAuth = (props) => {
    const { pathname } = useLocation();
    const { isValidAuth, redirect } = checkAuthAndRedirect();
    if (!isValidAuth && pathname !== redirect) {
      return <Navigate to={redirect} />;
    }
    setSentryContext();
    return <WrappedComponent {...props} />;
  };
  return ComponentWithAuth;
};

const checkAuthAndRedirect = () => {
  // Check token expiry and existence
  const isTokenValid = isLoggedIn();
  if (!isTokenValid) {
    return { isValidAuth: false, redirect: "/login" };
  }
  // Check if merchant is registered
  const isMerchantRegistered = !isEmpty(getCurrentMerchantId());
  if (!isMerchantRegistered) {
    return { isValidAuth: false, redirect: "/merchant-register" };
  }


  return { isValidAuth: true, redirect: "" };
};

const setSentryContext = () => {
  setContext("user", {
    id: getUserId(),
    merchantId: getCurrentMerchantId(),
    name: getUserName(),
    email: getEmail(),
  });
};

export default withAuthHOC;

import { BrowserRouter, Route } from "react-router-dom";
import {
  Login,
  Logout,
  MerchantRegister,
  UserRegister,
  ForgotPasswordForm,
  NewPasswordForm,
  VerifyEmail,
} from "../v1/modules/authentication";
import Transactions from "../v1/modules/transactions";
import AccountSettings from "../v1/modules/accountSettings";
import Landing from "../v1/modules/home";
import withNavbar from "../v1/common/HOCs/withNavbarHOC";
import withAuth from "../v1/common/HOCs/withAuthHOC";
import withAdminAuthHOC from "../v1/common/HOCs/withAdminAuthHOC";
import PageNotFound404 from "../v1/common/components/ErrorHandling/PageNotFound404";
import { SentryRoutes } from "../v1/common/utils/initSentry";
import Payments from "../v1/modules/payments";
import PaymentsV2 from "../v1/modules/paymentsV2/Payments";
import UserDetails from "../v1/modules/accountSettings/components/UserDetails";
import Balance from "../v1/modules/balance/Balance";
import Onboarding from "../v1/modules/onboarding/Onboarding";
import AdminDashboard from "../v1/modules/adminDashboard/AdminDashboard";
import AdminLogin from "../v1/modules/adminDashboard/authentication/AdminLogin";
import OnboardingDashboard from "../v1/modules/adminDashboard/OnboardingDashboard";
import MerchantDashboard from "../v1/modules/adminDashboard/MerchantDashboard";
import Subscription from "../v1/modules/subscription/Subscription";
import VirtualAccounts from "../v1/modules/virtualAccounts/VirtualAccounts";
import XpayGlobe from "../xPayGlobe/XpayGlobe";

const NavRoutes = () => {
  return (
    <BrowserRouter>
      <SentryRoutes>
        {/* Auth Routes  */}
        <Route path="/login" Component={Login} />
        <Route path="/logout" Component={Logout} />
        <Route path="/user-register" Component={UserRegister} />
        <Route path="/forget-password" Component={ForgotPasswordForm} />
        <Route path="/verify-email/:tokenId" Component={VerifyEmail} />
        <Route path="/forgot-password/:tokenId" Component={NewPasswordForm} />
        <Route
          path="/merchant-register"
          Component={withAuth(MerchantRegister)}
        />

        {/* Onboarding Routes */}
        <Route path="/onboarding" Component={withAuth(Onboarding)} />
        <Route path="/onboarding/:greeting" Component={withAuth(Onboarding)} />

        {/* Landing Page */}
        <Route path="/" Component={withAuth(withNavbar(Landing))} />

        {/* User Settings Routes */}
        <Route
          path="/settings"
          Component={withAuth(withNavbar(AccountSettings))}
        />
        <Route
          path="/user-profile"
          Component={withAuth(withNavbar(UserDetails))}
        />

        {/* Dashboard Routes */}
        <Route
          path="/transactions"
          Component={withAuth(withNavbar(Transactions))}
        />
        <Route path="/payments" Component={withAuth(withNavbar(PaymentsV2))} />
        <Route path="/payments/old" Component={withAuth(withNavbar(Payments))} />
        <Route path="/balances" Component={withAuth(withNavbar(Balance))} />
        <Route
          path="/subscriptions"
          Component={withAuth(withNavbar(Subscription))}
        />
        <Route
          path="/virtual-accounts"
          Component={withAuth(withNavbar(VirtualAccounts))}
        />

        {/* Other/Miscellaneous Routes */}
        <Route path="*" Component={PageNotFound404} />

        {/* Admin Dashboard */}
        <Route path="/admin/login" Component={AdminLogin} />
        <Route
          path="/admin/dashboard"
          Component={withAdminAuthHOC(AdminDashboard)}
        />
        <Route
          path="/admin/onboarding-details"
          Component={withAdminAuthHOC(OnboardingDashboard)}
        />
        <Route
          path="/admin/merchant-details"
          Component={withAdminAuthHOC(MerchantDashboard)}
        />

        {/* xPay Globe */}
        <Route
          path="/globe"
          Component={XpayGlobe}
        />
      </SentryRoutes>
    </BrowserRouter>
  );
};

export default NavRoutes;

import React, { useState } from "react";
import AntDesignContext from "./../../../common/contexts/AntDesignContext";
import {
  Drawer as AntDrawer,
  Divider,
  Timeline,
  Button,
  Modal,
  Tabs,
  Collapse,
} from "antd";
import { useNavigate } from "react-router-dom";
import { useSubscriptionContext } from "../contexts/SubscriptionContext";
import { useGetSubscription } from "../hooks/useGetSubscription";
import { useCancelSubscription } from "../hooks/useCancelSubscription";

// Utilities
import { isEmpty } from "lodash";
import { copyToClipboard } from "../../../common/utils/copyToClipboard";
import { useGlobalContext } from "../../../common/contexts/GlobalContext";
import ParseDateTimeTz from "../../../common/components/UI/ParseDateTimeTz";
import ParseAmount from "../../../common/components/UI/ParseAmount";

// Icons
import { IoClose } from "react-icons/io5";
import { GrTransaction } from "react-icons/gr";
import { BsCopy } from "react-icons/bs";
import { FaCheck } from "react-icons/fa6";
import { RxCross2 } from "react-icons/rx";
import { FaRegClock } from "react-icons/fa";
import { HiOutlineReceiptRefund } from "react-icons/hi2";
import { TbBookmarkQuestion } from "react-icons/tb";
import { TbProgress } from "react-icons/tb";
import { FaUserCircle } from "react-icons/fa";

const SubscriptionDrawer = () => {
  const { isMobile } = useGlobalContext();
  const { showDrawer, setShowDrawer, subscriptionId } =
    useSubscriptionContext();
  const [openDialog, setOpenDialog] = useState(false);
  const [activeKey, setActiveKey] = useState("1");
  const navigate = useNavigate();

  const {
    subscriptionDetails,
    setSubscriptionDetails,
    isLoadingTxn,
    subsTrans,
  } = useGetSubscription({
    subscriptionId,
  });

  const { cancelSubscription, loadingCancel } = useCancelSubscription({
    subscriptionId,
    setSubscriptionDetails,
    setOpenDialog,
  });

  const hideDrawer = () => {
    setShowDrawer(false);
    setActiveKey("1");
  };

  const cancelSubscriptionHandler = () => {
    cancelSubscription();
  };

  const openCancelDrawer = () => {
    setOpenDialog(true);
  };

  const handleNo = () => {
    setOpenDialog(false);
  };

  const transactionsDetails = [
    {
      title: "Status",
      value: (
        <div
          className={`flex gap-1 items-center justify-center px-2 rounded ${
            {
              ACTIVE: "bg-[#20BD96]",
              INACTIVE: "bg-[#e0343c]",
              CREATED: "bg-gray-600",
              INCOMPLETE: "bg-gray-600",
              CANCELLED: "bg-yellow-600",
            }[subscriptionDetails?.status] || ""
          }`}
        >
          {
            {
              ACTIVE: <FaCheck size={16} />,
              FAILED: <RxCross2 size={18} />,
              CREATED: <FaRegClock size={15} />,
              INCOMPLETE: <FaRegClock size={15} />,
              CANCELLED: <HiOutlineReceiptRefund size={15} />,
            }[subscriptionDetails?.status]
          }
          {subscriptionDetails?.status || "Unknown status"}
        </div>
      ),
    },
    {
      title: "Created at",
      value: (
        <div className="flex items-center justify-center gap-2">
          <ParseDateTimeTz
            dateTime={subscriptionDetails?.createdAt}
            onlyDate={false}
          />
        </div>
      ),
    },
    {
      title: "Frequency",
      value: (
        <div className="flex items-center justify-center gap-2">
          Every {subscriptionDetails?.intervalCount}{" "}
          {subscriptionDetails?.interval.toLowerCase()}
          {subscriptionDetails?.intervalCount > 1 ? "s" : ""} for{" "}
          {subscriptionDetails?.cycleCount} cycle
          {subscriptionDetails?.cycleCount > 1 ? "s" : ""}
        </div>
      ),
    },
    {
      title: "Next Payment",
      value: (
        <div className="flex items-center justify-center gap-2">
          {subscriptionDetails?.nextPaymentDate ? (
            <ParseDateTimeTz
              dateTime={subscriptionDetails?.nextPaymentDate}
              onlyDate
            />
          ) : (
            "-"
          )}
        </div>
      ),
    },
    {
      title: "Remaining Cycles",
      value: (
        <div className="flex items-center justify-center gap-2">
          {subscriptionDetails?.remainingCycleCount || "0"}
        </div>
      ),
    },
    {
      title: "Receipt id",
      value: subscriptionDetails?.receiptId || "-",
    },
    {
      title: "Description",
      value: subscriptionDetails?.description || "-",
    },
    subscriptionDetails?.presentmentCurrency &&
      subscriptionDetails?.presentmentCurrency !==
        subscriptionDetails?.currency && {
        title: "Paid in",
        value: subscriptionDetails?.presentmentCurrency,
      },
  ].filter(Boolean);

  let transactionTimeline = [];

  const billingDetailsContent = [
    {
      title: "Name",
      value: subscriptionDetails?.billingDetails?.name || "-",
    },
    {
      title: "Email",
      value: subscriptionDetails?.billingDetails?.email || "-",
    },
  ];

  const billingDetails = [
    {
      key: "1",
      label: <div className="opacity-50">Customer details</div>,
      children: (
        <div>
          {billingDetailsContent.map((item, index) => {
            return (
              <div key={index} className="flex justify-between py-2">
                <span className="opacity-50">{item?.title}</span>{" "}
                <span className="pr-2 font-medium text-ellipsis">
                  {item?.value}
                </span>
              </div>
            );
          })}
        </div>
      ),
      extra: (
        <div className="flex items-center gap-2 font-medium">
          <FaUserCircle />
          {subscriptionDetails?.billingDetails?.name}
        </div>
      ),
    },
  ];

  subscriptionDetails?.eventHistoryList?.forEach((event, index) => {
    transactionTimeline.push({
      color:
        event?.status === "ACTIVE"
          ? "#20BD96"
          : event?.status === "INACTIVE"
          ? "#e0343c"
          : event?.status === "CREATED"
          ? "#2563eb"
          : event?.status === "CANCELLED"
          ? "#ca8a04"
          : event?.status === "INCOMPLETE"
          ? "#4b5563"
          : " #4b5563 ",
      children: (
        <>
          <div
            className={`flex flex-col ${
              subscriptionDetails?.eventHistoryList?.length - 1 === index
                ? ""
                : "opacity-50"
            }`}
          >
            <div className={`font-medium`}>{event?.status}</div>
            <div>
              <ParseDateTimeTz dateTime={event?.eventTime} />
            </div>
            <div>{event?.message}</div>
          </div>
        </>
      ),
    });
  });

  const onTabChange = (key) => {
    setActiveKey(key);
  };

  const tabs = [
    {
      key: "1",
      label: "Timeline",
      children: (
        <div className="">
          {transactionTimeline?.length > 0 ? (
            <div id="transactionTimeline">
              <Timeline
                mode="left"
                className="py-4"
                items={transactionTimeline}
              />
            </div>
          ) : (
            <div className="py-4 opacity-50">No Events till now!</div>
          )}
        </div>
      ),
    },

    {
      key: "2",
      label: "Transactions",
      children: (
        <div className="">
          {subsTrans?.length ? (
            <>
              <div
                className="absolute top-[-40px] right-0 underline text-blue-400 hover:text-blue-600 cursor-pointer"
                onClick={() =>
                  navigate(
                    `/transactions?search-by-ids=${encodeURIComponent(
                      JSON.stringify(subscriptionDetails?.intentIds)
                    )}`
                  )
                }
              >
                Show all
              </div>
              <div className="flex flex-col gap-2 overflow-y-auto">
                {subsTrans?.map((record, index) => {
                  const first4 = record.xtxnId.slice(0, 4);
                  const last4 = record.xtxnId.slice(-4);

                  const displayId = `${first4}....${last4}`;
                  return (
                    <div
                      key={index}
                      className="flex flex-col items-start justify-start p-4 bg-[#242424] rounded-lg"
                    >
                      <div className="flex justify-between w-full">
                        {/* Amount */}
                        <div className="text-lg font-medium">
                          <ParseAmount
                            amount={record?.amount}
                            currency={record?.currency}
                            currencySize="sm"
                          />
                        </div>
                        {/* Status */}
                        <div
                          className={`flex gap-1 items-center justify-center px-2 rounded text-xs ${
                            {
                              SUCCESS: "bg-green-800",
                              FAILED: "bg-red-700",
                              CREATED: "bg-gray-600",
                              INCOMPLETE: "bg-gray-800",
                              REFUNDED: "bg-blue-800",
                              DISPUTED: "bg-yellow-800",
                            }[record?.transactionStatus] || ""
                          }`}
                        >
                          {
                            {
                              SUCCESS: <FaCheck size={16} />,
                              FAILED: <RxCross2 size={18} />,
                              CREATED: <FaRegClock size={15} />,
                              INCOMPLETE: <FaRegClock size={15} />,
                              REFUNDED: <HiOutlineReceiptRefund size={15} />,
                              DISPUTED: <TbBookmarkQuestion size={15} />,
                            }[record?.transactionStatus]
                          }
                          {record?.transactionStatus || "Unknown status"}
                        </div>
                      </div>
                      <div className="flex justify-between w-full pt-4 item-center">
                        {/* intentId */}
                        <div
                          className="flex items-center justify-center gap-2 text-sm cursor-pointer opacity-70 hover:text-blue-400 hover:opacity-100"
                          onClick={() => copyToClipboard(record.xtxnId)}
                        >
                          {displayId} <BsCopy size={16} />
                        </div>
                        {/* Date */}
                        <div className="flex flex-col items-center justify-center gap-2 text-sm opacity-50">
                          <ParseDateTimeTz
                            dateTime={record?.xtxnStartTime}
                            onlyDate={true}
                          />
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </>
          ) : (
            <div className="py-4 opacity-50">No recent transactions!</div>
          )}
        </div>
      ),
    },
  ];

  const renderContent = () => {
    if (isLoadingTxn) {
      return <TbProgress className="animate-spin" />;
    }

    if (isEmpty(subscriptionDetails)) {
      return <div>No subscription details found</div>;
    }

    return (
      <div className="" id="subsDrawers">
        <Modal
          open={openDialog}
          title="Cancel Subscription"
          okText="Yes"
          cancelText="No"
          onOk={cancelSubscriptionHandler}
          confirmLoading={loadingCancel}
          onCancel={handleNo}
          centered
        >
          <p>Are you sure you want to cancel the subscription?</p>
        </Modal>

        {/* Header */}
        <div className="flex items-center justify-between text-2xl font-medium">
          Subscription Details{" "}
          <IoClose
            onClick={hideDrawer}
            className="opacity-50 cursor-pointer hover:opacity-100"
          />
        </div>

        {/* Status Header */}
        <div className="py-2">
          {/* Header */}
          <div className="">
            <div className="flex items-center justify-start gap-4 py-4">
              <GrTransaction
                className="p-4 bg-black rounded-full drop-shadow-xl"
                size={50}
              />
              <div className="flex flex-col w-full">
                <div className="opacity-50">Amount</div>
                <div className="flex items-start justify-between gap-4">
                  <div className="text-3xl font-semibold">
                    <ParseAmount
                      amount={subscriptionDetails?.amount || 0}
                      currency={subscriptionDetails?.currency}
                      currencySize="2xl"
                      divideBy100={true}
                    />
                  </div>
                  <div>
                    {subscriptionDetails.status === "ACTIVE" && (
                      <Button
                        className="drop-shadow-xl"
                        loading={loadingCancel}
                        onClick={openCancelDrawer}
                      >
                        Cancel
                      </Button>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* intendId */}
          <div className="mt-2 p-2 bg-[#242424] rounded-md drop-shadow-xl w-fit">
            <div className="flex items-center justify-between gap-2">
              <div className="flex flex-row gap-2">
                <div className="opacity-50">Subscription Id : </div>
                <div className="opacity-80">{subscriptionId}</div>
              </div>
              <div
                className="flex items-center gap-2 text-blue-600 cursor-pointer"
                onClick={() => copyToClipboard(subscriptionId)}
              >
                <span>copy</span>
                <BsCopy />
              </div>
            </div>
          </div>
        </div>

        <Divider />
        {/* Details */}
        <div>
          <div className="text-xl font-normal">Details</div>
          <div className="py-2">
            {transactionsDetails.map((item, index) => {
              return (
                <div key={index} className="flex justify-between py-2">
                  <span className="opacity-50">{item?.title}</span>{" "}
                  <span className="font-medium">{item?.value}</span>
                </div>
              );
            })}
            {subscriptionDetails?.billingDetails && (
              <Collapse
                ghost
                items={billingDetails}
                className="mt-0"
                expandIconPosition={"end"}
              />
            )}
          </div>
        </div>

        <Divider />
        {/* Timeline */}
        <Tabs
          defaultActiveKey="1"
          activeKey={activeKey}
          items={tabs}
          onChange={onTabChange}
          size="small"
          type="line"
          // centered
          animated={{ inkBar: true, tabPane: true }}
          tabBarStyle={{ color: "white" }}
        />
      </div>
    );
  };

  return (
    <AntDesignContext>
      <AntDrawer
        zIndex={1100}
        className="bg-white rounded-xl"
        open={showDrawer}
        onClose={hideDrawer}
        closeIcon={false}
        width={isMobile ? "85%" : "30%"}
      >
        {renderContent()}
      </AntDrawer>
    </AntDesignContext>
  );
};

export default SubscriptionDrawer;

import { Button, Stack, TextField } from "@mui/material";
import { useRef, useState } from "react";
import { LoadingButton } from "@mui/lab";
import { has } from "lodash";
import { StdTextfieldProps } from "./LoginForm";
import { useNavigate } from "react-router-dom";
import LayoutContainerWithBg from "../../../common/components/UI/LayoutContainerWithBg";
import useForgotPasswordTrigger from "../hooks/useForgotPasswordTrigger";
import { validateEmail } from "../../../common/utils/validators";

const ForgotPasswordForm = () => {
  const emailRef = useRef(null);

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const handleForgotPassword = useForgotPasswordTrigger(setError);
  const navigate = useNavigate();

  const redirectAfterSuccess = () =>
    setTimeout(() => {
      navigate("/login");
    }, 2000);

  const validateInput = (inputName, inputValue) => {
    const validationErrors = { ...error };
    delete validationErrors[inputName];

    const emailError = validateEmail(inputValue);
    if (emailError) {
      validationErrors.email = emailError;
    }

    setError(validationErrors);
  };

  const handleInputChange = (event) => {
    const inputName = event.target.name;
    const inputValue = event.target.value.toLowerCase();
    validateInput(inputName, inputValue);
  };

  const handleSubmit = async () => {
    const email = emailRef.current?.value.toLowerCase();

    const validationErrors = { ...error };
    if (Object.keys(validationErrors).length > 0) {
      setIsLoading(false);
      return;
    }

    setIsLoading(true);

    const isSuccess = await handleForgotPassword(email);

    if (isSuccess) {
      redirectAfterSuccess();
    }
    setIsLoading(false);
  };

  return (
    <LayoutContainerWithBg>
      <Stack gap={2} my={4}>
        <TextField
          label="Email"
          name="email"
          {...StdTextfieldProps}
          inputRef={emailRef}
          error={has(error, "email")}
          helperText={error?.email ?? null}
          onChange={handleInputChange}
          value={emailRef.current?.value.toLowerCase()}
        />
      </Stack>
      <Stack gap={2}>
        <LoadingButton
          loading={isLoading}
          fullWidth
          variant="contained"
          onClick={handleSubmit}
        >
          Request reset link
        </LoadingButton>
        <Button
          fullWidth
          sx={{
            textTransform: "none",
          }}
          onClick={() => navigate("/login")}
        >
          Back to Login
        </Button>
      </Stack>
    </LayoutContainerWithBg>
  );
};

export default ForgotPasswordForm;

export const filterPresetsOptions = [
  {
    value: "Today",
    label: "Today",
  },
  {
    value: "Yesterday",
    label: "Yesterday",
  },
  {
    value: "Last 7 days",
    label: "Last 7 days",
  },
  {
    value: "Last 30 days",
    label: "Last 30 days",
  },
  {
    value: "Custom",
    label: "Custom",
  },
];

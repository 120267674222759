import React from "react";
import { Stack, Typography } from "@mui/material";

const NotFound = () => {
  return (
    <div>
      <Stack my={3} gap={2}>
        <Typography variant="h2" textAlign="center">
          🔍
        </Typography>
        <Typography variant="h3" textAlign="center">
          Page not found
        </Typography>
        <Typography textAlign="center" color="gray">
          Please check the link you are trying to visit
        </Typography>
      </Stack>
    </div>
  );
};

export default NotFound;

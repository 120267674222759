import dayjs from "../../../../utils/CustomDayjs";
import {
  getCurrentMerchantId,
  getStandardApiHeadersWithAuth,
  getTimezone,
} from "../../../../utils/stateFunctions";
import { axiosInstance } from "../../../common/hooks/useAxios";

class BalanceRepository {
  constructor() {
    this.baseUrlEndpoint = `/balance`;
  }

  async getBalance(mode) {
    const playload = {
      mode: mode,
    };

    const { data } = await axiosInstance.post(
      `${this.baseUrlEndpoint}/get-balance/${getCurrentMerchantId()}`,
      playload,
      {
        headers: getStandardApiHeadersWithAuth(),
      }
    );

    return data;
  }

  async getAllPayouts(mode) {
    const playload = {
      untilTime: dayjs().tz(getTimezone()).valueOf(),
      mode: mode,
    };

    const { data } = await axiosInstance.post(
      `${this.baseUrlEndpoint}/${getCurrentMerchantId()}/get-all-payouts`,
      playload,
      {
        headers: getStandardApiHeadersWithAuth(),
      }
    );
    return data;
  }

  async getOnePayout(payoutId) {
    const playload = {
      payoutId: payoutId,
    };

    const { data } = await axiosInstance.post(
      `${this.baseUrlEndpoint}/${getCurrentMerchantId()}/get-one-payout`,
      playload,
      {
        headers: getStandardApiHeadersWithAuth(),
      }
    );

    // add a delay of 5 seconds to avoid hitting rate limit
    await new Promise(resolve => setTimeout(resolve, 500));

    return data;
  }

  async createPayout(mode) {
    const playload = {
      mode: mode,
      untilTime: dayjs().tz(getTimezone()).subtract(1, "day").valueOf(),
    };
    const { data } = await axiosInstance.post(
      `${this.baseUrlEndpoint}/${getCurrentMerchantId()}/create-payout`,
      playload,
      {
        headers: getStandardApiHeadersWithAuth(),
      }
    );
    return data;
  }
}

class BalanceService {
  constructor() {
    this.repository = new BalanceRepository();
  }

  async getBalance(mode) {
    return this.repository.getBalance(mode);
  }

  async getAllPayouts(mode) {
    return this.repository.getAllPayouts(mode);
  }

  async getOnePayout(payoutId) {
    return this.repository.getOnePayout(payoutId);
  }

  async createPayout(mode) {
    return this.repository.createPayout(mode);
  }
}

export default BalanceService;

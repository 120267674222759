import linkicon from "../../../../assets/img/PaymentLinks/link.png";
import { BiLinkExternal } from "react-icons/bi";
import ParseAmount from "./../../../common/components/UI/ParseAmount";
import ParseDateTimeTz from "./../../../common/components/UI/ParseDateTimeTz";

export const GeneratedLinkInfo = ({ link, linkInfo }) => {
  return (
    <div className="flex flex-col items-center justify-center gap-6 mb-8">
      <img src={linkicon} alt="link" className="w-20" />
      <div className="flex flex-col items-center gap-2">
        <span className="text-xl font-medium">Payment link created</span>
        <span className="text-sm text-gray-300">
          Your payment link successfully created for{" "}
          <strong>
            <ParseAmount
              amount={linkInfo.amount}
              currency={linkInfo.currency}
            />{" "}
          </strong>
          request.
        </span>
      </div>
      <div className="flex justify-between w-full gap-4 p-4 bg-black border border-gray-700 rounded-md">
        <span>{link}</span>
        <a
          href={link}
          target="_blank"
          className="flex items-center gap-1 text-sm text-blue-400"
          rel="noreferrer"
        >
          <span>Open link</span> <BiLinkExternal />
        </a>
      </div>
      <div>
        <span className="text-sm text-gray-300">
          Your payment link expires on{" "}
          <strong>
            <ParseDateTimeTz dateTime={linkInfo.expiryDate} onlyDate />
          </strong>
          .
        </span>
      </div>
      {linkInfo?.description && (
        <div className="flex flex-col items-start w-full gap-3">
          <span className="">Description</span>
          <p className="w-full p-4 bg-black border border-gray-700 rounded-md">
            {linkInfo?.description}
          </p>
        </div>
      )}
    </div>
  );
};

import { useShallow } from "zustand/react/shallow";
import { LinearProgress } from "@mui/material";
import GenericFilter from "../../../../common/components/filters/GenericFilter";
import { PaymentLinkFilterTypes as FilterTypes } from "../../constants/entities";
import useGetFilterValues from "../../hooks/useGetFilterValues";
import DateRangePicker from "../../../../common/components/filters/DateRangePicker";
import useLinkRecordsStore from "../../store/PaymentLinkRecordsStore";

const LinksListFilters = () => {
  const { filterValues, isLoading } = useGetFilterValues();
  const {
    status,
    startDate,
    endDate,
    setStatusFilter,
    setDurationStart,
    setDurationEnd,
  } = useLinkRecordsStore(useShallow(selectStore));

  if (isLoading) {
    return <LinearProgress />;
  }

  if (!filterValues) {
    return null;
  }

  return (
    <>
      <DateRangePicker
        startDate={startDate}
        endDate={endDate}
        setStartDate={setDurationStart}
        setEndDate={setDurationEnd}
      />
      <GenericFilter
        type={FilterTypes.STATUS_FILTER}
        values={filterValues[FilterTypes.STATUS_FILTER]}
        label="Status"
        selectedValues={status}
        setSelectedValues={setStatusFilter}
      />
    </>
  );
};

const selectStore = (state) => ({
  status: state.status,
  currency: state.currency,
  startDate: state.duration.fromDate,
  endDate: state.duration.toDate,
  setStatusFilter: state.setStatusFilter,
  setCurrencyFilter: state.setCurrencyFilter,
  setDurationStart: state.setDurationStart,
  setDurationEnd: state.setDurationEnd,
});

export default LinksListFilters;

import React from "react";
import { ReactComponent as CopyIcon } from "../../../../../assets/icons/copyIcon.svg";
import toast from "react-hot-toast";
import { Tooltip } from "antd";

const CopyBtn = ({ text }) => {
  const copyToClipboard = () => {
    navigator.clipboard.writeText(text);
    toast.success("Copied to clipboard");
  };

  return (
    <Tooltip title="Copy all details">
    <div
      className="px-4 py-2 text-white bg-[#282828] text-xs rounded-full w-fit cursor-pointer"
      onClick={copyToClipboard}
    >
      {" "}
      <CopyIcon />
    </div>
    </Tooltip>
  );
};

export default CopyBtn;

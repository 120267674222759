import {
  Checkbox,
  ListItemText,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
} from "@mui/material";
import { useEffect } from "react";

const DROPDOWN_WIDTH = 200;

const GenericFilter = ({
  type,
  values,
  label,
  selectedValues,
  setSelectedValues,
  width = DROPDOWN_WIDTH,
  disabled = false,
}) => {
  const valuesMap = buildValuesMap(values);

  const handleChange = (event) => {
    const value = event.target.value;
    if (value.includes("All")) {
      if (selectedValues.length === values.length) {
        setSelectedValues([]);
        return;
      }
      setSelectedValues(Object.keys(valuesMap));
      return;
    }
    setSelectedValues(value);
  };

  const renderValue = (selected) => {
    if (selected.length === values.length) {
      return "All";
    }
    return selected.map((value) => valuesMap[value]).join(", ");
  };

  useEffect(() => {
    setSelectedValues(Object.keys(valuesMap));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <FormControl size="small" disabled={disabled}>
      <InputLabel id={`generic-filter-${type}-label`}>{label}</InputLabel>
      <Select
        labelId={`generic-filter-${type}-label`}
        variant="outlined"
        multiple
        value={selectedValues}
        onChange={handleChange}
        sx={{
          width,
        }}
        renderValue={renderValue}
        label={label}
      >
        <MenuItem value="All" sx={{ p: 0 }}>
          <Checkbox checked={selectedValues.length === values.length} />
          <ListItemText primary="All" />
        </MenuItem>
        {values.map(({ id, displayName }) => (
          <MenuItem key={id} value={id} sx={{ p: 0 }}>
            <Checkbox checked={selectedValues.indexOf(id) > -1} />
            <ListItemText primary={displayName} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

const buildValuesMap = (values) =>
  values.reduce((acc, { id, displayName }) => {
    acc[id] = displayName;
    return acc;
  }, {});

export default GenericFilter;

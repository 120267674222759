import { create } from "zustand";
import { SortOrder } from "../../../common/constants/entities";
import PaymentLinkService from "../services/PaymentLinkService";
import captureAxiosError from "../../../common/utils/captureAxiosError";
import dayjs from "../../../../utils/CustomDayjs";
import { getTimezone } from "../../../../utils/stateFunctions";

const initialState = {
  records: [],
  page: 1,
  pageSize: 7,
  sortField: null,
  sortOrder: SortOrder.DESC,
  isLoading: false,
  searchQuery: "",
  status: [],
  currency: [],
  duration: {
    fromDate: dayjs().tz(getTimezone()).subtract(0, "d"),
    toDate: dayjs().tz(getTimezone()),
  },
};

const useLinkRecordsStore = create((set, get) => ({
  ...initialState,

  setLoading: (isLoading) =>
    set({
      isLoading,
    }),

  setStatusFilter: (newStatus) => {
    set({
      status: newStatus,
    });
  },

  setCurrencyFilter: (newCurrency) => {
    set({
      currency: newCurrency,
    });
  },

  setDurationStart: (fromDate) => {
    set((state) => ({
      duration: {
        ...state.duration,
        fromDate,
      },
    }));
  },

  setDurationEnd: (toDate) => {
    set((state) => ({
      duration: {
        ...state.duration,
        toDate,
      },
    }));
  },

  setSearchQuery: (searchQuery) =>
    set({
      searchQuery,
    }),

  resetPage: () =>
    set({
      page: 1,
    }),

  setPage: async (page, mode) => {
    set({
      page,
    });
    get().fetchRecords(mode);
  },

  setSort: async (sortField, sortOrder, mode) => {
    set({
      sortField,
      sortOrder,
    });
    get().fetchRecords(mode);
  },

  getMetadata: () => {
    const { page, pageSize, sortField, sortOrder } = get();
    return {
      page,
      pageSize,
      sortField,
      sortOrder,
    };
  },

  getFilterQuery: () => {
    const { duration, status, currency } = get();
    return {
      duration,
      status,
      currency,
    };
  },

  fetchRecords: async (mode) => {
    const { setLoading, getMetadata, searchQuery, getFilterQuery } = get();
    const metadata = getMetadata();
    const filterQuery = getFilterQuery();
    metadata.mode = mode;
    filterQuery.searchQuery = searchQuery;

    try {
      setLoading(true);
      const service = new PaymentLinkService();
      const records = await service.fetchPaymentLinks(filterQuery, metadata);
      set({
        records,
      });
    } catch (error) {
      captureAxiosError(error);
    } finally {
      setLoading(false);
    }
  },
}));

export default useLinkRecordsStore;

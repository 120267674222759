import React, { useEffect } from "react";
import PartnerCard from "./PartnerCard";
import { IoMdPersonAdd } from "react-icons/io";
import { MdDeleteOutline } from "react-icons/md";
import { useOnboardingContext } from "../../../contexts/OnboardingContext";

const DirectorDetails = () => {
  const { noOfPartners, setNoOfPartners, partnerCards, setPartnerCards } = useOnboardingContext();
  const MAX_PARTNER = 4;

  useEffect(() => {
    const newCards = Array.from(
      { length: noOfPartners - 2 },
      (_, i) => i + 2
    ).map((id) => ({ id }));
    setPartnerCards([...partnerCards, ...newCards]);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const addPartnerCard = () => {
    if (partnerCards.length < MAX_PARTNER) {
      const newId = Math.max(...partnerCards.map((card) => card.id), 0) + 1;
      setPartnerCards([...partnerCards, { id: newId }]);
      setNoOfPartners(noOfPartners + 1);
    }
  };

  const removePartnerCard = (idToRemove) => {
    setPartnerCards(partnerCards.filter((card) => card.id !== idToRemove));
    setNoOfPartners(noOfPartners - 1);
  };

  return (
    <div>
      <div className="grid items-center justify-center grid-cols-1 gap-5 md:grid-cols-2">
        {partnerCards.map((card, index) => (
          <div key={card.id} className="relative">
            <PartnerCard partnerIndex={index} cardId={card.id} />
            {index > 1 && (
              <button
                onClick={() => removePartnerCard(card.id)}
                className="absolute px-2 py-1 text-white bg-red-500 rounded right-4 top-11 hover:bg-red-600"
                disabled={partnerCards.length <= 2}
              >
                <MdDeleteOutline />
              </button>
            )}
          </div>
        ))}
        {partnerCards.length < MAX_PARTNER && (
          <div
            className="flex items-center justify-center gap-3 p-4 border border-dashed rounded-lg cursor-pointer hover:opacity-50"
            onClick={addPartnerCard}
          >
            <IoMdPersonAdd className="flex items-center text-xl" />
            <button
              onClick={addPartnerCard}
              className="text-lg"
              disabled={partnerCards.length >= MAX_PARTNER}
            >
              Add Partner
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default DirectorDetails;

import { isEmpty } from "lodash";
import {
  getAuthToken,
  getStandardApiHeadersWithAuth,
} from "../../../../utils/stateFunctions";
import { useNavigate } from "react-router-dom";
import useAxios from "../../../common/hooks/useAxios";

const useLogout = () => {
  const navigate = useNavigate();

  const handleCleanUp = () => {
    sessionStorage.clear();
    localStorage.removeItem("authToken");
    localStorage.removeItem("userId");
    localStorage.removeItem("userName");
    localStorage.removeItem("userEmail");
    localStorage.removeItem("userAvatarColor");
    localStorage.removeItem("merchantId");
    navigate("/login");
  };

  const { callApi: initiateLogout } = useAxios({
    url: "/auth/logout",
    method: "POST",
    headers: getStandardApiHeadersWithAuth(),
  });

  const handleLogout = async () => {
    const hasAuthToken = !isEmpty(getAuthToken());
    if (!hasAuthToken) {
      handleCleanUp();
      return;
    }
    await initiateLogout();
    handleCleanUp();
  };

  return handleLogout;
};

export default useLogout;

import { createContext, useContext, useState, useEffect } from "react";
import { useGlobalContext } from "../../../common/contexts/GlobalContext";
import { getTimezone } from "../../../../utils/stateFunctions";
import dayjs from "../../../../utils/CustomDayjs";

const PaymentLinksContext = createContext({});

export const PaymentLinksProvider = (props) => {
  const [showDrawer, setShowDrawer] = useState(false);
  const [page, setPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const [xLinkId, setXLinkId] = useState();
  const [statusFilter, setStatusFilter] = useState({
    label: "Active",
    value: "ACTIVE",
    data: ["ACTIVE"],
  });
  const [oneTimeSearchByIdText, setOneTimeSearchByIdText] = useState([]);
  const [subsPage, setSubsPage] = useState(1);
  const [subsTotalCount, setSubsTotalCount] = useState(0);
  const [subsStatusFilter, setSubsStatusFilter] = useState({
    label: "Active",
    value: "ACTIVE",
    data: ["ACTIVE"],
  });
  const [subsSearchByIdText, setSubsSearchByIdText] = useState([]);
  const [newLink, setNewLink] = useState(null);
  const [newSubsLink, setNewSubsLink] = useState(null);
  const [endTime, setEndTime] = useState(
    dayjs().tz(getTimezone()).endOf("day").valueOf()
  );
  const [startTime, setStartTime] = useState(
    dayjs().tz(getTimezone()).subtract(29, "day").startOf("day").valueOf()
  );
  const [subsEndTime, setSubsEndTime] = useState(
    dayjs().tz(getTimezone()).endOf("day").valueOf()
  );
  const [subsStartTime, setSubsStartTime] = useState(
    dayjs().tz(getTimezone()).subtract(29, "day").startOf("day").valueOf()
  );

  const { timezone } = useGlobalContext();
  const pageSize = 7;

  useEffect(() => {
    setStartTime(
      dayjs().tz(getTimezone()).subtract(29, "day").startOf("day").valueOf()
    );
    setEndTime(dayjs().tz(getTimezone()).endOf("day").valueOf());
    setSubsStartTime(
      dayjs().tz(getTimezone()).subtract(29, "day").startOf("day").valueOf()
    );
    setSubsEndTime(dayjs().tz(getTimezone()).endOf("day").valueOf());
  }, [timezone]);

  useEffect(() => {
    setPage(1);
  }, [startTime, endTime, statusFilter]);

  useEffect(() => {
    setSubsPage(1);
  }, [subsStartTime, subsEndTime, subsStatusFilter]);

  return (
    <PaymentLinksContext.Provider
      value={{
        showDrawer,
        setShowDrawer,
        endTime,
        setEndTime,
        startTime,
        setStartTime,
        page,
        setPage,
        totalCount,
        setTotalCount,
        pageSize,
        statusFilter,
        setStatusFilter,
        subsSearchByIdText,
        setSubsSearchByIdText,
        newLink,
        setNewLink,
        newSubsLink,
        setNewSubsLink,
        oneTimeSearchByIdText,
        setOneTimeSearchByIdText,
        subsStatusFilter,
        setSubsStatusFilter,
        subsTotalCount,
        setSubsTotalCount,
        subsPage,
        setSubsPage,
        subsEndTime,
        setSubsEndTime,
        subsStartTime,
        setSubsStartTime,
        xLinkId,
        setXLinkId,
      }}
    >
      {props.children}
    </PaymentLinksContext.Provider>
  );
};

export const usePaymentLinksContext = () => {
  return useContext(PaymentLinksContext);
};

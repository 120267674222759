import { IconButton, Paper, Stack, Typography } from "@mui/material";
import useGenLinkFormStore from "../../store/GenerateLinkFormStore";
import { ClipboardDocumentIcon } from "@heroicons/react/24/solid";
import { copyToClipboard } from "../../../../common/utils/copyToClipboard";

const ViewPaymentLink = () => {
  const paymentLink = useGenLinkFormStore((state) => state.generatedLink);

  if (!paymentLink) {
    return <></>;
  }

  const handleCopy = () => copyToClipboard(paymentLink);

  return (
    <Paper sx={{ mt: 3 }} elevation={0}>
      <Stack p={2} gap={2}>
        <Typography>Payment link</Typography>
        <Stack direction="row" gap={1} alignItems="center">
          <IconButton onClick={handleCopy}>
            <ClipboardDocumentIcon height={16} />
          </IconButton>
          <Typography
            sx={{ overflowWrap: "break-word" }}
            variant="body2"
            color="primary"
            className="cursor-pointer" onClick={handleCopy}
          >
            {paymentLink}
          </Typography>
        </Stack>
      </Stack>
    </Paper>
  );
};

export default ViewPaymentLink;

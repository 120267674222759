import React from "react";

const Tags = ({ text }) => {
  return (
    <div className="px-1 py-[1px] text-[#9B9B9B] bg-[#282828] text-xs rounded-md w-fit border border-[#4d4d4d]">
      {text}
    </div>
  );
};

export default Tags;

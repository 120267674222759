import { getStandardApiHeaders } from "../../../../utils/stateFunctions";
import { toast } from "react-toastify";
import { axiosInstance } from "../../../common/hooks/useAxios";
import captureAxiosError from "../../../common/utils/captureAxiosError";

const useForgotPasswordTrigger = (setError) => {
  const forgotPassword = async (email) => {
    if (!email) {
      setError((state) => ({ ...state, email: "Email is required" }));
      return false;
    }
    setError(null);
    try {
      const promise = axiosInstance.post(
        "/auth/forgot-password",
        {
          email,
        },
        {
          headers: getStandardApiHeaders(),
        }
      );
      await toast.promise(promise, {
        pending: "Sending reset link...",
        success: "Reset link sent successfully!",
      });
      return true;
    } catch (e) {
      captureAxiosError(e);
    }
  };

  return forgotPassword;
};

export default useForgotPasswordTrigger;

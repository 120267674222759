import { CardDemo } from "../components/gradientCards/AnimatedCard";
import integrate from "../../../../assets/img/Landing/integrate.png";
import onboarding from "../../../../assets/img/Landing/onboarding.png";
import { Link } from "react-router-dom";

export const WelcomeSteps = [
  {
    step: 1,
    heading: "Complete your onboarding",
    description: (
      <div>
        <p>
          On your{" "}
          <Link to={"/"} className="text-[#34A1B9] hover:opacity-50">
            Home page
          </Link>
          , you will see a button to{" "}
          <strong>
            <i>COMPLETE KYB</i>
          </strong>
          . Keep your director and business docs ready 📄, then click the button
          and go through the flow to finish onboarding. For any questions, ping{" "}
          <a
            href="mailto:hello@xpaycheckout.com"
            target="_blank"
            rel="noreferrer"
            className="text-[#34A1B9] hover:opacity-50"
          >
            hello@xpaycheckout.com
          </a>
          .
        </p>
        <p className="mt-4">
          It typically takes our onboarding team <strong>2-3 days</strong> to
          finish verification. Your xPay account will remain in{" "}
          <strong>
            <i>TEST mode </i>{" "}
          </strong>{" "}
          until onboarding is complete.
        </p>
      </div>
    ),
    graphics: (
      <div className="flex items-center justify-center">
        <img src={onboarding} alt="onboarding-img" className="w-80 h-[15rem]" />
      </div>
    ),
  },
  {
    step: 2,
    heading: "Integrate xPay into your codebase or create Links",
    description: (
      <div>
        <p>
          Pick up your <strong>xPay test keys</strong> 🔑 from the{" "}
          <Link to={"/settings"} className="text-[#34A1B9] hover:opacity-50">
            account settings page
          </Link>{" "}
          and refer our{"  "}
          <a
            href="https://www.notion.so/Technical-Documentation-b3f865a2f2a74640a9f343607b043ec2?pvs=21"
            target="_blank"
            rel="noreferrer"
            className="text-[#34A1B9] hover:opacity-50"
          >
            documentation
          </a>{" "}
          to integrate xPay into your code 💻.
        </p>{" "}
        <p className="mt-4">
          Alternatively, you can <strong>collect payments</strong> into your
          xPay account by simply creating a link 🔗 in our{" "}
          <Link to={"payments"} className="text-[#34A1B9] hover:opacity-50">
            Payment Links
          </Link>{" "}
          page.
        </p>
      </div>
    ),
    graphics: (
      <div className="flex items-center justify-center">
        <img src={integrate} alt="integrate-img" className="w-80 h-[15rem]" />
      </div>
    ),
  },
  {
    step: 3,
    heading: "Collect payments from xPay",
    description: (
      <div>
        <p>
          Assuming your onboarding is <strong>complete</strong> and account is{" "}
          <strong>LIVE 🟢</strong> - you can replace your test keys with live
          keys. Avoid doing this until your first real customer payment.
        </p>
        <p className="mt-4">
          {" "}
          And <strong>voila!</strong> 🎉 You can now{" "}
          <strong>collect global payments </strong>
          with <strong>xPay!</strong>
        </p>
      </div>
    ),
    graphics: (
        <CardDemo />
    ),
  },
];

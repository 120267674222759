import { useShallow } from "zustand/react/shallow";
import useGlobalStore from "../../../../common/store/GlobalStore";
import useLinkRecordsStore from "../../store/PaymentLinkRecordsStore";
import { useEffect } from "react";
import { Button, Grid } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import LinksListFilters from "./LinksListFilters";
import LinksListSearch from "./LinksListSearch";
import { useGlobalContext } from "../../../../common/contexts/GlobalContext";

const LinksListFilterGroup = () => {
  const mode = useGlobalStore((state) => state.mode);
  const { timezone } = useGlobalContext();
  const { fetchRecords, isLoading } = useLinkRecordsStore(
    useShallow(selectStore)
  );

  useEffect(() => {
    fetchRecords(mode); // Fetch records on initial load and mode change
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mode, timezone]);

  const handleReset = () => window.location.reload();
  const handleApplyFilters = () => fetchRecords(mode);

  return (
    <Grid container spacing={2} my={3}>
      <Grid item container xs={12} gap={2}>
        <LinksListFilters />
      </Grid>
      <Grid item container xs={12} gap={2}>
        <LinksListSearch />
        <LoadingButton
          variant="outlined"
          onClick={handleApplyFilters}
          loading={isLoading}
        >
          Apply Filters
        </LoadingButton>
        <Button sx={{ ml: -1 }} onClick={handleReset}>
          Reset
        </Button>
      </Grid>
    </Grid>
  );
};

const selectStore = (state) => ({
  fetchRecords: state.fetchRecords,
  isLoading: state.isLoading,
});

export default LinksListFilterGroup;

import { Skeleton, Stack } from "@mui/material";

const ROWS = 6;

const TableSkeletonLoader = () => (
  <Stack gap={1} mt={8}>
    {Array.from(Array(ROWS).keys()).map((index) => (
      <Skeleton
        variant="rounded"
        width="100%"
        height={40}
        key={index}
        animation="wave"
      />
    ))}
  </Stack>
);

export default TableSkeletonLoader;

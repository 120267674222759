/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";
import SubsFilterBox from "./SubsFilterBox";
import SubsStatusFilter from "./SubsStatusFilter";
import { usePaymentLinksContext } from "../../contexts/PaymentLinksContext";
import { useGetSubsFilters } from "../../hooks/useGetSubsFilters";

const SubsFilter = () => {
  const { setSubsSearchByIdText } = usePaymentLinksContext();
  const { subsStatusFilters } = useGetSubsFilters();

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const searchByIds = searchParams.get("search-by-ids");
    setSubsSearchByIdText(JSON.parse(decodeURIComponent(searchByIds)));
  }, []);

  return (
    <div>
      <SubsFilterBox />
      <SubsStatusFilter statuses={subsStatusFilters} />
    </div>
  );
};

export default SubsFilter;

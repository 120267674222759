import {
  getStandardApiHeadersWithAuth,
  getCurrentMerchantId,
} from "../../../../utils/stateFunctions";
import { axiosInstance } from "../../../common/hooks/useAxios";
import captureAxiosError from "../../../common/utils/captureAxiosError";
import dayjs from "../../../../utils/CustomDayjs";
import { getTimezone } from "../../../../utils/stateFunctions";

const useGetRecentTransactionData = ({ mode }) => {
  const getRecentTransactionData = async ({ setRecentTransactions }) => {
    try {
      const response = await axiosInstance.post(
        `/transaction-page/merchant/${getCurrentMerchantId()}/filtered-transactions`,
        {
          currencies: ["USD"],
          mode: mode,
          pageSize: 3,
          startIndex: 0,
          startTime: dayjs().tz(getTimezone()).subtract(1, "year").valueOf(),
          endTime: dayjs().tz(getTimezone()).valueOf(),
        },
        {
          headers: getStandardApiHeadersWithAuth(),
        }
      );

      setRecentTransactions(response?.data?.transactions);
    } catch (error) {
      captureAxiosError(error);
    }
  };

  return { getRecentTransactionData };
};

export default useGetRecentTransactionData;

/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { axiosInstance } from "./../../../common/hooks/useAxios";
import { getStandardApiHeadersWithAuth } from "./../../../../utils/stateFunctions";
import captureAxiosError from "./../../../common/utils/captureAxiosError";

export const useGetTransaction = ({ transactionId }) => {
  const [transactionDetails, setTransactionDetails] = useState();
  const [isLoadingTxn, setIsLoadingTxn] = useState();

  useEffect(() => {
    const getTransaction = async () => {
      setIsLoadingTxn(true);
      try {
        const { data } = await axiosInstance.get(
          `/payments/dashboard/get-intent/${transactionId}`,
          {
            headers: getStandardApiHeadersWithAuth(),
          }
        );
        setTransactionDetails(data);
      } catch (error) {
        captureAxiosError(error);
      } finally {
        setIsLoadingTxn(false);
      }
    };

    if (transactionId) {
      getTransaction();
    }
  }, [transactionId]);

  return {
    transactionDetails,
    setTransactionDetails,
    isLoadingTxn,
  };
};

/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { axiosInstance } from "./../../../common/hooks/useAxios";
import {
  getStandardApiHeadersWithAuth,
  getCurrentMerchantId,
} from "./../../../../utils/stateFunctions";
import captureAxiosError from "./../../../common/utils/captureAxiosError";

export const useGetPayout = ({ payoutId }) => {
  const [payoutDetails, setPayoutDetails] = useState(null);
  const [isLoadingTxn, setIsLoadingTxn] = useState();

  useEffect(() => {
    const getPayout = async () => {
      setIsLoadingTxn(true);

      try {
        const { data } = await axiosInstance.post(
          `/balance/${getCurrentMerchantId()}/get-one-payout`,
          {
            payoutId: payoutId,
          },
          {
            headers: getStandardApiHeadersWithAuth(),
          }
        );
        setPayoutDetails(data);
      } catch (error) {
        captureAxiosError(error);
      } finally {
        setIsLoadingTxn(false);
      }
    };

    if (payoutId) {
      getPayout();
    }
  }, [payoutId]);

  return {
    payoutDetails,
    isLoadingTxn,
  };
};

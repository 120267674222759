/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { axiosInstance } from "./../../../common/hooks/useAxios";
import { getStandardApiHeadersWithAuth } from "./../../../../utils/stateFunctions";
import captureAxiosError from "./../../../common/utils/captureAxiosError";
import useGlobalStore from "../../../common/store/GlobalStore";
import { useSubscriptionContext } from "../contexts/SubscriptionContext";

export const useGetAllSubscription = () => {
  const [allSubscriptions, setAllSubscriptions] = useState();
  const [isLoading, setIsLoading] = useState();
  const { mode } = useGlobalStore();
  const {
    startTime,
    endTime,
    page,
    setTotalCount,
    pageSize,
    statusFilter,
    subsSearchByIdText,
  } = useSubscriptionContext();

  useEffect(() => {
    const getAllSubscription = async () => {
      setIsLoading(true);

      try {
        if (subsSearchByIdText?.length > 0) {
          const { data } = await axiosInstance.post(
            `/subscription-page/search-by-ids`,
            {
              searchText: subsSearchByIdText,
              pageSize: pageSize,
              startIndex: (page - 1) * pageSize,
              mode: mode,
              startTime: startTime,
              endTime: endTime,
            },
            {
              headers: getStandardApiHeadersWithAuth(),
            }
          );

          setAllSubscriptions(data?.subscriptions);
          setTotalCount(data?.totalCount);
        } else {
          const { data } = await axiosInstance.post(
            `/subscription-page/filtered-subscriptions`,
            {
              pageSize: pageSize,
              startIndex: (page - 1) * pageSize,
              mode: mode,
              startTime: startTime,
              endTime: endTime,
              statuses: statusFilter?.data,
            },
            {
              headers: getStandardApiHeadersWithAuth(),
            }
          );

          setAllSubscriptions(data?.subscriptions);
          setTotalCount(data?.totalCount);
        }
      } catch (error) {
        captureAxiosError(error);
      } finally {
        setIsLoading(false);
      }
    };

    getAllSubscription();
  }, [startTime, endTime, mode, page, statusFilter, subsSearchByIdText]);

  return { allSubscriptions, isLoading };
};

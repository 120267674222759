import React from "react";
import { PaymentLinksProvider } from "./contexts/PaymentLinksContext";
import { Tabs } from "antd";
import OneTimePaymentLink from "./OneTimePaymentLink";
import SubscriptionsLink from "./SubscriptionsLink";
import Header from "./components/Header";
import AntDesignContext from "../../common/contexts/AntDesignContext";

const onChange = (key) => {
  // console.log(key);
};

const items = [
  {
    key: "1",
    label: "One Time",
    children: <OneTimePaymentLink />,
  },
  {
    key: "2",
    label: "Subscriptions",
    children: <SubscriptionsLink />,
  },
];

const PaymentsV2 = () => {
  return (
    <PaymentLinksProvider>
      <AntDesignContext>
        <Header title="Payment Links" />
        <Tabs
          defaultActiveKey="1"
          items={items}
          onChange={onChange}
          animated={{ inkBar: true, tabPane: true }}
          tabBarStyle={{ color: "white" }}
          size="medium"
        />
      </AntDesignContext>
    </PaymentLinksProvider>
  );
};

export default PaymentsV2;

import useAxios from "../../../common/hooks/useAxios";
import {
  getCurrentMerchantId,
  getStandardApiHeadersWithAuth,
} from "../../../../utils/stateFunctions";

const useGetXKeys = (PGMode) => {
  const API_URL = `/merchant/${getCurrentMerchantId()}/mode/${PGMode}/xpay-keys`;
  return useAxios({
    url: API_URL,
    method: "GET",
    headers: getStandardApiHeadersWithAuth(),
  });
};

export default useGetXKeys;

import { useEffect } from "react";
import { Button, IconButton, Stack, TextField } from "@mui/material";
import { EyeIcon, EyeSlashIcon } from "@heroicons/react/24/solid";
import { useRef, useState } from "react";
import { LoadingButton } from "@mui/lab";
import { has } from "lodash";
import { StdTextfieldProps } from "./LoginForm";
import useRegister from "../hooks/useRegister";
import { useNavigate } from "react-router-dom";
import LayoutContainerWithBg from "../../../common/components/UI/LayoutContainerWithBg";
import {
  validateName,
  validateEmail,
  validatePassword,
  validateConfirmPassword,
} from "../../../common/utils/validators";
import {
  getCurrentMerchantId,
  isLoggedIn,
} from "../../../../utils/stateFunctions";
import { isEmpty } from "lodash";

const UserRegisterForm = () => {
  const unameRef = useRef(null);
  const emailRef = useRef(null);
  const passwordRef = useRef(null);
  const cnfPassRef = useRef(null);

  const [showPassword, setShowPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState({});

  const handleRegister = useRegister(setError);
  const navigate = useNavigate();

  const toggleShowPassword = () => setShowPassword(!showPassword);

  useEffect(() => {
    const isTokenValid = isLoggedIn();
    if (isTokenValid) {
      const isMerchantRegistered = !isEmpty(getCurrentMerchantId());
      if (!isMerchantRegistered) {
        navigate("/merchant-register");
      } else {
        navigate("/");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const redirectAfterSuccess = () =>
    setTimeout(() => {
      navigate("/login");
    }, 2000);

  const validateInput = (inputName, inputValue) => {
    const validationErrors = { ...error };
    delete validationErrors[inputName];

    switch (inputName) {
      case "uname":
        const nameError = validateName(inputValue);
        if (nameError) {
          validationErrors.uname = nameError;
        }
        break;
      case "email":
        const emailError = validateEmail(inputValue);
        if (emailError) {
          validationErrors.email = emailError;
        }
        break;
      case "password":
        const passwordError = validatePassword(inputValue);
        if (passwordError) {
          validationErrors.password = passwordError;
        }
        break;
      case "cnfPass":
        const confirmPasswordError = validateConfirmPassword(
          passwordRef.current.value,
          inputValue
        );
        if (confirmPasswordError) {
          validationErrors.cnfPass = confirmPasswordError;
        }
        break;
      default:
        break;
    }

    setError(validationErrors);
  };

  const handleInputChange = (event) => {
    const inputName = event.target.name;
    const inputValue = event.target.value;
    validateInput(inputName, inputValue);
  };

  const handleSubmit = async () => {
    const uname = unameRef.current?.value;
    const email = emailRef.current?.value;
    const password = passwordRef.current?.value;
    const confirmPassword = cnfPassRef.current?.value;

    setIsLoading(true);

    const validationErrors = { ...error };
    if (Object.keys(validationErrors).length > 0) {
      setIsLoading(false);
      return;
    }

    const isSuccess = await handleRegister({
      uname,
      email,
      password,
      confirmPassword,
    });
    if (isSuccess) {
      redirectAfterSuccess();
    } else {
      setIsLoading(false);
    }
  };

  return (
    <LayoutContainerWithBg>
      <Stack gap={2} my={6}>
        <TextField
          label="Name"
          name="uname"
          type="text"
          {...StdTextfieldProps}
          inputRef={unameRef}
          error={has(error, "uname")}
          helperText={error?.uname ?? null}
          onChange={handleInputChange}
        />
        <TextField
          name="email"
          label="Email"
          {...StdTextfieldProps}
          type="email"
          inputRef={emailRef}
          error={has(error, "email")}
          helperText={error?.email ?? null}
          onChange={handleInputChange}
          value={emailRef.current?.value.toLowerCase()}
        />
        <TextField
          error={has(error, "password")}
          helperText={error?.password ?? null}
          inputRef={passwordRef}
          name="password"
          label="Password"
          type={showPassword ? "text" : "password"}
          {...StdTextfieldProps}
          autoComplete="current-password"
          InputProps={{
            endAdornment: (
              <IconButton onClick={toggleShowPassword}>
                {showPassword ? (
                  <EyeSlashIcon height={14} />
                ) : (
                  <EyeIcon height={14} />
                )}
              </IconButton>
            ),
          }}
          onChange={handleInputChange}
        />
        <TextField
          error={has(error, "cnfPass")}
          helperText={error?.cnfPass ?? null}
          name="cnfPass"
          inputRef={cnfPassRef}
          label="Confirm Password"
          type={"password"}
          {...StdTextfieldProps}
          autoComplete="current-password"
          onChange={handleInputChange}
        />
      </Stack>
      <Stack gap={2}>
        <LoadingButton
          loading={isLoading}
          fullWidth
          variant="contained"
          onClick={handleSubmit}
        >
          Register
        </LoadingButton>
        <Button
          fullWidth
          sx={{
            textTransform: "none",
          }}
          onClick={() => navigate("/login")}
        >
          Already Registered? Login
        </Button>
      </Stack>
    </LayoutContainerWithBg>
  );
};

export default UserRegisterForm;

import { getStandardApiHeaders } from "../../../../utils/stateFunctions";
import { toast } from "react-toastify";
import { axiosInstance } from "../../../common/hooks/useAxios";
import captureAxiosError from "../../../common/utils/captureAxiosError";

const useVerify = (setError) => {
  const VerifyEmail = async (tokenId) => {
    try {
      const promise = axiosInstance.post(
        "/auth/email-verify",
        {
          tokenId,
        },
        {
          headers: getStandardApiHeaders(),
        }
      );
      await toast.promise(promise, {
        pending: "Verifying email...",
        success: "Email verified successfully!",
      });

      return true;
    } catch (e) {
      setError(true);
      captureAxiosError(e);
    }
  };

  return VerifyEmail;
};

export default useVerify;

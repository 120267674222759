import {
  Divider,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import { NavLink } from "react-router-dom";

const SideNavListItem = ({
  Icon,
  text,
  onClick = () => {},
  link = null,
  readonly = false,
}) => {
  const content = (
    <>
      <ListItemIcon className="h-5 w-5" sx={{ minWidth: 0, mr: 2 }}>
        {Icon}
      </ListItemIcon>
      <ListItemText primary={text + (readonly ? " (Disabled)" : "")} />
    </>
  );

  if (link) {
    return (
      <>
        <Divider />
        <ListItem disablePadding sx={{ opacity: 0.8 }}>
          <NavLink to={link} className="w-full">
            {({ isActive }) => (
              <ListItemButton selected={isActive}>{content}</ListItemButton>
            )}
          </NavLink>
        </ListItem>
      </>
    );
  }

  return (
    <>
      <Divider />
      <ListItem disablePadding sx={{ opacity: readonly ? 0.4 : 0.8 }}>
        <ListItemButton onClick={onClick}>{content}</ListItemButton>
      </ListItem>
    </>
  );
};

export default SideNavListItem;

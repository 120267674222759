export const SortOrder = {
  ASC: "ASC",
  DESC: "DESC",
};

export const PGMode = {
  LIVE: "LIVE",
  TEST: "TEST",
};

export const FilterTypes = {
  STATUS_FILTER: "STATUS_FILTER",
};

export const DateFilterTypes = {
  LAST_1_DAY: "Today",
  LAST_7_DAYS: "Last 7 days",
  LAST_30_DAYS: "Last 30 days",
  CUSTOM: "Custom",
};

export const TnCVersion = {
  tcversion: "1.1",
  modifiedDate: "JUNE 13, 2024"
}
import { DatePicker, Form } from "antd";
import React from "react";
import AntDesignContext from "../../../../common/contexts/AntDesignContext";
import { getValidationRules } from "../../utils/validation";

const DatePickerField = ({ name, label, required, minDate }) => {
  return (
    <AntDesignContext>
      <Form.Item
        hasFeedback
        label={label}
        name={name}
        validateFirst
        rules={getValidationRules(name, required, label)}
      >
        <DatePicker
          className="w-full"
          showToday={false}
          minDate={minDate}
          size="middle"
        />
      </Form.Item>
    </AntDesignContext>
  );
};

export default DatePickerField;

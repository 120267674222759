import {
  getStandardApiHeadersWithAuth,
  getCurrentMerchantId,
} from "../../../../utils/stateFunctions";
import { axiosInstance } from "../../../common/hooks/useAxios";
import captureAxiosError from "../../../common/utils/captureAxiosError";
import dayjs from "../../../../utils/CustomDayjs";
import { getTimezone } from "../../../../utils/stateFunctions";

const useGetRecentPaymentLinkData = ({ mode }) => {
  const getRecentPaymentLinkData = async ({ setRecentPaymentLinks }) => {
    try {
      const response = await axiosInstance.post(
        `/link/merchant/${getCurrentMerchantId()}/filtered-links`,
        {
          startTime: dayjs().tz(getTimezone()).subtract(1, "year").valueOf(),
          endTime: dayjs().tz(getTimezone()).valueOf(),
          pageSize: 3,
          startIndex: 0,
          mode: mode,
        },
        {
          headers: getStandardApiHeadersWithAuth(),
        }
      );

      setRecentPaymentLinks(response?.data?.paymentLinks);
    } catch (error) {
      captureAxiosError(error);
    }
  };

  return { getRecentPaymentLinkData };
};

export default useGetRecentPaymentLinkData;

import { getStandardApiHeaders } from "../../../../utils/stateFunctions";
import { toast } from "react-toastify";
import { axiosInstance } from "../../../common/hooks/useAxios";
import captureAxiosError from "../../../common/utils/captureAxiosError";

const useResetPassword = (setError) => {
  const resetPassword = async (newPassword, confirmPassword, tokenId) => {
    if (tokenId && newPassword && confirmPassword) {
      if (newPassword !== confirmPassword) {
        setError({ password: "Passwords do not match" });
        return false;
      }
      setError(null);
      try {
        const promise = axiosInstance.post(
          "/auth/reset-password",
          {
            newPassword,
            tokenId,
          },
          {
            headers: getStandardApiHeaders(),
          }
        );
        await toast.promise(promise, {
          pending: "Resetting password...",
          success: "Password reset successfully! Redirecting...",
        });
        return true;
      } catch (e) {
        captureAxiosError(e);
      }
    }

    if (!newPassword) {
      setError((state) => ({ ...state, password: "Password is required" }));
    }
    if (!confirmPassword) {
      setError((state) => ({
        ...state,
        password: "Confirm Password is required",
      }));
    }
    return false;
  };

  return resetPassword;
};

export default useResetPassword;

import React from "react";
import CreateSubscriptionLink from "./components/CreateSubscriptionLink";
import { useGetAllSubsLinks } from "./hooks/useGetAllSubsLinks";
import { usePaymentLinksContext } from "./contexts/PaymentLinksContext";
import SubsLinksTable from "./components/table/SubsLinksTable";
import SubsFilter from "./components/filters/SubsFilter";

const SubscriptionsLink = () => {
  const { isLoading, allSubsLinks } = useGetAllSubsLinks();
  const { subsPage, setSubsPage, subsTotalCount, pageSize } =
    usePaymentLinksContext();

  return (
    <div className="flex flex-col gap-4">
      <CreateSubscriptionLink />
      <SubsFilter />
      <SubsLinksTable
        isLoading={isLoading}
        records={allSubsLinks}
        page={subsPage}
        setPage={setSubsPage}
        totalCount={subsTotalCount}
        pageSize={pageSize}
      />
    </div>
  );
};

export default SubscriptionsLink;

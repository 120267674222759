import { Button, Modal } from "antd";
import React, { useState } from "react";
import { MdAddLink } from "react-icons/md";
import GeneratePaymentLink from "./GeneratePaymentLink";
import { getTimezone } from "../../../../utils/stateFunctions";
import dayjs from "../../../../utils/CustomDayjs";
import { Form } from "antd";
import { useCreatePaymentLink } from "../hooks/useCreatePaymentLink";
import { usePaymentLinksContext } from "../contexts/PaymentLinksContext";
import { copyToClipboard } from "./../../../common/utils/copyToClipboard";
import { GeneratedLinkInfo } from "./GeneratedLinkInfo";

const CreatePaymentLink = () => {
  const [open, setOpen] = useState(false);
  const { loading, createPaymentLink } = useCreatePaymentLink();
  const { newLink, setNewLink } = usePaymentLinksContext();
  const [linkInfo, setLinkInfo] = useState(null);

  const createPaymentLinkData = {
    name: "",
    email: "",
    description: "",
    amount: null,
    expiryDate: dayjs().tz(getTimezone()).add(1, "month").startOf("day"),
    receiptId: "",
    currency: "USD",
  };

  const [form] = Form.useForm();
  form.setFieldsValue(createPaymentLinkData);

  const showModal = () => {
    setOpen(true);
  };

  const handleOk = async () => {
    await form
      .validateFields()
      .then(async (values) => {
        await createPaymentLink({ formValues: values });
        setLinkInfo(values);
      })
      .catch((errorInfo) => {
        console.log("Validation Failed");
      });
  };

  const handleCancel = () => {
    setOpen(false);
    setNewLink(null);
  };

  return (
    <>
      <div className="absolute right-[0px] top-[-94px]">
        <Button
          type="primary"
          size="large"
          icon={<MdAddLink size={20} />}
          onClick={showModal}
        >
          New Payment Link
        </Button>
      </div>
      <div id="paymentLink">
        <Modal
          title="Create New Payment Link"
          open={open}
          onOk={() => {
            newLink ? copyToClipboard(newLink) : handleOk();
          }}
          okText={newLink ? "Copy Link" : "Create Link"}
          cancelText={newLink ? "Done" : "Cancel"}
          confirmLoading={loading}
          loading={loading}
          onCancel={handleCancel}
          wrapClassName={"backdrop-blur-sm"}
          className="pb-0 border border-gray-600 rounded-lg"
          width={600}
          footer={(_, { OkBtn, CancelBtn }) =>
            newLink ? (
              <div className="flex items-center justify-between gap-4">
                <div
                  className="underline cursor-pointer"
                  onClick={() => {
                    setNewLink(null);
                  }}
                >
                  Create New Payment Link
                </div>
                <div className="flex gap-2">
                  <CancelBtn />
                  <OkBtn />
                </div>
              </div>
            ) : (
              <>
                <CancelBtn />
                <OkBtn />
              </>
            )
          }
        >
          {!newLink ? (
            <GeneratePaymentLink form={form} />
          ) : (
            <GeneratedLinkInfo linkInfo={linkInfo} link={newLink} />
          )}
        </Modal>
      </div>
    </>
  );
};

export default CreatePaymentLink;

import { Select, Form } from "antd";
import React from "react";
import AntDesignContext from "../../../../common/contexts/AntDesignContext";
import { useOnboardingContext } from "../../contexts/OnboardingContext";
import { formFields } from "../../constants/OnboardingFields";
import { getValidationRules } from "../../services/validation";
import { FaRegQuestionCircle } from "react-icons/fa";

const SelectField = ({
  label,
  placeholder,
  options,
  helper,
  name,
  required,
  label2,
  helper2,
  tooltip,
}) => {
  const { onboardingFields, setOnboardingFields } = useOnboardingContext();

  const onChange = (value) => {
    if (name === formFields.businessDetails.companyAddress.country) {
      setOnboardingFields({
        ...onboardingFields,
        [name]: value,
        [formFields.businessDetails.companyAddress.state]: null,
        [formFields.businessDetails.companyAddress.city]: null,
      });
    } else if (name === formFields.businessDetails.companyAddress.state) {
      setOnboardingFields({
        ...onboardingFields,
        [name]: value,
        [formFields.businessDetails.companyAddress.city]: null,
      });
    } else {
      setOnboardingFields({
        ...onboardingFields,
        [name]: value,
      });
    }
  };

  return (
    <AntDesignContext>
      <Form.Item
        hasFeedback
        label={
          <div className="pb-2 mr-1 text-lg text-white">
            {label}{" "}
            <span className="text-sm font-normal opacity-50">{helper} </span>{" "}
            {label2}
            <span className="text-sm font-normal opacity-50">{helper2} </span>
          </div>
        }
        name={name}
        validateFirst
        rules={getValidationRules(name, required, label)}
        tooltip={
          tooltip && {
            title: tooltip,
            icon: <FaRegQuestionCircle style={{ marginBottom: "4px" }} />,
            overlayInnerStyle: {
              backgroundColor: "#171923",
            },
          }
        }
      >
        <Select
          name={name}
          showSearch
          className="w-full"
          size="large"
          placeholder={placeholder}
          options={options}
          value={onboardingFields[name]}
          onChange={onChange}
          required={required}
          popupClassName="bg-[#121212]"
        />
      </Form.Item>
    </AntDesignContext>
  );
};

export default SelectField;

import React from "react";
import { Spin } from "antd";
import AntDesignContext from "../../contexts/AntDesignContext";

const Loader = () => {
  return (
    <AntDesignContext>
      <div>
        <Spin fullscreen size="large" />
      </div>
    </AntDesignContext>
  );
};

export default Loader;

import { createContext, useContext, useState } from "react";

const BalanceContext = createContext({});

export const BalanceProvider = (props) => {
  const [showDrawer, setShowDrawer] = useState(false);
  const [payoutId, setPayoutId] = useState(null);
  const [page, setPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);

  const pageSize = 8;

  return (
    <BalanceContext.Provider
      value={{
        showDrawer,
        setShowDrawer,
        payoutId,
        setPayoutId,
        page,
        setPage,
        totalCount,
        setTotalCount,
        pageSize,
      }}
    >
      {props.children}
    </BalanceContext.Provider>
  );
};

export const useBalanceContext = () => {
  return useContext(BalanceContext);
};

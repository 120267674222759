import { LoadingButton } from "@mui/lab";
import React, { useEffect, useState } from "react";
import ReactMarkdown from "react-markdown";
import rehypeRaw from "rehype-raw";
import useTCversionRegister from "../hooks/useTCversionRegister";
import { TnCVersion } from "../../../common/constants/entities";
import { LuFileBadge } from "react-icons/lu";

const TermsConditions = () => {
  const [termsandConditions, setTermsandConditions] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  // Update TnC version in server
  const handleRegister = useTCversionRegister();
  const handleSubmit = async () => {
    setIsLoading(true);
    await handleRegister(TnCVersion.tcversion);
    setIsLoading(false);
  };

  // Fetch TnC markdown file
  useEffect(() => {
    fetch("/TnC.md")
      .then((response) => response.text())
      .then((text) => setTermsandConditions(text));
  }, []);

  return (
    <div className="flex flex-col px-4 pt-10 pb-2 md:px-20">
      <div className="flex flex-col items-center justify-between gap-3 md:flex-row">
        <div className="py-2 text-xl md:text-3xl font-semibold text-[#47ddff] flex gap-2 items-center">
        <LuFileBadge  className="text-white md:block"/>
        Terms and Conditions
        </div>
        <div className="">
          <LoadingButton
            variant="contained"
            onClick={handleSubmit}
            loading={isLoading}
          >
            Accept
          </LoadingButton>
        </div>
      </div>
      <div className="flex flex-col items-start justify-start w-full pt-10 text-left">
        <div className="font-bold">
          UPDATED {TnCVersion.modifiedDate.toUpperCase()}
        </div>
        <div className="my-8 h-[60vh] overflow-y-auto pr-10" id="tncElement">
          <div className="prose prose-lg max-w-none">
            <ReactMarkdown
              rehypePlugins={[rehypeRaw]}
              components={{
                h2: ({ node, ...props }) => (
                  <div
                    className="text-[#34A1B9] text-xl font-bold"
                    {...props}
                  />
                ),
                p: ({ node, ...props }) => (
                  <p className="text-white" {...props} />
                ),
                ol: ({ node, ...props }) => (
                  <ol className="custom-list" {...props} />
                ),
              }}
            >
              {termsandConditions}
            </ReactMarkdown>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TermsConditions;
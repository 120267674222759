import { useNavigate } from "react-router-dom";
import {
  getStandardApiHeadersWithAuth,
  setCurrentMerchantId,
} from "../../../../utils/stateFunctions";
import { axiosInstance } from "../../../common/hooks/useAxios";
import captureAxiosError from "../../../common/utils/captureAxiosError";

const useMerchantRegister = () => {
  const navigate = useNavigate();

  const handleRegister = async (merchantName) => {
    if (!merchantName) {
      return;
    }

    try {
      const { data } = await axiosInstance.post(
        "/merchant/register",
        {
          merchantName,
        },
        {
          headers: getStandardApiHeadersWithAuth(),
        }
      );
      setCurrentMerchantId(data.merchantId);
      navigate("/");
    } catch (e) {
      captureAxiosError(e);
    }
  };

  return handleRegister;
};

export default useMerchantRegister;

import React from "react";
import UploadFile from "../UI/UploadFile";
import { formFields } from "../../constants/OnboardingFields";

const AddressProof = () => {
  return (
    <div>
      <div className="grid items-start justify-start w-full grid-cols-1 pb-10 md:grid-cols-2 gap-x-20 md:gap-y-10 gap-y-3">
        <UploadFile
          label="Rental agreement or ownership documents"
          name={formFields.businessDetails.addressProof.rentalAgreement}
          keyUpload={"RENTAL_AGREEMENT"}
          required={true}
        />
        <UploadFile
          label="Utility bills"
          helper="(electricity, water, gas)"
          label2="of the business premises"
          name={formFields.businessDetails.addressProof.utilityBills}
          keyUpload={"UTILITY_BILLS"}
          required={false}
        />
      </div>
    </div>
  );
};

export default AddressProof;

import React from "react";
import { ReactComponent as CopyIcon } from "../../../../../assets/icons/copyIcon.svg";
import toast from "react-hot-toast";

const InfoDisplay = ({ label, value, description }) => {
  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text);
    toast.success("Copied to clipboard");
  };

  return (
    <div>
      <p className="flex flex-col gap-1">
        <span className="text-xs text-[#868686]">{label}</span>
        <div
          className="flex items-start gap-2 cursor-pointer group"
          onClick={() => copyToClipboard(value)}
        >
          <span className="text-[18px] text-white">{value}</span>
          <CopyIcon className="mt-2 transition-opacity duration-300 opacity-0 group-hover:opacity-100" />
        </div>

        {description && (
          <div
            className="flex items-start gap-2 cursor-pointer group"
            onClick={() => copyToClipboard(description)}
          >
            <span className="text-sm text-[#AFAFAF] w-[70%]">
              {description}
            </span>
            <CopyIcon className="mt-1 transition-opacity duration-300 opacity-0 group-hover:opacity-100" />
          </div>
        )}
      </p>
    </div>
  );
};

export default InfoDisplay;

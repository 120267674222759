import { Box, Card, Container, Stack, Typography } from "@mui/material";
import BgGraphic from "../../../../assets/svg/bg_bubbles.svg";
import Logo from "../../../../assets/icons/Logo";

const BgStyles = {
  backgroundImage: `url(${BgGraphic})`,
  backgroundRepeat: "no-repeat",
  backgroundSize: "cover",
  width: "100vw",
};

const CardStyles = {
  p: 4,
  borderRadius: 2,
  boxShadow: "none",
};

const LayoutContainerWithBg = ({ children, hideBranding = false }) => {
  return (
    <div className="bg-[#1B1A1A]">
      <Box sx={BgStyles}>
        <Container maxWidth="xs">
          <Stack
            justifyContent="center"
            sx={{
              height: "100vh",
            }}
          >
            <Card sx={CardStyles} elevation={0}>
              {!hideBranding && (
                <Stack alignItems="center" gap={2}>
                  <Logo height={50} width="auto" />
                  <Typography
                    fontStyle="italic"
                    variant="body2"
                    sx={{ opacity: 0.5 }}
                  >
                    Global Access, Simplified
                  </Typography>
                </Stack>
              )}
              {children}
            </Card>
          </Stack>
        </Container>
      </Box>
    </div>
  );
};

export default LayoutContainerWithBg;

import { ClipboardDocumentIcon } from "@heroicons/react/24/solid";
import { IconButton } from "@mui/material";
import { copyToClipboard } from "../../../common/utils/copyToClipboard";

const CopyButton = ({ text }) => {
  return (
    <IconButton sx={{ ml: 1 }} onClick={createCopyHandler(text)}>
      <ClipboardDocumentIcon height={14} />
    </IconButton>
  );
};

const createCopyHandler = (text) => () => copyToClipboard(text);

export default CopyButton;

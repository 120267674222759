import React from "react";
import { useOnboardingContext } from "../../contexts/OnboardingContext";

const LineIndicators = () => {
  const { currentPage, currentBDPage } = useOnboardingContext();

  return (
    <>
      <div className="flex gap-2">
        {Array.from({ length: 7 }, (_, i) => (
          <div
            key={i}
            className={`${
              (currentPage === 0 && i === 0) ||
              (currentPage !== 0 && currentBDPage + 1 === i && currentPage !== 2)||
              (currentPage === 2 && i === 6)
                ? "w-10 h-1 bg-white"
                : "w-2 h-1 bg-white opacity-50"
            }  rounded-full`}
          ></div>
        ))}
      </div>
    </>
  );
};

export default LineIndicators;

import LinksTableFooter from "./LinksTableFooter";

import { Table } from "antd";
import AntDesignContext from "../../../../common/contexts/AntDesignContext";

import { copyToClipboard } from "../../../../common/utils/copyToClipboard";

import { FaCheck } from "react-icons/fa6";
import { RxCross2 } from "react-icons/rx";
import { FaRegClock } from "react-icons/fa";
import { BsCopy } from "react-icons/bs";

import ParseDateTimeTz from "../../../../common/components/UI/ParseDateTimeTz";
import ParseAmount from "../../../../common/components/UI/ParseAmount";

const LinksListTable = ({
  isLoading,
  records,
  page,
  setPage,
  totalCount,
  pageSize,
  setShowDrawer,
  setXLinkId,
}) => {
  const columns = [
    {
      title: "Link Id",
      dataIndex: "xlinkId",
      key: "xlinkId",
      align: "center",
      render: (xlinkId) => {
        const first4 = xlinkId.slice(0, 4);
        const last4 = xlinkId.slice(-4);

        const displayId = `${first4}....${last4}`;

        return (
          <div
            className="flex items-center justify-center gap-2 cursor-pointer hover:text-blue-400"
            onClick={() => copyToClipboard(xlinkId)}
          >
            {displayId} <BsCopy size={16} />
          </div>
        );
      },
    },
    {
      title: "Customer",
      dataIndex: "customerDetails",
      key: "customerDetails",
      align: "center",
      render: (customerDetails) => {
        return (
          <div className="flex flex-col items-center justify-center">
            <div>{customerDetails.name}</div>
            <div className="opacity-50">{customerDetails.email}</div>
          </div>
        );
      },
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
      align: "center",
      render: (amount, details) => {
        return <ParseAmount amount={amount} currency={details?.currency} />;
      },
    },
    {
      title: "Date",
      dataIndex: "createdAt",
      key: "createdAt",
      align: "center",

      render: (dateTime) => {
        return (
          <div className="flex flex-col items-center justify-center gap-2 md:flex-row">
            <ParseDateTimeTz dateTime={dateTime} />
          </div>
        );
      },
    },
    {
      title: "Status",
      key: "status",
      dataIndex: "status",
      align: "center",
      render: (status) => {
        return (
          <div
            className={`flex gap-1 items-center justify-center ${
              {
                COMPLETED: "text-[#20BD96]",
                EXPIRED: "text-gray-400",
                ACTIVE: "text-blue-400",
              }[status] || ""
            }`}
          >
            {
              {
                COMPLETED: <FaCheck size={16} />,
                EXPIRED: <RxCross2 size={18} />,
                ACTIVE: <FaRegClock size={15} />,
              }[status]
            }
            {status || "Unknown status"}
          </div>
        );
      },
    },
    {
      title: "Link",
      key: "shortUrl",
      dataIndex: "shortUrl",
      align: "center",
      render: (shortUrl, details) => {
        return (
          <div
            className="flex items-center justify-center gap-2 cursor-pointer hover:text-blue-400"
            onClick={() => copyToClipboard(shortUrl)}
          >
            {details?.shortUrlCode} <BsCopy size={16} />
          </div>
        );
      },
    },
  ];

  const handleShowDetails = (xlinkId) => () => {
    setXLinkId(xlinkId);
    setShowDrawer(true);
  };

  return (
    <>
      <AntDesignContext>
        <Table
          columns={columns}
          dataSource={records}
          rowClassName={() => "cursor-pointer"}
          pagination={false}
          loading={isLoading}
          onRow={(record) => {
            return {
              onClick: (event) => {
                handleShowDetails(record.xlinkId)();
              },
            };
          }}
        />
        <LinksTableFooter
          page={page}
          setPage={setPage}
          total={totalCount}
          pageSize={pageSize}
        />
      </AntDesignContext>
    </>
  );
};

export default LinksListTable;

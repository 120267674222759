/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import captureAxiosError from "../../../common/utils/captureAxiosError";
import { axiosInstance } from "../../../common/hooks/useAxios";
import { getStandardApiHeadersWithAuth } from "../../../../utils/stateFunctions";
import useLSCache from "./../../../common/hooks/useLSCache";

export const useGetSubsIntervalOptions = () => {
  const [subsIntervalOptions, setSubsIntervalOptions] = useState();
  const { createCache, getCachedData, isCacheValid } = useLSCache({
    key: "SUBS_INTERVAL_OPTIONS",
    ttl: 1,
  });

  useEffect(() => {
    const getSubsIntervalOptions = async () => {
      try {
        const { data } = await axiosInstance.post(
          "/subscription/link/filter-values",
          {
            filterTypes: ["INTERVAL_FILTER"],
          },
          {
            headers: getStandardApiHeadersWithAuth(),
          }
        );

        let filter = [];
        data?.filterValues?.INTERVAL_FILTER.forEach((item) => {
          filter.push({
            label: item.displayName,
            value: item.id,
          });
        });

        setSubsIntervalOptions(filter);
        createCache(filter);
      } catch (e) {
        captureAxiosError(e);
      }
    };

    if (isCacheValid) {
      setSubsIntervalOptions(getCachedData());
    } else {
      getSubsIntervalOptions();
    }
  }, []);

  return { subsIntervalOptions };
};

// User Registration Validation
export const validateName = (value) => {
  if (value.length < 4) {
    return "Name must be at least 4 characters long";
  }
  return undefined;
};

export const validateEmail = (value) => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  if (!emailRegex.test(value)) {
    return "Please enter a valid email address";
  }
  return undefined;
};

export const validatePassword = (value) => {
  if (value.length < 8) {
    return "Password must be at least 8 characters long";
  }
  return undefined;
};

export const validateConfirmPassword = (password, confirmPassword) => {
  if (password !== confirmPassword) {
    return "Passwords do not match";
  }
  return undefined;
};

// Payment Links Validation Functions
export const validateAmount = (value) => {
  const amount = parseFloat(value);
  return amount <= 0
    ? "Amount cannot be less than 1"
    : undefined;
};

import React from "react";
import { Link } from "react-router-dom";

const RightSideMetricsHeader = ({title, link, route, icon}) => {
  return (
    <div className="flex items-center justify-between">
      <div className="flex items-center justify-start gap-2">
        <div className="p-4 text-lg bg-black rounded-full drop-shadow-xl">{icon}</div>
        <span className="text-lg font-medium">{title}</span>
      </div>
      <div className="font-medium text-[#34A1B9] cursor-pointer text-sm hover:opacity-50 pt-1">
        <Link to={route}>{link}</Link>
      </div>
    </div>
  );
};

export default RightSideMetricsHeader;

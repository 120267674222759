import React from "react";
import ReactDOM from "react-dom/client";
import "./assets/css/index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import initSentry from "./v1/common/utils/initSentry";
import { PostHogProvider } from "posthog-js/react";
import config from "./config";

const options = {
  api_host: config.postHog_host,
};

initSentry(); // Initialise Sentry for error and pefromance monitoring

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  // <React.StrictMode>
  <PostHogProvider
    apiKey={config.postHog_key}
    options={options}
  >
    <App />
  </PostHogProvider>
  // {/* </React.StrictMode> */}
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

import React, { useEffect, useState } from "react";
import { Popover } from "@mui/material";
import { MdOutlineAccountBalanceWallet } from "react-icons/md";
import { FiInfo } from "react-icons/fi";
import ParseAmount from "./../../../common/components/UI/ParseAmount";
import { Button, Modal, Tooltip } from "antd";
import AntDesignContext from "../../../common/contexts/AntDesignContext";
import FilterBox from "./FilterBox";
import { getTimezone } from "../../../../utils/stateFunctions";
import { AiOutlineInfoCircle } from "react-icons/ai";
import { useExportBalanceCSV } from "./../hooks/useExportBalanceCSV";
import { useGlobalContext } from "../../../common/contexts/GlobalContext";
import dayjs from "../../../../utils/CustomDayjs";
import { MdOutlineSimCardDownload } from "react-icons/md";
import { useGetBalanceDetails } from "../hooks/useGetBalanceDetails";

const Header = () => {
  const { balanceAvailable, balanceAvailableSoon } = useGetBalanceDetails();
  const [anchorEl, setAnchorEl] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const [endTime, setEndTime] = useState(
    dayjs().tz(getTimezone()).subtract(1, "day").endOf("day").valueOf()
  );
  const [startTime, setStartTime] = useState(
    dayjs().tz(getTimezone()).subtract(1, "day").startOf("day").valueOf()
  );
  const { timezone } = useGlobalContext();

  useEffect(() => {
    setStartTime(
      dayjs().tz(getTimezone()).subtract(1, "day").startOf("day").valueOf()
    );
    setEndTime(dayjs().tz(getTimezone()).subtract(1, "day").endOf("day").valueOf());
  }, [timezone]);

  const { loading, exportBalanceCSV, exportData, setExportData } =
    useExportBalanceCSV();

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleDownload = (csv) => {
    const blob = new Blob([csv], { type: "text/csv" });
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "xpay_balances.csv");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleOk = async () => {
    await exportBalanceCSV({ startTime, endTime });
  };

  const handleCancel = () => {
    setExportData(null);
    setIsModalOpen(false);
  };

  return (
    <>
      <div className="flex items-center justify-between gap-2">
        <div className="text-2xl font-medium">Balances</div>
        <div className="flex items-center gap-4">
          <div className="">
            <Button
              type="primary"
              icon={<MdOutlineSimCardDownload />}
              onClick={showModal}
              className="bg-[#121212]"
              size="large"
            >
              Export Ledger
            </Button>
            <AntDesignContext>
              <Modal
                title="Export Ledger"
                open={isModalOpen}
                onOk={() => {
                  exportData ? handleDownload(exportData) : handleOk();
                }}
                onCancel={handleCancel}
                confirmLoading={loading}
                loading={loading}
                okText={exportData ? "Download" : "Export"}
                wrapClassName={"backdrop-blur-sm"}
                className="pb-0 border border-gray-600 rounded-lg"
                footer={(_, { OkBtn, CancelBtn }) =>
                  !exportData ? (
                    <div className="flex items-center justify-between gap-4">
                      <div className="flex items-center gap-2">
                        Timezone: {getTimezone()}
                        <Tooltip title="Make sure you have selected the correct timezone for exporting the transaction. You can update timezone setting from the navbar above.">
                          <AiOutlineInfoCircle className="cursor-pointer" />
                        </Tooltip>{" "}
                      </div>
                      <div className="flex gap-2">
                        <CancelBtn />
                        <OkBtn />
                      </div>
                    </div>
                  ) : (
                    <>
                      <CancelBtn />
                      <OkBtn />
                    </>
                  )
                }
              >
                {!exportData ? (
                  <>
                    <div className="py-4">Select your Date Range:</div>
                    <div className="pb-4">
                      <FilterBox
                        setStartTime={setStartTime}
                        setEndTime={setEndTime}
                      />
                    </div>
                  </>
                ) : (
                  <div className="py-4">
                    Your export is ready. If you don't see your file, you can
                    try downloading it again.
                  </div>
                )}
              </Modal>
            </AntDesignContext>
          </div>
          <div className="px-4 py-2 rounded-md bg-[#121212] text-white font-bold hidden md:block backdrop-blur-lg drop-shadow-xl">
            <div className="flex items-center justify-start gap-4">
              <div className="p-4 text-lg bg-black rounded-full drop-shadow-xl">
                <MdOutlineAccountBalanceWallet />{" "}
              </div>
              <ParseAmount
                amount={Number(balanceAvailable) + Number(balanceAvailableSoon)}
                currencySize="sm"
              />
              <FiInfo
                onMouseEnter={handlePopoverOpen}
                onMouseLeave={handlePopoverClose}
                className="opacity-50 cursor-pointer hover:opacity-25"
              />
            </div>
          </div>
          <Popover
            id="mouse-over-popover"
            sx={{
              pointerEvents: "none",
              marginLeft: -30,
              marginTop: -5,
            }}
            open={open}
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            onClose={handlePopoverClose}
            disableRestoreFocus
          >
            <div className="flex flex-col gap-4 px-8 py-4 rounded-xl">
              <div className="flex gap-2">
                Balance Available :{" "}
                <strong>
                  <ParseAmount amount={balanceAvailable} currencySize="sm" />
                </strong>
              </div>
              <div className="flex gap-2">
                Balance Available Soon :{" "}
                <strong>
                  <ParseAmount
                    amount={balanceAvailableSoon}
                    currencySize="sm"
                  />
                </strong>
              </div>
            </div>
          </Popover>
        </div>
      </div>

      <div className="flex items-end justify-end md:hidden pb-14">
        <div
          className=" px-8 py-4 rounded-md bg-[#34a1b9] text-white font-bold "
          onMouseEnter={handlePopoverOpen}
          onMouseLeave={handlePopoverClose}
        >
          ${" "}
          {(Number(balanceAvailable) + Number(balanceAvailableSoon)).toFixed(2)}
        </div>
      </div>
    </>
  );
};

export default Header;

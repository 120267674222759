import { Select, Form } from "antd";
import React from "react";
import AntDesignContext from "../../../../common/contexts/AntDesignContext";
import { getValidationRules } from "../../utils/validation";
const SelectField = ({ label = " ", placeholder, options, name, required }) => {
  return (
    <AntDesignContext>
      <Form.Item
        hasFeedback
        label={label}
        name={name}
        validateFirst
        rules={getValidationRules(name, required, label)}
        className="w-full"
      >
        <Select
          name={name}
          showSearch
          className="w-full bg-[#121212]"
          size="middle"
          placeholder={placeholder}
          options={options}
          required={required}
          popupClassName="bg-[#121212]"
        />
      </Form.Item>
    </AntDesignContext>
  );
};

export default SelectField;

/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import captureAxiosError from "../../../common/utils/captureAxiosError";
import { axiosInstance } from "../../../common/hooks/useAxios";
import { getAuthToken } from "./../../../../utils/stateFunctions";
import useGlobalStore from "../../../common/store/GlobalStore";
import { usePaymentLinksContext } from "../contexts/PaymentLinksContext";

export const useGetAllSubsLinks = () => {
  const { mode } = useGlobalStore();
  const {
    subsStartTime,
    subsEndTime,
    pageSize,
    setSubsTotalCount,
    subsPage,
    subsStatusFilter,
    subsSearchByIdText,
    newSubsLink,
  } = usePaymentLinksContext();
  const [isLoading, setIsLoading] = useState(false);
  const [allSubsLinks, setAllSubsLinks] = useState([]);

  useEffect(() => {
    const getAllSubsLinks = async () => {
      setIsLoading(true);

      try {
        if (subsSearchByIdText?.length > 0) {
          const { data } = await axiosInstance.post(
            `/subscription/link/search-by-ids`,
            {
              searchText: subsSearchByIdText,
              pageSize: pageSize,
              startIndex: (subsPage - 1) * pageSize,
              mode: mode,
              startTime: subsStartTime,
              endTime: subsEndTime,
            },
            {
              headers: {
                Authorization: `Bearer ${getAuthToken()}`,
              },
            }
          );

          setAllSubsLinks(data.subPaymentLinks);
          setSubsTotalCount(data.totalCount);
        } else {
          const { data } = await axiosInstance.post(
            `/subscription/link/filtered-links`,
            {
              pageSize: pageSize,
              startIndex: (subsPage - 1) * pageSize,
              mode: mode,
              startTime: subsStartTime,
              endTime: subsEndTime,
              statuses: subsStatusFilter.data,
            },
            {
              headers: {
                Authorization: `Bearer ${getAuthToken()}`,
              },
            }
          );

          setAllSubsLinks(data.subPaymentLinks);
          setSubsTotalCount(data.totalCount);
        }
      } catch (error) {
        captureAxiosError(error);
      } finally {
        setIsLoading(false);
      }
    };

    getAllSubsLinks();
  }, [
    subsStartTime,
    subsEndTime,
    mode,
    subsPage,
    subsStatusFilter,
    subsSearchByIdText,
    newSubsLink,
  ]);

  return { isLoading, allSubsLinks };
};

import { useNavigate } from "react-router-dom";
import { axiosInstance } from "../../../common/hooks/useAxios";
import {
  setAuthToken,
  setCurrentMerchantId,
  setEmail,
  setUserAvatarColor,
  setUserId,
  setUserName,
} from "../../../../utils/stateFunctions";
import captureAxiosError from "../../../common/utils/captureAxiosError";
import { TnCVersion } from "../../../common/constants/entities";
import { useGlobalContext } from "../../../common/contexts/GlobalContext";

const useLogin = (setError) => {
  const navigate = useNavigate();
  const { setOpen } = useGlobalContext();

  const handleLogin = async (email, password) => {
    if (email && password) {
      setError(null);
      try {
        const { data } = await axiosInstance.post("/auth/login", {
          email,
          password,
        });
        const {
          authToken,
          userId,
          userEmail,
          merchantId,
          tncVersion,
          userName,
        } = data;
        setAuthToken(authToken);
        setUserId(userId);
        setUserName(userName);
        setEmail(userEmail);
        setUserAvatarColor();
        if (merchantId) {
          setCurrentMerchantId(merchantId);
        } else {
          navigate("/merchant-register");
        }
        if (tncVersion) {
          setOpen(tncVersion !== TnCVersion.tcversion);
        } else {
          setOpen(true);
        }

        navigate("/");
      } catch (e) {
        captureAxiosError(e);
      } finally {
        return;
      }
    }
    if (!email) {
      setError((state) => ({ ...state, email: "Email is required" }));
    }
    if (!password) {
      setError((state) => ({ ...state, password: "Password is required" }));
    }
  };

  return handleLogin;
};

export default useLogin;

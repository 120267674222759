import React from "react";
import BalanceTable from "./components/table/BalancesTable";
import Header from "./components/Header";
import PayoutDrawer from "./components/table/PayoutDrawer";
import { BalanceProvider } from "./context/BalanceContext";

const Balance = () => {
  return (
    <div className="flex flex-col gap-4">
      <BalanceProvider>
        <Header />
        <BalanceTable />
        <PayoutDrawer />
      </BalanceProvider>
    </div>
  );
};

export default Balance;

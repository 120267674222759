import { Input, Form } from "antd";
import React from "react";
import AntDesignContext from "../../../../common/contexts/AntDesignContext";
import { useOnboardingContext } from "../../contexts/OnboardingContext";
import { getValidationRules } from "../../services/validation";
import { formFields } from "../../constants/OnboardingFields";
import { FaRegQuestionCircle } from "react-icons/fa";

const InputField = ({
  name,
  label,
  placeholder,
  helper,
  prefix,
  suffix,
  addonAfter,
  addonBefore,
  required,
  label2,
  helper2,
  type = "text",
  tooltip,
  dependencies,
  copyPaste = true,
}) => {
  const { onboardingFields, setOnboardingFields } = useOnboardingContext();

  const onInputChange = (e) => {
    if (formFields.businessDetails.companyEmail === name) {
      const value = e.target.value.toLowerCase();

      setOnboardingFields({ ...onboardingFields, [name]: value });
    } else {
      setOnboardingFields({ ...onboardingFields, [name]: e.target.value });
    }
  };

  return (
    <AntDesignContext>
      <Form.Item
        hasFeedback
        label={
          <div className="pb-2 mr-1 text-lg font-normal text-white">
            {label}{" "}
            <span className="text-sm font-normal opacity-50">{helper} </span>{" "}
            {label2}
            <span className="text-sm font-normal opacity-50">{helper2} </span>
          </div>
        }
        name={name}
        validateFirst
        rules={getValidationRules(name, required, label)}
        tooltip={
          tooltip && {
            title: tooltip,
            icon: <FaRegQuestionCircle style={{ marginBottom: "4px" }} />,
            overlayInnerStyle: {
              backgroundColor: "#171923",
            },
          }
        }
        dependencies={dependencies}
      >
        <Input
          prefix={prefix}
          suffix={suffix}
          addonAfter={addonAfter}
          addonBefore={addonBefore}
          name={name}
          size="large"
          placeholder={placeholder}
          className="text-white bg-black rounded-lg placeholder:text-white placeholder:opacity-50"
          required={required}
          onChange={onInputChange}
          value={onboardingFields[name]}
          type={type}
          onPaste={(e) => {
            !copyPaste && e.preventDefault();
          }}
        />
      </Form.Item>
    </AntDesignContext>
  );
};

export default InputField;

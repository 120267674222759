export const FilterQueryKeyMap = {
  status: "statuses",
  currency: "currencies",
  pg: "paymentGateways",
};

export const XPAY_QUERY_PARAMS = {
  IntentId: "xpay_intent_id",
  PaymentType: "xpay_payment_type",
};

export const PaymentTypes = {
  SUB: "subscription",
  ONE_TIME: "one-time",
};

export const PaymentLinkFilterTypes = {
  STATUS_FILTER: "STATUS_FILTER",
};

export const LinksTableColHeaders = [
  {
    displayName: "X-Pay Link ID",
    sortKey: null,
  },
  {
    displayName: "Amount",
    sortKey: null,
  },
  {
    displayName: "Currency",
    sortKey: null,
  },
  {
    displayName: "Status",
    sortKey: null,
  },
  {
    displayName: "Date",
    // sortKey: "TRANSACTION_DATE", // TODO: Get sort implemented on backend firstt
    sortKey: null,
  },
  {
    displayName: "Receipt ID",
    sortKey: null,
  },
  {
    displayName: "Customer",
    sortKey: null,
  },
  {
    displayName: "Link",
    sortKey: null,
  },
];
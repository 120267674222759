import React from "react";
import { Input, Form } from "antd";
import AntDesignContext from "../../../../common/contexts/AntDesignContext";
import { useOnboardingContext } from "../../contexts/OnboardingContext";
import { getValidationRules } from "../../services/validation";

const TextAreaField = ({
  label,
  placeholder,
  name,
  helper,
  required,
  label2,
  helper2,
}) => {
  const { onboardingFields, setOnboardingFields } = useOnboardingContext();
  const onChange = (e) => {
    setOnboardingFields({ ...onboardingFields, [name]: e.target.value });
  };

  return (
    <AntDesignContext>
      <Form.Item
        hasFeedback
        label={
          <div className="pb-2 text-lg font-normal text-white">
            {label}{" "}
            <span className="text-sm font-normal opacity-50">{helper} </span>{" "}
            {label2}
            <span className="text-sm font-normal opacity-50">{helper2} </span>
          </div>
        }
        name={name}
        validateFirst
        rules={getValidationRules(name, required, label)}
      >
        <Input.TextArea
          showCount
          autoSize={{ minRows: 2, maxRows: 4 }}
          maxLength={1000}
          size="large"
          placeholder={placeholder}
          onChange={onChange}
          value={onboardingFields[name]}
          name={name}
          className="text-white bg-black rounded-lg placeholder:text-white placeholder:opacity-50"
          required={required}
        />
      </Form.Item>
    </AntDesignContext>
  );
};

export default TextAreaField;

import { Grid, Typography } from "@mui/material";
import CopyButton from "./CopyButton";
import dayjs from "../../../../utils/CustomDayjs";
import { isEmpty } from "lodash";
import { TypographyStyles } from "./MerchantDetails";
import { useGlobalContext } from "../../../common/contexts/GlobalContext";
import { getTimezone } from "../../../../utils/stateFunctions";

const TextStyles = TypographyStyles.subheading;

const KeysView = ({ keys }) => {
  const { isMobile } = useGlobalContext();

  if (isEmpty(keys)) {
    return (
      <Grid item xs={12}>
        <Typography {...TextStyles}>No keys found</Typography>
      </Grid>
    );
  }

  return (
    <>
      <Grid item xs={isMobile ? 4 : 2}>
        <Typography {...TextStyles} noWrap>
          Public Key
        </Typography>
        <Typography {...TextStyles} noWrap>
          Secret
        </Typography>
        <Typography {...TextStyles} noWrap>
          Created On
        </Typography>
        <Typography {...TextStyles} noWrap>
          Expiry On
        </Typography>
      </Grid>
      <Grid item xs={isMobile ? 6 : 9}>
        <Typography {...TextStyles} noWrap>
          {keys.publicKey}
        </Typography>
        <Typography {...TextStyles} noWrap>
          {keys.privateKey}
        </Typography>
        <Typography {...TextStyles}>{renderDate(keys.createdAt)}</Typography>
        <Typography {...TextStyles}>
          {Boolean(keys.expiry) ? renderDate(keys.expiry) : "Never"}
        </Typography>
      </Grid>
      <Grid item xs={1} sx={{ opacity: 0.7 }}>
        <CopyButton text={keys.publicKey} />
        <CopyButton text={keys.privateKey} />
      </Grid>
    </>
  );
};

const renderDate = (date) => {
  const dateObj = dayjs(date).tz(getTimezone());
  return dateObj.format("DD MMM YYYY");
};

export default KeysView;

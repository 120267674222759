/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import captureAxiosError from "../../../common/utils/captureAxiosError";
import { axiosInstance } from "../../../common/hooks/useAxios";
import {
  getStandardApiHeadersWithAuth,
  getCurrentMerchantId,
} from "../../../../utils/stateFunctions";
import useLSCache from "./../../../common/hooks/useLSCache";

export const useGetOneTimeCurrencies = () => {
  const [oneTimeCurrencies, setOneTimeCurrencies] = useState();
  const { createCache, getCachedData, isCacheValid } = useLSCache({
    key: "ONE_TIME_CURRENCIES",
    ttl: 1,
  });

  useEffect(() => {
    const getSubsFilters = async () => {
      try {
        const { data } = await axiosInstance.post(
          `/link/merchant/${getCurrentMerchantId()}/filter-values`,
          {
            filterTypes: ["CURRENCY_FILTER"],
          },
          {
            headers: getStandardApiHeadersWithAuth(),
          }
        );

        let currencies = [];
        data?.filterValues?.CURRENCY_FILTER.forEach((item) => {
          currencies.push({
            label: item.displayName,
            value: item.id,
            data: [item.id],
          });
        });

        setOneTimeCurrencies(currencies);
        createCache(currencies);
      } catch (e) {
        captureAxiosError(e);
      }
    };

    if (isCacheValid) {
      setOneTimeCurrencies(getCachedData());
    } else {
      getSubsFilters();
    }
  }, []);

  return { oneTimeCurrencies };
};

import { create } from "zustand";
import BalanceService from "../services/BalanceServices";
import { captureException } from "@sentry/react";
import toast from "react-hot-toast";

const isMobile = typeof window !== "undefined" && window.innerWidth < 768;

const ITEMS_PER_PAGE = isMobile ? 5 : 8;

const initialState = {
  balanceAvailable: "",
  balanceAvailableSoon: "",
  isLoading: false,
  drawerLoading: false,
  shouldShowDrawer: false,
  allPayoutEntries: [],
  payoutDetails: {},
  activeId: null,
  page: 1,
};

const useBalanceDetailsStore = create((set, get) => ({
  ...initialState,

  setPage: (newPage) => set({ page: newPage }),

  paginatedPayoutEntries: () => {
    const { allPayoutEntries, page } = get();
    const startIndex = (page - 1) * ITEMS_PER_PAGE;
    const endIndex = startIndex + ITEMS_PER_PAGE;
    return allPayoutEntries.slice(startIndex, endIndex);
  },

  totalPages: () => {
    const { allPayoutEntries } = get();
    return Math.ceil(allPayoutEntries.length / ITEMS_PER_PAGE);
  },

  openDrawer: (id) => {
    const { activeId, getOnePayout } = get();
    if (activeId === id) {
      return set({ shouldShowDrawer: true, drawerLoading: false });
    }
    set({ shouldShowDrawer: true, activeId: id, drawerLoading: true });
    getOnePayout(id);
  },

  hideDrawer: () => set({ shouldShowDrawer: false }),

  fetchBalance: async (mode) => {
    const { reset } = get();

    try {
      set({ isLoading: true });
      const service = new BalanceService();
      const balanceData = await service.getBalance(mode);
      set({
        balanceAvailable: balanceData.balanceAvailable / 100,
        balanceAvailableSoon: balanceData.balanceAvailableSoon / 100,
        isLoading: false,
      });
    } catch (error) {
      captureException(error);
      toast.error("Error fetching Balance details", {
        id: "errorToast",
      });
      reset();
    }
  },

  getAllPayouts: async (mode) => {
    const { reset } = get();
    try {
      set({ isLoading: true });
      const service = new BalanceService();
      const data = await service.getAllPayouts(mode);
      set({
        allPayoutEntries: data?.entries,
        isLoading: false,
      });
    } catch (error) {
      captureException(error);
      toast.error("Error fetching Balance details", {
        id: "errorToast",
      });
      set({ isLoading: false });
      reset();
    }
  },

  getOnePayout: async () => {
    const { activeId, reset } = get();
    try {
      set({ drawerLoading: true });
      const service = new BalanceService();
      const details = await service.getOnePayout(activeId);
      set({
        payoutDetails: details,
        drawerLoading: false,
      });
    } catch (error) {
      captureException(error);
      toast.error("Error fetching Balance details", {
        id: "errorToast",
      });
      reset();
    }
  },

  createPayout: async (mode) => {
    const { reset } = get();
    try {
      set({ isLoading: true });
      const service = new BalanceService();
      await service.createPayout(mode);
      set({ isLoading: false });
    } catch (error) {
      captureException(error);
      toast.error("Something went wrong");
      reset();
    }
  },

  reset: () => set(initialState),
}));

export default useBalanceDetailsStore;

import React from "react";
import { Line } from "react-chartjs-2";
import { Tooltip as AntTooltip } from "antd";
import { FiInfo } from "react-icons/fi";
import { useLandingPageContext } from "../../../contexts/LandingPageContext";

const ChartsComponent = () => {
  const { chartsContent } = useLandingPageContext();

  return (
    <div className="flex flex-wrap gap-4">
      {chartsContent.map((chart, index) => {
        return (
          <div
            className={`w-full md:w-[48%] p-4 bg-[#121212] rounded-lg backdrop-blur-3xl drop-shadow-2xl`}
            key={index}
          >
            <div className="flex items-start justify-between gap-2">
              <div className="flex items-center justify-between pb-4">
                <div className="flex flex-col gap-1 text-left">
                  <div className="flex items-center gap-2 opacity-50">
                    {chart.title}
                    <AntTooltip title={chart.infoText} placement="right">
                      <FiInfo className="cursor-pointer hover:opacity-50" />
                    </AntTooltip>
                  </div>
                  <div className="text-lg font-medium">{chart.amount}</div>
                </div>
              </div>
            </div>
            {chart?.chartDataState?.length > 0 && (
              <Line options={chart.chartOptions} data={chart.chartData} />
            )}
            <div className="flex justify-between w-full text-sm opacity-50">
              <div>{chart?.startTimeLable}</div>
              <div>{chart?.endTimeLable}</div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default ChartsComponent;

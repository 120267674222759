import React from "react";
import UploadFile from "../UI/UploadFile";
import InputField from "../UI/InputField";
import { formFields } from "../../constants/OnboardingFields";

const AccountDetails = () => {
  return (
    <div>
      <div className="grid items-start justify-start w-full grid-cols-1 pb-10 md:grid-cols-2 gap-x-20 md:gap-y-10 gap-y-3">
        <InputField
          label="Account Name"
          placeholder={"Enter your account name"}
          name={formFields.businessDetails.settlementAccountDetails.accountName}
          required={true}
        />
        <InputField
          label="IFSC"
          placeholder={"Enter your ifsc code"}
          name={formFields.businessDetails.settlementAccountDetails.ifscCode}
          required={true}
        />
        <InputField
          label="Account Number"
          placeholder={"Enter your account number"}
          name={
            formFields.businessDetails.settlementAccountDetails.accountNumber
          }
          required={true}
        />
        <InputField
          label="Confirm Account Number"
          placeholder={"Enter your account number again"}
          name={
            formFields.businessDetails.settlementAccountDetails
              .confirmAccountName
          }
          required={true}
          type="password"
          dependencies={[
            formFields.businessDetails.settlementAccountDetails.accountNumber,
          ]}
          copyPaste={false}
        />
        <UploadFile
          label="Recent bank statements of the business account"
          name={
            formFields.businessDetails.settlementAccountDetails.bankStatement
          }
          keyUpload={"BANK_STATEMENTS"}
          required={true}
        />
        <UploadFile
          label="Cancelled Cheque"
          name={
            formFields.businessDetails.settlementAccountDetails.cancelledCheque
          }
          keyUpload={"CANCELLED_CHEQUE"}
          required={true}
        />
      </div>
    </div>
  );
};

export default AccountDetails;

import { createContext, useContext, useState, useEffect } from "react";
import { useGlobalContext } from "../../../common/contexts/GlobalContext";
import { getTimezone } from "../../../../utils/stateFunctions";
import dayjs from "../../../../utils/CustomDayjs";

const SubscriptionContext = createContext({});

export const SubscriptionProvider = (props) => {
  const [showDrawer, setShowDrawer] = useState(false);
  const [subscriptionId, setSubscriptionId] = useState(null);
  const [page, setPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const [statusFilter, setStatusFilter] = useState({
    label: "Active",
    value: "ACTIVE",
    data: ["ACTIVE"],
  });
  const [subsSearchByIdText, setSubsSearchByIdText] = useState([]);
  const [endTime, setEndTime] = useState(
    dayjs().tz(getTimezone()).endOf("day").valueOf()
  );
  const [startTime, setStartTime] = useState(
    dayjs().tz(getTimezone()).subtract(29, "day").startOf("day").valueOf()
  );
  const { timezone } = useGlobalContext();

  const pageSize = 7;

  useEffect(() => {
    setStartTime(
      dayjs().tz(getTimezone()).subtract(29, "day").startOf("day").valueOf()
    );
    setEndTime(dayjs().tz(getTimezone()).endOf("day").valueOf());
  }, [timezone]);

  useEffect(() => {
    setPage(1);
  }, [startTime, endTime, statusFilter]);

  return (
    <SubscriptionContext.Provider
      value={{
        showDrawer,
        setShowDrawer,
        subscriptionId,
        setSubscriptionId,
        endTime,
        setEndTime,
        startTime,
        setStartTime,
        page,
        setPage,
        totalCount,
        setTotalCount,
        pageSize,
        statusFilter,
        setStatusFilter,
        subsSearchByIdText,
        setSubsSearchByIdText,
      }}
    >
      {props.children}
    </SubscriptionContext.Provider>
  );
};

export const useSubscriptionContext = () => {
  return useContext(SubscriptionContext);
};

import { create } from "zustand";
import dayjs from "../../../../utils/CustomDayjs";
import captureAxiosError from "../../../common/utils/captureAxiosError";
import { validateForm } from "../utils/generateLinkUtils";
import PaymentLinkService from "../services/PaymentLinkService";
import {
  validateEmail,
  validateAmount,
} from "../../../common/utils/validators";
import toast from "react-hot-toast";
import { getTimezone } from "../../../../utils/stateFunctions";

const initialState = {
  isLoading: false,
  error: {},
  generatedLink: "",
  // Metadata Fields
  name: "",
  email: "",
  phone: "",
  description: "",
  amount: "",
  currency: "USD",
  dueDate: dayjs().tz(getTimezone()).add(1, "month").endOf("day"),
  receiptId: "",
  isSubscription: false,
};

const useGenLinkFormStore = create((set, get) => ({
  ...initialState,

  setLoading: (isLoading) =>
    set({
      isLoading,
    }),

  setMetadata: (key, value) => set({ [key]: value }),

  setError: (key, msg) =>
    set((state) => ({ error: { ...state.error, [key]: msg } })),

  resetError: (key) =>
    set((state) => {
      delete state.error[key];
      return { error: state.error };
    }),

  getMetadata: () => {
    const {
      name,
      email,
      phone,
      description,
      amount,
      currency,
      dueDate,
      receiptId,
      isSubscription,
    } = get();
    return {
      dueDate,
      currency,
      amount,
      receiptId,
      description,
      name,
      email,
      phone,
      isSubscription,
    };
  },

  checkFormReady: () => {
    const { getMetadata, setError } = get();
    const metadata = getMetadata();

    if (validateEmail(metadata.email)) {
      validateForm(metadata, setError);
      return false;
    }

    if (validateAmount(metadata.amount)) {
      validateForm(metadata, setError);
      return false;
    }

    return validateForm(metadata, setError);
  },

  resetForm: () => set({...initialState}),

  generateLink: async (mode) => {
    const { getMetadata, setLoading } = get();
    const metadata = getMetadata();
    metadata.mode = mode;
    try {
      setLoading(true);
      const service = new PaymentLinkService();
      const paymentLink = await service.generatePaymentLink(metadata);
      set({ generatedLink: paymentLink });
      toast.success("Payment link generated successfully");
    } catch (e) {
      captureAxiosError(e);
    } finally {
      setLoading(false);
    }
  },
}));
export default useGenLinkFormStore;

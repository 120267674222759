import { createContext, useContext, useState, useEffect } from "react";
import dayjs from "../../../../utils/CustomDayjs";
import useGlobalStore from "../../../common/store/GlobalStore";
import useFetchHeaderMetrics from "../hooks/useFetchHeaderMetrics";
import useGetChartsData from "../hooks/useGetChartsData";
import useGetRecentTransactionData from "../hooks/useGetRecentTransactionData";
import useGetRecentPaymentLinkData from "../hooks/useGetRecentPaymentLinkData";
import useBalanceDetailsStore from "../../balance/store/BalanceDetailsStore";
import { useGlobalContext } from "../../../common/contexts/GlobalContext";
import { getTimezone } from "../../../../utils/stateFunctions";

const LandingPageContext = createContext({});

export const LandingPageProvider = (props) => {
  const { timezone } = useGlobalContext();
  const [HeaderMetricsContent, setHeaderMetricsContent] = useState([]);
  const [startTime, setStartTime] = useState(dayjs().tz(getTimezone()).startOf("day").valueOf());
  const [endTime, setEndTime] = useState(dayjs().tz(getTimezone()).endOf("day").valueOf());
  const [loading, setLoading] = useState(false);
  const [grossChartData, setGrossChartData] = useState({});
  const [netVolumeChartData, setNetVolumeChartData] = useState({});
  const [txnCntSuccessChartData, setTxnCntSuccessChartData] = useState({});
  const [txnCntFailureChartData, setTxnCntFailureChartData] = useState({});
  const [chartsContent, setChartsContent] = useState([]);
  const [recentTransactions, setRecentTransactions] = useState([]);
  const [recentPaymentLinks, setRecentPaymentLinks] = useState([]);

  const mode = useGlobalStore((state) => state.mode);
  const { balanceAvailable, balanceAvailableSoon, fetchBalance } =
    useBalanceDetailsStore();
  const { HeaderMetricsContentHandler } = useFetchHeaderMetrics();
  const { getRecentTransactionData } = useGetRecentTransactionData({ mode });
  const { getRecentPaymentLinkData } = useGetRecentPaymentLinkData({ mode });
  const { getChartDataVolume, getChartDataCount, getChartsContent } =
  useGetChartsData({
    mode: mode,
    startTime: startTime,
    endTime: endTime,
  });

  useEffect(() => {
    setStartTime(dayjs().tz(getTimezone()).startOf("day").valueOf());
    setEndTime(dayjs().tz(getTimezone()).endOf("day").valueOf());
  }, [timezone]);

  const getDashboardData = async () => {
    setLoading(true);

    await getChartDataVolume({
      type: ["SUCCESS"],
      setChartData: setGrossChartData,
      endpoint: "/charts/query/volume",
    });
    await getChartDataVolume({
      setChartData: setNetVolumeChartData,
      endpoint: "/charts/query/volume",
    });
    await getChartDataCount({
      type: ["SUCCESS"],
      setChartData: setTxnCntSuccessChartData,
      endpoint: "/charts/query/count",
    });
    await getChartDataCount({
      type: ["FAILED"],
      setChartData: setTxnCntFailureChartData,
      endpoint: "/charts/query/count",
    });

    await new Promise((resolve) => setTimeout(resolve, 1500));

    setLoading(false);
  };

  useEffect(() => {
    getDashboardData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startTime, endTime, mode]);

  useEffect(() => {
    fetchBalance(mode);
    getRecentTransactionData({ setRecentTransactions });
    getRecentPaymentLinkData({ setRecentPaymentLinks });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mode]);

  useEffect(() => {
    setHeaderMetricsContent(
      HeaderMetricsContentHandler({
        grossChartData,
        txnCntSuccessChartData,
        txnCntFailureChartData,
      })
    );

    setChartsContent(
      getChartsContent({
        grossChartData,
        netVolumeChartData,
        txnCntSuccessChartData,
        txnCntFailureChartData,
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    grossChartData,
    netVolumeChartData,
    txnCntSuccessChartData,
    txnCntFailureChartData,
  ]);

  return (
    <LandingPageContext.Provider
      value={{
        HeaderMetricsContent,
        startTime,
        setStartTime,
        endTime,
        setEndTime,
        loading,
        setLoading,
        chartsContent,
        setChartsContent,
        balanceAvailable,
        balanceAvailableSoon,
        recentTransactions,
        setRecentTransactions,
        recentPaymentLinks,
        setRecentPaymentLinks,
      }}
    >
      {props.children}
    </LandingPageContext.Provider>
  );
};

export const useLandingPageContext = () => {
  return useContext(LandingPageContext);
};

import { isEmpty, toNumber } from "lodash";
import {
  getCurrentMerchantId,
  getStandardApiHeadersWithAuth,
} from "../../../../utils/stateFunctions";
import { convertDayJsToEpoch } from "../utils/generateLinkUtils";
import { axiosInstance } from "../../../common/hooks/useAxios";
import FilterTransformer from "../../../common/utils/FilterTransformer";

class PaymentLinkRepository {
  constructor() {
    this.baseUrlEndpoint = `link/merchant/${getCurrentMerchantId()}`;
  }

  async generatePaymentLink(metadata) {
    const payload = this.getPayloadForGenerateLink(metadata);
    const { data } = await axiosInstance.post(
      `${this.baseUrlEndpoint}/generate-link`,
      payload,
      {
        headers: getStandardApiHeadersWithAuth(),
      }
    );
    return data.shortUrl;
  }

  async fetchFilteredRecords(filterQuery, metadata) {
    const payload = this.getPayloadFromFilters(filterQuery, metadata);
    const { data } = await axiosInstance.post(
      `${this.baseUrlEndpoint}/filtered-links`,
      payload,
      {
        headers: getStandardApiHeadersWithAuth(),
      }
    );
    return data.paymentLinks;
  }

  async searchRecordsById(searchText, metadata) {
    const payload = this.getSearchPayload(searchText, metadata);
    const { data } = await axiosInstance.post(
      `${this.baseUrlEndpoint}/search-by-ids`,
      payload,
      {
        headers: getStandardApiHeadersWithAuth(),
      }
    );
    return data.paymentLinks;
  }

  getSearchPayload(searchText, metadata) {
    const payload = { searchText };
    const transformer = new FilterTransformer({}, metadata);
    transformer.applySort(payload);
    transformer.applyPageFilter(payload);
    payload.mode = metadata.mode;
    return payload;
  }

  getPayloadFromFilters(filterQuery, metadata) {
    const transformer = new FilterTransformer(filterQuery, metadata);
    const payload = {};
    transformer.applyGenericPaymentLinkFilters(payload);
    transformer.applyDurationFilter(payload);
    transformer.applySort(payload);
    transformer.applyPageFilter(payload);
    payload.mode = metadata.mode;
    return payload;
  }

  getPayloadForGenerateLink(metadata) {
    const payload = {};
    payload.dueDate = convertDayJsToEpoch(metadata.dueDate);
    payload.amount = toNumber(metadata.amount) * 100;
    payload.receiptId = metadata.receiptId;
    payload.description = metadata.description;
    payload.mode = metadata.mode;
    payload.currency = metadata.currency;
    payload.customerDetails = {
      name: metadata.name,
      email: metadata.email,
      contactNumber: metadata.phone,
    };
    return payload;
  }
}

class PaymentLinkService {
  constructor() {
    this.repository = new PaymentLinkRepository();
  }

  async generatePaymentLink(metadata) {
    return await this.repository.generatePaymentLink(metadata);
  }

  async fetchPaymentLinks(filterQuery, metadata) {
    if (isEmpty(filterQuery.searchQuery)) {
      return this.repository.fetchFilteredRecords(filterQuery, metadata);
    }
    return this.repository.searchRecordsById(filterQuery.searchQuery, metadata);
  }
}

export default PaymentLinkService;

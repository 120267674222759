import React from "react";
import { ReactComponent as VBAIcon } from "../../../../assets/icons/virtualBankAccount.svg";

const DeActivatedVBA = () => {
  return (
    <div className="flex flex-col items-center justify-center h-[80vh] w-[80vw] gap-12 overflow-hidden bg-dot-white/[0.1]">
      <VBAIcon />
      <p className="text-2xl text-center">
        To Activate Virtual Bank Accounts (VBA),
        <br />
        Please contact{" "}
        <a href="mailto:hello@xpaycheckout.com" className="text-[#34A1B9]">
          hello@xpaycheckout
        </a>
        .
      </p>
    </div>
  );
};

export default DeActivatedVBA;

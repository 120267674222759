import React from "react";
import RightSideMetrics from "../RightSideMetrics/RightSideMetrics";
import ChartsComponent from "./charts/ChartsComponent";
import GreatingMessage from "./GreetingMessage";
import HeaderMetric from "./HeaderMetric";

function ChartsAndOtherMetrics() {
  return (
    <div className="flex flex-col md:flex-row">
      <div className="flex gap-4 w-[65vw] flex-col">
        <GreatingMessage />
        <HeaderMetric />
        <ChartsComponent />
      </div>
      <div className="w-full md:w-[30vw] h-full  flex flex-col gap-4">
        <RightSideMetrics />
      </div>
    </div>
  );
}

export default ChartsAndOtherMetrics;

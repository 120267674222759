import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import { getTimezone } from './stateFunctions';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(isSameOrBefore);

dayjs.tz.setDefault(getTimezone());
export default dayjs;

import { useEffect } from "react";
import useLogout from "../hooks/useLogout";
import { Typography } from "@mui/material";
import LayoutContainerWithBg from "../../../common/components/UI/LayoutContainerWithBg";

const Logout = () => {
  const handleLogout = useLogout();

  useEffect(() => {
    handleLogout();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <LayoutContainerWithBg>
      <Typography textAlign="center" mt={4}>
        Logging out...
      </Typography>
    </LayoutContainerWithBg>
  );
};

export default Logout;

/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import captureAxiosError from "../../../common/utils/captureAxiosError";
import { axiosInstance } from "../../../common/hooks/useAxios";
import {
  getAuthToken,
  getCurrentMerchantId,
} from "./../../../../utils/stateFunctions";
import useGlobalStore from "../../../common/store/GlobalStore";
import { usePaymentLinksContext } from "../contexts/PaymentLinksContext";

export const useGetAllPaymentLinks = () => {
  const { mode } = useGlobalStore();
  const {
    startTime,
    endTime,
    pageSize,
    setTotalCount,
    page,
    statusFilter,
    oneTimeSearchByIdText,
    newLink,
  } = usePaymentLinksContext();
  const [isLoading, setIsLoading] = useState(false);
  const [allPaymentLinks, setAllPaymentLinks] = useState([]);

  useEffect(() => {
    const getAllSubscriptionLinks = async () => {
      setIsLoading(true);

      try {
        if (oneTimeSearchByIdText?.length > 0) {
          const { data } = await axiosInstance.post(
            `/link/merchant/${getCurrentMerchantId()}/search-by-ids`,
            {
              searchText: oneTimeSearchByIdText,
              pageSize: pageSize,
              startIndex: (page - 1) * pageSize,
              mode: mode,
              startTime: startTime,
              endTime: endTime,
            },
            {
              headers: {
                Authorization: `Bearer ${getAuthToken()}`,
              },
            }
          );

          setAllPaymentLinks(data.paymentLinks);
          setTotalCount(data.totalCount);
        } else {
          const { data } = await axiosInstance.post(
            `/link/merchant/${getCurrentMerchantId()}/filtered-links`,
            {
              pageSize: pageSize,
              startIndex: (page - 1) * pageSize,
              mode: mode,
              startTime: startTime,
              endTime: endTime,
              statuses: statusFilter?.data,
            },
            {
              headers: {
                Authorization: `Bearer ${getAuthToken()}`,
              },
            }
          );

          setAllPaymentLinks(data.paymentLinks);
          setTotalCount(data.totalCount);
        }
      } catch (error) {
        captureAxiosError(error);
      } finally {
        setIsLoading(false);
      }
    };

    getAllSubscriptionLinks();
  }, [
    startTime,
    endTime,
    mode,
    page,
    statusFilter,
    oneTimeSearchByIdText,
    newLink,
  ]);

  return { isLoading, allPaymentLinks };
};

import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  palette: {
    mode: "dark",
    primary: {
      main: "#34A1B9",
      60: "#34A1B9",
      65: "#40CAE9",
      70: "#67E3FF",
      80: "#B7F2FF",
      90: "#D4F7FF",
      95: "#E9FBFF",
      97: "#FEFFFA",
      99: "#FEFFFA",
    },
    text: {
      primary: "#FCFCFD",
      secondary: "#E4E4E7",
      caption: "#A0A0A0",
    },
    background: {
      default: "#1B1A1A",
    },
  },
});

export default theme;
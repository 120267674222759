import { Stack } from "@mui/material";
import useGlobalStore from "../../store/GlobalStore";
import { useShallow } from "zustand/react/shallow";
import { PGMode } from "../../constants/entities";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { axiosInstance } from "../../hooks/useAxios";
import {
  getCurrentMerchantId,
  getStandardApiHeadersWithAuth,
} from "../../../../utils/stateFunctions";
import { useLocation } from "react-router-dom";
import useLogout from "../../../modules/authentication/hooks/useLogout";
import AntDesignContext from "./../../contexts/AntDesignContext";
import { Select } from "antd";
import { FaCircleExclamation } from "react-icons/fa6";
import { FaCheckCircle } from "react-icons/fa";

const options = [
  {
    label: (
      <div className="flex items-center gap-2 text-yellow-400">
        <FaCircleExclamation /> Test Mode
      </div>
    ),
    value: "TEST",
  },
  {
    label: (
      <div className="flex items-center gap-2 text-blue-400">
        <FaCheckCircle /> Live Mode
      </div>
    ),
    value: "LIVE",
  },
];

const ModeSwitcher = () => {
  const {
    mode,
    setMode,
    setMerchantActivated,
    merchantActivated,
    setIsWelcomed,
  } = useGlobalStore(useShallow(selectStore));
  const [isError, setIsError] = useState(false);
  const handleLogout = useLogout();

  const { pathname } = useLocation();
  const shouldFetch = pathname === "/";

  useEffect(() => {
    const fetchMerchantDetails = async () => {
      try {
        const response = await axiosInstance.get(
          `/merchant/${getCurrentMerchantId()}/merchant-details`,
          {
            headers: getStandardApiHeadersWithAuth(),
          }
        );

        const isActivated = response?.data?.activated;
        setMerchantActivated(response?.data?.activated);
        setIsWelcomed(response?.data?.welcomed);
        if (!isActivated) {
          setMode(PGMode.TEST);
        }
      } catch (error) {
        setIsError(true);
        setMode(PGMode.TEST);
        toast.error("Something went wrong, you will be in test mode now.", {
          id: "errorToast",
        });
        if (error?.response?.status === 401) {
          handleLogout();
        }
      }
    };

    if (shouldFetch) {
      fetchMerchantDetails();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSwitchChange = (value) => {
    if (merchantActivated) {
      const newValue = value === "TEST" ? PGMode.TEST : PGMode.LIVE;
      setMode(newValue);
    } else {
      const errMsg = !isError
        ? "LIVE mode is disabled. Please contact hello@xpaycheckout.com"
        : "Something went wrong, you will be in test mode now.";
      toast.error(errMsg, {
        id: "errorToast",
      });
      setMode(PGMode.TEST);
    }
  };

  return (
    <>
      <Stack direction="row" alignItems="center" ml="auto" mr={2}>
        <AntDesignContext>
          <Select
            options={options}
            onChange={handleSwitchChange}
            defaultValue={mode === PGMode.TEST ? "TEST" : "LIVE"}
            value={mode === PGMode.TEST ? "TEST" : "LIVE"}
            className="w-30"
            popupClassName="z-[100000000] bg-[#121212]"
          />
        </AntDesignContext>
      </Stack>
    </>
  );
};

const selectStore = (state) => ({
  mode: state.mode,
  setMode: state.setMode,
  setMerchantActivated: state.setMerchantActivated,
  merchantActivated: state.merchantActivated,
  setIsWelcomed: state.setIsWelcomed,
});

export default ModeSwitcher;

import { formFields } from "../constants/formFields";

export const getValidationRules = (name, required, label) => {
  const baseRules = [
    {
      required,
      message: `Please input your ${label.toLowerCase()}!`,
    },
  ];

  switch (name) {
    case formFields.name:
      return [
        ...baseRules,
        {
          min: 2,
          message: "Name must be at least 2 characters long!",
        },
        {
          max: 100,
          message: "The Name can only be up to 100 characters long!",
        },
      ];
    case formFields.email:
      return [
        ...baseRules,
        {
          type: "email",
          message: "Please enter a valid email address!",
        },
      ];
    case formFields.description:
      return [
        ...baseRules,
        {
          max: 100,
          message: "Description can only be 100 characters long!",
        },
      ];
    case formFields.cycleCount:
      return [
        ...baseRules,
        {
          min: 2,
          type: "number",
          message: "Minimum 2 cycles are required!",
        },
      ];
    case formFields.amount:
      return [
        ...baseRules,
        {
          min: 1,
          type: "number",
          message: "Amount must be at least 1!",
        },
      ];
    case formFields.intervalCount:
      return [
        ...baseRules,
        {
          min: 1,
          type: "number",
          message: "Interval Count must be at least 1!",
        },
      ];
    default:
      return baseRules;
  }
};

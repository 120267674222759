import React from "react";
import Filter from "./components/filters/Filters";
import LinksListTable from "./components/table/LinksListTable";
import CreatePaymentLink from "./components/CreatePaymentLink";
import { useGetAllPaymentLinks } from "./hooks/useGetAllPaymentLinks";
import { usePaymentLinksContext } from "./contexts/PaymentLinksContext";
import OneTimeDrawer from "./components/table/OneTimeDrawer";

const OneTimePaymentLink = () => {
  const { isLoading, allPaymentLinks } = useGetAllPaymentLinks();
  const { page, setPage, totalCount, pageSize, setShowDrawer, setXLinkId } =
    usePaymentLinksContext();

  return (
    <div className="flex flex-col gap-4">
      <CreatePaymentLink />
      <Filter />
      <LinksListTable
        isLoading={isLoading}
        records={allPaymentLinks}
        page={page}
        setPage={setPage}
        totalCount={totalCount}
        pageSize={pageSize}
        setShowDrawer={setShowDrawer}
        setXLinkId={setXLinkId}
      />
      <OneTimeDrawer />
    </div>
  );
};

export default OneTimePaymentLink;

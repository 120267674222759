import dayjs from "../../../utils/CustomDayjs";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import { getTimezone } from "../../../utils/stateFunctions";
import { useCallback } from "react";

// Utility hook to create and use local storage cache
const useLSCache = ({ key, ttl }) => {
  const currentTS = dayjs().tz(getTimezone());
  const isCacheValid = checkCacheValidity({ currentTS, key });

  return {
    createCache: useCallback(
      (dump) => createCache({ currentTS, dump, key, ttl }),
      [currentTS, key, ttl]
    ),
    getCachedData: useCallback(
      () => getCachedData(isCacheValid, key),
      [isCacheValid, key]
    ),
    isCacheValid,
  };
};

const checkCacheValidity = ({ currentTS, key }) => {
  let cache = localStorage.getItem(key);
  if (cache) {
    cache = JSON.parse(cache);
    if (!cache.expiresOn || !cache.dump) {
      return false;
    }
    const expiryTS = dayjs(cache.expiresOn);
    dayjs.extend(isSameOrAfter);
    return expiryTS.isSameOrAfter(currentTS);
  }
  return false;
};

const createCache = ({ currentTS, dump, key, ttl = 1 }) => {
  const expiryTS = currentTS.add(ttl, "day");
  localStorage.setItem(
    key,
    JSON.stringify({
      expiresOn: expiryTS,
      dump,
    })
  );
};

const getCachedData = (isValid, key) => {
  if (isValid) {
    const cache = localStorage.getItem(key);
    return JSON.parse(cache).dump;
  }
  return null;
};

export default useLSCache;

import { Button, IconButton, Stack, TextField } from "@mui/material";
import { EyeIcon, EyeSlashIcon } from "@heroicons/react/24/solid";
import { useEffect, useRef, useState } from "react";
import { LoadingButton } from "@mui/lab";
import { has } from "lodash";
import useLogin from "../hooks/useLogin";
import { useNavigate } from "react-router-dom";
import LayoutContainerWithBg from "../../../common/components/UI/LayoutContainerWithBg";
import { validateEmail } from "../../../common/utils/validators";
import {
  getCurrentMerchantId,
  isLoggedIn,
} from "../../../../utils/stateFunctions";
import { isEmpty } from "lodash";

export const StdTextfieldProps = {
  variant: "outlined",
  fullWidth: true,
  size: "small",
  inputProps: {
    sx: {
      boxShadow: "none !important",
    },
  },
};

const LoginForm = () => {
  const emailRef = useRef(null);
  const passwordRef = useRef(null);

  const [showPassword, setShowPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const handleLogin = useLogin(setError);
  const navigate = useNavigate();

  const toggleShowPassword = () => setShowPassword(!showPassword);

  useEffect(() => {
    const isTokenValid = isLoggedIn();
    if (isTokenValid) {
      const isMerchantRegistered = !isEmpty(getCurrentMerchantId());
      if (!isMerchantRegistered) {
        navigate("/merchant-register");
      } else {
        navigate("/");
      }
    } 
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const validateInput = (inputName, inputValue) => {
    const validationErrors = { ...error };
    delete validationErrors[inputName];

    const emailError = validateEmail(inputValue);
    if (emailError) {
      validationErrors.email = emailError;
    }

    setError(validationErrors);
  };

  const handleInputChange = (event) => {
    const inputName = event.target.name;
    const inputValue = event.target.value;
    validateInput(inputName, inputValue);
  };

  const handleSubmit = async () => {
    const email = emailRef.current?.value;
    const password = passwordRef.current?.value;
    setIsLoading(true);
    await handleLogin(email, password);
    setIsLoading(false);
  };

  return (
    <LayoutContainerWithBg>
      <Stack gap={2} my={6}>
        <TextField
          label="Email"
          name="email"
          {...StdTextfieldProps}
          inputRef={emailRef}
          error={has(error, "email")}
          helperText={error?.email ?? null}
          onChange={handleInputChange}
          value={emailRef.current?.value.toLowerCase()}
        />
        <TextField
          name="pass"
          error={has(error, "password")}
          helperText={error?.password ?? null}
          inputRef={passwordRef}
          label="Password"
          type={showPassword ? "text" : "password"}
          {...StdTextfieldProps}
          autoComplete="current-password"
          InputProps={{
            endAdornment: (
              <IconButton onClick={toggleShowPassword}>
                {showPassword ? (
                  <EyeSlashIcon height={14} />
                ) : (
                  <EyeIcon height={14} />
                )}
              </IconButton>
            ),
          }}
        />
      </Stack>
      <Stack gap={2}>
        <LoadingButton
          loading={isLoading}
          fullWidth
          variant="contained"
          onClick={handleSubmit}
        >
          Login
        </LoadingButton>
        <Stack direction="row">
          <Button
            fullWidth
            sx={{
              textTransform: "none",
            }}
            onClick={() => navigate("/user-register")}
          >
            Register New User
          </Button>
          <Button
            fullWidth
            sx={{
              textTransform: "none",
            }}
            onClick={() => navigate("/forget-password")}
          >
            Forget Password
          </Button>
        </Stack>
      </Stack>
    </LayoutContainerWithBg>
  );
};

export default LoginForm;

import { Table } from "antd";
import AntDesignContext from "../../../../common/contexts/AntDesignContext";

import { FaCheck } from "react-icons/fa6";
import { RxCross2 } from "react-icons/rx";
import { FaRegClock } from "react-icons/fa";
import { HiOutlineExternalLink } from "react-icons/hi";
import { HiOutlineReceiptRefund } from "react-icons/hi2";
import { TbBookmarkQuestion } from "react-icons/tb";
import { MdCancel, MdOutlineGavel } from "react-icons/md";

import ParseAmount from "../../../../common/components/UI/ParseAmount";
import ParseDateTimeTz from "./../../../../common/components/UI/ParseDateTimeTz";
import { useTransactionContext } from "../../context/TransactionContext";
import { useGetAllTransaction } from "../../hooks/useGetAllTransaction";
import TransactionTableFooter from "./TransactionsTableFooter";

const TransactionsTable = () => {
  const {
    setShowDrawer,
    setTransactionId,
    pageSize,
    page,
    setPage,
    totalCount,
  } = useTransactionContext();
  const { allTransactions, isLoading } = useGetAllTransaction();

  const handleShowDetails = (transactionId) => () => {
    setTransactionId(transactionId);
    setShowDrawer(true);
  };

  const columns = [
    {
      title: "Intent Id",
      dataIndex: "xtxnId",
      key: "xtxnId",
      align: "center",
      render: (intentId) => {
        const first4 = intentId.slice(0, 4);
        const last4 = intentId.slice(-4);

        const displayId = `${first4}....${last4}`;

        return (
          <div className="flex items-center justify-center gap-2">
            {displayId} <HiOutlineExternalLink size={16} />
          </div>
        );
      },
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
      align: "center",
      render: (amount, details) => {
        return <ParseAmount amount={amount} currency={details?.currency} />;
      },
    },
    {
      title: "Date",
      dataIndex: "xtxnStartTime",
      key: "xtxnStartTime",
      align: "center",
      render: (dateTime) => {
        return (
          <div className="flex flex-col items-center justify-center gap-2 md:flex-row">
            <ParseDateTimeTz dateTime={dateTime} />
          </div>
        );
      },
    },
    {
      title: "Status",
      key: "transactionStatus",
      dataIndex: "transactionStatus",
      align: "center",
      render: (status) => {
        return (
          <div
            className={`flex gap-1 items-center justify-center ${
              {
                SUCCESS: "text-[#20BD96]",
                FAILED: "text-[#e0343c]",
                CREATED: "text-gray-400",
                INCOMPLETE: "text-gray-400",
                REFUNDED: "text-blue-400",
                DISPUTED: "text-yellow-400",
                DISPUTE_WON: "text-[#6B8E23]", 
                CANCELLED: "text-[#ff8c0094]", 
              }[status] || ""
            }`}
          >
            {
              {
                SUCCESS: <FaCheck size={16} />,
                FAILED: <RxCross2 size={18} />,
                CREATED: <FaRegClock size={15} />,
                INCOMPLETE: <FaRegClock size={15} />,
                REFUNDED: <HiOutlineReceiptRefund size={15} />,
                DISPUTED: <TbBookmarkQuestion size={15} />,
                DISPUTE_WON: <MdOutlineGavel size={15} />, 
                CANCELLED: <MdCancel size={16} />, 
              }[status]
            }
            {status || "Unknown status"}
          </div>
        );
      },
    },
  ];

  return (
    <AntDesignContext>
      <Table
        onRow={(record) => {
          return {
            onClick: (event) => {
              handleShowDetails(record.xtxnId)();
            },
          };
        }}
        columns={columns}
        dataSource={allTransactions}
        rowClassName={() => "cursor-pointer"}
        pagination={false}
        loading={isLoading}
      />
      <TransactionTableFooter
        page={page}
        setPage={setPage}
        total={totalCount}
        pageSize={pageSize}
      />
    </AntDesignContext>
  );
};

export default TransactionsTable;

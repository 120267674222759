import React, { useState, useEffect } from "react";
import InputField from "../UI/InputField";
import SelectField from "../UI/SelectField";
import { formFields } from "../../constants/OnboardingFields";
import { Country, State, City } from "country-state-city";
import { forEach } from "lodash";
import { useOnboardingContext } from "../../contexts/OnboardingContext";

const BasicDetails = () => {
  const { onboardingFields } = useOnboardingContext();

  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);

  useEffect(() => {
    const countryData = Country.getAllCountries();
    let countryOptions = [];
    forEach(countryData, (country) => {
      countryOptions.push({
        label: country.flag + "  " + country.name,
        value: country.isoCode + "-" + country.name,
      });
    });

    setCountries(countryOptions);
  }, []);

  useEffect(() => {
    const countryIsoCode =
      onboardingFields[
        formFields.businessDetails.companyAddress.country
      ]?.split("-")[0];

    if (countryIsoCode) {
      const stateData = State.getStatesOfCountry(countryIsoCode);
      let stateOptions = [];
      forEach(stateData, (state) => {
        stateOptions.push({
          label: state.name,
          value: countryIsoCode + "-" + state.isoCode + "-" + state.name,
        });
      });

      setStates(stateOptions);
    }
  }, [onboardingFields]);

  useEffect(() => {
    const countryIsoCode =
      onboardingFields[formFields.businessDetails.companyAddress.state]?.split(
        "-"
      )[0];
    const stateIsoCode =
      onboardingFields[formFields.businessDetails.companyAddress.state]?.split(
        "-"
      )[1];

    if (stateIsoCode) {
      const cityData = City.getCitiesOfState(countryIsoCode, stateIsoCode);

      let cityOptions = [];
      forEach(cityData, (city) => {
        cityOptions.push({
          label: city.name,
          value: city.name,
        });
      });

      setCities(cityOptions);
    }
  }, [onboardingFields]);

  return (
    <div>
      <div className="grid items-start justify-start w-full grid-cols-1 pb-10 md:grid-cols-2 gap-x-20 md:gap-y-10 gap-y-3">
        <InputField
          label="Company Name"
          placeholder="Enter your company name"
          name={formFields.businessDetails.companyName}
          required={true}
        />
        <InputField
          label="Company Email"
          placeholder="Enter your company email"
          name={formFields.businessDetails.companyEmail}
          required={true}
          fontSize=""
        />
        <div className="text-xl font-normal md:col-span-2">Company Address</div>
        <InputField
          label="Address Line1"
          placeholder="Enter your address line 1"
          name={formFields.businessDetails.companyAddress.addressLine1}
          required={true}
        />
        <InputField
          label="Address Line2"
          placeholder="Enter your address line 2"
          name={formFields.businessDetails.companyAddress.addressLine2}
          required={false}
        />
        <SelectField
          label="Country"
          placeholder="Country"
          options={countries}
          name={formFields.businessDetails.companyAddress.country}
          required={true}
        />
        {states.length > 0 && (
          <SelectField
            label="State"
            placeholder="State"
            options={states}
            name={formFields.businessDetails.companyAddress.state}
            required={true}
          />
        )}
        {states.length > 0 && cities.length > 0 && (
          <SelectField
            label="City"
            placeholder="City"
            options={cities}
            name={formFields.businessDetails.companyAddress.city}
            required={true}
          />
        )}
        <InputField
          label="Pincode"
          placeholder="Pincode"
          name={formFields.businessDetails.companyAddress.pinCode}
          required={true}
        />
      </div>
    </div>
  );
};

export default BasicDetails;

export const formFields = {
  name: "name",
  email: "email",
  amount: "amount",
  currency: "currency",
  receiptId: "receiptId",
  expiryDate: "expiryDate",
  description: "description",
  interval: "interval",
  intervalCount: "intervalCount",
  cycleCount: "cycleCount",
};

import { useState } from "react";
import { axiosInstance } from "./../../../common/hooks/useAxios";
import captureAxiosError from "./../../../common/utils/captureAxiosError";
import {
  getStandardApiHeadersWithAuth,
  getCurrentMerchantId,
  getTimezone,
} from "../../../../utils/stateFunctions";
import useGlobalStore from "./../../../common/store/GlobalStore";
import { usePaymentLinksContext } from "../contexts/PaymentLinksContext";
import dayjs from "dayjs";
import toast from "react-hot-toast";

export const useCreatePaymentLink = () => {
  const [loading, setLoading] = useState(false);
  const { mode } = useGlobalStore();
  const { setNewLink } = usePaymentLinksContext();

  const createPaymentLink = async ({ formValues }) => {
    setLoading(true);
    try {
      const { data } = await axiosInstance.post(
        `/link/merchant/${getCurrentMerchantId()}/generate-link`,
        {
          amount: formValues.amount * 100,
          currency: formValues.currency,
          description: formValues.description,
          customerDetails: {
            name: formValues.name,
            email: formValues.email,
          },
          dueDate: dayjs(formValues.expiryDate).tz(getTimezone()).valueOf(),
          mode: mode,
          receiptId: formValues.receiptId,
        },
        {
          headers: getStandardApiHeadersWithAuth(),
        }
      );

      setNewLink(data.shortUrl);
      toast.success("Payment link created successfully!");
    } catch (err) {
      captureAxiosError(err);
    } finally {
      setLoading(false);
    }
  };

  return { loading, createPaymentLink };
};

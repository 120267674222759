import { Form, Divider, Space } from "antd";
import InputField from "./formFields/InputField";
import { formFields } from "../constants/formFields";
import TextAreaField from "./formFields/TextAreaField";
import InputNumberField from "./formFields/InputNumberField";
import DatePickerField from "./formFields/DatePickerField";
import dayjs from "../../../../utils/CustomDayjs";
import { getTimezone } from "../../../../utils/stateFunctions";
import SelectField from "./formFields/SelectField";
import { useGetOneTimeCurrencies } from "../hooks/useGetOneTimeCurrencies";

const getSymbol = (currency) => {
  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: currency,
  });
  const symbol = formatter.formatToParts(0)[0].value;
  return symbol;
};

const GeneratePaymentLink = ({ form }) => {
  const { oneTimeCurrencies } = useGetOneTimeCurrencies();

  return (
    <>
      <Form
        form={form}
        name={`PaymentLinkForm`}
        className="pt-4"
        id="PaymentLinkForm"
        layout="vertical"
        autoComplete="on"
        requiredMark={"optional"}
      >
        <div className="grid grid-cols-2 gap-4">
          <InputField
            name={formFields.name}
            label="Name"
            required={false}
            placeholder={"Enter customer name"}
          />
          <InputField
            name={formFields.email}
            label="Email"
            required={false}
            placeholder={"Enter customer email"}
          />
        </div>
        <TextAreaField
          name={formFields.description}
          label="Description"
          required={false}
          placeholder={"Enter description of payment"}
        />
        <Divider />
        <Space.Compact className="w-full">
          <InputNumberField
            name={formFields.amount}
            label="Amount"
            required
            prefix={
              <Form.Item shouldUpdate noStyle>
                {() => getSymbol(form.getFieldValue(formFields.currency))}
              </Form.Item>
            }
            placeholder={"Enter amount "}
            controls={false}
          />
          <div className="w-28">
            <SelectField
              name={formFields.currency}
              required={true}
              options={oneTimeCurrencies}
            />
          </div>
        </Space.Compact>
        <div className="grid grid-cols-2 gap-4">
          <DatePickerField
            name={formFields.expiryDate}
            label="Expiry Date"
            required={true}
            minDate={dayjs().tz(getTimezone()).add(1, "day").startOf("day")}
          />
          <InputField
            name={formFields.receiptId}
            label="Receipt Id"
            required={false}
            placeholder={"Enter receipt id"}
          />
        </div>
      </Form>
    </>
  );
};

export default GeneratePaymentLink;

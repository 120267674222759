import { Form, Divider, Space } from "antd";
import InputField from "./formFields/InputField";
import { formFields } from "../constants/formFields";
import TextAreaField from "./formFields/TextAreaField";
import InputNumberField from "./formFields/InputNumberField";
import DatePickerField from "./formFields/DatePickerField";
import SelectField from "./formFields/SelectField";
import dayjs from "../../../../utils/CustomDayjs";
import { getTimezone } from "../../../../utils/stateFunctions";
import { useGetSubsIntervalOptions } from "../hooks/useGetSubsIntervalOptions";

const GeneratePaymentLink = ({ form }) => {
  const { subsIntervalOptions } = useGetSubsIntervalOptions();

  return (
    <>
      <Form
        form={form}
        name={`PaymentLinkForm`}
        className="pt-4"
        id="PaymentLinkForm"
        layout="vertical"
        autoComplete="on"
        requiredMark={"optional"}
      >
        <div className="grid grid-cols-2 gap-4">
          <InputField
            name={formFields.name}
            label="Name"
            required={true}
            placeholder={"Enter customer name"}
          />
          <InputField
            name={formFields.email}
            label="Email"
            required={true}
            placeholder={"Enter customer email"}
          />
        </div>
        <TextAreaField
          name={formFields.description}
          label="Description"
          required={false}
          placeholder={"Enter description of payment"}
        />
        <Divider />
        <InputNumberField
          name={formFields.amount}
          label="Amount"
          required={true}
          prefix="$"
          addonAfter="USD"
          placeholder={"Enter amount "}
          controls={false}
        />
        <Space.Compact>
          <div className="w-full" id={"subsFrequency"}>
            <InputNumberField
              name={formFields.intervalCount}
              label="Frequency"
              required={true}
              placeholder={"0"}
              addonBefore={"Every"}
              changeOnWheel={true}
            />
          </div>
          <SelectField
            name={formFields.interval}
            label=" "
            required={true}
            options={subsIntervalOptions}
            placeholder={"select interval"}
          />
          <InputNumberField
            name={formFields.cycleCount}
            label=" "
            required={true}
            placeholder={"0"}
            addonBefore={"For"}
            addonAfter={"cycles"}
            changeOnWheel={true}
          />
        </Space.Compact>
        <div className="grid grid-cols-2 gap-4">
          <DatePickerField
            name={formFields.expiryDate}
            label="Expiry Date"
            required={true}
            minDate={dayjs().tz(getTimezone()).add(1, "day").startOf("day")}
          />
          <InputField
            name={formFields.receiptId}
            label="Receipt Id"
            required={false}
            placeholder={"Enter receipt id"}
          />
        </div>
      </Form>
    </>
  );
};

export default GeneratePaymentLink;

import NavbarWrapper from "../components/Navbar/NavbarWrapper";
import { TermsConditions } from "../../modules/authentication";
import { useGlobalContext } from "../../common/contexts/GlobalContext";
import { Box, Modal } from "@mui/material";

const withNavbarHOC = (WrappedComponent) => {
  const ComponentWithNavbar = (props) => {
    const { open } = useGlobalContext();

    return (
      <NavbarWrapper>
        <WrappedComponent {...props} />

        {/* Terms and Conditions Modal */}
        <Modal
          open={open}
          onClose={null}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          className=""
        >
          <Box
            id="tncModal"
            className="absolute bg-black shadow-xl top-[5vh] w-[80vw] h-[90vh] left-[10vw] rounded-3xl border-gray-300 border overflow-y-auto"
          >
            <TermsConditions />
          </Box>
        </Modal>
      </NavbarWrapper>
    );
  };
  return ComponentWithNavbar;
};

export default withNavbarHOC;
import {
  HomeIcon,
  CurrencyDollarIcon,
  Cog6ToothIcon,
  BookOpenIcon,
  ArrowRightStartOnRectangleIcon as LogoutIcon,
  WalletIcon,
  BanknotesIcon,
} from "@heroicons/react/24/solid";
import SideNavListItem from "./SideNavListItem";
import { Technical_Doc } from "../../../../routes/ExternalRoutes";
import { LuCalendarClock } from "react-icons/lu";
export const SideNavMenuTop = () => {
  return (
    <>
      <SideNavListItem Icon={<HomeIcon />} text="Home" link="/" />
      <SideNavListItem
        Icon={<CurrencyDollarIcon />}
        text="Transactions"
        link="/transactions"
      />
      <SideNavListItem
        Icon={<LuCalendarClock />}
        text="Subscriptions"
        link="/subscriptions"
      />
      <SideNavListItem
        Icon={<WalletIcon />}
        text="Payment Links"
        link="/payments"
      />
      <SideNavListItem
        Icon={<BanknotesIcon />}
        text="Balances"
        link="/balances"
      />
      {/* <SideNavListItem
        Icon={<BiSolidBank />}
        text="Virtual Accounts"
        link="/virtual-accounts"
      /> */}
    </>
  );
};

export const SideNavMenuBottom = () => {
  const onClickHelp = () => {
    window.open(Technical_Doc, "_blank");
  };

  return (
    <>
      <SideNavListItem
        Icon={<Cog6ToothIcon />}
        text="Settings"
        link="/settings"
      />
      <SideNavListItem
        Icon={<BookOpenIcon />}
        text="Documentation"
        onClick={onClickHelp}
      />
      <SideNavListItem Icon={<LogoutIcon />} text="Logout" link="/logout" />
    </>
  );
};

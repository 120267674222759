import { useState } from "react";
import { axiosInstance } from "../../../common/hooks/useAxios";
import captureAxiosError from "../../../common/utils/captureAxiosError";
import { getStandardApiHeadersWithAuth } from "../../../../utils/stateFunctions";
import toast from "react-hot-toast";
import useGlobalStore from "../../../common/store/GlobalStore";

export const useAddWebhookEndpoint = () => {
  const [loading, setLoading] = useState(false);
  const { mode } = useGlobalStore();

  const addWebhookEndpoint = async ({ endpoint }) => {
    setLoading(true);
    try {
      await axiosInstance.post(
        `webhook/register`,
        {
          mode: mode,
          endpoint: "https://" + endpoint,
        },
        {
          headers: getStandardApiHeadersWithAuth(),
        }
      );

      toast.success("Webhook endpoint added successfully!");
    } catch (e) {
      captureAxiosError(e);
    } finally {
      setLoading(false);
    }
  };

  return { loading, addWebhookEndpoint };
};

import useAxios from "../../../common/hooks/useAxios";
import {
  getCurrentMerchantId,
  getStandardApiHeadersWithAuth,
} from "../../../../utils/stateFunctions";

const useGenerateXKeys = (PGMode) => {
  const API_URL = `/merchant/${getCurrentMerchantId()}/generate-xpay-keys`;
  return useAxios({
    url: API_URL,
    method: "POST",
    data: { mode: PGMode },
    headers: getStandardApiHeadersWithAuth(),
  });
};

export default useGenerateXKeys;

/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import captureAxiosError from "../../../common/utils/captureAxiosError";
import { axiosInstance } from "../../../common/hooks/useAxios";
import { getStandardApiHeadersWithAuth } from "../../../../utils/stateFunctions";
import useLSCache from "./../../../common/hooks/useLSCache";

export const useGetSubsFilters = () => {
  const [subsStatusFilters, setSubsStatusFilters] = useState();
  const { createCache, getCachedData, isCacheValid } = useLSCache({
    key: "SUBS_LINK_FILTERS",
    ttl: 1,
  });

  useEffect(() => {
    const getSubsFilters = async () => {
      try {
        const { data } = await axiosInstance.post(
          "/subscription/link/filter-values",
          {
            filterTypes: ["STATUS_FILTER"],
          },
          {
            headers: getStandardApiHeadersWithAuth(),
          }
        );

        let filter = [];
        let allValues = [];
        data?.filterValues?.STATUS_FILTER.forEach((item) => {
          filter.push({
            label: item.displayName,
            value: item.id,
            data: [item.id],
          });

          allValues.push(item.id);
        });

        filter.push({
          label: "All",
          value: "ALL",
          data: allValues,
        });

        setSubsStatusFilters(filter);
        createCache(filter);
      } catch (e) {
        captureAxiosError(e);
      }
    };

    if (isCacheValid) {
      setSubsStatusFilters(getCachedData());
    } else {
      getSubsFilters();
    }
  }, []);

  return { subsStatusFilters };
};

/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { axiosInstance } from "../../../common/hooks/useAxios";
import { getStandardApiHeadersWithAuth } from "../../../../utils/stateFunctions";
import captureAxiosError from "../../../common/utils/captureAxiosError";
import useGlobalStore from "../../../common/store/GlobalStore";
import { useVBAContext } from "../context/VBAContext";

export const useGetVBATnxs = () => {
  const [vbaTnxs, setVBATnxs] = useState();
  const [isLoading, setIsLoading] = useState();
  const { mode } = useGlobalStore();
  const { setTotalCount } = useVBAContext();

  useEffect(() => {
    const getAllPayouts = async () => {
      setIsLoading(true);

      try {
        const { data } = await axiosInstance.post(
          `/xFlowPay/get-virtual-account-transactions`,
          {
            mode: mode,
          },
          {
            headers: getStandardApiHeadersWithAuth(),
          }
        );

        data?.transactions?.forEach((txn, index) => {
          txn.key = index;
        });

        setVBATnxs(data?.transactions);
        setTotalCount(data?.transactions.length);
      } catch (error) {
        captureAxiosError(error);
      } finally {
        setIsLoading(false);
      }
    };

    getAllPayouts();
  }, [mode]);

  return { vbaTnxs, isLoading };
};

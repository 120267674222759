/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from "react";
import { axiosInstance } from "./../../../common/hooks/useAxios";
import captureAxiosError from "./../../../common/utils/captureAxiosError";
import {
  getStandardApiHeadersWithAuth,
  getCurrentMerchantId
} from "../../../../utils/stateFunctions";
import useGlobalStore from "./../../../common/store/GlobalStore";

export const useGetBalanceDetails = () => {
  const { mode } = useGlobalStore();

  const [balanceAvailable, setBalanceAvailable] = useState();
  const [balanceAvailableSoon, setBalanceAvailableSoon] = useState();

  useEffect(() => {
    const getBalanceDetails = async () => {
      try {
        const { data } = await axiosInstance.post(
          `/balance/get-balance/${getCurrentMerchantId()}`,
          {
            mode: mode,
          },
          {
            headers: getStandardApiHeadersWithAuth(),
          }
        );
        setBalanceAvailable(data?.balanceAvailable / 100);
        setBalanceAvailableSoon(data?.balanceAvailableSoon / 100);
      } catch (err) {
        captureAxiosError(err);
      }
    };

    getBalanceDetails();
  }, [mode]);

  return { balanceAvailable, balanceAvailableSoon};
};

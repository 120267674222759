import React, { useState, useEffect } from "react";
import { axiosInstance } from "../../common/hooks/useAxios";
import { getAdminAuthToken } from "../../../utils/stateFunctions";
import MerchantInfo from "./MerchantInfo";
import toast from "react-hot-toast";

const OnboardingDashboard = () => {
  const [selectedMerchant, setSelectedMerchant] = useState(null);
  const [merchants, setMerchants] = useState([]);

  useEffect(() => {
    const getAllDetails = async () => {
      toast.loading("Fetching all merchant details...", {
        id: "merchantDetails",
      });
      try {
        const response = await axiosInstance.get(
          `/admin/getAllDetails`,
          {
            headers: {
              Authorization: `Internal ${getAdminAuthToken()}`,
            },
          }
        );

        setMerchants(response.data);
        toast.success("All merchant details fetched", {
          id: "merchantDetails",
        });
      } catch (error) {
        toast.error("Error fetching all merchant details", {
          id: "merchantDetails",
        });
      }
    };

    getAllDetails();
  }, []);

  const getMerchantDetails = async (merchantId) => {
    toast.loading("Fetching merchant details...", { id: "merchantDetails" });
    try {
      const response = await axiosInstance.get(
        `/admin/${merchantId}`,
        {
          headers: {
            Authorization: `Internal ${getAdminAuthToken()}`,
          },
        }
      );

      setSelectedMerchant(response?.data);

      toast.success("Merchant details fetched", { id: "merchantDetails" });
    } catch (error) {
      toast.error("Error fetching merchant details", { id: "merchantDetails" });
    }
  };

  return (
    <div className="min-h-[100vh] p-6 mx-auto text-black bg-gray-100">
      <h1 className="mb-6 text-3xl font-bold">Onboarding Dashboard</h1>
      <div className="flex">
        {/* Merchant List */}
        <div className="w-1/3 pr-4">
          <h2 className="mb-4 text-xl font-semibold">Merchant List</h2>
          <div className="bg-white rounded shadow-md">
            {merchants.map((merchant) => (
              <div
                key={merchant.merchantId}
                className="p-4 border-b cursor-pointer hover:bg-gray-100"
                onClick={() => getMerchantDetails(merchant.merchantId)}
              >
                <p className="font-semibold">{merchant?.merchantName}</p>
                <p className="text-sm text-gray-600">
                  ID: {merchant?.merchantId}
                </p>
                <p className="text-sm text-gray-600">
                  Status: {merchant?.status}
                </p>
              </div>
            ))}
          </div>
        </div>

        {/* Merchant Details */}
        <div className="w-2/3 pl-4">
          {selectedMerchant ? (
            <MerchantInfo merchantData={selectedMerchant} />
          ) : (
            <p className="mt-8 text-xl text-center">
              Select a merchant to view details
            </p>
          )}
        </div>
      </div>
    </div>
  );
};

export default OnboardingDashboard;

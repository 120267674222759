import {
  getStandardApiHeadersWithAuth,
  getTimezone,
} from "../../../../utils/stateFunctions";
import { axiosInstance } from "../../../common/hooks/useAxios";
import captureAxiosError from "../../../common/utils/captureAxiosError";
import { options, options2 } from "../constants/chartsConfig";
import dayjs from "../../../../utils/CustomDayjs";
import ParseAmount from "../../../common/components/UI/ParseAmount";

const useGetChartsData = ({ mode, startTime, endTime }) => {
  const hourPerBucket = endTime - startTime > 345600000 ? 24 : 1;
  const timezone = getTimezone();

  function createLabels(startTime, endTime) {
    let labels = [];

    const startDay =
      dayjs().tz(timezone).format() === dayjs.utc().format()
        ? dayjs.utc().startOf("day")
        : dayjs.tz(startTime, timezone).startOf("day");
    const endDay =
      dayjs().tz(timezone).format() === dayjs.utc().format()
        ? dayjs.utc().endOf("day")
        : dayjs.tz(endTime, timezone).endOf("day");

    const now = dayjs().tz(timezone);
    const today = now.startOf("day");
    const yesterday = today.subtract(1, "day");
    const fourDays = 345600000; // milliseconds in 4 days

    if (endTime - startTime < fourDays) {
      for (
        let day = startDay;
        day.isSameOrBefore(endDay, "day");
        day = day.add(1, "day")
      ) {
        const currHour = day.isSame(now, "day") ? now.hour() : 23;

        for (let hour = 0; hour <= currHour; hour++) {
          let dateLabel;
          if (day.isSame(today, "day")) {
            dateLabel = "Today";
          } else if (day.isSame(yesterday, "day")) {
            dateLabel = "Yesterday";
          } else {
            dateLabel = day.format("D MMM"); // e.g., "14 Aug"
          }
          labels.push(`${dateLabel}, ${String(hour).padStart(2, "0")}:00`);
        }
      }
    } else {
      for (
        let day = startDay;
        day.isSameOrBefore(endDay, "day");
        day = day.add(1, "day")
      ) {
        let dateLabel;
        if (day.isSame(today, "day")) {
          dateLabel = "Today";
        } else if (day.isSame(yesterday, "day")) {
          dateLabel = "Yesterday";
        } else {
          dateLabel = day.format("D MMM"); // e.g., "14 Aug"
        }
        labels.push(dateLabel);
      }
    }

    return labels;
  }

  let chartLabels = createLabels(startTime, endTime);

  const getChartDataVolume = async ({ type, setChartData, endpoint }) => {
    const reqData = {
      type,
      mode,
      startTime,
      endTime,
      timezone: getTimezone(),
      hourPerBucket,
    };

    try {
      const response = await axiosInstance.post(endpoint, reqData, {
        headers: getStandardApiHeadersWithAuth(),
      });

      const aggregatedArray = Object.values(response?.data?.data)
        .flat()
        .map((num) => num / 100);

      const amountSum = response?.data?.amountSum
        ? response?.data?.amountSum / 100
        : 0;
      const chartData = { aggregatedArray, amountSum };

      setChartData(chartData);
    } catch (error) {
      captureAxiosError(error);
    }
  };

  const getChartDataCount = async ({ type, setChartData, endpoint }) => {
    const reqData = {
      type,
      mode,
      startTime,
      endTime,
      timezone: getTimezone(),
      hourPerBucket,
    };

    try {
      const response = await axiosInstance.post(endpoint, reqData, {
        headers: getStandardApiHeadersWithAuth(),
      });

      const aggregatedArray = Object.values(response?.data?.data).flat();

      const amountSum = response?.data?.amountSum || 0;
      const chartData = { aggregatedArray, amountSum };

      setChartData(chartData);
    } catch (error) {
      captureAxiosError(error);
    }
  };

  const makeChartDatasets = ({ label, chartData, color }) => {
    return {
      label: label,
      data: chartData?.length ? chartData : Array(label?.length).fill(0),
      borderColor: color,
      borderWidth: 2,
      pointRadius: 0,
      tension: 0.1,
      fill: true,
      lineTension: 0.1,
      hoverBorderColor: color,
    };
  };

  const getChartsContent = ({
    grossChartData,
    netVolumeChartData,
    txnCntSuccessChartData,
    txnCntFailureChartData,
  }) => {
    return [
      {
        title: "Gross Amount",
        infoText:
          "Displays the total amount before any deductions, including taxes, fees, or other withholdings.",
        amount: (
          <ParseAmount
            amount={grossChartData?.amountSum}
            currencySize="sm"
          />
        ),
        chartDataState: grossChartData?.aggregatedArray,
        chartData: {
          labels: chartLabels,
          datasets: [
            makeChartDatasets({
              label: "Gross Amount",
              chartData: grossChartData?.aggregatedArray,
              color: "#2d9ec9",
            }),
          ],
        },
        chartOptions: options,
        startTimeLable: chartLabels[0],
        endTimeLable: chartLabels[chartLabels.length - 1],
      },
      {
        title: "Net Amount",
        infoText:
          "Shows the total value of transactions after deducting any fees, taxes, or other withholdings.",
        amount: (
          <ParseAmount
            amount={netVolumeChartData?.amountSum}
            currencySize="sm"
          />
        ),
        chartDataState: netVolumeChartData?.aggregatedArray,
        chartData: {
          labels: chartLabels,
          datasets: [
            makeChartDatasets({
              label: "Net Amount",
              chartData: netVolumeChartData?.aggregatedArray,
              color: "#46abae",
            }),
          ],
        },
        chartOptions: options,
        startTimeLable: chartLabels[0],
        endTimeLable: chartLabels[chartLabels.length - 1],
      },
      {
        title: "Success Count",
        infoText:
          "Displays a transaction that has been completed successfully without any errors or issues.",
        amount: (
          <div>
            {" "}
            {txnCntSuccessChartData?.amountSum}{" "}
            <span className="text-sm opacity-50">Success</span>{" "}
          </div>
        ),
        chartDataState: txnCntSuccessChartData?.aggregatedArray,
        chartData: {
          labels: chartLabels,
          datasets: [
            makeChartDatasets({
              label: "Success",
              chartData: txnCntSuccessChartData?.aggregatedArray,
              color: "#46abae",
            }),
          ],
        },
        chartOptions: options2,
        startTimeLable: chartLabels[0],
        endTimeLable: chartLabels[chartLabels.length - 1],
      },
      {
        title: "Failure Count",
        infoText:
          "Shows a transaction that has been completed successfully without any errors or issues.",
        amount: (
          <div>
            {" "}
            {txnCntFailureChartData?.amountSum}{" "}
            <span className="text-sm opacity-50">Failure</span>
          </div>
        ),
        chartDataState: txnCntFailureChartData?.aggregatedArray,
        chartData: {
          labels: chartLabels,
          datasets: [
            makeChartDatasets({
              label: "Failure",
              chartData: txnCntFailureChartData?.aggregatedArray,
              color: "#2d9ec9",
            }),
          ],
        },
        chartOptions: options2,
        startTimeLable: chartLabels[0],
        endTimeLable: chartLabels[chartLabels.length - 1],
      },
    ];
  };

  return {
    getChartDataVolume,
    getChartDataCount,
    getChartsContent,
  };
};

export default useGetChartsData;

import { isEmpty } from "lodash";
import { FilterQueryKeyMap } from "../../modules/transactions/constants/entities";
import { FilterQueryKeyMap as PaymentLinkFilterQueryKeyMap } from "../../modules/payments/constants/entities";
import { getTimezone } from "../../../utils/stateFunctions";

/* Transform filter query and metadata to payload for Txn type filters */
class FilterTransformer {
  constructor(filterQuery, metadata) {
    this.filterQuery = filterQuery;
    this.metadata = metadata;
  }

  // Apply status, currency, pg filters
  applyGenericTxnFilters = (payload) => {
    const query = this.filterQuery;
    Object.keys(FilterQueryKeyMap).forEach((key) => {
      if (!isEmpty(query[key])) {
        payload[FilterQueryKeyMap[key]] = query[key];
      }
    });
  };

  applyGenericPaymentLinkFilters = (payload) => {
    const query = this.filterQuery;
    Object.keys(PaymentLinkFilterQueryKeyMap).forEach((key) => {
      if (!isEmpty(query[key])) {
        payload[PaymentLinkFilterQueryKeyMap[key]] = query[key];
      }
    });
  };

  // Apply start and end time filters
  applyDurationFilter = (payload) => {
    const { duration } = this.filterQuery;
    let { fromDate, toDate } = duration;
    fromDate = fromDate.tz(getTimezone()).startOf("d");
    toDate = toDate.tz(getTimezone()).endOf("d");
    payload.startTime = fromDate.valueOf();
    payload.endTime = toDate.valueOf();
  };
  // Apply sort field and sort order
  applySort = (payload) => {
    const { sortField, sortOrder } = this.metadata;
    if (!isEmpty(sortField) && !isEmpty(sortOrder)) {
      payload.sortField = sortField;
      payload.sortOrder = sortOrder;
    }
  };
  // Apply page size and page number
  applyPageFilter = (payload) => {
    const { page, pageSize } = this.metadata;
    payload.pageSize = pageSize;
    payload.startIndex = (page - 1) * pageSize;
  };
}

export default FilterTransformer;

import React from "react";
import { Form, Radio } from "antd";
import { useOnboardingContext } from "../../contexts/OnboardingContext";
import { formFields } from "../../constants/OnboardingFields";
import { businessTypesOptions } from "../../constants/BusinessTypesOptions";
import { getValidationRules } from "../../services/validation";

const BusinessType = () => {
  const { onboardingFields, setOnboardingFields } = useOnboardingContext();

  const name = formFields.businessType;
  const label = "Business Type";
  const required = true;

  const onChange = (e) => {
    setOnboardingFields({
      ...onboardingFields,
      [formFields.businessType]: e.target.value,
    });
  };

  return (
    <div className="flex items-center justify-center mt-16">
      <Form.Item
        hasFeedback
        name={name}
        validateFirst
        rules={getValidationRules(name, required, label)}
      >
        <Radio.Group
          name={formFields.businessType}
          defaultValue={"Private Limited"}
          className="flex flex-col items-center justify-center gap-10 md:flex-row"
          optionType="button"
          value={onboardingFields[formFields.businessType]}
          onChange={onChange}
          required={required}
        >
          {businessTypesOptions.map((option) => {
            return (
              <Radio.Button
                value={option.value}
                className="text-white flex flex-col items-center justify-center w-full h-40 md:w-72 md:h-40 p-4  rounded-lg bg-[#121212]"
              >
                <div className="flex justify-center text-4xl font-bold items-centers">
                  {option.icon}
                </div>
                <div className="mt-4 text-lg font-bold">{option.label}</div>
              </Radio.Button>
            );
          })}
        </Radio.Group>
      </Form.Item>
    </div>
  );
};

export default BusinessType;

import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import NavRoutes from "./routes";
import theme from "./v1/common/constants/Theme";
import GlobalErrorBoundary from "./v1/common/components/ErrorHandling/GlobalErrorBoundary";
import { GlobalProvider } from "./v1/common/contexts/GlobalContext";
import { Toaster } from "react-hot-toast";

function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <GlobalProvider>
        <GlobalErrorBoundary>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <NavRoutes />
            <Toaster
              containerStyle={{
                bottom: 50,
              }}
            />
          </LocalizationProvider>
        </GlobalErrorBoundary>
      </GlobalProvider>
    </ThemeProvider>
  );
}

export default App;

import { IconButton, Stack, TextField } from "@mui/material";
import { EyeIcon, EyeSlashIcon } from "@heroicons/react/24/solid";
import { useRef, useState } from "react";
import { LoadingButton } from "@mui/lab";
import { has } from "lodash";
import useResetPassword from "../hooks/useResetPassword";
import { useNavigate, useParams } from "react-router-dom";
import LayoutContainerWithBg from "../../../common/components/UI/LayoutContainerWithBg";
import {
  validatePassword,
  validateConfirmPassword,
} from "../../../common/utils/validators";

export const StdTextfieldProps = {
  variant: "outlined",
  fullWidth: true,
  size: "small",
  inputProps: {
    sx: {
      boxShadow: "none !important",
    },
  },
};

const NewPasswordForm = () => {
  const passwordRef = useRef(null);
  const cnfPassRef = useRef(null);

  const { tokenId } = useParams();

  const [showPassword, setShowPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const handleResetPassword = useResetPassword(setError);
  const navigate = useNavigate();

  const toggleShowPassword = () => setShowPassword(!showPassword);

  const redirectAfterSuccess = () =>
    setTimeout(() => {
      navigate("/login");
    }, 2000);

  const validateInput = (inputName, inputValue) => {
    const validationErrors = { ...error };
    delete validationErrors[inputName];

    switch (inputName) {
      case "password":
        const passwordError = validatePassword(inputValue);
        if (passwordError) {
          validationErrors.password = passwordError;
        }
        break;
      case "cnfPass":
        const confirmPasswordError = validateConfirmPassword(
          passwordRef.current.value,
          inputValue
        );
        if (confirmPasswordError) {
          validationErrors.cnfPass = confirmPasswordError;
        }
        break;
      default:
        break;
    }

    setError(validationErrors);
  };

  const handleInputChange = (event) => {
    const inputName = event.target.name;
    const inputValue = event.target.value;
    validateInput(inputName, inputValue);
  };

  const handleSubmit = async () => {
    const password = passwordRef.current?.value;
    const confirmPassword = cnfPassRef.current?.value;

    const validationErrors = { ...error };
    if (Object.keys(validationErrors).length > 0) {
      setIsLoading(false);
      return;
    }

    setIsLoading(true);

    const isSuccess = await handleResetPassword(
      password,
      confirmPassword,
      tokenId
    );

    if (isSuccess) {
      redirectAfterSuccess();
    }
    setIsLoading(false);
  };

  return (
    <LayoutContainerWithBg>
      <Stack gap={2} my={6}>
        <TextField
          error={has(error, "password")}
          helperText={error?.password ?? null}
          inputRef={passwordRef}
          name="password"
          label="Password"
          type={showPassword ? "text" : "password"}
          {...StdTextfieldProps}
          autoComplete="current-password"
          InputProps={{
            endAdornment: (
              <IconButton onClick={toggleShowPassword}>
                {showPassword ? (
                  <EyeSlashIcon height={14} />
                ) : (
                  <EyeIcon height={14} />
                )}
              </IconButton>
            ),
          }}
          onChange={handleInputChange}
        />
        <TextField
          error={has(error, "cnfPass")}
          helperText={error?.cnfPass ?? null}
          name="cnfPass"
          inputRef={cnfPassRef}
          label="Confirm Password"
          type={"password"}
          {...StdTextfieldProps}
          autoComplete="current-password"
          onChange={handleInputChange}
        />
      </Stack>
      <Stack gap={2}>
        <LoadingButton
          loading={isLoading}
          fullWidth
          variant="contained"
          onClick={handleSubmit}
        >
          Change Password
        </LoadingButton>
      </Stack>
    </LayoutContainerWithBg>
  );
};

export default NewPasswordForm;

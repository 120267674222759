import React from "react";
import { ConfigProvider } from "antd";

const AntDesignContext = (props) => {
  return (
    <ConfigProvider
      theme={{
        components: {
          Button: {
            defaultBorderColor: `rgba(255, 255, 255, 0.5)`,
            defaultHoverBg: `rgba(255, 255, 255, 0)`,
            defaultActiveBg: `rgba(255, 255, 255, 0)`,
            borderColorDisabled: `rgba(255, 255, 255, 0.2)`,
            colorTextDisabled: `rgba(255, 255, 255, 0.5)`,
          },
          Form: {
            labelColor: "#FFFFFF",
            colorText: "#FFFFFF",
            colorTextDescription: `rgba(255, 255, 255, 0.5)`,
          },
          Input: {
            activeBg: "transparent",
            hoverBg: "transparent",
            colorText: "#FFFFFF",
            colorBgContainer: "#000000",
            handleBorderColor: "#000000",
            colorBorder: `rgba(255, 255, 255, 0.5)`,
            colorTextPlaceholder: `rgba(255, 255, 255, 0.5)`,
          },
          InputNumber: {
            activeBg: "transparent",
            hoverBg: "transparent",
            colorText: "#FFFFFF",
            colorBgContainer: "#000000",
            handleBorderColor: "#000000",
            colorBorder: `rgba(255, 255, 255, 0.5)`,
            colorTextPlaceholder: `rgba(255, 255, 255, 0.5)`,
            filledHandleBg: `rgba(255, 255, 255, 1)`,
            colorTextDescription: `rgba(255, 255, 255, 1)`,
          },
          Select: {
            colorBgContainer: "#000000",
            colorBorder: `rgba(255, 255, 255, 0.5)`,
            colorTextPlaceholder: `rgba(255, 255, 255, 0.5)`,
            colorText: `rgba(255, 255, 255, 1)`,
            colorTextDisabled: `rgba(255, 255, 255, 0.5)`,
            optionActiveBg: `rgba(0, 0, 0, 0.5)`,
            optionSelectedBg: `#1e293b`,
            multipleItemBg: `rgba(255, 255, 255, 0.2)`,
            colorIcon: `rgba(255, 255, 255, 0.5)`,
            colorIconHover: `rgba(255, 255, 255, 0.3)`,
            colorTextDescription: `rgba(255, 255, 255, 0.5)`,
          },
          Progress: {
            colorText: `rgba(255, 255, 255, 1)`,
          },
          Alert: {
            colorSuccessBg: "#121212",
            colorSuccess: "#FFFFFF",
            colorText: `rgba(255, 255, 255, 1)`,
            colorTextHeading: `rgba(255, 255, 255, 1)`,
            colorSuccessBorder: `rgba(255, 255, 255, 0.1)`,
          },
          Spin: {
            colorBgMask: "#000000",
          },
          Drawer: {
            colorBgElevated: "#353535",
            colorText: "#FFFFFF",
          },
          Modal: {
            contentBg: "#121212",
            headerBg: "#121212",
            titleColor: `rgba(255, 255, 255, 1)`,
            colorText: `rgba(255, 255, 255, 1)`,
            colorBgTextActive: `rgba(255, 255, 255, 1)`,
          },
          Table: {
            colorBgContainer: "#1e1e1e",
            colorText: "#FFFFFF",
            headerColor: "#FFFFFF",
            headerBg: "#000000",
            borderColor: "#515151",
            bodySortBg: "rgba(0, 0, 0, 0.5)",
            headerSortActiveBg: "rgba(0, 0, 0, 0.5)",
            headerSortHoverBg: "rgba(0, 0, 0, 0.5)",
            rowHoverBg: `rgba(255, 255, 255, 0.1)`,
            headerSplitColor: `rgba(255, 255, 255, 0.5)`,
            colorTextDisabled: `rgba(255, 255, 255, 0.5)`,
            colorTextHeading: `rgba(255, 255, 255, 1)`,
          },
          Pagination: {
            itemActiveBg: "transparent",
            itemBg: "transparent",
            itemActiveColorDisabled: "#FFFFFF",
            itemActiveBgDisabled: "#FFFFFF",
            colorText: `rgba(255, 255, 255, 1)`,
            colorTextDisabled: `rgba(255, 255, 255, 0.5)`,
          },
          Empty: {
            colorTextDescription: `rgba(255, 255, 255, 0.5)`,
          },
          Divider: {
            colorSplit: `rgba(255, 255, 255, 0.25)`,
          },
          Timeline: {
            colorText: `rgba(255, 255, 255, 1)`,
            tailColor: `rgba(255, 255, 255, 0.5)`,
          },
          Collapse: {
            contentBg: "tranparent",
            colorText: `rgba(255, 255, 255, 1)`,
            colorTextHeading: `rgba(255, 255, 255, 1)`,
            colorBorder: `rgba(255, 255, 255, 0.4)`,
            padding: 0,
            paddingSM: 8,
          },
          DatePicker: {
            activeBg: "transparent",
            colorBgContainer: "#121212",
            colorBgElevated: "#000000",
            colorText: `rgba(255, 255, 255, 1)`,
            colorTextLightSolid: `rgba(255, 255, 255, 1)`,
            colorTextPlaceholder: `rgba(255, 255, 255, 0.5)`,
            colorTextTertiary: `rgba(255, 255, 255, 0.5)`,
            colorTextHeading: `rgba(255, 255, 255, 1)`,
            colorTextDescription: `rgba(255, 255, 255, 0.5)`,
            colorSplit: `rgba(255, 255, 255, 0.25)`,
            colorIconHover: `rgba(255, 255, 255, 0.5)`,
            colorIcon: `rgba(255, 255, 255, 1)`,
            colorBorder: `rgba(255, 255, 255, 0.5)`,
            addonBg: `rgba(255, 255, 255, 0.1)`,
            cellActiveWithRangeBg: `rgba(255, 255, 255, 0.2)`,
            colorFillSecondary: `rgba(255, 255, 255, 0.5)`,
            controlItemBgActive: `rgba(255, 255, 255, 0.1)`,
            cellBgDisabled: `rgba(255, 255, 255, 0.1)`,
            cellHoverBg: `rgba(255, 255, 255, 0.1)`,
            cellHoverWithRangeBg: `rgba(255, 255, 255, 1)`,
            colorTextDisabled: `rgba(255, 255, 255, 0.5)`,
          },
          Tabs: {
            itemColor: `rgba(255, 255, 255, 1)`,
            colorText: `rgba(255, 255, 255, 1)`,
            itemSelectedColor: `#0284c7`,
            inkBarColor: `#0284c7`,
            itemHoverColor: `#0369a1`,
            itemActiveColor: `#0284c7`,
            colorBorderSecondary: `rgba(255, 255, 255, 0.5)`,
          },
        },
      }}
    >
      {props.children}
    </ConfigProvider>
  );
};

export default AntDesignContext;

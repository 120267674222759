import React from "react";
import AntDesignContext from "../../../../common/contexts/AntDesignContext";
import { Input, Form } from "antd";
import { useOnboardingContext } from "../../contexts/OnboardingContext";
import { getValidationRules } from "../../services/validation";

const InputFieldPartners = ({
  label,
  placeholder,
  helper,
  prefix,
  suffix,
  addonAfter,
  addonBefore,
  name,
  required,
  label2,
  helper2,
}) => {
  const { onboardingFields, setOnboardingFields } = useOnboardingContext();

  const onInputChange = (e) => {
    setOnboardingFields({ ...onboardingFields, [name]: e.target.value });
  };

  return (
    <div>
      <AntDesignContext>
        <Form.Item
          hasFeedback
          label={
            <div className="pb-2 text-lg font-normal text-white">
              {label}{" "}
              <span className="text-sm font-normal opacity-50">{helper} </span>{" "}
              {label2}
              <span className="text-sm font-normal opacity-50">{helper2} </span>
            </div>
          }
          name={name}
          validateFirst
          rules={getValidationRules(name, required, label)}
        >
          <Input
            prefix={prefix}
            suffix={suffix}
            addonAfter={addonAfter}
            addonBefore={addonBefore}
            size="default"
            placeholder={placeholder}
            className="text-white bg-[#121212] rounded-lg placeholder:text-white placeholder:opacity-50 hover:bg-[#121212] focus:bg-[#121212]"
            required={required}
            onChange={onInputChange}
            value={onboardingFields[name]}
          />
        </Form.Item>
      </AntDesignContext>
    </div>
  );
};

export default InputFieldPartners;

import { Radio } from "antd";
import { usePaymentLinksContext } from "../../contexts/PaymentLinksContext";

const SubsStatusFilter = ({ statuses }) => {
  const { subsStatusFilter, setSubsStatusFilter, subsSearchByIdText } =
    usePaymentLinksContext();
  const onChange = (e) => {
    const selectedOption = statuses.find(
      (option) => option.value === e.target.value
    );
    if (selectedOption) {
      setSubsStatusFilter(selectedOption);
    }
  };

  return (
    <div id="status-filter">
      <Radio.Group
        onChange={onChange}
        value={subsStatusFilter.value}
        className="flex w-full gap-4 mt-4 mb-8"
        disabled={subsSearchByIdText?.length > 0}
        block
      >
        {statuses?.map((option, index) => {
          return (
            <Radio.Button
              value={option.value}
              className="flex items-center justify-start w-full h-10 p-4 text-white border border-opacity-50 rounded-lg"
              style={{
                backgroundColor:
                  subsStatusFilter === option.value ? "#121212" : "initial",
              }}
              key={index}
            >
              <div className="">{option.label}</div>
            </Radio.Button>
          );
        })}
      </Radio.Group>
    </div>
  );
};

export default SubsStatusFilter;

import React from "react";
import InputField from "../UI/InputField";
import SelectField from "../UI/SelectField";
import TextAreaField from "../UI/TextAreaField";
import InputNumberField from "../UI/InputNumberField";
import { ExportPurposeCode } from "../../constants/ExportPurposeCode";
import { formFields } from "../../constants/OnboardingFields";
import MultipleSelectField from "../UI/MultipleSelectField";
import { CountryList } from "../../constants/CountryList";

const Description = () => {
  return (
    <div>
      <div className="grid items-start justify-start w-full grid-cols-1 pb-10 md:grid-cols-2 gap-x-20 md:gap-y-10 gap-y-3">
        <InputField
          label="Website"
          placeholder="company.website.com"
          addonBefore="https://"
          name={formFields.businessDetails.website}
          required={true}
        />
        <InputField
          label="Doing Business As"
          placeholder="Enter how are you doing your business"
          name={formFields.businessDetails.doingBusinessAs}
          required={true}
          tooltip={
            "Enter the name under which your business operates publicly. This may differ from your legal business name. For example, if your legal name is 'Alphabet Inc.' but you operate as 'Google,' enter 'Google' here."
          }
        />
        <TextAreaField
          label="Brief Description of Product/Services offered"
          placeholder="Enter your company description here."
          name={formFields.businessDetails.productDesc}
          required={true}
        />
        <InputNumberField
          label="Next 12 months volume"
          helper="(in USD)"
          prefix="$"
          addonAfter="USD"
          placeholder="0000000"
          name={formFields.businessDetails.volume}
          required={true}
        />
        <SelectField
          label=" Export Purpose Code"
          placeholder="Enter your export purpose code"
          options={ExportPurposeCode}
          name={formFields.businessDetails.exportPurposeCode}
          required={true}
          tooltip={
            "Select the appropriate Export Purpose Code to classify the nature of your export transaction. This code is required for compliance with RBI regulations and helps identify the purpose of foreign exchange inflow related to exports."
          }
        />
        <MultipleSelectField
          label="Local Currencies Needed"
          helper="(by default in USD)"
          placeholder="Select the countries where you need local currencies"
          options={CountryList}
          name={formFields.businessDetails.localCurrenciesNeeded}
          required={false}
        />
      </div>
    </div>
  );
};

export default Description;

import { round } from "lodash";

export const CapitaliseString = (str) => {
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
};

export const GenerateRandomColorHex = (exclude = "#000000") => {
  const hex = Math.floor(Math.random() * 0xffffff);
  const color = "#" + hex.toString(16);
  if (color === exclude) {
    return GenerateRandomColorHex(exclude);
  }
  return color;
};

export const getInitials = (str) => {
  if (!str) {
    return;
  }
  return str
    .split(" ")
    .map((c) => c[0])
    .join("");
};

export const RoundNumberUsingSymbols = (num) => {
  if (num < 1000) {
    return num;
  }
  return round(num / 1000, 2) + "K";
};

import React from "react";

const QuestionHeader = ({ heading, subHeading }) => {
  return (
    <div className="flex flex-col gap-4 pb-8">
      <div className="text-2xl font-bold">{heading}</div>
      <div className="text-lg opacity-50">{subHeading}</div>
    </div>
  );
};

export default QuestionHeader;

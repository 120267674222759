import { axiosInstance } from "../../../common/hooks/useAxios";
import {
  getCurrentMerchantId,
  getStandardApiHeadersWithAuth,
} from "../../../../utils/stateFunctions";
import { toast } from "react-toastify";
import { useGlobalContext } from "../../../common/contexts/GlobalContext";
import useLogout from "../../authentication/hooks/useLogout";

const useGetOnboardingStatus = () => {
    const { setOnboardingStatus } = useGlobalContext();
    const handleLogout = useLogout();

  const getOnboardingStatus = async () => {
    try {
      const response = await axiosInstance.get(
        `/onboarding/status/${getCurrentMerchantId()}`,
        {
          headers: getStandardApiHeadersWithAuth(),
        }
      );

      setOnboardingStatus(response.data);
    } catch (error) {
      toast.error(error?.response?.data?.errorDescription || "Something went wrong");
      if (error?.response?.status === 401) {
        handleLogout();
      }
    }
  };

  return getOnboardingStatus;
};

export default useGetOnboardingStatus;

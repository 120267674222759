// src/pages/AdminLogin.js
import React from "react";
import { Form, Input, Button, Checkbox } from "antd";
import { FaUserShield, FaLock } from "react-icons/fa";
import captureAxiosError from "../../../common/utils/captureAxiosError";
import { axiosInstance } from "../../../common/hooks/useAxios";
import { setAdminAuthToken } from "./../../../../utils/stateFunctions";
import { toast } from "react-hot-toast";
import { useNavigate } from "react-router-dom";

const AdminLogin = () => {
  const navigateTo = useNavigate();

  const onFinish = async (values) => {
    toast.loading("loging you as admin...", {
      id: "toast",
    });
    try {
      const { data } = await axiosInstance.post("/internal-auth/login", {
        email: values.adminUsername,
        password: values.adminPassword,
      });
      setAdminAuthToken(data.authToken);

      toast.success("Login Success. Redirecting to admin dashbaord...", {
        id: "toast",
      });

      await new Promise((resolve) => setTimeout(resolve, 2000));

      navigateTo("/admin/dashboard");
    } catch (error) {
      toast.dismiss("toast");
      captureAxiosError(error);
    }
  };

  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-200">
      <div className="w-full max-w-md p-10 bg-white rounded-lg shadow-lg">
        <h2 className="mb-6 text-3xl font-bold text-center text-gray-800">
          Admin Login
        </h2>
        <Form
          name="admin_login"
          initialValues={{ remember: true }}
          onFinish={onFinish}
          className="admin-login-form"
        >
          {/* Username field */}
          <Form.Item
            name="adminUsername"
            rules={[
              { required: true, message: "Please enter your admin username!" },
            ]}
          >
            <Input
              prefix={<FaUserShield className="text-gray-400" />}
              placeholder="Admin Username"
              className="rounded-md"
            />
          </Form.Item>

          {/* Password field */}
          <Form.Item
            name="adminPassword"
            rules={[{ required: true, message: "Please enter your password!" }]}
          >
            <Input.Password
              prefix={<FaLock className="text-gray-400" />}
              placeholder="Password"
              className="rounded-md"
            />
          </Form.Item>

          {/* Remember me checkbox */}
          <Form.Item name="remember" valuePropName="checked" noStyle>
            <Checkbox onClick={()=> toast.success("Admin's are always remembered.")}>Remember me</Checkbox>
          </Form.Item>

          {/* Submit button */}
          <Form.Item className="mt-6">
            <Button
              type="primary"
              htmlType="submit"
              className="w-full bg-blue-700 rounded-md hover:bg-blue-800"
            >
              Log in as Admin
            </Button>
          </Form.Item>

          {/* Forgot password link */}
          <div className="text-center">
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <a className="text-blue-600 login-form-forgot" onClick={()=>toast.error("Forgetting Password is not expected from Admin! You should eat Almonds daily.")}>
              Forgot password?
            </a>
          </div>
        </Form>
      </div>
    </div>
  );
};

export default AdminLogin;

import React from "react";
import RecentTransactions from "./RecentTransactions";
import RecentPaymentLinks from "./RecentPaymentLinks";
import BalancesMetric from "./BalancesMetric";

const RightSideMetrics = () => {
  return (
    <>
      <BalancesMetric />
      <RecentTransactions />
      <RecentPaymentLinks />
    </>
  );
};

export default RightSideMetrics;

import { captureException } from "@sentry/react";
import { isEmpty } from "lodash";
import {
  getAuthToken,
  getStandardApiHeadersWithAuth,
  getApiPath,
} from "../../../utils/stateFunctions";
import toast from "react-hot-toast";

const captureAxiosError = (error) => {
  const isProduction = process.env.REACT_APP_XPAY_ENV === "prod";
  if (!isProduction) {
    console.error(error);
  }

  const handleCleanUp = () => {
    sessionStorage.clear();
    localStorage.removeItem("authToken");
    localStorage.removeItem("userId");
    localStorage.removeItem("userName");
    localStorage.removeItem("userEmail");
    localStorage.removeItem("userAvatarColor");
    localStorage.removeItem("merchantId");
    window.location.href = "/login";
  };

  const initiateLogout = async () => {
    const headers = getStandardApiHeadersWithAuth();
    await fetch(`${getApiPath()}/auth/logout`, {
      method: "POST",
      headers: headers,
    });
  };

  const handleLogout = async () => {
    const hasAuthToken = !isEmpty(getAuthToken());
    if (!hasAuthToken) {
      handleCleanUp();
      return;
    }
    await initiateLogout();
    handleCleanUp();
  };

  if (error?.response?.status === 401) {
    handleLogout();
  }

  if (!error) {
    toast.error("Unknown error occured", {
      id: "errorToast",
    });
    return;
  }
  captureException(error);
  const desc = error.response?.data?.errorDescription;
  if (desc) {
    toast.error(desc, {
      id: "errorToast",
    });
  } else {
    toast.error("Something went wrong", {
      id: "errorToast",
    });
  }
};

export default captureAxiosError;

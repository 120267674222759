/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import captureAxiosError from "../../../common/utils/captureAxiosError";
import { axiosInstance } from "../../../common/hooks/useAxios";
import {
  getCurrentMerchantId,
  getStandardApiHeadersWithAuth,
} from "../../../../utils/stateFunctions";
import useLSCache from "./../../../common/hooks/useLSCache";

export const useGetTransactionFilters = () => {
  const [statusFilters, setStatusFilters] = useState();
  const { createCache, getCachedData, isCacheValid } = useLSCache({
    key: "TRANSACTION_FILTERS",
    ttl: 1,
  });

  useEffect(() => {
    const getTransactionFilters = async () => {
      try {
        const { data } = await axiosInstance.post(
          `/transaction-page/merchant/${getCurrentMerchantId()}/filter-values`,
          {
            filterTypes: ["STATUS_FILTER"],
          },
          {
            headers: getStandardApiHeadersWithAuth(),
          }
        );

        let filter = [];

        data?.filterValues?.STATUS_FILTER.forEach((item) => {
          filter.push({
            label: item.displayName,
            value: item.id,
          });
        });

        setStatusFilters(filter);
        createCache(filter);
      } catch (e) {
        captureAxiosError(e);
      }
    };

    if (isCacheValid) {
      setStatusFilters(getCachedData());
      
    } else {
      getTransactionFilters();
    }
  }, []);

  return { statusFilters };
};

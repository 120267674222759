import axios from "axios";
import { useState } from "react";
import { useCallback } from "react";
import { getApiPath } from "../../../utils/stateFunctions";
import captureAxiosError from "../utils/captureAxiosError";

export const axiosInstance = axios.create({
  baseURL: getApiPath(),
  timeout: 5000,
});

const useAxios = (
  axiosOptions,
  onSuccessCallback = () => {},
  onErrorCallback = () => {}
) => {
  const [isLoading, setLoading] = useState(false);
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);

  const callApi = useCallback(async () => {
    setLoading(true);
    try {
      const response = await axiosInstance.request(axiosOptions);
      onSuccessCallback(response.data);
      setData(response.data);
      return response.data;
    } catch (e) {
      captureAxiosError(e);
      setError(e);
      onErrorCallback(e);
    } finally {
      setLoading(false);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [axiosOptions]);

  return { isLoading, data, error, callApi };
};

export default useAxios;

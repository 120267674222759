import { ReactComponent as LogoDarkBg } from "../svg/logo_dark_bg.svg";
import { ReactComponent as LogoLightBg } from "../svg/logo_light_bg.svg";

const Logo = ({ darkBg = true, height = 38, width = 120, style }) => {
  return darkBg ? (
    <LogoDarkBg
      style={{
        height: height,
        width: width,
        ...style,
      }}
    />
  ) : (
    <LogoLightBg
      style={{
        height: height,
        width: width,
        ...style,
      }}
    />
  );
};

export default Logo;


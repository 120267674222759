/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";
import FilterBox from "./FilterBox";
import StatusFilters from "./StatusFilters";
import { useSubscriptionContext } from "../contexts/SubscriptionContext";
import { useGetSubscriptionFilters } from "../hooks/useGetSubscriptionFilters";

const Filters = () => {
  const { setSubsSearchByIdText } = useSubscriptionContext();
  const { subsStatusFilters } = useGetSubscriptionFilters();

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const searchByIds = searchParams.get("search-by-ids");
    setSubsSearchByIdText(JSON.parse(decodeURIComponent(searchByIds)));
  }, []);

  return (
    <div>
      <FilterBox />
      <StatusFilters statuses={subsStatusFilters} />
    </div>
  );
};

export default Filters;

import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import toast from "react-hot-toast";
import { axiosInstance } from "./../../common/hooks/useAxios";
import { getAdminAuthToken } from "../../../utils/stateFunctions";
import MerchantDetails from "./MerchantDetails";
import MerchantInfo from "./MerchantInfo";
import Payouts from "./Payouts";
import { Collapse, Card } from 'antd';

const MerchantDashboard = () => {
  const [selectedMerchant, setSelectedMerchant] = useState(null);
  const [selectedOnboardingData, setSelectedOnboardingData] = useState(null);
  const [merchants, setMerchants] = useState([]);
  const [payouts, setPayouts] = useState([]);
  const [balance, setBalance] = useState();

  useEffect(() => {
    getAllMerchantDetails();
  }, []);

  const getAllMerchantDetails = async () => {
    toast.loading("Fetching all merchant details...", {
      id: "merchantDetails",
    });
    try {
      const response = await axiosInstance.get(
        `/admin/getAllOnboardedMerchants`,
        {
          headers: {
            Authorization: `Internal ${getAdminAuthToken()}`,
          },
        }
      );

      setMerchants(response.data);
      toast.success("All merchant details fetched", {
        id: "merchantDetails",
      });
    } catch (error) {
      toast.error("Error fetching all merchant details", {
        id: "merchantDetails",
      });
    }
  };

  const getMerchantDetails = async (merchantId) => {
    toast.loading("Fetching merchant details...", { id: "merchantDetails" });
    try {
      const response = await axiosInstance.get(
        `/admin/getMerchantDetails/${merchantId}`,
        {
          headers: {
            Authorization: `Internal ${getAdminAuthToken()}`,
          },
        }
      );

      setSelectedMerchant(response?.data);

      const { data } = await axiosInstance.get(`/admin/${merchantId}`, {
        headers: {
          Authorization: `Internal ${getAdminAuthToken()}`,
        },
      });

      setSelectedOnboardingData(data);

      const { data: payouts } = await axiosInstance.post(
        `/admin/get-payouts`,
        { merchantId: merchantId },
        {
          headers: {
            Authorization: `Internal ${getAdminAuthToken()}`,
          },
        }
      );

      setPayouts(payouts?.entries);

      const { data: balance } = await axiosInstance.post(
        `/admin/get-balance`,
        { merchantId: merchantId },
        {
          headers: {
            Authorization: `Internal ${getAdminAuthToken()}`,
          },
        }
      );

      setBalance(balance);

      window.scrollTo(0, 0);

      toast.success("Merchant details fetched", { id: "merchantDetails" });
    } catch (error) {
      toast.error("Error fetching merchant details", { id: "merchantDetails" });
    }
  };

  return (
    <div className="min-h-[100vh] p-6 mx-auto text-black bg-gray-100">
      <h1 className="mb-6 text-3xl font-bold">Merchant Dashboard</h1>
      <div className="flex">
        {/* Merchant List */}
        <div className="w-1/3 pr-4">
          <h2 className="mb-4 text-xl font-semibold">Merchant List</h2>
          <div className="bg-white rounded shadow-md">
            {merchants.map((merchant) => (
              <div
                key={merchant.merchantId}
                className="p-4 border-b cursor-pointer hover:bg-gray-100"
                onClick={() => getMerchantDetails(merchant.merchantId)}
              >
                <p className="font-semibold">{merchant?.merchantName}</p>
                <p className="text-sm text-gray-600">
                  ID: {merchant?.merchantId}
                </p>
                <p className="text-sm text-gray-600">
                  dba: {merchant?.businessDetails?.doingBusinessAs}
                </p>
                <p className="text-sm text-gray-600">
                  Company Name: {merchant?.businessDetails?.companyName}
                </p>
                <p className="text-sm text-gray-600">
                  Onboarding Status: {merchant?.status}
                </p>
              </div>
            ))}
          </div>
        </div>

        {/* Merchant Details */}
        <div className="w-2/3 pl-4">
          {selectedMerchant ? (
            <>
              <MerchantDetails
                merchantData={selectedMerchant}
                setMerchantData={setSelectedMerchant}
              />
              <Collapse accordion>
                {selectedOnboardingData && (
                  <Collapse.Panel header="Merchant Information" key="1">
                    <Card className="mb-4">
                      <MerchantInfo merchantData={selectedOnboardingData} />
                    </Card>
                  </Collapse.Panel>
                )}
                {payouts && (
                  <Collapse.Panel header="Payouts" key="2">
                    <Card className="mb-4">
                      <Payouts
                        payouts={payouts}
                        setPayouts={setPayouts}
                        merchantId={selectedMerchant.merchantId}
                        balance={balance}
                        setBalance={setBalance}
                      />
                    </Card>
                  </Collapse.Panel>
                )}
              </Collapse>
            </>
          ) : (
            <p className="mt-8 text-xl text-center">
              Select a merchant to view details
            </p>
          )}
        </div>
      </div>
    </div>
  );
};

export default MerchantDashboard;

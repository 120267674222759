/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";
import FilterBox from "./FilterBox";
import StatusFilters from "./StatusFilters";
import { usePaymentLinksContext } from "../../contexts/PaymentLinksContext";
import { useGetOneTimeFilters } from "../../hooks/useGetOneTimeFilters";

const Filters = () => {
  const { setSubsSearchByIdText } = usePaymentLinksContext();
  const { oneTimeStatusFilters } = useGetOneTimeFilters();

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const searchByIds = searchParams.get("search-by-ids");
    setSubsSearchByIdText(JSON.parse(decodeURIComponent(searchByIds)));
  }, []);

  return (
    <div>
      <FilterBox />
      <StatusFilters statuses={oneTimeStatusFilters} />
    </div>
  );
};

export default Filters;

import { Drawer, Stack, Divider, List, Box } from "@mui/material";
import Logo from "../../../../assets/icons/Logo";
import { SideNavMenuBottom, SideNavMenuTop } from "./SideNavMenu";

export const SIDENAV_WIDTH = 240;

const SideNav = () => {
  return (
    <Drawer
      variant="permanent"
      anchor="left"
      open
      sx={{
        width: SIDENAV_WIDTH,
        "& .MuiDrawer-paper": { boxSizing: "border-box", width: SIDENAV_WIDTH },
      }}
    >
      <Stack justifyContent="space-between" height="100vh">
        <Box pt={6}>
          <Logo style={{ margin: "auto" }} />
          <List disablePadding sx={{ mt: 6 }}>
            <SideNavMenuTop />
            <Divider />
          </List>
        </Box>
        <List disablePadding>
          <SideNavMenuBottom />
          <Divider />
        </List>
      </Stack>
    </Drawer>
  );
};

export default SideNav;

import React from "react";
import { useOnboardingContext } from "../../contexts/OnboardingContext";
import { Steps } from "antd";

const OnboardingSteps = () => {
  const { currentPage, onboardingSteps, currentBDPage, setCurrentPage } =
    useOnboardingContext();

  const progress = currentPage === 1 ? (currentBDPage + 1) * 20 : 0;

  const onChange = (value) => {
    if (value < currentPage) {
      setCurrentPage(value);
    }
  };

  return (
    <div className="hidden w-1/4 md:block">
      <Steps
        current={currentPage}
        items={onboardingSteps}
        direction="vertical"
        percent={progress}
        style={{ color: "white" }}
        size="small"
        onChange={onChange}
      />
    </div>
  );
};

export default OnboardingSteps;

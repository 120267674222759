import React from "react";
import { InputNumber, Form } from "antd";
import AntDesignContext from "../../../../common/contexts/AntDesignContext";
import { useOnboardingContext } from "../../contexts/OnboardingContext";
import { getValidationRules } from "../../services/validation";

const InputNumberField = ({
  label,
  placeholder,
  helper,
  prefix,
  suffix,
  addonAfter,
  addonBefore,
  name,
  required,
  label2,
  helper2,
}) => {
  const { onboardingFields, setOnboardingFields } = useOnboardingContext();

  const onInputChange = (value) => {
    setOnboardingFields({ ...onboardingFields, [name]: value });
  };

  return (
    <AntDesignContext>
      <Form.Item
        hasFeedback
        label={
          <div className="pb-2 text-lg font-normal text-white">
            {label}{" "}
            <span className="text-sm font-normal opacity-50">{helper} </span>{" "}
            {label2}
            <span className="text-sm font-normal opacity-50">{helper2} </span>
          </div>
        }
        name={name}
        validateFirst
        rules={getValidationRules(name, required, label)}
      >
        <InputNumber
          name={name}
          type="number"
          size="large"
          placeholder={placeholder}
          prefix={prefix}
          suffix={suffix}
          addonAfter={addonAfter}
          addonBefore={addonBefore}
          controls={false}
          className="w-full rounded-lg"
          onChange={onInputChange}
          value={onboardingFields[name]}
          required={required}
        />
      </Form.Item>
    </AntDesignContext>
  );
};

export default InputNumberField;

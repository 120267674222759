export const CountryList = [
  {
    label: "Asia",
    options: [
      { label: "Bangladesh (BDT)", value: "Bangladesh (BDT)" },
      { label: "Nepal (NPR)", value: "Nepal (NPR)" },
      { label: "Pakistan (PKR)", value: "Pakistan (PKR)" },
      { label: "Saudi Arabia (SAR)", value: "Saudi Arabia (SAR)" },
      { lable: "Sri Lanka (LKR)", value: "Sri Lanka (LKR)" },
      {
        label: "United Arab Emirates (AED)",
        value: "United Arab Emirates (AED)",
      },
      { label: "Vietnam (VND)", value: "Vietnam (VND)" },
    ],
  },
  {
    label: "North America",
    options: [
      { label: "United States (USD)", value: "United States (USD)" },
      { label: "Canada (CAD)", value: "Canada (CAD)" },
    ],
  },
  {
    label: "Europe",
    options: [
      { label: "Eurozone (EUR)", value: "Eurozone (EUR)" },
      { label: "United Kingdom (GBP)", value: "United Kingdom (GBP)" },
    ],
  },
  {
    label: "Australia",
    options: [{ label: "Australia (AUD)", value: "Australia (AUD)" }],
  },
  {
    label: "Others",
    options: [],
  },
];

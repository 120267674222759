import { useEffect } from "react";
import { useLocation } from "react-router-dom";

import { LoadingButton } from "@mui/lab";
import { Button, Grid } from "@mui/material";

import DueDateInput from "./DueDateInput";
import CustomTextField from "./CustomTextField";

import { useShallow } from "zustand/react/shallow";
import useGenLinkFormStore from "../../store/GenerateLinkFormStore";
import useLinkRecordsStore from "../../store/PaymentLinkRecordsStore";
import { useGlobalContext } from "../../../../common/contexts/GlobalContext";
import useGlobalStore from "../../../../common/store/GlobalStore";
import {
  validateEmail,
  validateAmount,
} from "../../../../common/utils/validators";

const GenerateLinkForm = () => {
  const location = useLocation();
  const { isMobile } = useGlobalContext();

  const { resetForm, generateLink, isLoading, checkFormReady } =
    useGenLinkFormStore(useShallow(selectStore));
  const { fetchRecords } = useLinkRecordsStore(useShallow(selectFetchStore));
  const pgMode = useGlobalStore((state) => state.mode);

  useEffect(() => {
    resetForm();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  const handleSubmit = async () => {
    const isFormReady = checkFormReady();
    if (isFormReady) {
      await generateLink(pgMode);
      fetchRecords(pgMode);
    }
  };

  return (
    <>
      <Grid container spacing={3} py={4}>
        <Grid item xs={isMobile ? 6 : 4}>
          <CustomTextField label="Name" metadataKey="name" />
        </Grid>
        <Grid item xs={isMobile ? 6 : 4}>
          <CustomTextField
            label="Email"
            metadataKey="email"
            validator={validateEmail}
          />
        </Grid>
        <Grid item xs={isMobile ? 6 : 4}>
          <CustomTextField label="Phone (optional)" metadataKey="phone" />
        </Grid>
        <Grid item xs={isMobile ? 6 : 4}>
          <CustomTextField
            label="Receipt ID (optional)"
            metadataKey="receiptId"
          />
        </Grid>
        <Grid item xs={isMobile ? 6 : 4}>
          <CustomTextField
            label="Amount (USD)"
            metadataKey="amount"
            type="number"
            validator={validateAmount}
          />
        </Grid>
        <Grid item xs={isMobile ? 6 : 4}>
          <DueDateInput />
        </Grid>
        <Grid item xs={isMobile ? 6 : 4}>
          <CustomTextField
            label="Description (optional)"
            metadataKey="description"
          />
        </Grid>
        {!isMobile && (
          <>
            <Grid item xs={2}>
              <Button fullWidth variant="outlined" onClick={resetForm}>
                Reset
              </Button>
            </Grid>
            <Grid item xs={2}>
              <LoadingButton
                fullWidth
                variant="contained"
                onClick={handleSubmit}
                loading={isLoading}
              >
                Generate Link
              </LoadingButton>
            </Grid>
          </>
        )}
      </Grid>
      {isMobile && (
        <Grid container spacing={3} py={4}>
          <Grid item xs={4}>
            <Button fullWidth variant="outlined" onClick={resetForm}>
              Reset
            </Button>
          </Grid>
          <Grid item xs={8}>
            <LoadingButton
              fullWidth
              variant="contained"
              onClick={handleSubmit}
              loading={isLoading}
            >
              Generate Link
            </LoadingButton>
          </Grid>
        </Grid>
      )}
    </>
  );
};

const selectStore = (state) => ({
  resetForm: state.resetForm,
  checkFormReady: state.checkFormReady,
  generateLink: state.generateLink,
  isLoading: state.isLoading,
});

const selectFetchStore = (state) => ({
  fetchRecords: state.fetchRecords,
});

export default GenerateLinkForm;

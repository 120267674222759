import React from "react";
import InputFieldPartners from "../../UI/InputFieldPartners";
import UploadFilePartners from "../../UI/UploadFilePartners";
import { formFields } from "../../../constants/OnboardingFields";

const PartnerCard = ({ partnerIndex, cardId }) => {
  return (
    <>
      <div className="">
        <div className="pb-2 text-lg font-medium">
          Partner/Director {partnerIndex + 1}
        </div>
        <div className="grid items-start justify-start grid-cols-1 md:grid-cols-2  md:gap-4 p-4 md:pb-4 bg-[#121212] rounded-lg">
          <InputFieldPartners
            label="Full Name"
            placeholder={"Director/Partner name"}
            name={
              formFields.directorDetails.baseName +
              `[${cardId}].` +
              formFields.directorDetails.nameOfPartner
            }
            required={true}
          />
          <InputFieldPartners
            label="Linkedin profile"
            placeholder={"LinkedIn of Partner"}
            name={
              formFields.directorDetails.baseName +
              `[${cardId}].` + formFields.directorDetails.linkedinOfPartner
            }
            required={true}
          />
          <UploadFilePartners
            label="Aadhaar card"
            name={
              formFields.directorDetails.baseName +
              `[${cardId}].` +
              formFields.directorDetails.aadhaarCardOfPartner
            }
            keyUpload={`AADHAAR_CARD_PARTNER-${cardId + 1}`}
            required={true}
          />
          <UploadFilePartners
            label="PAN card"
            name={
              formFields.directorDetails.baseName +
              `[${cardId}].` +
              formFields.directorDetails.panOfPartner
            }
            keyUpload={`PAN_CARD_PARTNER-${cardId + 1}`}
            required={true}
          />
        </div>
      </div>
    </>
  );
};

export default PartnerCard;

import React from "react";
import { Select } from "antd";
import { useTransactionContext } from "../../context/TransactionContext";
import AntDesignContext from "../../../../common/contexts/AntDesignContext";
import ExportCSVButton from "./ExportCSVButton";

const StatusFilters = ({ statuses }) => {
  const { statusFilter, setStatusFilter, searchByIdText } =
    useTransactionContext();

  const onChange = (e) => {
    setStatusFilter(e);
  };

  return (
    <div
      id="status-filter"
      className="flex items-center justify-between w-full gap-4 mt-4 mb-8"
    >
      <AntDesignContext>
        <Select
          showSearch
          onChange={onChange}
          options={statuses}
          mode="multiple"
          placeholder="Status: All"
          allowClear
          value={statusFilter?.length ? statusFilter : undefined}
          size="large"
          popupClassName="z-[100000000] bg-[#1b1a1a]"
          className="min-w-[125px] border-none"
          disabled={searchByIdText?.length > 0}
          prefix={<span className="text-white">Status</span>}
        />
      </AntDesignContext>
      <ExportCSVButton statuses={statuses} />
    </div>
  );
};

export default StatusFilters;

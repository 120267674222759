import TransactionsTable from "./table/TransactionsTable";
import TxnDetailsDrawer from "./table/TxnDetailsDrawer";
import Header from "./Header";
import Filters from "./filters/Filters";
import { TransactionProvider } from "../context/TransactionContext";

const TransactionsPage = () => {
  return (
    <div className="flex flex-col gap-4">
      <TransactionProvider>
        <Header />
        <Filters />
        <TransactionsTable />
        <TxnDetailsDrawer />
      </TransactionProvider>
    </div>
  );
};

export default TransactionsPage;

import { createContext, useContext, useState } from "react";
import { getTimezone } from "../../../utils/stateFunctions";
const GlobalContext = createContext({});

export const GlobalProvider = (props) => {
  const [open, setOpen] = useState(false);
  const [onboardingStatus, setOnboardingStatus] = useState();
  const [timezone, setTimezone] = useState(getTimezone());

  const isMobile = typeof window !== "undefined" && window.innerWidth < 768;

  return (
    <GlobalContext.Provider
      value={{
        open,
        setOpen,
        onboardingStatus,
        setOnboardingStatus,
        isMobile,
        timezone,
        setTimezone,
      }}
    >
      {props.children}
    </GlobalContext.Provider>
  );
};

export const useGlobalContext = () => {
  return useContext(GlobalContext);
};

import React from "react";
import { Pagination } from "antd";

const SubscriptionTableFooter = ({ page, setPage, total, pageSize }) => {
  const onChange = (curPage) => {
    setPage(curPage);
  };

  return (
    <div className="flex items-center justify-between">
      <div className="text-sm">
        {total > pageSize && (
          <span>
            <span className="opacity-80">Viewing </span>
            <strong>
              {pageSize * (page - 1) + 1}-
              {pageSize * page > total ? total : pageSize * page}
            </strong>{" "}
            <span className="opacity-80">of </span>
          </span>
        )}
        <span>
          {new Intl.NumberFormat().format(total)}
          <span className="opacity-80"> result{total > 1 ? "s" : ""}</span>
        </span>
      </div>
      {total > pageSize && (
        <Pagination
          current={page}
          total={total}
          pageSize={pageSize}
          showSizeChanger={false}
          onChange={onChange}
          align="end"
        />
      )}
    </div>
  );
};

export default SubscriptionTableFooter;

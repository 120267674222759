import { useEffect } from "react";
import useAxios from "../../../common/hooks/useAxios";
import {
  getCurrentMerchantId,
  getStandardApiHeadersWithAuth,
} from "../../../../utils/stateFunctions";
import { CircularProgress, Grid, Paper, Typography } from "@mui/material";
import { isEmpty } from "lodash";
import CopyButton from "./CopyButton";
import { useGlobalContext } from "../../../common/contexts/GlobalContext";
import UploadLogo from "./UploadLogo";

export const TypographyStyles = {
  heading: {
    textAlign: "left",
    variant: "h6",
  },
  subheading: {
    textAlign: "left",
    sx: {
      opacity: 0.7,
      mb: 1,
    },
  },
};

const MerchantDetails = () => {
  const { isMobile } = useGlobalContext();
  const { data, isLoading, callApi } = useAxios({
    url: `/merchant/${getCurrentMerchantId()}/merchant-details`,
    method: "GET",
    headers: getStandardApiHeadersWithAuth(),
  });

  useEffect(() => {
    callApi();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isEmpty(data)) {
    return null;
  }

  const renderDetails = () => {
    if (isLoading) {
      return <CircularProgress sx={{ mt: 2, mx: "auto" }} />;
    }

    return (
      <>
        <Grid item xs={isMobile ? 4 : 2}>
          <Typography {...TypographyStyles.subheading}>Name</Typography>
          <Typography {...TypographyStyles.subheading}>ID</Typography>
        </Grid>
        <Grid item xs={isMobile ? 6 : 9}>
          <Typography {...TypographyStyles.subheading} noWrap>
            {data.merchantName}
          </Typography>
          <Typography {...TypographyStyles.subheading} noWrap>
            {data.merchantId}
          </Typography>
        </Grid>
        <Grid item xs={1} sx={{ opacity: 0.7 }}>
          <CopyButton text={data.merchantName} />
          <CopyButton text={data.merchantId} />
        </Grid>
      </>
    );
  };

  return (
    <>
      <Paper elevation={3} sx={{ p: 2, flexGrow: 1 }}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography {...TypographyStyles.heading}>
              Merchant Details
            </Typography>
          </Grid>
          {renderDetails()}
        </Grid>
        <UploadLogo merchantLogo={data?.merchantLogo} />
      </Paper>
      
    </>
  );
};

export default MerchantDetails;

import { createContext, useContext, useEffect, useState } from "react";
import { Steps } from "antd";

import BusinessType from "../components/OnboardingBody/BusinessType";
import DirectorDetails from "../components/OnboardingBody/DirectorDetails/DirectorDetails";

import BasicDetails from "../components/OnboardingBody/BasicDetails";
import Description from "../components/OnboardingBody/Description";
import RegistrationDetails from "../components/OnboardingBody/RegistrationDetails";
import AddressProof from "../components/OnboardingBody/AddressProof";
import AccountDetails from "../components/OnboardingBody/AccountDetails";
import OnboardingBody from "../components/OnboardingBody/OnboardingBody";

import useGetDetails from "../hooks/useGetDetails";

const OnboardingContext = createContext({});

export const OnboardingProvider = (props) => {
  const [currentPage, setCurrentPage] = useState(0);
  const [currentBDPage, setCurrentBDPage] = useState(0);
  const [onboardingFields, setOnboardingFields] = useState({});
  const [noOfPartners, setNoOfPartners] = useState(2);
  const [loading, setLoading] = useState(false);
  const [form, setForm] = useState();
  const [partnerCards, setPartnerCards] = useState([{ id: 0 }, { id: 1 }]);

  const getOnboardingDetails = useGetDetails();

  useEffect(() => {
    getOnboardingDetails(
      setCurrentPage,
      setCurrentBDPage,
      setOnboardingFields,
      setNoOfPartners,
      setLoading
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onboardingBDStepsChange = (value) => {
    if (value < currentBDPage) {
      setCurrentBDPage(value);
    }
  };

  const onboardingBDSteps = [
    {
      title: "Basic Details",
      content: <BasicDetails />,
      heading: "Company Details",
      subHeading: "Enter your company details",
      // status: "process",
    },
    {
      title: "Description",
      content: <Description />,
      heading: "Company Description",
      subHeading: "Enter your company description",
    },
    {
      title: "Registration Details",
      content: <RegistrationDetails />,
      heading: "Registration Details Documents",
      subHeading: "Upload your company registration details documents",
    },
    {
      title: "Address Proof",
      content: <AddressProof />,
      heading: "Address Proof",
      subHeading: "Upload your address proof",
    },
    {
      title: "Account Details",
      content: <AccountDetails />,
      heading: "Account Details",
      subHeading: "Enter and upload your company account details",
    },
  ];

  const onboardingSteps = [
    {
      title: "Business Type",
      content: <BusinessType />,
      heading: "Business Type",
      subHeading: "Select your business type",
    },
    {
      title: "Business Details",
      content: <OnboardingBody />,
      subTitle:
        currentPage === 1 ? (
          <div className="py-5">
            <Steps
              current={currentBDPage}
              items={onboardingBDSteps}
              direction="vertical"
              size="small"
              onChange={onboardingBDStepsChange}
            />
          </div>
        ) : null,
    },
    {
      title: "Director Details",
      content: <DirectorDetails />,
      heading: "Director Details",
      subHeading:
        "Enter Details For Every Director and Beneficial Owners owning more than 10% (minimum 2)",
    },
  ];

  return (
    <OnboardingContext.Provider
      value={{
        currentPage,
        setCurrentPage,
        currentBDPage,
        setCurrentBDPage,
        onboardingSteps,
        onboardingBDSteps,
        onboardingFields,
        setOnboardingFields,
        noOfPartners,
        setNoOfPartners,
        loading,
        setLoading,
        form,
        setForm,
        partnerCards,
        setPartnerCards,
      }}
    >
      {props.children}
    </OnboardingContext.Provider>
  );
};

export const useOnboardingContext = () => {
  return useContext(OnboardingContext);
};

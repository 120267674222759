import { Navigate, useLocation } from "react-router-dom";
import {
  getCurrentMerchantId,
  getEmail,
  getUserId,
  getUserName,
  isAdminLoggedIn,
} from "../../../utils/stateFunctions";
import { setContext } from "@sentry/react";

const withAdminAuthHOC = (WrappedComponent) => {
  const ComponentWithAdminAuth = (props) => {
    const { pathname } = useLocation();
    const { isValidAuth, redirect } = checkAuthAndRedirectAdmin();
    if (!isValidAuth && pathname !== redirect) {
      return <Navigate to={redirect} />;
    }
    setSentryContextAdmin();
    return <WrappedComponent {...props} />;
  };

  return ComponentWithAdminAuth;
};

const checkAuthAndRedirectAdmin = () => {
  // Check token expiry and existence
  const isTokenValid = isAdminLoggedIn();
  if (!isTokenValid) {
    return { isValidAuth: false, redirect: "/admin/login" };
  }

  return { isValidAuth: true, redirect: "" };
};

const setSentryContextAdmin = () => {
  setContext("admin", {
    id: getUserId(),
    merchantId: getCurrentMerchantId(),
    name: getUserName(),
    email: getEmail(),
  });
};

export default withAdminAuthHOC;

import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import useGenLinkFormStore from "../../store/GenerateLinkFormStore";
import dayjs from "../../../../../utils/CustomDayjs";
import { getTimezone } from "../../../../../utils/stateFunctions";

const DATE_FORMAT = "DD - MM - YYYY";
const DatePickerSlotProps = {
  textField: {
    size: "small",
  },
};

const DueDateInput = () => {
  const dueDate = useGenLinkFormStore((state) => state.dueDate);
  const setMetadata = useGenLinkFormStore((state) => state.setMetadata);

  const handleChange = (date) => {
    setMetadata("dueDate", date);
  };

  return (
    <DatePicker
      sx={{
        width: "100%",
      }}
      label="Expiry Date"
      value={dueDate}
      minDate={dayjs().tz(getTimezone()).add(1, "day").endOf("day")}
      slotProps={DatePickerSlotProps}
      onChange={handleChange}
      format={DATE_FORMAT}
    />
  );
};

export default DueDateInput;

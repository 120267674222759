import { useCallback, useEffect, useState } from "react";
import { PaymentLinkFilterTypes } from "../constants/entities";
import {
  getCurrentMerchantId,
  getStandardApiHeadersWithAuth,
} from "../../../../utils/stateFunctions";
import useLSCache from "../../../common/hooks/useLSCache";
import useAxios from "../../../common/hooks/useAxios";

const CACHE_KEY = "XPFV_Cache"; // XPaymentlinkFilterValues_Cache
const CACHE_TTL = 1; // in days
const FILTER_TYPES = Object.values(PaymentLinkFilterTypes);

const useGetFilterValues = () => {
  const [filterValues, setFilterValues] = useState(null);

  const { callApi: fetchFilterValues, isLoading } = useAxios({
    url: `/link/merchant/${getCurrentMerchantId()}/filter-values`,
    method: "POST",
    data: {
      filterTypes: FILTER_TYPES,
    },
    headers: getStandardApiHeadersWithAuth(),
  });

  const { createCache, getCachedData, isCacheValid } = useLSCache({
    key: CACHE_KEY,
    ttl: CACHE_TTL,
  });

  const init = useCallback(async () => {
    // if cache is valid, use it
    if (isCacheValid) {
      const cachedData = getCachedData();
      setFilterValues(cachedData);
      return;
    }
    // else fetch from backend and create cache
    const res = await fetchFilterValues();
    const dump = res?.filterValues;
    createCache(dump);
    setFilterValues(dump);
  }, [fetchFilterValues, createCache, getCachedData, isCacheValid]);

  useEffect(() => {
    init();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { filterValues, isLoading };
};

export default useGetFilterValues;

import React from "react";

import RightSideMetricsHeader from "./RightSideMetricsHeader";
import { useLandingPageContext } from "../../contexts/LandingPageContext";

import { MdOutlinePayments } from "react-icons/md";
import { FaCheck } from "react-icons/fa6";
import { RxCross2 } from "react-icons/rx";
import { FaRegClock } from "react-icons/fa";
import ParseAmount from './../../../../common/components/UI/ParseAmount';
import ParseDateTimeTz from './../../../../common/components/UI/ParseDateTimeTz';

const RecentPaymentLinks = () => {
  const { recentPaymentLinks } = useLandingPageContext();

  return (
    <div className="bg-[#121212] rounded-lg p-4">
      <RightSideMetricsHeader
        title="Recent Payment Links"
        link="Show all"
        route="/payments"
        icon={<MdOutlinePayments />}
      />
      {recentPaymentLinks.length ? (
        <div className="flex flex-col gap-2 py-4">
          {recentPaymentLinks.map((record, index) => {
            return (
              <div
                key={index}
                className="flex flex-col items-start justify-start p-4 bg-[black] rounded-lg"
              >
                <div className="flex justify-between w-full">
                  {/* Amount */}
                  <div className="text-lg font-medium">
                    <ParseAmount
                      amount={record?.amount}
                      currency={record?.currency}
                      currencySize="sm"
                    />
                  </div>
                  {/* Status */}
                  <div
                    className={`flex gap-1 items-center justify-center px-2 rounded text-xs ${
                      {
                        COMPLETED: "bg-green-800",
                        EXPIRED: "bg-gray-600",
                        ACTIVE: "bg-blue-800",
                      }[record?.status] || ""
                    }`}
                  >
                    {
                      {
                        COMPLETED: <FaCheck size={16} />,
                        EXPIRED: <RxCross2 size={18} />,
                        ACTIVE: <FaRegClock size={15} />,
                      }[record?.status]
                    }
                    {record?.status || "Unknown status"}
                  </div>
                </div>
                <div className="flex justify-between w-full pt-4 item-center">
                  {/* Customer Name */}
                  <div className="flex items-center justify-center gap-2 text-sm opacity-70">
                    {record?.customerDetails?.name}
                  </div>
                  {/* Date */}
                  <div className="flex flex-col items-center justify-center gap-2 text-sm opacity-50">
                    <ParseDateTimeTz dateTime={record?.createdAt} onlyDate={true}/>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      ) : (
        <div className="py-10 text-center">No recent payment links</div>
      )}
    </div>
  );
};

export default RecentPaymentLinks;

import React from "react";
import { Tooltip as AntTooltip } from "antd";
import { FiInfo } from "react-icons/fi";
import { useLandingPageContext } from "../../contexts/LandingPageContext";

const HeaderMetric = () => {
  const {HeaderMetricsContent} = useLandingPageContext();

  return (
    <div className="flex justify-start w-full gap-4 ">
      {HeaderMetricsContent.map((metric, index) => {
        return (
          <div key={index} className="bg-[#121212] rounded-lg w-1/2 md:w-[31.5%] p-4 h-fit">
            <div className="flex items-center justify-start gap-4">
              <div className="p-4 bg-black rounded-full">{metric.icon} </div>
              <div className="flex flex-col gap-1">
                <div className="flex items-center gap-2 text-sm opacity-50">
                  {metric.title}{" "}
                  <AntTooltip title={metric.toolTipText} placement="right">
                    <FiInfo className="cursor-pointer hover:opacity-50" />
                  </AntTooltip>
                </div>
                {HeaderMetricsContent && <div className="text-xl">{metric.amount}</div>}
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default HeaderMetric;

import React, { useState } from "react";

// UI Components
import {
  Drawer as AntDrawer,
  Divider,
  Timeline,
  Button,
  Collapse,
  Modal,
} from "antd";
import AntDesignContext from "../../../../common/contexts/AntDesignContext";

// Utilities
import { isEmpty } from "lodash";
import { copyToClipboard } from "../../../../common/utils/copyToClipboard";
import { useGlobalContext } from "../../../../common/contexts/GlobalContext";
import ParseDateTimeTz from "../../../../common/components/UI/ParseDateTimeTz";
import ParseAmount from "../../../../common/components/UI/ParseAmount";

// Icons
import { IoClose } from "react-icons/io5";
import { GrTransaction } from "react-icons/gr";
import { BsCopy } from "react-icons/bs";
import { FaCheck } from "react-icons/fa6";
import { RxCross2 } from "react-icons/rx";
import { FaRegClock } from "react-icons/fa";
import { HiOutlineReceiptRefund } from "react-icons/hi2";
import { TbBookmarkQuestion } from "react-icons/tb";
import { FaCreditCard } from "react-icons/fa";
import { FaUserCircle } from "react-icons/fa";
import { TbProgress } from "react-icons/tb";
import { MdCancel, MdOutlineGavel } from "react-icons/md";

import { useTransactionContext } from "../../context/TransactionContext";
import { useGetTransaction } from "../../hooks/useGetTransaction";
import { useRefundTransaction } from "../../hooks/useRefundTransaction";

const TxnDetailsDrawer = () => {
  const { isMobile } = useGlobalContext();
  const { showDrawer, setShowDrawer, transactionId } = useTransactionContext();
  const [openDialog, setOpenDialog] = useState(false);

  const { transactionDetails, setTransactionDetails, isLoadingTxn } =
    useGetTransaction({ transactionId });

  const { refundTransaction, loadingCancel } = useRefundTransaction({
    transactionId,
    setTransactionDetails,
    setOpenDialog,
  });

  const handleRefundConfirmation = () => {
    setOpenDialog(true);
  };

  const handleNo = () => {
    setOpenDialog(false);
  };

  const refundTransactionHandler = () => {
    refundTransaction();
  };

  const cardDetails = [
    {
      title: "Brand",
      value: transactionDetails?.paymentMetadata?.cardMetadata?.brand || "-",
    },
    {
      title: "Card No.",
      value:
        (transactionDetails?.paymentMetadata?.cardMetadata?.lastFourDigit &&
          `**** **** **** ${transactionDetails?.paymentMetadata?.cardMetadata?.lastFourDigit}`) ||
        "-",
    },
    {
      title: "Funding type",
      value:
        transactionDetails?.paymentMetadata?.cardMetadata?.fundingType || "-",
    },
    {
      title: "Country",
      value: transactionDetails?.paymentMetadata?.cardMetadata?.country || "-",
    },
    {
      title: "Expiry",
      value:
        (transactionDetails?.paymentMetadata?.cardMetadata?.expiryMonth &&
          `${transactionDetails?.paymentMetadata?.cardMetadata?.expiryMonth} / ${transactionDetails?.paymentMetadata?.cardMetadata?.expiryYear}`) ||
        "-",
    },
    {
      title: "3D-Secure flow",
      value:
        transactionDetails?.paymentMetadata?.cardMetadata?.threeDSFlow || "-",
    },
    {
      title: "Postal Code Check",
      value:
        transactionDetails?.paymentMetadata?.cardMetadata?.postalCodeCheck ||
        "-",
    },
    {
      title: "CVV Check",
      value: transactionDetails?.paymentMetadata?.cardMetadata?.cvvCheck || "-",
    },
    {
      title: "Risk level",
      value:
        (transactionDetails?.paymentMetadata?.cardMetadata?.riskLevel &&
          `${transactionDetails?.paymentMetadata?.cardMetadata?.riskLevel} (${transactionDetails?.paymentMetadata?.cardMetadata?.riskScore})`) ||
        "-",
    },
  ];

  const billingDetailsContent = [
    {
      title: "Name",
      value: transactionDetails?.billingDetails?.name || "-",
    },
    {
      title: "Email",
      value: transactionDetails?.billingDetails?.email || "-",
    },
  ];

  const paymentMethodDetails = [
    {
      key: "1",
      label: <div className="opacity-50">Payment info</div>,
      children: (
        <div>
          {cardDetails.map((item, index) => {
            return (
              <div key={index} className="flex justify-between py-2">
                <span className="opacity-50">{item?.title}</span>{" "}
                <span className="pr-2 font-medium">{item?.value}</span>
              </div>
            );
          })}
        </div>
      ),
      extra: (
        <div className="flex items-center gap-2 font-medium">
          <FaCreditCard />
          {transactionDetails?.paymentMetadata?.paymentMethod}
        </div>
      ),
    },
  ];

  const billingDetails = [
    {
      key: "1",
      label: <div className="opacity-50">Customer details</div>,
      children: (
        <div>
          {billingDetailsContent.map((item, index) => {
            return (
              <div key={index} className="flex justify-between py-2">
                <span className="opacity-50">{item?.title}</span>{" "}
                <span className="pr-2 font-medium">{item?.value}</span>
              </div>
            );
          })}
        </div>
      ),
      extra: (
        <div className="flex items-center gap-2 font-medium">
          <FaUserCircle />
          {transactionDetails?.billingDetails?.name}
        </div>
      ),
    },
  ];

  const transactionsDetails = [
    {
      title: "Status",
      value: (
        <div
          className={`flex gap-1 items-center justify-center px-2 rounded ${
            {
              SUCCESS: "bg-[#20BD96]",
              FAILED: "bg-[#e0343c]",
              CREATED: "bg-gray-400",
              INCOMPLETE: "bg-gray-400",
              REFUNDED: "bg-blue-400",
              DISPUTED: "bg-yellow-400",
              DISPUTE_WON: "bg-[#6B8E23]",
              CANCELLED: "bg-[#ff8c0094]",
            }[transactionDetails?.status] || ""
          }`}
        >
          {
            {
              SUCCESS: <FaCheck size={16} />,
              FAILED: <RxCross2 size={18} />,
              CREATED: <FaRegClock size={15} />,
              INCOMPLETE: <FaRegClock size={15} />,
              REFUNDED: <HiOutlineReceiptRefund size={15} />,
              DISPUTED: <TbBookmarkQuestion size={15} />,
              DISPUTE_WON: <MdOutlineGavel size={15} />,
              CANCELLED: <MdCancel size={16} />,
            }[transactionDetails?.status]
          }
          {transactionDetails?.status || "Unknown status"}
        </div>
      ),
    },
    {
      title: "Created on",
      value: (
        <div className="flex items-center justify-center gap-2">
          <ParseDateTimeTz dateTime={transactionDetails?.createdAt} />
        </div>
      ),
    },
    {
      title: "Receipt id",
      value: transactionDetails?.receiptId || "-",
    },
    {
      title: "Description",
      value: transactionDetails?.description,
    },
    transactionDetails?.presentmentCurrency &&
      transactionDetails?.presentmentCurrency !==
        transactionDetails?.currency && {
        title: "Paid in",
        value: transactionDetails?.presentmentCurrency,
      },
    transactionDetails?.netAmount && {
      title: "Net amount",
      value: (
        <>
          {transactionDetails?.netAmount ? (
            <ParseAmount
              amount={transactionDetails?.netAmount || 0}
              currency={"USD"}
              currencySize="xs"
              divideBy100={true}
            />
          ) : (
            "-"
          )}
        </>
      ),
    },
  ].filter(Boolean);

  let transactionTimeline = [];

  transactionDetails?.eventHistoryList?.forEach((event, index) => {
    transactionTimeline.push({
      color:
        event?.status === "SUCCESS"
          ? "#20BD96"
          : event?.status === "FAILED"
          ? "#e0343c"
          : event?.status === "CREATED"
          ? "#2563eb"
          : event?.status === "INCOMPLETE"
          ? "#2563eb"
          : event?.status === "REFUNDED"
          ? "#2563eb"
          : event?.status === "DISPUTED"
          ? "#ca8a04"
          : event?.status === "DISPUTE_WON"
          ? "#6B8E23"
          : event?.status === "CANCELLED"
          ? "#FF8C00"
          : "#4b5563", // Default gray,
      children: (
        <>
          <div
            className={`flex flex-col ${
              transactionDetails?.eventHistoryList.length - 1 === index
                ? ""
                : "opacity-50"
            }`}
          >
            <div className={`font-medium`}>{event?.status}</div>
            <div>
              <ParseDateTimeTz dateTime={event?.attemptTime} />
            </div>
            <div>{event?.message}</div>
          </div>
        </>
      ),
    });
  });

  const renderContent = () => {
    if (isLoadingTxn) {
      return <TbProgress className="animate-spin" />;
    }

    if (isEmpty(transactionDetails)) {
      return <div>No transaction details found</div>;
    }

    return (
      <div className="">
        <Modal
          open={openDialog}
          title="Refund Transaction"
          okText="Yes"
          cancelText="No"
          onOk={refundTransactionHandler}
          confirmLoading={loadingCancel}
          onCancel={handleNo}
          centered
        >
          <p>Are you sure you want to refund the transaction?</p>
        </Modal>

        {/* Header */}
        <div className="flex items-center justify-between text-2xl font-medium">
          Transaction Details{" "}
          <IoClose
            onClick={hideDrawer}
            className="opacity-50 cursor-pointer hover:opacity-100"
          />
        </div>

        {/* Status Header */}
        <div className="py-2">
          {/* Header */}
          <div className="">
            <div className="flex items-center justify-start gap-4 py-4">
              <GrTransaction
                className="p-4 bg-black rounded-full drop-shadow-xl"
                size={50}
              />
              <div className="flex flex-col w-full">
                <div className="opacity-50">Amount</div>
                <div className="flex items-start justify-between gap-4">
                  <div className="text-3xl font-semibold">
                    <ParseAmount
                      amount={transactionDetails?.amount || 0}
                      currency={transactionDetails?.currency}
                      currencySize="2xl"
                      divideBy100={true}
                    />
                  </div>
                  <div>
                    {transactionDetails.status === "SUCCESS" && (
                      <Button
                        className="drop-shadow-xl"
                        loading={isLoadingTxn}
                        onClick={handleRefundConfirmation}
                      >
                        Issue Refund
                      </Button>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* intendId */}
          <div className="mt-2 p-2 bg-[#242424] rounded-md drop-shadow-xl w-fit">
            <div className="flex items-center justify-between gap-2">
              <div className="flex flex-row gap-2">
                <div className="opacity-50">Intend Id : </div>
                <div className="opacity-80">
                  {transactionDetails?.xIntentId}
                </div>
              </div>
              <div
                className="flex items-center gap-2 text-blue-600 cursor-pointer"
                onClick={() => copyToClipboard(transactionId)}
              >
                <span>copy</span>
                <BsCopy />
              </div>
            </div>
          </div>
        </div>

        <Divider />
        {/* transactionDetails */}
        <div>
          <div className="text-xl font-normal">Details</div>
          <div className="py-2">
            {transactionsDetails.map((item, index) => {
              return (
                <div
                  key={index}
                  className="flex justify-between gap-2 py-2 text-right"
                >
                  <span className="opacity-50">{item?.title}</span>{" "}
                  <span className="font-medium">{item?.value}</span>
                </div>
              );
            })}
            {transactionDetails?.paymentMetadata && (
              <Collapse
                ghost
                items={paymentMethodDetails}
                className="mt-0"
                expandIconPosition={"end"}
              />
            )}
            {transactionDetails?.billingDetails && (
              <Collapse
                ghost
                items={billingDetails}
                className="mt-0"
                expandIconPosition={"end"}
              />
            )}
          </div>
        </div>

        <Divider />
        {/* Timeline */}
        <div className="">
          <div className="pb-2 text-xl font-normal">Timeline</div>
          {transactionTimeline.length > 0 ? (
            <div id="transactionTimeline">
              <Timeline
                mode="left"
                className="py-4"
                items={transactionTimeline}
              />
            </div>
          ) : (
            <div className="py-4 opacity-50">No Events till now!</div>
          )}
        </div>
      </div>
    );
  };

  const hideDrawer = () => {
    setShowDrawer(false);
  };

  return (
    <AntDesignContext>
      <AntDrawer
        zIndex={1100}
        className="bg-white rounded-xl"
        open={showDrawer}
        onClose={hideDrawer}
        closeIcon={false}
        width={isMobile ? "85%" : "30%"}
      >
        {renderContent()}
      </AntDrawer>
    </AntDesignContext>
  );
};

export default TxnDetailsDrawer;

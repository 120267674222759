/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { axiosInstance } from "./../../../common/hooks/useAxios";
import { getStandardApiHeadersWithAuth } from "./../../../../utils/stateFunctions";
import captureAxiosError from "./../../../common/utils/captureAxiosError";

export const useGetOneTimePayment = ({ xLinkId }) => {
  const [oneTimeLinkDetails, setOneTimeLinkDetails] = useState();
  const [isLoadingTxn, setIsLoadingTxn] = useState();

  useEffect(() => {
    const getOneTimePayment = async () => {
      setIsLoadingTxn(true);
      try {
        const { data } = await axiosInstance.get(`/link/dashboard/${xLinkId}`, {
          headers: getStandardApiHeadersWithAuth(),
        });
        setOneTimeLinkDetails(data);
      } catch (error) {
        captureAxiosError(error);
      } finally {
        setIsLoadingTxn(false);
      }
    };

    if (xLinkId) {
      getOneTimePayment();
    }
  }, [xLinkId]);

  return {
    oneTimeLinkDetails,
    setOneTimeLinkDetails,
    isLoadingTxn,
  };
};

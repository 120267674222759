/* eslint-disable react-hooks/exhaustive-deps */
import { Table, Tooltip  } from "antd";
import AntDesignContext from "../../../../common/contexts/AntDesignContext";

import { copyToClipboard } from "../../../../common/utils/copyToClipboard";

import { BsCopy } from "react-icons/bs";

import ParseAmount from "../../../../common/components/UI/ParseAmount";
import ParseDateTimeTz from "./../../../../common/components/UI/ParseDateTimeTz";
import { useGetVBATnxs } from "../../hooks/useGetVBATnxs";
import VBATableFooter from "./VBATableFooter";
import { useVBAContext } from "../../context/VBAContext";
import { useEffect, useState } from "react";

const VBATnxTable = () => {
  const { pageSize, page, setPage, totalCount } = useVBAContext();
  const { vbaTnxs, isLoading } = useGetVBATnxs();
  const [currPageVBATnxs, setCurrPageVBATnxs] = useState();

  useEffect(() => {
    const handlePagination = () => {
      const startIndex = (page - 1) * pageSize;
      const Tnxs = vbaTnxs?.slice(startIndex, startIndex + pageSize);
      setCurrPageVBATnxs(Tnxs);
    };

    handlePagination();
  }, [page, vbaTnxs]);

  const columns = [
    {
      title: "Deposit Id",
      dataIndex: "depositId",
      key: "depositId",
      align: "center",
      render: (depositId) => {
        const first4 = depositId.slice(0, 4);
        const last4 = depositId.slice(-4);

        const displayId = `${first4}....${last4}`;

        return (
          <div
            className="flex items-center justify-center gap-2 cursor-pointer"
            onClick={() => copyToClipboard(depositId)}
          >
            {displayId} <BsCopy size={16} />
          </div>
        );
      },
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
      align: "center",
      render: (amount, details) => {
        return <ParseAmount amount={amount} currency={details?.currency} />;
      },
    },
    {
      title: "Deposited At",
      dataIndex: "depositedAt",
      key: "depositedAt",
      align: "center",
      render: (depositedAt) => {
        return (
          <div className="flex flex-col items-center justify-center gap-2 md:flex-row">
            {<ParseDateTimeTz dateTime={depositedAt} />}
          </div>
        );
      },
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      align: "center",
      ellipsis: {
        showTitle: false,
      },
      render: (address) => (
        <Tooltip placement="topLeft" title={address}>
          {address}
        </Tooltip>
      ),
    },
    Table.EXPAND_COLUMN,
  ];

  return (
    <AntDesignContext>
      <Table
        columns={columns}
        dataSource={currPageVBATnxs}
        pagination={false}
        loading={isLoading}
        expandable={{
          expandedRowRender: (record) => (
            <p className="">{record.description}</p>
          ),
        }}
      />
      <div className="pt-4">
        <VBATableFooter
          page={page}
          setPage={setPage}
          total={totalCount}
          pageSize={pageSize}
        />
      </div>
    </AntDesignContext>
  );
};

export default VBATnxTable;

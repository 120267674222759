import { FaChartLine } from "react-icons/fa";
import { FaCheckDouble } from "react-icons/fa6";
import { LuShieldClose } from "react-icons/lu";
import ParseAmount from "./../../../common/components/UI/ParseAmount";

const useFetchHeaderMetrics = () => {
  const HeaderMetricsContentHandler = ({
    grossChartData,
    txnCntSuccessChartData,
    txnCntFailureChartData,
  }) => {
    return [
      {
        title: "Gross Amount",
        amount:
          (
            <ParseAmount
              amount={grossChartData?.amountSum}
              currencySize="sm"
              toInteger={true}
            />
          ) || 0,
        toolTipText:
          "The total amount before any deductions, including taxes, fees, or other withholdings.",
        icon: <FaChartLine size={20} />,
      },
      {
        title: "Success",
        amount: txnCntSuccessChartData?.amountSum || 0,
        toolTipText:
          "A transaction that has been completed successfully without any errors or issues.",
        icon: <FaCheckDouble size={20} />,
      },
      {
        title: "Failure",
        amount: txnCntFailureChartData?.amountSum || 0,
        toolTipText:
          "A transaction that did not complete successfully due to errors or issues.",
        icon: <LuShieldClose size={20} />,
      },
    ];
  };

  return {
    HeaderMetricsContentHandler,
  };
};

export default useFetchHeaderMetrics;

import { createContext, useContext, useState, useEffect } from "react";
import { useGlobalContext } from "../../../common/contexts/GlobalContext";
import { getTimezone } from "../../../../utils/stateFunctions";
import dayjs from "../../../../utils/CustomDayjs";

const TransactionContext = createContext({});

export const TransactionProvider = (props) => {
  const [showDrawer, setShowDrawer] = useState(false);
  const [transactionId, setTransactionId] = useState(null);
  const [page, setPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const [statusFilter, setStatusFilter] = useState();
  const [searchByIdText, setSearchByIdText] = useState([]);
  const [endTime, setEndTime] = useState(
    dayjs().tz(getTimezone()).endOf("day").valueOf()
  );
  const [startTime, setStartTime] = useState(
    dayjs().tz(getTimezone()).subtract(29, "day").startOf("day").valueOf()
  );
  const { timezone } = useGlobalContext();

  const pageSize = 7;

  useEffect(() => {
    setStartTime(
      dayjs().tz(getTimezone()).subtract(29, "day").startOf("day").valueOf()
    );
    setEndTime(dayjs().tz(getTimezone()).endOf("day").valueOf());
  }, [timezone]);

  useEffect(() => {
    setPage(1);
  }, [startTime, endTime, statusFilter]);
  const [refresh, setRefresh] = useState(false);

  return (
    <TransactionContext.Provider
      value={{
        showDrawer,
        setShowDrawer,
        transactionId,
        setTransactionId,
        endTime,
        setEndTime,
        startTime,
        setStartTime,
        page,
        setPage,
        totalCount,
        setTotalCount,
        pageSize,
        statusFilter,
        setStatusFilter,
        searchByIdText,
        setSearchByIdText,
        refresh,
        setRefresh,
      }}
    >
      {props.children}
    </TransactionContext.Provider>
  );
};

export const useTransactionContext = () => {
  return useContext(TransactionContext);
};

import React from "react";
import { Spin } from "antd";

import WelcomeSteps from "./WelcomeSteps";
import ChartsAndOtherMetrics from "./LeftSideMetrics/ChartsAndOtherMetrics";
import OnboardingCard from "./OnboardingCard";

import useGlobalStore from "../../../common/store/GlobalStore";
import { useLandingPageContext } from "../contexts/LandingPageContext";

const Main = () => {
  const isWelcomed = useGlobalStore((state) => state.isWelcomed);
  const { loading } = useLandingPageContext();

  return (
    <div>
      {loading && <Spin fullscreen size="large" />}
      <OnboardingCard />
      {!isWelcomed ? <WelcomeSteps /> : <ChartsAndOtherMetrics />}
    </div>
  );
};

export default Main;

import copy from "copy-to-clipboard";
import { captureException } from "@sentry/react";
import toast from "react-hot-toast";

export const copyToClipboard = (text) => {
  try {
    const res = copy(text);
    if (!res) {
      throw new Error("Copy failed");
    }
    toast.success("Copied to clipboard");
  } catch (err) {
    captureException(err);
    toast.error(err?.message || "Something went wrong");
  }
};

import { Button, Stack, Typography } from "@mui/material";
import LayoutContainerWithBg from "../UI/LayoutContainerWithBg";
import { truncate } from "lodash";
import { ErrorBoundary, showReportDialog } from "@sentry/react";
import { getEmail, getUserName } from "../../../../utils/stateFunctions";

const ErrorScreen = ({ error, componentStack, resetError }) => {
  const reloadWindow = () => {
    resetError();
    window.location.reload(false); // Force reload from server and ignore cached files
  };

  const redirectToHome = () => {
    window.location.replace(window.location.origin);
  };

  const openReportDialog = () => {
    showReportDialog({
      user: {
        name: getUserName(),
        email: getEmail(),
      },
    });
  };

  return (
    <LayoutContainerWithBg hideBranding>
      <Typography variant="h6" textAlign="center">
        ⚠️ Oops! Something went wrong
      </Typography>
      <Typography
        variant="body2"
        textAlign="center"
        sx={{ opacity: 0.7 }}
        my={4}
      >
        Error :&nbsp;
        {truncate(error?.message, {
          length: 140,
        })}
      </Typography>
      <Button
        variant="contained"
        sx={{ textTransform: "none" }}
        onClick={reloadWindow}
        fullWidth
      >
        Try Again
      </Button>
      <Stack direction="row" gap={1} mt={2}>
        <Button
          variant="outlined"
          sx={{ textTransform: "none", flex: 1 }}
          onClick={redirectToHome}
        >
          Go Home
        </Button>
        <Button
          variant="outlined"
          sx={{ textTransform: "none", flex: 1 }}
          onClick={openReportDialog}
        >
          Report Issue
        </Button>
      </Stack>
    </LayoutContainerWithBg>
  );
};

const GlobalErrorBoundary = ({ children }) => {
  return <ErrorBoundary fallback={ErrorScreen}>{children}</ErrorBoundary>;
};

export default GlobalErrorBoundary;

import { Button, Paper } from "@mui/material";
import {
  UserIcon,
  Cog6ToothIcon,
  ArrowRightStartOnRectangleIcon as LogoutIcon,
} from "@heroicons/react/24/solid";
import { useNavigate } from "react-router-dom";

const UserAccountDropdown = () => {
  const navigate = useNavigate();

  const handleProfileClick = () => {
    navigate("/user-profile");
  };

  return (
    <Paper
      sx={{ py: 2 }}
      className="flex flex-col items-start justify-start w-full gap-4"
    >
      <Button
        onClick={handleProfileClick}
        sx={{ color: "text.primary", px: 4, textTransform: "none", gap: 1 }}
      >
        <UserIcon height={18} />
        My Profile
      </Button>
      <Button
        onClick={() => navigate("/settings")}
        sx={{
          color: "text.primary",
          px: 4,
          textTransform: "none",
          gap: 1,
          width: "100%",
          textAlign: "left",
          display: "flex",
          alignItems: "left",
          justifyContent: "left",
        }}
      >
        <Cog6ToothIcon height={18} />
        Settings
      </Button>
      <Button
        onClick={() => navigate("/logout")}
        sx={{
          color: "text.primary",
          px: 4,
          textTransform: "none",
          gap: 1,
          width: "100%",
          textAlign: "left",
          display: "flex",
          alignItems: "left",
          justifyContent: "left",
        }}
      >
        <LogoutIcon height={18} />
        Logout
      </Button>
    </Paper>
  );
};

export default UserAccountDropdown;

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

// for volume
export const options = {
  responsive: true,
  interaction: {
    mode: "index",
    intersect: false,
  },
  plugins: {
    legend: {
      display: false,
      position: "top",
      labels: {
        font: {
          size: 14,
        },
      },
    },
    tooltip: {
      enabled: false,
      external: function (context) {
        let tooltipEl = document.getElementById("chartjs-tooltip");
        if (!tooltipEl) {
          tooltipEl = document.createElement("div");
          tooltipEl.id = "chartjs-tooltip";
          document.body.appendChild(tooltipEl);
        }
    
        // Hide if no tooltip
        const tooltipModel = context.tooltip;
        if (tooltipModel.opacity === 0) {
          tooltipEl.style.opacity = 0;
          return;
        }
    
        // Set caret Position
        tooltipEl.classList.remove("above", "below", "no-transform");
        if (tooltipModel.yAlign) {
          tooltipEl.classList.add(tooltipModel.yAlign);
        } else {
          tooltipEl.classList.add("no-transform");
        }
    
        function getBody(bodyItem) {
          return bodyItem.lines;
        }
    
        // Set Text
        if (tooltipModel.body) {
          const titleLines = tooltipModel.title || [];
          const bodyLines = tooltipModel.body.map(getBody);
    
          let innerHtml = '<div style="padding: 10px;">';
    
          titleLines.forEach(function (title) {
            innerHtml += '<div style="font-size: 14px; font-weight: bold; margin-bottom: 5px;">' + title + '</div>';
          });
    
          bodyLines.forEach(function (body, i) {
            const colors = tooltipModel.labelColors[i];
            const lineColor = colors.borderColor;
            
            innerHtml += '<div style="display: flex; align-items: center; margin-bottom: 5px;">';
            innerHtml += '<div style="width: 10px; height: 10px; border-radius: 50%; background-color: ' + lineColor + '; margin-right: 5px;"></div>';
            innerHtml += '<span style="font-size: 12px;">' + body + ' USD </span>';
            innerHtml += '</div>';
          });
    
          innerHtml += '</div>';
    
          tooltipEl.innerHTML = innerHtml;
        }
    
        const position = context.chart.canvas.getBoundingClientRect();
    
        // Display, position, and set styles for font
        tooltipEl.style.opacity = 1;
        tooltipEl.style.position = "absolute";
        tooltipEl.style.left = position.left + window.pageXOffset + tooltipModel.caretX + "px";
        tooltipEl.style.top = position.top + window.pageYOffset + tooltipModel.caretY + "px";
        tooltipEl.style.font = "12px 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif";
        tooltipEl.style.padding = tooltipModel.padding + "px " + tooltipModel.padding + "px";
        tooltipEl.style.pointerEvents = "none";
        tooltipEl.style.backgroundColor = "rgba(0, 0, 0, 0.8)";
        tooltipEl.style.color = "white";
        tooltipEl.style.borderRadius = "4px";
        tooltipEl.style.boxShadow = "0px 2px 4px rgba(0, 0, 0, 0.1)";
      },
    },
  },
  scales: {
    x: {
      grid: {
        color: "rgba(128, 128, 128, 0)",
      },
      ticks: {
        display: false,
        color: "rgba(128, 128, 128, 0)",
      },
    },
    y: {
      grid: {
        color: "rgba(128, 128, 128, 0)",
      },
      ticks: {
        display: false,
        color: "rgba(128, 128, 128, 0)",
      },
      suggestedMin: 0,
    },
  },
};

// for count
export const options2 = {
  responsive: true,
  interaction: {
    mode: "index",
    intersect: false,
  },
  plugins: {
    legend: {
      display: false,
    },
    tooltip: {
      enabled: false,
      external: function (context) {
        let tooltipEl = document.getElementById("chartjs-tooltip");
        if (!tooltipEl) {
          tooltipEl = document.createElement("div");
          tooltipEl.id = "chartjs-tooltip";
          document.body.appendChild(tooltipEl);
        }
    
        // Hide if no tooltip
        const tooltipModel = context.tooltip;
        if (tooltipModel.opacity === 0) {
          tooltipEl.style.opacity = 0;
          return;
        }
    
        // Set caret Position
        tooltipEl.classList.remove("above", "below", "no-transform");
        if (tooltipModel.yAlign) {
          tooltipEl.classList.add(tooltipModel.yAlign);
        } else {
          tooltipEl.classList.add("no-transform");
        }
    
        function getBody(bodyItem) {
          return bodyItem.lines;
        }
    
        // Set Text
        if (tooltipModel.body) {
          const titleLines = tooltipModel.title || [];
          const bodyLines = tooltipModel.body.map(getBody);
    
          let innerHtml = '<div style="padding: 10px;">';
    
          titleLines.forEach(function (title) {
            innerHtml += '<div style="font-size: 14px; font-weight: bold; margin-bottom: 5px;">' + title + '</div>';
          });
    
          bodyLines.forEach(function (body, i) {
            const colors = tooltipModel.labelColors[i];
            // const label = tooltipModel.dataPoints[i].dataset.label;
            const lineColor = colors.borderColor;
            
            innerHtml += '<div style="display: flex; align-items: center; margin-bottom: 5px;">';
            innerHtml += '<div style="width: 10px; height: 10px; border-radius: 50%; background-color: ' + lineColor + '; margin-right: 5px;"></div>';
            innerHtml += '<span style="font-size: 12px;">' + body + '</span>';
            innerHtml += '</div>';
          });
    
          innerHtml += '</div>';
    
          tooltipEl.innerHTML = innerHtml;
        }
    
        const position = context.chart.canvas.getBoundingClientRect();
    
        // Display, position, and set styles for font
        tooltipEl.style.opacity = 1;
        tooltipEl.style.position = "absolute";
        tooltipEl.style.left = position.left + window.pageXOffset + tooltipModel.caretX + "px";
        tooltipEl.style.top = position.top + window.pageYOffset + tooltipModel.caretY + "px";
        tooltipEl.style.font = "12px 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif";
        tooltipEl.style.padding = tooltipModel.padding + "px " + tooltipModel.padding + "px";
        tooltipEl.style.pointerEvents = "none";
        tooltipEl.style.backgroundColor = "rgba(0, 0, 0, 0.8)";
        tooltipEl.style.color = "white";
        tooltipEl.style.borderRadius = "4px";
        tooltipEl.style.boxShadow = "0px 2px 4px rgba(0, 0, 0, 0.1)";
      },
    },
  },
  scales: {
    x: {
      grid: {
        color: "rgba(128, 128, 128, 0)",
      },
      ticks: {
        display: false,
        color: "rgba(128, 128, 128, 0)",
      },
    },
    y: {
      grid: {
        color: "rgba(128, 128, 128, 0)",
      },
      ticks: {
        display: false,
        color: "rgba(128, 128, 128, 0)",
      },
      suggestedMin: 0,
    },
  },
};


import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { useState } from "react";
import { DateFilterTypes } from "../../constants/entities";
import dayjs from "../../../../utils/CustomDayjs"
import { getTimezone } from "../../../../utils/stateFunctions";

const DROPDOWN_WIDTH =
  typeof window !== "undefined" && window.innerWidth < 768 ? 100 : 200;
const FILTER_LABEL = "Duration";
const DATE_FORMAT = "DD-MM-YYYY";

const DatePickerSlotProps = {
  textField: {
    sx: {
      width: DROPDOWN_WIDTH,
    },
    size: "small",
  },
};

const DateRangePicker = ({
  startDate,
  endDate,
  setStartDate,
  setEndDate,
  label = FILTER_LABEL,
}) => {
  const [durationType, setDurationType] = useState(DateFilterTypes.LAST_1_DAY);

  const handleDurationTypeChange = (event) => {
    const newType = event.target.value;
    setDurationType(newType);
    switch (newType) {
      case DateFilterTypes.LAST_1_DAY:
        setStartDate(dayjs().tz(getTimezone()).subtract(0, "d"));
        setEndDate(dayjs().tz(getTimezone()));
        break;
      case DateFilterTypes.LAST_7_DAYS:
        setStartDate(dayjs().tz(getTimezone()).subtract(6, "d"));
        setEndDate(dayjs().tz(getTimezone()));
        break;
      case DateFilterTypes.LAST_30_DAYS:
        setStartDate(dayjs().tz(getTimezone()).subtract(29, "d"));
        setEndDate(dayjs().tz(getTimezone()));
        break;
      default:
        return;
    }
  };

  const disableDatePicker = durationType !== DateFilterTypes.CUSTOM;

  return (
    <>
      <FormControl size="small">
        <InputLabel id="custom-date-range-picker">{label}</InputLabel>
        <Select
          labelId="custom-date-range-picker"
          variant="outlined"
          sx={{
            width: DROPDOWN_WIDTH,
          }}
          className="w-full"
          label={label}
          value={durationType}
          onChange={handleDurationTypeChange}
        >
          {Object.values(DateFilterTypes).map((filter) => (
            <MenuItem key={filter} value={filter}>
              {filter}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <DatePicker
        label="From"
        value={startDate}
        disableFuture
        slotProps={DatePickerSlotProps}
        onChange={setStartDate}
        disabled={disableDatePicker}
        format={DATE_FORMAT}
      />
      <DatePicker
        label="To"
        value={endDate}
        disableFuture
        slotProps={DatePickerSlotProps}
        onChange={setEndDate}
        disabled={disableDatePicker}
        format={DATE_FORMAT}
      />
    </>
  );
};

export default DateRangePicker;

import React, { useEffect } from "react";
import { useOnboardingContext } from "../../contexts/OnboardingContext";
import QuestionHeader from "./QuestionHeader";
import { Form } from "antd";

const OnboardingBody = () => {
  const {
    currentPage,
    onboardingSteps,
    currentBDPage,
    onboardingBDSteps,
    setForm,
    onboardingFields,
  } = useOnboardingContext();

  const [form] = Form.useForm();

  setForm(form);

  form.setFieldsValue(onboardingFields);

  const content =
    currentPage !== 1
      ? onboardingSteps[currentPage]?.content
      : onboardingBDSteps[currentBDPage]?.content;

  useEffect(() => {
    const fieldElement = document.getElementById(`onboardingFields`);
    if (fieldElement) {
      fieldElement.scrollTo({ top: 0, behavior: "smooth" });
    }
  }, [content]);

  return (
    <div className="">
      <QuestionHeader
        heading={
          currentPage !== 1
            ? onboardingSteps[currentPage]?.heading
            : onboardingBDSteps[currentBDPage]?.heading
        }
        subHeading={
          currentPage !== 1
            ? onboardingSteps[currentPage]?.subHeading
            : onboardingBDSteps[currentBDPage]?.subHeading
        }
      />
      <Form
        form={form}
        name={`step-${currentPage}-${currentBDPage}`}
        className="h-[62vh] md:h-[53vh] overflow-auto pr-4 md:pr-20 scroll-shadows text-white"
        initialValues={onboardingFields}
        id="onboardingFields"
        layout="vertical"
        autoComplete="on"
      >
        {content}
      </Form>
    </div>
  );
};

export default OnboardingBody;

import { animate, motion } from "framer-motion";
import React, { useEffect } from "react";
import { cn } from "./cn";

import dollarLogo from "../../../../../assets/img/Landing/icons8-dollar-96.png";
import rupeesLogo from "../../../../../assets/img/Landing/icons8-rupee-96.png";
import euroLogo from "../../../../../assets/img/Landing/icons8-euro-96.png";
import dinarLogo from "../../../../../assets/img/Landing/icons8-dinar-96.png";
import shekelLogo from "../../../../../assets/img/Landing/icons8-shekel-96.png";

export function CardDemo() {
  return (
    <CardSkeletonContainer >
      <Skeleton />
    </CardSkeletonContainer>
  );
}

const Skeleton = () => {
  const scale = [1, 1.1, 1];
  const transform = ["translateY(0px)", "translateY(-4px)", "translateY(0px)"];
  const sequence = [
    [
      ".circle-1",
      {
        scale,
        transform,
      },
      { duration: 0.8 },
    ],
    [
      ".circle-2",
      {
        scale,
        transform,
      },
      { duration: 0.8 },
    ],
    [
      ".circle-3",
      {
        scale,
        transform,
      },
      { duration: 0.8 },
    ],
    [
      ".circle-4",
      {
        scale,
        transform,
      },
      { duration: 0.8 },
    ],
    [
      ".circle-5",
      {
        scale,
        transform,
      },
      { duration: 0.8 },
    ],
  ];

  useEffect(() => {
    animate(sequence, {
      repeat: Infinity,
      repeatDelay: 1,
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div className="relative flex items-center justify-center h-full p-8 overflow-hidden">
      <div className="flex flex-row items-center justify-center flex-shrink-0 gap-2">
        <Container className="w-8 h-8 circle-1">
          <img src={shekelLogo} className="w-4 h-4" alt="shekelLogo"/>
        </Container>
        <Container className="w-12 h-12 circle-2">
          <img src={rupeesLogo} className="w-6 h-6" alt="rupeesLogo"/>
        </Container>
        <Container className="circle-3">
          <img src={dollarLogo} className="w-8 h-8" alt="dollarLogo"/>
        </Container>
        <Container className="w-12 h-12 circle-4">
          <img src={euroLogo} className="w-6 h-6" alt="euroLogo"/>
        </Container>
        <Container className="w-8 h-8 circle-5">
          <img src={dinarLogo} className="w-6 h-6" alt="dinarLogo"/>
        </Container>
      </div>

      <div className="absolute z-40 w-px h-40 m-auto top-15 bg-gradient-to-b from-transparent via-cyan-500 to-transparent animate-move">
        <div className="absolute w-10 h-32 -translate-y-1/2 top-1/2 -left-10">
          <Sparkles />
        </div>
      </div>
    </div>
  );
};
const Sparkles = () => {
  const randomMove = () => Math.random() * 2 - 1;
  const randomOpacity = () => Math.random();
  const random = () => Math.random();
  return (
    <div className="absolute inset-0">
      {[...Array(12)].map((_, i) => (
        <motion.span
          key={`star-${i}`}
          animate={{
            top: `calc(${random() * 100}% + ${randomMove()}px)`,
            left: `calc(${random() * 100}% + ${randomMove()}px)`,
            opacity: randomOpacity(),
            scale: [1, 1.2, 0],
          }}
          transition={{
            duration: random() * 2 + 4,
            repeat: Infinity,
            ease: "linear",
          }}
          style={{
            position: "absolute",
            top: `${random() * 100}%`,
            left: `${random() * 100}%`,
            width: `2px`,
            height: `2px`,
            borderRadius: "50%",
            zIndex: 1,
          }}
          className="inline-block bg-white"
        ></motion.span>
      ))}
    </div>
  );
};

export const CardSkeletonContainer = ({
  className,
  children,
  showGradient = true,
}) => {
  return (
    <div
      className={cn(
        "h-[15rem] rounded-xl z-40",
        className,
        showGradient &&
          "bg-[rgba(40,40,40,0.70)] [mask-image:radial-gradient(50%_50%_at_50%_50%,white_0%,transparent_100%)]"
      )}
    >
      {children}
    </div>
  );
};

const Container = ({ className, children }) => {
  return (
    <div
      className={cn(
        `h-16 w-16 rounded-full flex items-center justify-center bg-[rgba(248,248,248,0.01)]
    shadow-[0px_0px_8px_0px_rgba(248,248,248,0.25)_inset,0px_32px_24px_-16px_rgba(0,0,0,0.40)]
    `,
        className
      )}
    >
      {children}
    </div>
  );
};

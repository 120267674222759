export const FilterQueryKeyMap = {
  status: "txnStatuses",
  currency: "currencies",
  pg: "paymentGateways",
};

export const TxnTableColHeaders = [
  {
    displayName: "Intent Id",
    sortKey: null,
  },
  {
    displayName: "Amount",
    sortKey: null,
  },
  {
    displayName: "Date",
    sortKey: "TRANSACTION_DATE",
  },
  {
    displayName: "Status",
    sortKey: null,
  },
];

import React from "react";

const ParseAmount = ({
  amount,
  currency = "USD",
  currencySize = "xs",
  divideBy100 = false,
  toInteger = false,
}) => {
  return (
    <>
      {`${new Intl.NumberFormat("en-US").format(
        toInteger
          ? Math.round(divideBy100 ? amount / 100 : amount)
          : Number(divideBy100 ? amount / 100 : amount).toFixed(2)
      )}`}{" "}
      <span className={`text-${currencySize}`}>{currency}</span>
    </>
  );
};

export default ParseAmount;

import React from "react";
import Card from "./gradientCards/Card";
import { WelcomeSteps as WelcomeStepsContent } from "../constants/welcomeSteps";

const WelcomeSteps = () => {
  return (
    <div className="relative flex flex-wrap gap-8 mt-10 lg:gap-4 md:mx-2 justify-evenly lg:flex-row">
      {WelcomeStepsContent.map((step, index) => {
        return (
          <Card
            key={index}
            step={step.step}
            heading={step.heading}
            description={step.description}
            graphics={step.graphics}
          />
        );
      })}
    </div>
  );
};

export default WelcomeSteps;

import React, { useEffect, useState } from "react";
import { Select, DatePicker, Button, Tooltip } from "antd";
import { GrPowerReset } from "react-icons/gr";
import AntDesignContext from "../../../common/contexts/AntDesignContext";
import dayjs from "../../../../utils/CustomDayjs";
import { filterPresetsOptions } from "../constants/filterPresetsOptions.js";
import { getTimezone } from "../../../../utils/stateFunctions";
import { useGlobalContext } from "../../../common/contexts/GlobalContext";
import { useSubscriptionContext } from "../contexts/SubscriptionContext.jsx";

const { RangePicker } = DatePicker;

const FilterBox = () => {
  const { timezone } = useGlobalContext();
  const [range, setRange] = useState("Last 30 days");
  const [dateRange, setDateRange] = useState([
    dayjs().tz(getTimezone()).subtract(29, "day").startOf("day"),
    dayjs().tz(getTimezone()).endOf("day"),
  ]);
  const {
    setStartTime,
    setEndTime,
    subsSearchByIdText,
    setSubsSearchByIdText,
    setStatusFilter,
  } = useSubscriptionContext();

  useEffect(() => {
    setDateRange([
      dayjs().tz(getTimezone()).subtract(29, "day").startOf("day"),
      dayjs().tz(getTimezone()).endOf("day"),
    ]);
    setRange("Last 30 days");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timezone]);

  const handleSearchById = (value) => {
    setStatusFilter({
      label: "ALL",
      value: "ALL",
    });
    setSubsSearchByIdText(value);
  };

  const handleChange = (value) => {
    setRange(value);
    switch (value) {
      case "Today":
        setDateRange([
          dayjs().tz(getTimezone()).startOf("day"),
          dayjs().tz(getTimezone()).endOf("day"),
        ]);
        setStartTime(dayjs().tz(getTimezone()).startOf("day").valueOf());
        setEndTime(dayjs().tz(getTimezone()).endOf("day").valueOf());
        break;
      case "Yesterday":
        setDateRange([
          dayjs().tz(getTimezone()).subtract(1, "day").startOf("day"),
          dayjs().tz(getTimezone()).subtract(1, "day").endOf("day"),
        ]);
        setStartTime(
          dayjs().tz(getTimezone()).subtract(1, "day").startOf("day").valueOf()
        );
        setEndTime(
          dayjs().tz(getTimezone()).subtract(1, "day").endOf("day").valueOf()
        );
        break;
      case "Last 7 days":
        setDateRange([
          dayjs().tz(getTimezone()).subtract(6, "day").startOf("day"),
          dayjs().tz(getTimezone()).endOf("day"),
        ]);
        setStartTime(
          dayjs().tz(getTimezone()).subtract(6, "day").startOf("day").valueOf()
        );
        setEndTime(dayjs().tz(getTimezone()).endOf("day").valueOf());
        break;
      case "Last 30 days":
        setDateRange([
          dayjs().tz(getTimezone()).subtract(29, "day").startOf("day"),
          dayjs().tz(getTimezone()).endOf("day"),
        ]);
        setStartTime(
          dayjs().tz(getTimezone()).subtract(29, "day").startOf("day").valueOf()
        );
        setEndTime(dayjs().tz(getTimezone()).endOf("day").valueOf());
        break;
      default:
        setDateRange([
          dayjs().tz(getTimezone()).startOf("day"),
          dayjs().tz(getTimezone()).endOf("day"),
        ]);
        setStartTime(dayjs().tz(getTimezone()).startOf("day").valueOf());
        setEndTime(dayjs().tz(getTimezone()).endOf("day").valueOf());
        break;
    }
  };

  const handleRangeChange = (dates, dateStrings) => {
    const startDate = dayjs.tz(dateStrings[0], getTimezone()).startOf("day");
    const endDate = dayjs.tz(dateStrings[1], getTimezone()).endOf("day");

    setDateRange([startDate, endDate]);

    setStartTime(startDate.valueOf());
    setEndTime(endDate.valueOf());

    const Today = [
      dayjs().tz(getTimezone()).startOf("day").format("YYYY-MM-DD"),
      dayjs().tz(getTimezone()).endOf("day").format("YYYY-MM-DD"),
    ];

    const Yesterday = [
      dayjs()
        .tz(getTimezone())
        .subtract(1, "day")
        .startOf("day")
        .format("YYYY-MM-DD"),
      dayjs()
        .tz(getTimezone())
        .subtract(1, "day")
        .endOf("day")
        .format("YYYY-MM-DD"),
    ];

    const Last7Days = [
      dayjs()
        .tz(getTimezone())
        .subtract(6, "day")
        .startOf("day")
        .format("YYYY-MM-DD"),
      dayjs().tz(getTimezone()).endOf("day").format("YYYY-MM-DD"),
    ];

    const Last30Days = [
      dayjs()
        .tz(getTimezone())
        .subtract(29, "day")
        .startOf("day")
        .format("YYYY-MM-DD"),
      dayjs().tz(getTimezone()).endOf("day").format("YYYY-MM-DD"),
    ];

    if (dateStrings[0] === Today[0] && dateStrings[1] === Today[1]) {
      setRange("Today");
    } else if (
      dateStrings[0] === Yesterday[0] &&
      dateStrings[1] === Yesterday[1]
    ) {
      setRange("Yesterday");
    } else if (
      dateStrings[0] === Last7Days[0] &&
      dateStrings[1] === Last7Days[1]
    ) {
      setRange("Last 7 days");
    } else if (
      dateStrings[0] === Last30Days[0] &&
      dateStrings[1] === Last30Days[1]
    ) {
      setRange("Last 30 days");
    } else {
      setRange("Custom");
    }
  };

  const handleReset = () => {
    setSubsSearchByIdText([]);
    setStartTime(
      dayjs().tz(getTimezone()).subtract(29, "day").startOf("day").valueOf()
    );
    setEndTime(dayjs().tz(getTimezone()).endOf("day").valueOf());
    setDateRange([
      dayjs().tz(getTimezone()).subtract(29, "day").startOf("day"),
      dayjs().tz(getTimezone()).endOf("day"),
    ]);
    setRange("Last 30 days");

    setStatusFilter({
      label: "Active",
      value: "ACTIVE",
      data: ["ACTIVE"],
    });
  };

  return (
    <AntDesignContext>
      <div className="flex justify-between gap-4">
        <div className="flex gap-4">
          <Select
            size="large"
            onChange={handleChange}
            defaultValue="Today"
            value={range}
            options={filterPresetsOptions}
            className="w-[150px] border-none"
            popupClassName="bg-[#121212]"
          />
          <Tooltip
            title="Start Date &nbsp; -> &nbsp; End Date"
            placement="bottom"
          >
            <RangePicker
              minDate={dayjs()
                .tz(getTimezone())
                .subtract(90, "days")
                .startOf("day")}
              maxDate={dayjs().tz(getTimezone()).endOf("day")}
              defaultValue={[
                dayjs().tz(getTimezone()).subtract(29, "day").startOf("day"),
                dayjs().tz(getTimezone()).endOf("day"),
              ]}
              onChange={handleRangeChange}
              value={dateRange}
              allowClear={false}
            />
          </Tooltip>
        </div>
        <div className="flex gap-4">
          <Select
            mode="tags"
            style={{
              width: "320px",
            }}
            onChange={handleSearchById}
            tokenSeparators={[","]}
            options={[
              { label: "Enter Subscription Id to search", value: "none", disabled: true },
            ]}
            maxTagCount="responsive"
            placeholder="Search by Subscription Id"
            popupClassName="bg-[#121212]"
            value={subsSearchByIdText}
          />
          <Tooltip title="Reset Filters" placement="bottom">
            <Button
              size="large"
              icon={<GrPowerReset />}
              className="text-white bg-[#121212] hover:text-blue-600"
              onClick={handleReset}
            >
              Reset
            </Button>
          </Tooltip>
        </div>
      </div>
    </AntDesignContext>
  );
};

export default FilterBox;

import { Button, Stack, Typography, CircularProgress } from "@mui/material";
import { useState, useEffect } from "react";
import {
  ExclamationTriangleIcon,
  CheckBadgeIcon,
} from "@heroicons/react/24/solid";
import LayoutContainerWithBg from "../../../common/components/UI/LayoutContainerWithBg";
import useVerify from "../hooks/useVerify";
import { useParams } from "react-router-dom";

const VerifyEmail = () => {
  const [isVerified, setIsVerified] = useState(false);
  const [error, setError] = useState(null);

  const { tokenId } = useParams();

  const handleVerify = useVerify(setError);

  const verifyEmail = async () => {
    const isSuccess = await handleVerify(tokenId);
    if (isSuccess) {
      setIsVerified(true);
    }
  };

  useEffect(() => {
    verifyEmail();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <LayoutContainerWithBg>
      {!isVerified ? (
        !error ? (
          <Stack gap={2} my={6} alignItems={"center"}>
            <Typography fontSize={20}>Verifying Email</Typography>
            <CircularProgress />
          </Stack>
        ) : (
          <Stack gap={2} my={6} textAlign="center">
            <ExclamationTriangleIcon height={80} />
            <Typography fontSize={20}>Error</Typography>
            <Typography className="pb-10">
              Something went wrong while verifying your email. Please try again
            </Typography>
            <Button
              variant="contained"
              onClick={() => window.location.replace("/")}
            >
              Continue
            </Button>
          </Stack>
        )
      ) : (
        <Stack gap={2} my={6} textAlign="center">
          <CheckBadgeIcon height={80} />
          <Typography fontSize={20}>Email Verified</Typography>
          <Typography className="pb-10">
            Your email has been verified. You can continue using the
            application.
          </Typography>
          <Button
            variant="contained"
            onClick={() => window.location.replace("/")}
          >
            Continue
          </Button>
        </Stack>
      )}
    </LayoutContainerWithBg>
  );
};

export default VerifyEmail;

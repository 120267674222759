import React from 'react'
import { OnboardingProvider } from './contexts/OnboardingContext'
import Main from './components/Index'

const Onboarding = () => {
  return (
    <div className='h-[100vh] overflow-hidden bg-black pt-4 px-4 md:pt-10 md:px-14' id='onboardingIndex'>
      <OnboardingProvider>
        <Main />
      </OnboardingProvider>
    </div>
  )
}

export default Onboarding
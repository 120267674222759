import React from "react";
import GreetingContent from "./GreetingContent";
import NotFound from "./NotFound";

const Greetings = ({greeting}) => {
  return (
    <div>
      {greeting === `welcome` || greeting === `thankyou` ? (
        <GreetingContent greeting={greeting} />
      ) : (
        <NotFound />
      )}
    </div>
  );
};

export default Greetings;

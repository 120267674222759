import { MdOutlineCorporateFare } from "react-icons/md";
import { AiOutlineTeam } from "react-icons/ai";

export const businessTypesOptions = [
  {
    label: "Private Limited",
    value: "PRIVATE_LIMITED",
    icon: <MdOutlineCorporateFare />,
  },
  {
    label: "Limited Liability Partnership",
    value: "LIMITED_LIABILITY_PARTNER",
    icon: <AiOutlineTeam />,
  },
];

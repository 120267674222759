import React, { useState } from "react";
import { axiosInstance } from "../../common/hooks/useAxios";
import { getAdminAuthToken } from "../../../utils/stateFunctions";
import { Button, Popconfirm, Modal, Input, Form } from "antd";
import toast from "react-hot-toast";

const MerchantInfo = ({ merchantData }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [xfpAccountId, setXfpAccountId] = useState();
  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const inputChange = (e) => {
    setXfpAccountId(e.target.value);
  };

  const confirm = async () => {
    toast.loading("Verifying merchant...", {
      id: "verifyMerchant",
    });
    setConfirmLoading(true);
    try {
      await axiosInstance.post(
        `/admin/verify`,
        {
          merchantId: merchantData?.merchantId,
          xfpAccountId: xfpAccountId,
        },
        {
          headers: {
            Authorization: `Internal ${getAdminAuthToken()}`,
          },
        }
      );

      toast.success("Merchant verified", {
        id: "verifyMerchant",
      });
      setIsModalOpen(false);
    } catch (error) {
      toast.error("Error verifying merchant", {
        id: "verifyMerchant",
      });
    } finally {
      setConfirmLoading(false);
    }
  };

  const decline = async () => {
    toast.loading("Declining merchant...", {
      id: "declineMerchant",
    });
    try {
      await axiosInstance.post(
        `/admin/decline/${merchantData?.merchantId}`,
        null,
        {
          headers: {
            Authorization: `Internal ${getAdminAuthToken()}`,
          },
        }
      );

      toast.success("Merchant declined", {
        id: "declineMerchant",
      });
    } catch (error) {
      toast.error("Error declining merchant", {
        id: "declineMerchant",
      });
    }
  };

  return (
    <div>
      <div className="flex justify-between">
        <h2 className="mb-4 text-2xl font-semibold">
          {merchantData?.businessDetails?.companyName}
        </h2>
        <div className="flex gap-4">
          {merchantData?.status === "VERIFYING" && (
            <Popconfirm
              title="Decline this merchant?"
              description="Are you sure you want to decline this merchant?"
              onConfirm={decline}
              onCancel={null}
              okText="Yes"
              cancelText="No"
            >
              <Button className="text-white bg-red-500">Decline</Button>
            </Popconfirm>
          )}
          {merchantData?.status === "VERIFYING" && (
            <>
              <Button onClick={showModal}>Verify</Button>
              <Modal
                title="Verify this merchant?"
                open={isModalOpen}
                onOk={confirm}
                okText="Verify"
                onCancel={handleCancel}
                confirmLoading={confirmLoading}
                centered
              >
                <p className="my-4">Please enter merchant's XFlowPay Id:</p>
                <Input className="mb-2" onChange={inputChange} />
                <p className="mb-4 text-sm text-gray-500">
                  By clicking "Verify" the merchant will be automatically
                  verified. Please be careful as this action is critical.
                </p>
              </Modal>
            </>
          )}
        </div>
      </div>
      <div className="px-8 pt-6 pb-8 mb-4 bg-white rounded shadow-md">
        <h3 className="mb-4 text-xl font-semibold">Basic Information</h3>
        <p>
          <strong>Merchant ID:</strong> {merchantData?.merchantId}
        </p>
        <p>
          <strong>Status:</strong> {merchantData?.status}
        </p>
        <p>
          <strong>Current Page:</strong> {merchantData?.currentPage}
        </p>
        <p>
          <strong>Business Type:</strong> {merchantData?.businessType}
        </p>
      </div>

      <div className="px-8 pt-6 pb-8 mb-4 bg-white rounded shadow-md">
        <h3 className="mb-4 text-xl font-semibold">Business Details</h3>
        <p>
          <strong>Company Name:</strong>{" "}
          {merchantData?.businessDetails?.companyName}
        </p>
        <p>
          <strong>Company Email:</strong>{" "}
          <a
            href={`mailto:${merchantData?.businessDetails?.companyEmail}`}
            className="text-blue-600 hover:underline"
          >
            {merchantData?.businessDetails?.companyEmail}
          </a>
        </p>
        <p>
          <strong>Company Website:</strong>{" "}
          <a
            href={`https://${merchantData?.businessDetails?.website}`}
            target="_blank"
            rel="noopener noreferrer"
            className="text-blue-600 hover:underline"
          >
            {merchantData?.businessDetails?.website}
          </a>
        </p>
        <p>
          <strong>Product Name:</strong>{" "}
          {merchantData?.businessDetails?.nameOfProduct}
        </p>
        <p>
          <strong>Product Description:</strong>{" "}
          {merchantData?.businessDetails?.productDesc}
        </p>
        <p>
          <strong>Doing Business As:</strong>{" "}
          {merchantData?.businessDetails?.doingBusinessAs}
        </p>
        <p>
          <strong>Export Purpose Code:</strong>{" "}
          {merchantData?.businessDetails?.exportPurposeCode}
        </p>
        <p>
          <strong>Local Currencies Needed:</strong>{" "}
          {merchantData?.businessDetails?.localCurrenciesNeeded &&
            merchantData?.businessDetails?.localCurrenciesNeeded.toLocaleString()}
        </p>
        <p>
          <strong>Volume: USD</strong>{" "}
          {merchantData?.businessDetails?.volume.toLocaleString()}
        </p>
      </div>

      <div className="px-8 pt-6 pb-8 mb-4 bg-white rounded shadow-md">
        <h3 className="mb-4 text-xl font-semibold">Company Address</h3>
        <p>{merchantData?.businessDetails?.companyAddress?.addressLine1}</p>
        <p>{merchantData?.businessDetails?.companyAddress?.addressLine2}</p>
        <p>
          {merchantData?.businessDetails?.companyAddress?.city},{" "}
          {merchantData?.businessDetails?.companyAddress?.state}
        </p>
        <p>
          {merchantData?.businessDetails?.companyAddress?.country} -{" "}
          {merchantData?.businessDetails?.companyAddress?.pinCode}
        </p>
      </div>

      <div className="px-8 pt-6 pb-8 mb-4 bg-white rounded shadow-md">
        <h3 className="mb-4 text-xl font-semibold">Documents</h3>
        <h4 className="mt-2 mb-1 text-lg font-semibold">
          Business Registration Documents
        </h4>
        <ul className="pl-5 list-disc">
          {Object.entries(merchantData?.businessDetails?.businessRegDoc).map(
            ([key, value]) => (
              <li key={key}>
                {key}:{" "}
                <a
                  href={value}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-blue-600 hover:underline"
                >
                  View Document
                </a>
              </li>
            )
          )}
        </ul>
        <h4 className="mt-4 mb-1 text-lg font-semibold">Address Proof</h4>
        <ul className="pl-5 list-disc">
          {Object.entries(merchantData?.businessDetails?.addressProof).map(
            ([key, value]) => (
              <li key={key}>
                {key}:{" "}
                <a
                  href={value}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-blue-600 hover:underline"
                >
                  View Document
                </a>
              </li>
            )
          )}
        </ul>
      </div>

      <div className="px-8 pt-6 pb-8 mb-4 bg-white rounded shadow-md">
        <h3 className="mb-4 text-xl font-semibold">
          Settlement Account Details
        </h3>
        <p>
          <strong>Account Name:</strong>{" "}
          {merchantData?.businessDetails?.settlementAccountDetails?.accountName}
        </p>
        <p>
          <strong>Account Number:</strong>{" "}
          {
            merchantData?.businessDetails?.settlementAccountDetails
              ?.accountNumber
          }
        </p>
        <p>
          <strong>IFSC Code:</strong>{" "}
          {merchantData?.businessDetails?.settlementAccountDetails?.ifscCode}
        </p>
        <p>
          <strong>Bank Statement:</strong>{" "}
          <a
            href={
              merchantData?.businessDetails?.settlementAccountDetails
                ?.bankStatementUrl
            }
            target="_blank"
            rel="noopener noreferrer"
            className="text-blue-600 hover:underline"
          >
            View Document
          </a>
        </p>
        <p>
          <strong>Cancelled Cheque:</strong>{" "}
          <a
            href={
              merchantData?.businessDetails?.settlementAccountDetails
                ?.cancelledChequeUrl
            }
            target="_blank"
            rel="noopener noreferrer"
            className="text-blue-600 hover:underline"
          >
            View Document
          </a>
        </p>
      </div>

      <div className="px-8 pt-6 pb-8 mb-4 bg-white rounded shadow-md">
        <h3 className="mb-4 text-xl font-semibold">Director Details</h3>
        {merchantData?.directorDetails?.map((director, index) => (
          <div key={index} className="mb-4">
            <h4 className="text-lg font-semibold">{director?.nameOfPartner}</h4>
            <p>
              <strong>Aadhaar Card:</strong>{" "}
              <a
                href={director?.aadhaarCardOfPartnerUrl}
                target="_blank"
                rel="noopener noreferrer"
                className="text-blue-600 hover:underline"
              >
                View Document
              </a>
            </p>
            <p>
              <strong>PAN:</strong>{" "}
              <a
                href={director?.panOfPartnerUrl}
                target="_blank"
                rel="noopener noreferrer"
                className="text-blue-600 hover:underline"
              >
                View Document
              </a>
            </p>
            <p>
              <strong>LinkedIn:</strong>{" "}
              <a
                href={director?.linkedinOfPartner}
                target="_blank"
                rel="noopener noreferrer"
                className="text-blue-600 hover:underline"
              >
                {director?.linkedinOfPartner}
              </a>
            </p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default MerchantInfo;

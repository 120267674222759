import React from "react";
import LineIndicators from "./LineIndicators";
import FooterButtons from "./FooterButtons";

const OnboardingFooter = () => {

  return (
    <div className="sticky bottom-0 flex items-center justify-between pt-8 pb-8 bg-black md:">
      <LineIndicators />
      <FooterButtons />
    </div>
  );
};

export default OnboardingFooter;

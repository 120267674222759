/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { Upload, Image, Modal, Tooltip } from "antd";
import { IoCloudUpload } from "react-icons/io5";
import { useState } from "react";
import ImgCrop from "antd-img-crop";
import { axiosInstance } from "./../../../common/hooks/useAxios";
import { getAuthToken } from "../../../../utils/stateFunctions";
import toast from "react-hot-toast";
import captureAxiosError from "../../../common/utils/captureAxiosError";
import { GrCircleInformation } from "react-icons/gr";

const getBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

const UploadLogo = ({ merchantLogo }) => {
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [fileList, setFileList] = useState([]);
  const [shouldUpload, setShouldUpload] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = async () => {
    setConfirmLoading(true);
    try {
      await axiosInstance.delete(`/merchant/delete-merchant-logo`, {
        headers: {
          Authorization: `Bearer ${getAuthToken()}`,
        },
      });
      setFileList([]);
      toast.success("Logo deleted successfully");
    } catch (err) {
      captureAxiosError(err);
    }
    setConfirmLoading(false);
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    if (merchantLogo) {
      setFileList([
        {
          url: merchantLogo,
        },
      ]);
    }
  }, []);

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(file.url || file.preview);
    setPreviewOpen(true);
  };
  const handleChange = ({ fileList: newFileList }) => setFileList(newFileList);

  const uploadButton = (
    <button
      style={{
        border: 0,
        background: "none",
      }}
      type="button"
    >
      <IoCloudUpload
        style={{
          marginLeft: 15,
          color: "white",
        }}
      />
      <div
        style={{
          marginTop: 8,
          color: "white",
        }}
      >
        Upload
      </div>
    </button>
  );

  return (
    <div className="w-full mt-5 font-medium" id="logo-upload">
      <div className="flex items-center w-full gap-2 mb-5">
        Your logo
        <Tooltip title="The logo should fit within a 120x40 pixels space, with an aspect ratio between 1:1 and 3:1, and a minimum size of 200x200 pixels.">
          <GrCircleInformation size={16} />
        </Tooltip>
      </div>

      <ImgCrop
        rotationSlide
        fillColor={"transparent"}
        beforeCrop={(file) => {
          setShouldUpload(false);
          const isPNG = file.type === "image/png";
          const isLessThan50KB = file.size / 1024 < 50;
          if (!isPNG) {
            toast.error(`${file.name} is not a png file`);
          }
          if (!isLessThan50KB) {
            toast.error(`${file.name} is too big (max 50KB)`);
          }

          setShouldUpload(isPNG && isLessThan50KB);
          return isPNG && isLessThan50KB;
        }}
        modalTitle="Edit you logo"
        showGrid={true}
        showReset={true}
        aspectSlider={true}
        quality={1}
        aspect={3 / 1}
      >
        <Upload
          id="logo-upload"
          listType="picture-card"
          maxCount={1}
          fileList={fileList}
          onPreview={handlePreview}
          onChange={handleChange}
          customRequest={async ({ onSuccess, onError, file, onProgress }) => {
            const formData = new FormData();
            formData.append("file", file);
            if (shouldUpload) {
              await axiosInstance
                .post(`/merchant/upload-merchant-logo`, formData, {
                  headers: {
                    Authorization: `Bearer ${getAuthToken()}`,
                  },
                  onUploadProgress: ({ total, loaded }) =>
                    onProgress({
                      percent: Math.round((loaded / total) * 100),
                    }),
                })
                .then((res) => {
                  onSuccess(res.data);
                  toast.success("Logo uploaded successfully");
                })
                .catch((err) => {
                  onError({ err });
                  captureAxiosError(err);
                });
            } else {
              onError({ err: "Failed to Upload" });
            }
          }}
          onRemove={() => {
            showModal();
            return false;
          }}
        >
          {fileList.length >= 1 ? null : uploadButton}
        </Upload>
      </ImgCrop>
      {previewImage && (
        <Image
          wrapperStyle={{
            display: "none",
          }}
          preview={{
            visible: previewOpen,
            onVisibleChange: (visible) => setPreviewOpen(visible),
            afterOpenChange: (visible) => !visible && setPreviewImage(""),
          }}
          src={previewImage}
        />
      )}
      <Modal
        title="Are you sure you want to delete your logo?"
        open={isModalOpen}
        onOk={handleOk}
        confirmLoading={confirmLoading}
        onCancel={handleCancel}
      ></Modal>
    </div>
  );
};

export default UploadLogo;

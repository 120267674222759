import { Box } from "@mui/material";
import SideNav, { SIDENAV_WIDTH } from "./SideNav";
import TopNav, { TOPNAV_HEIGHT } from "./TopNav";
import { PGMode } from "../../constants/entities";
import useGlobalStore from "../../store/GlobalStore";

const NavbarWrapper = ({ children }) => {
  const isMobile = typeof window !== "undefined" && window.innerWidth < 768;
  const { mode } = useGlobalStore();

  return (
    <div className="bg-[#1B1A1A]">
      {mode === PGMode.TEST && (
        <div>
          <div className="fixed top-0 left-0 z-[100000000] w-full bg-yellow-500 text-center h-1"></div>
          <div
            id="test-mode"
            className="fixed top-0 left-[45%] z-[9998] w-[150px] bg-yellow-500 text-center h-7 rounded-b-[20px] pt-1 text-black text-sm font-medium"
          >
            Test Mode
          </div>
        </div>
      )}
      <Box className="flex">
        <TopNav />
        {!isMobile && <SideNav />}
        <Box
          component="main"
          sx={{
            p: 2,
            width: !isMobile
              ? `calc(100vw - ${SIDENAV_WIDTH + 20}px)`
              : `100vw`, // 20px for right hand scrollbar
            mt: `${TOPNAV_HEIGHT}px`,
            minHeight: `calc(100vh - ${TOPNAV_HEIGHT}px)`,
          }}
          className="flex-shrink-0"
        >
          {children}
        </Box>
      </Box>
    </div>
  );
};
export default NavbarWrapper;

import React from "react";
import VBACard from "./components/VBACard/VBACard";
import Header from "./components/Header";
import BalanceCard from "./components/BalanceCard";
import TestModeUI from "./components/TestModeUI";
import useGlobalStore from "./../../common/store/GlobalStore";
import VBATnxTable from "./components/table/VBATnxTable";
import { VBAProvider } from "./context/VBAContext";
import { useGetVBABalance } from "./hooks/useGetVBABalance";
import { useGetVBADetails } from "./hooks/useGetVBADetails";
import DeActivatedVBA from "./components/DeActivatedVBA";
import { Spin, ConfigProvider } from "antd";

const VirtualAccounts = () => {
  const { mode } = useGlobalStore();
  const { vbaBalance, isLoading: vbaBalanceLoading } = useGetVBABalance();
  const { vbaDetails, isLoading } = useGetVBADetails();

  const renderContent = () => {
    if (!vbaDetails) {
      return <DeActivatedVBA />;
    } else if (mode === "TEST") {
      return <TestModeUI />;
    } else {
      return (
        <VBAProvider>
          <div className="flex flex-col gap-4">
            <Header />
            <div className="flex flex-row justify-between gap-4">
              <VBACard vbaDetails={vbaDetails} />
              <BalanceCard amount={vbaBalance} />
            </div>
            <div>
              <div className="pt-3 pb-4">VBA Deposits Logs:</div>
              <VBATnxTable />
            </div>
          </div>
        </VBAProvider>
      );
    }
  };

  return (
    <>
      {renderContent()}
      <ConfigProvider
        theme={{
          components: {
            Spin: {
              colorBgMask: "#121212",
            },
          },
        }}
      >
        <Spin spinning={vbaBalanceLoading || isLoading} fullscreen />
      </ConfigProvider>
    </>
  );
};

export default VirtualAccounts;

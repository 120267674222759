/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { axiosInstance } from "../../../common/hooks/useAxios";
import { getStandardApiHeadersWithAuth } from "../../../../utils/stateFunctions";
import captureAxiosError from "../../../common/utils/captureAxiosError";
import useGlobalStore from "../../../common/store/GlobalStore";

export const useGetVBADetails = () => {
  const [vbaDetails, setVBADetails] = useState();
  const [isLoading, setIsLoading] = useState();

  const { mode } = useGlobalStore();

  useEffect(() => {
    const getAllPayouts = async () => {
      setIsLoading(true);

      try {
        const { data } = await axiosInstance.post(
          `/xFlowPay/get-account-details`,
          {
            mode: mode,
          },
          {
            headers: getStandardApiHeadersWithAuth(),
          }
        );

        setVBADetails(data);
      } catch (error) {
        captureAxiosError(error);
      } finally {
        setIsLoading(false);
      }
    };

    getAllPayouts();
  }, [mode]);

  return { vbaDetails, isLoading };
};

import React from "react";

const SwitchToggle = ({ isFedWire, setIsFedWire }) => {

  const handleToggle = () => {
    setIsFedWire((prev) => !prev);
  };

  return (
    <div
      className="flex items-center bg-[#282828] rounded-full w-fit h-[32px] px-[4px] gap-[4px] cursor-pointer pt-[10px] pr-[4px] pb-[10px] pl-[4px]"
      onClick={handleToggle}
    >
      <div
        className={`flex items-center justify-center w-fit h-[24px] py-[6px] px-3 rounded-full text-[10px] font-medium leading-[120%] transition-all duration-300 ${
          isFedWire ? "bg-white text-black" : "text-white"
        }`}
      >
        Fed Wire
      </div>
      <div
        className={`flex items-center justify-center w-fit h-[24px] py-[6px] px-3 rounded-full text-[10px] font-medium leading-[120%] transition-all duration-300 ${
          isFedWire ? "text-white" : "bg-white text-black"
        }`}
      >
        ACH
      </div>
    </div>
  );
};

export default SwitchToggle;

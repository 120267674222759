import { createContext, useContext, useState } from "react";

const VBAContext = createContext({});

export const VBAProvider = (props) => {
  const [page, setPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);

  const pageSize = 4;

  return (
    <VBAContext.Provider
      value={{
        page,
        setPage,
        totalCount,
        setTotalCount,
        pageSize,
      }}
    >
      {props.children}
    </VBAContext.Provider>
  );
};

export const useVBAContext = () => {
  return useContext(VBAContext);
};

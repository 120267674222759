import config from "../config";
import { jwtDecode } from "jwt-decode";
import { GenerateRandomColorHex } from "../v1/common/utils/stringUtils";

export function getApiPath() {
  return config.api_path;
}

export function isLoggedIn() {
  if (!isNullOrUndefined(getAuthToken())) {
    if (jwtDecode(getAuthToken()).exp > Date.now() / 1000) {
      return true;
    }
  }
  return false;
}

export function isAdminLoggedIn() {
  if (!isNullOrUndefined(getAdminAuthToken())) {
    if (jwtDecode(getAdminAuthToken()).exp > Date.now() / 1000) {
      return true;
    }
  }
  return false;
}

export function getEmail() {
  return localStorage.getItem("userEmail");
}

export function getAuthToken() {
  return localStorage.getItem("authToken");
}

export function getAdminAuthToken() {
  return localStorage.getItem("adminAuthToken");
}

export function getCurrentMerchantId() {
  return localStorage.getItem("merchantId");
}

export function getUserName() {
  return localStorage.getItem("userName");
}

export function getUserId() {
  return localStorage.getItem("userId");
}

export function getTimezone() {
  if (isNullOrUndefined(localStorage.getItem("timezone"))) {
    localStorage.setItem("timezone", "Asia/Kolkata");
  }
  return localStorage.getItem("timezone");
};

export function setUserId(userId) {
  return localStorage.setItem("userId", userId);
}

export function setUserName(userName) {
  return localStorage.setItem("userName", userName);
}

export function setUserAvatarColor() {
  return localStorage.setItem("userAvatarColor", GenerateRandomColorHex());
}

export function setEmail(userEmail) {
  return localStorage.setItem("userEmail", userEmail);
}

export function setAuthToken(authToken) {
  return localStorage.setItem("authToken", authToken);
}

export function setAdminAuthToken(adminAuthToken) {
  return localStorage.setItem("adminAuthToken", adminAuthToken);
}

export function setCurrentMerchantId(merchantId) {
  return localStorage.setItem("merchantId", merchantId);
}

export function setTimezone(timezone) {
  return localStorage.setItem("timezone", timezone);
}

export function createAuthHeader() {
  return "Bearer " + getAuthToken();
}

export function isNullOrUndefined(value) {
  return typeof value == "undefined" || value == null;
}

export function getStandardApiHeaders() {
  return { "Content-Type": "application/json" };
}

export function addAuthHeaders(value) {
  value["Authorization"] = createAuthHeader();
  return value;
}

export function getStandardApiHeadersWithAuth() {
  return addAuthHeaders(getStandardApiHeaders());
}

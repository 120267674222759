import { axiosInstance } from "../../../common/hooks/useAxios";
import {
  getCurrentMerchantId,
  getAuthToken,
} from "../../../../utils/stateFunctions";

const useSubmitOnboardingDetails = () => {
  const submitOnboardingDetails = async () => {
    await axiosInstance.post(
      `/onboarding/submit/${getCurrentMerchantId()}`,
      null,
      {
        headers: {
          Authorization: `Bearer ${getAuthToken()}`,
        },
      }
    );
  };

  return submitOnboardingDetails;
};

export default useSubmitOnboardingDetails;

/* eslint-disable react-hooks/exhaustive-deps */
import { useState } from "react";
import { axiosInstance } from "./../../../common/hooks/useAxios";
import { getStandardApiHeadersWithAuth } from "./../../../../utils/stateFunctions";
import captureAxiosError from "./../../../common/utils/captureAxiosError";
import useGlobalStore from "../../../common/store/GlobalStore";
import toast from "react-hot-toast";

export const useCancelSubscription = ({
  subscriptionId,
  setSubscriptionDetails,
  setOpenDialog,
}) => {
  const [loadingCancel, setLoadingCancel] = useState();
  const { mode } = useGlobalStore();

  const cancelSubscription = async () => {
    setLoadingCancel(true);
    try {
      await axiosInstance.post(
        `/subscription/cancel`,
        {
          subscriptionId: subscriptionId,
          mode: mode,
        },
        {
          headers: getStandardApiHeadersWithAuth(),
        }
      );

      const { data } = await axiosInstance.get(
        `/subscription/dashboard/get-subscription/${subscriptionId}`,
        {
          headers: getStandardApiHeadersWithAuth(),
        }
      );

      setSubscriptionDetails(data);

      toast.success("Subscription cancelled successfully");
    } catch (error) {
      captureAxiosError(error);
    } finally {
      setLoadingCancel(false);
      setOpenDialog(false);
    }
  };

  return { cancelSubscription, loadingCancel };
};

import React from "react";

import { copyToClipboard } from "../../../../common/utils/copyToClipboard";
import { useLandingPageContext } from "../../contexts/LandingPageContext";
import RightSideMetricsHeader from "./RightSideMetricsHeader";

import { FaCheck } from "react-icons/fa6";
import { RxCross2 } from "react-icons/rx";
import { FaRegClock } from "react-icons/fa";
import { HiOutlineReceiptRefund } from "react-icons/hi2";
import { TbBookmarkQuestion } from "react-icons/tb";
import { BsCopy } from "react-icons/bs";
import { GrTransaction } from "react-icons/gr";
import ParseAmount from "../../../../common/components/UI/ParseAmount";
import ParseDateTimeTz from './../../../../common/components/UI/ParseDateTimeTz';

const RecentTransactions = () => {
  const { recentTransactions } = useLandingPageContext();

  return (
    <div className="bg-[#121212] rounded-lg p-4">
      <RightSideMetricsHeader
        title="Recent Transactions"
        link="Show all"
        route="/transactions"
        icon={<GrTransaction />}
      />
      {recentTransactions.length ? (
        <div className="flex flex-col gap-2 py-4">
          {recentTransactions.map((record, index) => {
            const first4 = record.xtxnId.slice(0, 4);
            const last4 = record.xtxnId.slice(-4);

            const displayId = `${first4}....${last4}`;
            return (
              <div
                key={index}
                className="flex flex-col items-start justify-start p-4 bg-[black] rounded-lg"
              >
                <div className="flex justify-between w-full">
                  {/* Amount */}
                  <div className="text-lg font-medium">
                    <ParseAmount
                      amount={record?.amount}
                      currency={record?.currency}
                      currencySize="sm"
                    />
                  </div>
                  {/* Status */}
                  <div
                    className={`flex gap-1 items-center justify-center px-2 rounded text-xs ${
                      {
                        SUCCESS: "bg-green-800",
                        FAILED: "bg-red-700",
                        CREATED: "bg-gray-600",
                        INCOMPLETE: "bg-gray-800",
                        REFUNDED: "bg-blue-800",
                        DISPUTED: "bg-yellow-800",
                      }[record?.transactionStatus] || ""
                    }`}
                  >
                    {
                      {
                        SUCCESS: <FaCheck size={16} />,
                        FAILED: <RxCross2 size={18} />,
                        CREATED: <FaRegClock size={15} />,
                        INCOMPLETE: <FaRegClock size={15} />,
                        REFUNDED: <HiOutlineReceiptRefund size={15} />,
                        DISPUTED: <TbBookmarkQuestion size={15} />,
                      }[record?.transactionStatus]
                    }
                    {record?.transactionStatus || "Unknown status"}
                  </div>
                </div>
                <div className="flex justify-between w-full pt-4 item-center">
                  {/* intentId */}
                  <div
                    className="flex items-center justify-center gap-2 text-sm cursor-pointer opacity-70 hover:text-blue-400 hover:opacity-100"
                    onClick={() => copyToClipboard(record.xtxnId)}
                  >
                    {displayId} <BsCopy size={16} />
                  </div>
                  {/* Date */}
                  <div className="flex flex-col items-center justify-center gap-2 text-sm opacity-50">
                    <ParseDateTimeTz dateTime={record?.xtxnStartTime} onlyDate={true}/>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      ) : (
        <div className="py-10 text-center">No recent transactions</div>
      )}
    </div>
  );
};

export default RecentTransactions;

import { Input, Form } from "antd";
import React from "react";
import AntDesignContext from "../../../../common/contexts/AntDesignContext";
import { getValidationRules } from "../../utils/validation";

const InputField = ({
  name,
  label,
  placeholder,
  prefix,
  suffix,
  addonAfter,
  addonBefore,
  required,
}) => {
  return (
    <AntDesignContext>
      <Form.Item
        hasFeedback
        label={label}
        name={name}
        validateFirst
        rules={getValidationRules(name, required, label)}
      >
        <Input
          prefix={prefix}
          suffix={suffix}
          addonAfter={addonAfter}
          addonBefore={addonBefore}
          name={name}
          size="middle"
          placeholder={placeholder}
          className="text-white bg-[#121212] rounded-lg placeholder:text-white placeholder:opacity-50"
          required={required}
        />
      </Form.Item>
    </AntDesignContext>
  );
};

export default InputField;

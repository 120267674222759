import React from "react";
import { BackgroundGradient } from "./Background-Gradient";

function Card({ heading, description, step, graphics }) {
  return (
    <div className="relative">
      <BackgroundGradient className="relative rounded-[22px]  max-w-[320px]  md:max-w-[350px] px-10 pb-10 bg-zinc-900 min-h-[560px] md:min-h-[550px] flex flex-col">
        <div className="relative flex-grow">
          <div className="">{graphics}</div>
          <p className="mt-0 mb-2 text-base sm:text-xl text-neutral-200">
            {heading}
          </p>
          <p className="text-sm text-neutral-400">
            {description}
          </p>
        </div>
        <button className="absolute flex items-center px-4 py-1 space-x-1 text-xs font-bold text-white transform -translate-x-1/2 rounded-full cursor-default bottom-6 left-1/2 bg-zinc-800">
          <span>Step {step}</span>
        </button>
      </BackgroundGradient>
    </div>
  );
}

export default Card;

/* eslint-disable no-useless-escape */
import { formFields } from "../constants/OnboardingFields";

export const getValidationRules = (name, required, label) => {
  const baseRules = [
    {
      required,
      message: `Please input your ${label.toLowerCase()}!`,
    },
  ];

  switch (name) {
    case formFields.businessDetails.companyName:
      return [
        ...baseRules,
        {
          min: 2,
          message: "Company name must be at least 2 characters long!",
        },
        {
          max: 100,
          message: "The company name can only be up to 100 characters long!",
        },
      ];
    case formFields.businessDetails.doingBusinessAs:
      return [
        ...baseRules,
        {
          max: 30,
          message: "DBA can only be up to 30 characters long!",
        },
      ];
    case formFields.businessDetails.companyEmail:
      return [
        ...baseRules,
        {
          type: "email",
          message: "Please enter a valid email address!",
        },
      ];
    case formFields.businessDetails.companyAddress.addressLine1:
    case formFields.businessDetails.companyAddress.addressLine2:
      return [...baseRules];
    case formFields.businessDetails.nameOfProduct:
    case formFields.businessDetails.settlementAccountDetails.accountName:
      return [
        ...baseRules,
        {
          pattern: /^[a-zA-Z\s]*$/,
          message: `Please enter a valid ${label.toLowerCase()}!`,
        },
      ];
    case formFields.businessDetails.website:
      return [
        ...baseRules,
        {
          // eslint-disable-next-line no-useless-escape
          pattern:
            /^[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)$/,
          message: "Please enter a valid website address!",
        },
      ];
    case formFields.businessDetails.productDesc:
      return [
        ...baseRules,
        {
          min: 50,
          message: "Description must be at least 50 characters long!",
        },
      ];
    case formFields.businessDetails.volume:
      return [
        ...baseRules,
        {
          type: "number",
          min: 100,
          message: "Volume should be greater than 100!",
        },
      ];
    case formFields.businessDetails.settlementAccountDetails.accountNumber:
      return [
        ...baseRules,
        {
          pattern: /^\d{9,18}$/,
          message: "Please enter a valid account number!",
        },
      ];
    case formFields.businessDetails.settlementAccountDetails.confirmAccountName:
      return [
        ...baseRules,
        ({ getFieldValue }) => ({
          validator(_, value) {
            if (
              !value ||
              getFieldValue(
                formFields.businessDetails.settlementAccountDetails
                  .accountNumber
              ) === value
            ) {
              return Promise.resolve();
            }
            return Promise.reject(
              new Error("The Account number that you entered do not match!")
            );
          },
        }),
      ];
    case formFields.businessDetails.settlementAccountDetails.ifscCode:
      return [
        ...baseRules,
        {
          pattern: /^[A-Z]{4}0[A-Z0-9]{6}$/,
          message: "Please enter a valid IFSC code!",
        },
      ];
    default:
      return baseRules;
  }
};

import { useState } from "react";
import { axiosInstance } from "./../../../common/hooks/useAxios";
import captureAxiosError from "./../../../common/utils/captureAxiosError";
import {
  getStandardApiHeadersWithAuth,
  getTimezone,
} from "../../../../utils/stateFunctions";
import useGlobalStore from "./../../../common/store/GlobalStore";
import { usePaymentLinksContext } from "../contexts/PaymentLinksContext";
import dayjs from "dayjs";
import toast from "react-hot-toast";

export const useCreateSubsPaymentLink = () => {
  const [loading, setLoading] = useState(false);
  const { mode } = useGlobalStore();
  const { setNewSubsLink } = usePaymentLinksContext();

  const createSubsPaymentLink = async ({ formValues }) => {
    setLoading(true);
    try {
      const { data } = await axiosInstance.post(
        `/subscription/link/generate-link`,
        {
          amount: formValues.amount * 100,
          currency: "USD",
          description: formValues.description,
          customerDetails: {
            name: formValues.name,
            email: formValues.email,
          },
          expiryDate: dayjs(formValues.expiryDate).tz(getTimezone()).valueOf(),
          mode: mode,
          receiptId: formValues.receiptId,
          interval: formValues.interval,
          intervalCount: formValues.intervalCount,
          cycleCount: formValues.cycleCount,
        },
        {
          headers: getStandardApiHeadersWithAuth(),
        }
      );

      setNewSubsLink(data.shortUrl);
      toast.success("Subscription link created successfully!");
    } catch (err) {
      captureAxiosError(err);
    } finally {
      setLoading(false);
    }
  };

  return { loading, createSubsPaymentLink };
};

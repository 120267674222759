import React from "react";
import UploadFile from "../UI/UploadFile";
import { formFields } from "../../constants/OnboardingFields";

const RegistrationDetails = () => {
  return (
    <>
      <div className="grid items-start justify-start w-full grid-cols-1 pb-10 md:grid-cols-2 gap-x-20 md:gap-y-10 gap-y-3">
        <UploadFile
          label="PAN"
          helper="( Permanent Account Number )"
          name={formFields.businessDetails.businessRegDoc.pan}
          keyUpload={"BUSINESS_PAN"}
          required={true}
        />
        <UploadFile
          label="TAN"
          helper="( Tax Deduction and Collection Account Number )"
          name={formFields.businessDetails.businessRegDoc.tan}
          keyUpload="BUSINESS_TAN"
          required={true}
        />
        <UploadFile
          label="GST registration certificate / No GST Declaration"
          helper=""
          name={formFields.businessDetails.businessRegDoc.gst}
          customPb="mb-7"
          keyUpload="BUSINESS_GST"
          required={true}
        />
        <UploadFile
          label="Memorandum and Articles of Association "
          helper="(for Pvt Ltd)"
          label2=" / Partnership Deed "
          helper2="(for partnership firms)"
          name={
            formFields.businessDetails.businessRegDoc.memoArticleAssociation
          }
          keyUpload="MEMO_ARTICLE_OF_ASSOCIATION"
          required={true}
        />{" "}
        <UploadFile
          label="Registration Certificate / COI"
          helper="( Certificate of Incorporation )"
          name={formFields.businessDetails.businessRegDoc.registrationCert}
          keyUpload="REGISTRATION_CERTIFICATE"
          required={false}
        />
        <UploadFile
          label="IEC"
          helper="( Import Export Code )"
          name={formFields.businessDetails.businessRegDoc.iec}
          keyUpload="IEC"
          required={false}
        />
      </div>
    </>
  );
};

export default RegistrationDetails;

import React from "react";
import ParseAmount from "./../../../common/components/UI/ParseAmount";
import { MdOutlineAccountBalanceWallet } from "react-icons/md";

const BalanceCard = ({ amount }) => {
  return (
    <div className="h-fit px-4 py-2 rounded-md bg-[#121212] text-white hidden md:block backdrop-blur-lg drop-shadow-xl">
      <div className="flex items-center justify-start gap-4 py-4">
        <MdOutlineAccountBalanceWallet
          className="p-3 bg-black rounded-full drop-shadow-xl"
          size={50}
        />
        <div className="flex items-center w-full gap-2">
          <div className="opacity-50">Balance:</div>
          <div className="flex items-center justify-between gap-4">
            <div className="text-xl font-semibold">
              <ParseAmount amount={amount || 0} currencySize="lg" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BalanceCard;

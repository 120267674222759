import { useEffect } from "react";
import { Stack, TextField } from "@mui/material";
import { StdTextfieldProps } from "./LoginForm";
import { useRef, useState } from "react";
import { LoadingButton } from "@mui/lab";
import useMerchantRegister from "../hooks/useMerchantRegister";
import LayoutContainerWithBg from "../../../common/components/UI/LayoutContainerWithBg";
import {
  getCurrentMerchantId,
} from "../../../../utils/stateFunctions";
import { isEmpty } from "lodash";
import { useNavigate } from "react-router-dom";

const MerchantRegisterForm = () => {
  const nameRef = useRef(null);
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
      const isMerchantRegistered = !isEmpty(getCurrentMerchantId());
      if (isMerchantRegistered) {
        navigate("/");
      }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleRegister = useMerchantRegister();

  const handleSubmit = async () => {
    const merchantName = nameRef.current?.value;
    setIsLoading(true);
    await handleRegister(merchantName);
    setIsLoading(false);
  };

  return (
    <LayoutContainerWithBg>
      <Stack gap={2} mt={6} mb={2}>
        <TextField
          name="merchantName"
          label="Merchant Name"
          {...StdTextfieldProps}
          inputRef={nameRef}
        />
        <LoadingButton
          loading={isLoading}
          fullWidth
          variant="contained"
          onClick={handleSubmit}
          sx={{
            textTransform: "none",
          }}
        >
          Register Merchant
        </LoadingButton>
      </Stack>
    </LayoutContainerWithBg>
  );
};

export default MerchantRegisterForm;

export const ExportPurposeCode = [
  {
    label:
      "P0102 - Realisation of export bills (in respect of goods) sent on collection (full invoice value)",
    value: "P0102",
    description:
      "Realisation of export bills (in respect of goods) sent on collection (full invoice value)",
  },
  {
    label: "P0306 - Other Travel Receipts",
    value: "P0306",
    description: "Other Travel Receipts",
  },
  {
    label: "P0801 - Hardware consultancy/implementation",
    value: "P0801",
    description: "Hardware consultancy/implementation",
  },
  {
    label:
      "P0802 - Software consultancy/implementation (other than those covered in SOFTEX form)",
    value: "P0802",
    description:
      "Software consultancy/implementation (other than those covered in SOFTEX form)",
  },
  {
    label: "P0803 - Data base, data processing charges",
    value: "P0803",
    description: "Data base, data processing charges",
  },
  {
    label: "P0804 - Repair and maintenance of computer and software",
    value: "P0804",
    description: "Repair and maintenance of computer and software",
  },
  {
    label:
      "P0806 - Other information services- Subscription to newspapers, periodicals, etc.",
    value: "P0806",
    description:
      "Other information services- Subscription to newspapers, periodicals, etc.",
  },
  {
    label: "P0807 - Off site Software Exports",
    value: "P0807",
    description: "Off site Software Exports",
  },
  {
    label:
      "P0901 - Franchises services – patents,copy rights, trade marks, industrial processes, franchises etc.",
    value: "P0901",
    description:
      "Franchises services – patents,copy rights, trade marks, industrial processes, franchises etc.",
  },
  {
    label:
      "P0902 - Receipts for use, through licensing arrangements, of produced originals or prototypes (such as manuscripts and films)",
    value: "P0902",
    description:
      "Receipts for use, through licensing arrangements, of produced originals or prototypes (such as manuscripts and films)",
  },
  {
    label: "P1002 - Trade related services – Commission on exports/imports.",
    value: "P1002",
    description: "Trade related services – Commission on exports/imports.",
  },
  {
    label:
      "P1003 - Operational leasing services (other than financial leasing and without operating crew) including charter hire",
    value: "P1003",
    description:
      "Operational leasing services (other than financial leasing and without operating crew) including charter hire",
  },
  {
    label: "P1004 - Legal services",
    value: "P1004",
    description: "Legal services",
  },
  {
    label:
      "P1005 - Accounting, auditing, book keeping and tax consulting services",
    value: "P1005",
    description:
      "Accounting, auditing, book keeping and tax consulting services",
  },
  {
    label:
      "P1006 - Business and management consultancy and public relations services",
    value: "P1006",
    description:
      "Business and management consultancy and public relations services",
  },
  {
    label:
      "P1007 - Advertising, trade fair, market research and public opinion polling services",
    value: "P1007",
    description:
      "Advertising, trade fair, market research and public opinion polling services",
  },
  {
    label: "P1008 - Research & Development services",
    value: "P1008",
    description: "Research & Development services",
  },
  {
    label: "P1009 - Architectural, engineering and other technical services",
    value: "P1009",
    description: "Architectural, engineering and other technical services",
  },
  {
    label: "P1014 - Engineering Services",
    value: "P1014",
    description: "Engineering Services",
  },
  {
    label: "P1016 - Market research and public opinion polling service",
    value: "P1016",
    description: "Market research and public opinion polling service",
  },
  {
    label: "P1017 - Publishing and printing services",
    value: "P1017",
    description: "Publishing and printing services",
  },
  {
    label: "P1019 - Commission agent services",
    value: "P1019",
    description: "Commission agent services",
  },
  {
    label: "P1020 - Wholesale and retailing trade services",
    value: "P1020",
    description: "Wholesale and retailing trade services",
  },
  {
    label:
      "P1022 - Other Technical Services including scientific/space services",
    value: "P1022",
    description: "Other Technical Services including scientific/space services",
  },
  {
    label:
      "P1101 - Audio-visual and related services – services and associated fees related to production of motion pictures, rentals, fees received by actors, directors, producers and fees for distribution rights.",
    value: "P1101",
    description:
      "Audio-visual and related services – services and associated fees related to production of motion pictures, rentals, fees received by actors, directors, producers and fees for distribution rights.",
  },
  {
    label: "P1104 - Entertainment services",
    value: "P1104",
    description: "Entertainment services",
  },
  {
    label:
      "P1107 - Educational services (e.g. fees received for correspondence courses offered to non-resident by Indian institutions)",
    value: "P1107",
    description:
      "Educational services (e.g. fees received for correspondence courses offered to non-resident by Indian institutions)",
  },
  {
    label: "P1109 - Other Personal, Cultural & Recreational services",
    value: "P1109",
    description: "Other Personal, Cultural & Recreational services",
  },
  {
    label: "P1701 - Receipts on account of processing of goods",
    value: "P1701",
    description: "Receipts on account of processing of goods",
  },
];

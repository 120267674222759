import { Button } from "antd";
import React from "react";
import logo from "../../../../../assets/icons/logoWhite.png";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";

const OnboardingHeader = () => {
  const { greeting } = useParams();

  const navigate = useNavigate();
  return (
    <>
      <div className="flex items-center justify-between px-2 pb-10">
        <div>
          <img src={logo} alt="logo" className="w-20 md:w-36" />
        </div>
        <div className="flex items-center gap-8">
          {!greeting && (
            <a
              className="text-sm text-white opacity-50 cursor-pointer"
              href="mailto:hello@xpaycheckout.com"
            >
              <u>Having issues</u> ?
            </a>
          )}
          {(!greeting || greeting === "welcome") && (
            <Button
              className="text-white bg-transparent rounded-md"
              onClick={() => navigate("/")}
            >
              Skip
            </Button>
          )}
        </div>
      </div>
    </>
  );
};

export default OnboardingHeader;

import { Container, Divider, Typography } from "@mui/material";
import LinksListTable from "./table/LinksListTable";
import LinksListFilterGroup from "./filters/LinksListFilterGroup";
import ViewPaymentLink from "./generateLinkForm/ViewPaymentLink";
import GenerateLinkForm from "./generateLinkForm/GenerateLinkForm";

const PaymentLinksPage = () => {
  return (
    <>
      <Container maxWidth="lg" sx={{ mr: "auto", ml: 0 }}>
        <Typography variant="h6">Generate a payment link</Typography>
        <ViewPaymentLink />
        <GenerateLinkForm />
      </Container>
      <Container maxWidth={false}>
        <Divider />
        <LinksListFilterGroup />
        <LinksListTable />
      </Container>
    </>
  );
};

export default PaymentLinksPage;

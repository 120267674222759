/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { axiosInstance } from "./../../../common/hooks/useAxios";
import { getCurrentMerchantId, getStandardApiHeadersWithAuth } from "./../../../../utils/stateFunctions";
import captureAxiosError from "./../../../common/utils/captureAxiosError";
import useGlobalStore from "../../../common/store/GlobalStore";
import { useTransactionContext } from "../context/TransactionContext";

export const useGetAllTransaction = () => {
  const [allTransactions, setAllTransactions] = useState();
  const [isLoading, setIsLoading] = useState();
  const { mode } = useGlobalStore();
  const {
    startTime,
    endTime,
    page,
    setTotalCount,
    pageSize,
    statusFilter,
    searchByIdText,
    refresh,
  } = useTransactionContext();

  useEffect(() => {
    const getAllTransactions = async () => {
      setIsLoading(true);

      try {
        if (searchByIdText?.length > 0) {
          const { data } = await axiosInstance.post(
            `/transaction-page/merchant/${getCurrentMerchantId()}/search-by-ids`,
            {
              searchText: searchByIdText,
              pageSize: pageSize,
              startIndex: (page - 1) * pageSize,
              mode: mode,
              startTime: startTime,
              endTime: endTime,
            },
            {
              headers: getStandardApiHeadersWithAuth(),
            }
          );

          setAllTransactions(data?.transactions);
          setTotalCount(data?.totalCount);
        } else {
          const { data } = await axiosInstance.post(
            `/transaction-page/merchant/${getCurrentMerchantId()}/filtered-transactions`,
            {
              pageSize: pageSize,
              startIndex: (page - 1) * pageSize,
              mode: mode,
              startTime: startTime,
              endTime: endTime,
              txnStatuses: statusFilter,
            },
            {
              headers: getStandardApiHeadersWithAuth(),
            }
          );

          setAllTransactions(data?.transactions);
          setTotalCount(data?.totalCount);
        }
      } catch (error) {
        captureAxiosError(error);
      } finally {
        setIsLoading(false);
      }
    };

    getAllTransactions();
  }, [startTime, endTime, mode, page, statusFilter, searchByIdText, refresh]);

  return { allTransactions, isLoading };
};

import React, { useEffect, useState } from "react";
import { Form, Upload, Modal } from "antd";
import { FiUploadCloud } from "react-icons/fi";
import AntDesignContext from "../../../../common/contexts/AntDesignContext";
import { getValidationRules } from "../../services/validation";
import toast from "react-hot-toast";
import { useOnboardingContext } from "../../contexts/OnboardingContext";

const UploadFile = ({
  name,
  label,
  label2,
  helper,
  helper2,
  required,
  keyUpload,
}) => {
  const { onboardingFields, setOnboardingFields, currentBDPage } =
    useOnboardingContext();
  const [fileList, setFileList] = useState([]);
  const [previewUrl, setPreviewUrl] = useState(null);
  const [previewModal, setPreviewModal] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [status, setStatus] = useState();

  useEffect(() => {
    const temp = name + "Url";
    if (onboardingFields[temp]) {
      setFileList([
        {
          uid: name,
          name: keyUpload,
          status: "done",
          url: onboardingFields[temp],
          type: "application/pdf",
        },
      ]);

      setPreviewUrl(onboardingFields[temp]);
    } else if (onboardingFields[name]) {
      setFileList([
        {
          uid: name,
          name: onboardingFields[name]?.name || keyUpload,
          status: "done",
          originFileObject: onboardingFields[name],
          type: "application/pdf",
        },
      ]);

      const file = onboardingFields[name];
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreviewUrl(reader.result);
      };
      reader.readAsDataURL(file);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onboardingFields, currentBDPage]);

  const beforeUpload = (file) => {
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      toast.error("File must be smaller than 2MB!", {
        position: "bottom-center",
      });
      setStatus("error");
    }
    return false; // Prevent upload
  };

  const onChange = ({ fileList: newFileList }) => {
    setFileList(newFileList);
    if (newFileList.length > 0 && newFileList[0].size / 1024 / 1024 < 2) {
      const file = newFileList[0].originFileObj;
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreviewUrl(reader.result);
      };
      reader.readAsDataURL(file);
      setOnboardingFields({ ...onboardingFields, [name]: file });
    } else {
      newFileList.pop();
      setFileList(newFileList);
      setOnboardingFields({
        ...onboardingFields,
        [name]: null,
        [name + "Url"]: null,
      });
      setPreviewUrl(null);
    }
  };

  return (
    <AntDesignContext>
      <Form.Item
        hasFeedback
        label={
          <div className="pb-2 text-lg font-normal text-white">
            {label}{" "}
            <span className="text-sm font-normal opacity-50">{helper} </span>{" "}
            {label2}
            <span className="text-sm font-normal opacity-50">{helper2} </span>
          </div>
        }
        name={name}
        validateFirst
        rules={getValidationRules(name, required, label)}
      >
        <Upload
          listType="picture"
          accept=".pdf"
          fileList={fileList}
          maxCount={1}
          name={name}
          onChange={onChange}
          beforeUpload={beforeUpload}
          onPreview={() => setPreviewModal(true)}
          status={"error"}
          className="cursor-pointer"
        >
          {fileList.length < 1 && (
            <button
              className={`flex flex-col gap-2 items-center justify-center w-full md:w-[28vw] h-[120px] p-4 text-white transition-all duration-300 ease-in-out bg-black border border-dashed rounded-lg hover:bg-gray-800 focus:outline-none focus:ring-2 focus:ring-gray-800 focus:ring-opacity-50 hover:shadow-lg`}
              type="button"
            >
              <FiUploadCloud className="text-lg text-white" />
              <div className="text-lg">Click to choose a file or drag here</div>
              <div className="flex justify-between gap-4">
                <div className="text-sm opacity-50">Supported format : PDF</div>
                <div className="text-sm opacity-50">Size limit : 2 MB</div>
              </div>
            </button>
          )}
        </Upload>
      </Form.Item>

      {previewUrl && (
        <Modal
          title="Preview"
          footer={null}
          open={previewModal}
          onCancel={() => setPreviewModal(false)}
          centered
          width={1000}
          className="text-white bg-black"
        >
          <div className="flex items-center justify-center w-full overflow-auto h-[80vh]">
            {fileList[0]?.type?.startsWith("image/") ? (
              <img src={previewUrl} alt="Preview" className="" />
            ) : (
              <embed
                src={previewUrl}
                type="application/pdf"
                width="100%"
                height="98%"
              />
            )}
          </div>
        </Modal>
      )}
    </AntDesignContext>
  );
};

export default UploadFile;

import { useEffect, useState } from "react";
import { TypographyStyles } from "./MerchantDetails";
import useGetXKeys from "../hooks/useGetXKeys";
import { isEmpty } from "lodash";
import { LoadingButton } from "@mui/lab";
import {
  CircularProgress,
  Grid,
  Paper,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  Button,
} from "@mui/material";
import useGenerateXKeys from "../hooks/useGenerateXKeys";
import KeysView from "./KeysView";
import useGlobalStore from "./../../../common/store/GlobalStore";

const XPayKeys = () => {
  const [keys, setKeys] = useState({});
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const { mode } = useGlobalStore();
  const { isLoading, callApi: getKeys } = useGetXKeys(mode);
  const { isLoading: generatingKeys, callApi: generateNewKeys } =
    useGenerateXKeys(mode);

  const fetchKeys = async () => {
    const data = await getKeys();
    setKeys(data);
  };

  useEffect(() => {
    fetchKeys();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mode]);

  const handleGenerateKeys = async () => {
    const data = await generateNewKeys();
    setKeys(data);
    setIsDialogOpen(false);
  };

  const renderKeys = () => {
    if (isLoading) {
      return <CircularProgress sx={{ mt: 2, mx: "auto" }} />;
    }
    return <KeysView keys={keys} />;
  };

  const handleDialogClose = () => {
    setIsDialogOpen(false);
  };

  return (
    <Paper elevation={3} sx={{ p: 2 }}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography {...TypographyStyles.heading}>xPay Keys</Typography>
        </Grid>
        {renderKeys()}
        <Grid item xs={12}>
          <LoadingButton
            variant="outlined"
            loading={generatingKeys}
            onClick={() => setIsDialogOpen(true)}
          >
            {isEmpty(keys) ? "" : "RE"}GENERATE KEYS
          </LoadingButton>
          <Dialog open={isDialogOpen} onClose={handleDialogClose}>
            <DialogContent>
              <Typography>
                Are you sure you want to regenerate the keys?
              </Typography>
            </DialogContent>
            <DialogActions
              sx={{
                justifyContent: "space-between",
                padding: "1.5rem",
                marginTop: "-1rem",
              }}
            >
              <Button onClick={handleDialogClose} color="primary">
                Cancel
              </Button>
              <LoadingButton
                variant="contained"
                loading={generatingKeys}
                onClick={handleGenerateKeys}
              >
                {isEmpty(keys) ? "" : "RE"}GENERATE KEYS
              </LoadingButton>
            </DialogActions>
          </Dialog>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default XPayKeys;

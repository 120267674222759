import { useShallow } from "zustand/react/shallow";
import useLinkRecordsStore from "../../store/PaymentLinkRecordsStore";
import { TextField } from "@mui/material";

const StyleProps = {
  InputProps: {
    sx: {
      height: 40,
      width:
        typeof window !== "undefined" && window.innerWidth < 768 ? 216 : 416,
    },
  },
  inputProps: {
    style: {
      margin: 0,
      width: "100%",
      border: "none",
      boxShadow: "none",
    },
  },
};

const LinksListSearch = () => {
  const { searchQuery, setSearchQuery } = useLinkRecordsStore(
    useShallow(selectStore)
  );

  const handleChange = (e) => {
    setSearchQuery(e.target.value);
  };

  return (
    <TextField
      label="Search by Link ID or Receipt ID"
      size="small"
      value={searchQuery}
      onChange={handleChange}
      {...StyleProps}
    />
  );
};

const selectStore = (state) => ({
  searchQuery: state.searchQuery,
  setSearchQuery: state.setSearchQuery,
});

export default LinksListSearch;

import welcome from "../../../../assets/img/Onboarding/Welcome.png";
import thankyou from "../../../../assets/img/Onboarding/Thankyou.png";

export const content = {
  welcome: {
    img: welcome,
    heading: (
      <div>
        Let's take you global, effortlessly. <br /> In order to Activate
        payment, please complete the KYB onboarding
      </div>
    ),
    subheading: "",
    button: "Start Onboarding",
    link: "/onboarding",
  },
  thankyou: {
    img: thankyou,
    heading: "Congratulations on completing your onboarding and KYB!",
    subheading: (
      <div>
        Our team is now reviewing your details and will get back to you soon. If
        you have any doubts, feel free to reach out at{" "}
        <strong>
          <a
            href="mailto:hello@xpaycheckout.com"
            target="_blank"
            rel="noreferrer"
          >
            hello@xpaycheckout.com
          </a>
        </strong>
      </div>
    ),
    button: "Go to Dashboard",
    link: "/",
  },
};

import { useState } from "react";
import {
  AppBar,
  Avatar,
  Box,
  Typography,
  Stack,
  Divider,
  List,
} from "@mui/material";
import { SIDENAV_WIDTH } from "./SideNav";
import { GenerateRandomColorHex } from "../../utils/stringUtils";
import ModeSwitcher from "./ModeSwitcher";
import UserAccountDropdown from "./UserAccountDropdown";
import { useGlobalContext } from "../../contexts/GlobalContext";
import Logo from "../../../../assets/icons/Logo";
import { IoMenu } from "react-icons/io5";
import { Drawer, Select } from "antd";
import { SideNavMenuBottom, SideNavMenuTop } from "./SideNavMenu";
import AntDesignContext from "../../contexts/AntDesignContext";
import {
  getTimezone,
  setTimezone as setTimezoneCache,
} from "../../../../utils/stateFunctions";
import moment from "moment-timezone";

export const TOPNAV_HEIGHT = 64;

const options = moment.tz.names().map((zone) => ({
  label: zone,
  value: zone,
}));

const TopNav = () => {
  const [isUserDetailsOpen, setIsUserDetailsOpen] = useState(false);
  const { isMobile } = useGlobalContext();
  const { setTimezone } = useGlobalContext();

  const [open, setOpen] = useState(false);

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  const toggleUserDetails = () => {
    setIsUserDetailsOpen((prev) => !prev);
  };

  const onChange = (value) => {
    setTimezoneCache(value);
    setTimezone(value);
  };

  return (
    <>
      <AntDesignContext>
        <Drawer
          onClose={onClose}
          open={open}
          placement="left"
          className="bg-transparent"
          zIndex={10000}
          size="large"
        >
          <Stack justifyContent="space-between" height="80vh">
            <Box>
              <List>
                <SideNavMenuTop />
                <SideNavMenuBottom />
                <Divider />
              </List>
            </Box>
          </Stack>
        </Drawer>
      </AntDesignContext>
      <AppBar
        position="fixed"
        sx={{
          width: !isMobile ? `calc(100% - ${SIDENAV_WIDTH}px)` : `100%`,
          ml: !isMobile ? `${SIDENAV_WIDTH}px` : 0,
          px: 2,
          height: TOPNAV_HEIGHT,
          flexDirection: "row",
        }}
        className="items-center"
        elevation={0}
      >
        {isMobile && (
          <IoMenu
            className="w-5 h-5 mr-4 cursor-pointer"
            onClick={showDrawer}
          />
        )}
        {isMobile && <Logo style={{ width: 80 }} />}
        <ModeSwitcher />
        {!isMobile && (
          <Typography mr={2} variant="body2" color="white">
            <AntDesignContext>
              <Select
                options={options}
                onChange={onChange}
                defaultValue={getTimezone()}
                className="w-44"
                popupClassName="z-[100000000] bg-[#1b1a1a]"
                showSearch
              />
            </AntDesignContext>
          </Typography>
        )}
        <Avatar
          sx={{ backgroundColor: getAvatarColor() }}
          onClick={toggleUserDetails}
          style={{ cursor: "pointer" }}
        >
          {getAvatarText()}
        </Avatar>
      </AppBar>
      {isUserDetailsOpen && (
        <Box sx={{ position: "fixed", top: 60, right: 16, zIndex: 11000 }}>
          <UserAccountDropdown />
        </Box>
      )}
    </>
  );
};

const getAvatarText = () => {
  const uname = localStorage.getItem("userName");
  if (!uname) return "";
  return uname[0].toUpperCase();
};

const getAvatarColor = () => {
  let color = localStorage.getItem("userAvatarColor");
  if (!color) {
    color = GenerateRandomColorHex();
    localStorage.setItem("userAvatarColor", color);
  }
  return color;
};

export default TopNav;

import { useEffect } from "react";
import useAxios from "../../../common/hooks/useAxios";
import {
  getUserId,
  getStandardApiHeadersWithAuth,
} from "../../../../utils/stateFunctions";
import {
  Breadcrumbs,
  CircularProgress,
  Grid,
  Paper,
  Stack,
  Typography,
  Box,
  Button,
} from "@mui/material";
import { EnvelopeIcon, CheckBadgeIcon } from "@heroicons/react/24/solid";
import { isEmpty } from "lodash";
import CopyButton from "./CopyButton";
import useVerifyEmail from "../hooks/useVerifyEmail";
import { useGlobalContext } from "../../../common/contexts/GlobalContext";

export const TypographyStyles = {
  heading: {
    textAlign: "left",
    variant: "h6",
  },
  subheading: {
    textAlign: "left",
    sx: {
      opacity: 0.7,
      mb: 1,
    },
  },
};

const UserDetails = () => {
  const handleSendVerifyEmail = useVerifyEmail();
  const { isMobile } = useGlobalContext();

  const { data, isLoading, callApi } = useAxios({
    url: `/users/user/${getUserId()}`,
    method: "GET",
    headers: getStandardApiHeadersWithAuth(),
  });

  useEffect(() => {
    callApi();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isEmpty(data)) {
    return null;
  }

  const renderDetails = () => {
    if (isLoading) {
      return <CircularProgress sx={{ mt: 2, mx: "auto" }} />;
    }

    return (
      <>
        <Grid item xs={isMobile ? 3 : 2}>
          <Typography {...TypographyStyles.subheading}>Name</Typography>
          <Typography {...TypographyStyles.subheading}>E-Mail</Typography>
        </Grid>
        <Grid item xs={isMobile ? 8 : 9}>
          <Typography {...TypographyStyles.subheading} noWrap>
            {data.name}
          </Typography>
          <Box display="flex" alignItems="center" gap={1}>
            <Typography {...TypographyStyles.subheading} noWrap>
              {data.email}
            </Typography>
            {data.emailVerified ? (
              <CheckBadgeIcon className="w-6 mb-2 ml-3" />
            ) : (
              !isMobile && (
                <Button
                  variant="contained"
                  startIcon={<EnvelopeIcon />}
                  sx={{ px: "8px", py: "4px", mb: "10px", ml: "16px" }}
                  onClick={handleSendVerifyEmail}
                >
                  Verify Email
                </Button>
              )
            )}
          </Box>
        </Grid>
        <Grid item xs={1} sx={{ opacity: 0.7 }}>
          <CopyButton text={data.name} />
          <CopyButton text={data.email} />
        </Grid>
        {isMobile && (
          <Button
            variant="contained"
            startIcon={<EnvelopeIcon />}
            sx={{ px: "8px", py: "4px", mb: "10px", ml: "16px", mt: "12px" }}
            onClick={handleSendVerifyEmail}
          >
            Verify Email
          </Button>
        )}
      </>
    );
  };

  return (
    <>
      <Breadcrumbs>
        <Typography color="gray">Settings</Typography>
        <Typography>My Profile</Typography>
      </Breadcrumbs>
      <Stack direction="row" gap={3} alignItems="start" mt={2}>
        <Box sx={{ display: "inline-block" }}>
          <Paper elevation={3} sx={{ p: 3, flexGrow: 1 }}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography {...TypographyStyles.heading}>
                  User Details
                </Typography>
              </Grid>
              {renderDetails()}
            </Grid>
          </Paper>
        </Box>
      </Stack>
    </>
  );
};

export default UserDetails;

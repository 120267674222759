import { TextField } from "@mui/material";
import useGenLinkFormStore from "../../store/GenerateLinkFormStore";
import { has } from "lodash";

const CustomTextField = ({ label, metadataKey, validator, ...props }) => {
  const error = useGenLinkFormStore((state) => state.error ?? {});
  const resetError = useGenLinkFormStore((state) => state.resetError);
  const value = useGenLinkFormStore((state) => state[metadataKey]);
  const setMetadata = useGenLinkFormStore((state) => state.setMetadata);
  const setError = useGenLinkFormStore((state) => state.setError);

  const hasError = has(error, metadataKey);

  const handleChange = (e) => {
    const newValue = e.target.value;
    setMetadata(metadataKey, newValue);

    if (hasError) {
      resetError(metadataKey);
    }

    if (validator) {
      const errorMessage = validator(newValue);
      if (errorMessage) {
        setError(metadataKey, errorMessage);
      }
    }
  };

  return (
    <TextField
      {...props}
      value={value}
      onChange={handleChange}
      name={metadataKey}
      label={label}
      error={hasError}
      helperText={error[metadataKey]}
      size="small"
      fullWidth
      inputProps={{
        sx: {
          boxShadow: "none !important",
        },
      }}
    />
  );
};

export default CustomTextField;

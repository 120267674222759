import { axiosInstance } from "../../../common/hooks/useAxios";
import {
  getCurrentMerchantId,
  getStandardApiHeadersWithAuth,
} from "../../../../utils/stateFunctions";
import { toast } from "react-hot-toast";
import useLogout from "../../authentication/hooks/useLogout";

const useGetDetails = () => {
  const handleLogout = useLogout();
  const updateStateWithFlattenedObject = async (
    obj,
    setStateFunction,
    prefix
  ) => {
    if (obj.accountNumber) {
      setStateFunction((prevState) => ({
        ...prevState,
        [`businessDetails.settlementAccountDetails.confirmAccountName`]:
          obj.accountNumber,
      }));
    }

    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        const newKey = prefix ? `${prefix}.${key}` : key;

        if (
          typeof obj[key] === "object" &&
          obj[key] !== null &&
          !Array.isArray(obj[key])
        ) {
          updateStateWithFlattenedObject(obj[key], setStateFunction, newKey);
        } else if (Array.isArray(obj[key]) && key !== "localCurrenciesNeeded") {
          obj[key].forEach((item, index) => {
            updateStateWithFlattenedObject(
              item,
              setStateFunction,
              `${newKey}[${index}]`
            );
          });
        } else {
          setStateFunction((prevState) => ({
            ...prevState,
            [newKey]: obj[key],
          }));

          if (newKey.endsWith("Url") && typeof obj[key] === "string") {
            try {
              const file = await handleFile(obj[key]);
              const newFileKey = newKey.slice(0, -3); // Remove 'Url' from the end
              setStateFunction((prevState) => ({
                ...prevState,
                [newFileKey]: file,
              }));
            } catch (error) {
              const isProduction = process.env.REACT_APP_XPAY_ENV === "prod";
              if (!isProduction) {
                console.error(
                  `Error converting URL to file for ${newKey}:`,
                  error
                );
              }
              if (error?.response?.status === 401) {
                handleLogout();
              }
            }
          }
        }
      }
    }
  };

  async function handleFile(url) {
    try {
      // Fetch the file from S3
      const response = await axiosInstance.get(url, {
        responseType: "blob",
      });

      // Get the content type from the response headers
      const contentType = response.headers["content-type"];

      const filename =
        url.substring(url.lastIndexOf("/") + 1).split("?")[0] + ".pdf";

      // Create a new File object with the original content type
      const file = new File([response.data], filename, { type: contentType });
      return file;
    } catch (error) {
      toast.error(`Error fetching file from database. Please try again later.`);
      if (error?.response?.status === 401) {
        handleLogout();
      }
    }
  }

  const getDetails = async (
    setCurrentPage,
    setCurrentBDPage,
    setOnboardingFields,
    setNoOfPartners,
    setLoading
  ) => {
    setLoading(true);

    try {
      const response = await axiosInstance.get(
        `/onboarding/${getCurrentMerchantId()}`,
        {
          headers: getStandardApiHeadersWithAuth(),
        }
      );

      const resCurPage = parseInt(response.data?.currentPage);
      if (!isNaN(resCurPage)) {
        if (resCurPage === 1) {
          setCurrentPage(0);
        } else if (resCurPage === 7) {
          setCurrentPage(2);
          setCurrentBDPage(4);
        } else {
          setCurrentPage(1);
          setCurrentBDPage(resCurPage - 2);
        }
      } else {
        setCurrentPage(0);
        setCurrentBDPage(0);
      }

      updateStateWithFlattenedObject(response?.data, setOnboardingFields);

      const partner = response.data?.directorDetails?.length || 2;
      setNoOfPartners(partner);
    } catch (error) {
      toast.error(
        error?.response?.data?.errorDescription || "Something went wrong"
      );
      if (error?.response?.status === 401) {
        handleLogout();
      }
    } finally {
      setLoading(false);
    }
  };

  return getDetails;
};

export default useGetDetails;

import { useShallow } from "zustand/react/shallow";
import useLinkRecordsStore from "../../store/PaymentLinkRecordsStore";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/24/solid";
import { IconButton, Typography } from "@mui/material";
import useGlobalStore from "../../../../common/store/GlobalStore";

const LinksTableFooter = () => {
  const { page, setPage, recordCount } = useLinkRecordsStore(
    useShallow(selectStore)
  );
  const mode = useGlobalStore((state) => state.mode);

  const onNextPage = () => {
    setPage(page + 1, mode);
  };

  const onPrevPage = () => {
    setPage(page - 1, mode);
  };

  return (
    <>
      <div className="flex items-center justify-center pt-4">
        <IconButton onClick={onPrevPage} disabled={page === 1}>
          <ChevronLeftIcon height={16} width={16} />
        </IconButton>
        <Typography variant="caption" mx={3}>
          Page {page}
        </Typography>
        <IconButton onClick={onNextPage} disabled={recordCount === 0}>
          <ChevronRightIcon height={16} width={16} />
        </IconButton>
      </div>
    </>
  );
};

const selectStore = (state) => ({
  page: state.page,
  setPage: state.setPage,
  recordCount: state.records.length,
  pageSize: state.pageSize,
});

export default LinksTableFooter;

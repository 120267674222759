import React from "react";
import { ReactComponent as VBAIcon } from "../../../../assets/icons/virtualBankAccount.svg";
import { Button } from "antd";
import { HiOutlineStatusOnline } from "react-icons/hi";
import useGlobalStore from "./../../../common/store/GlobalStore";

const TestModeUI = () => {
  const { setMode } = useGlobalStore();

  const enableLiveMode = () => {
    setMode("LIVE");
  };

  return (
    <div className="flex flex-col items-center justify-center h-[80vh] w-[80vw] gap-12 overflow-hidden bg-dot-white/[0.1]">
      <VBAIcon />
      <p className="text-2xl text-center">
        To see your Virtual Bank Accounts (VBA) details,
        <br />
        Please switch to live mode.
      </p>
      <Button
        type="primary"
        icon={<HiOutlineStatusOnline />}
        size="large"
        onClick={enableLiveMode}
      >
        Switch to Live Mode
      </Button>
    </div>
  );
};

export default TestModeUI;

export const formFields = {
  businessType: "businessType",
  businessDetails: {
    companyName: "businessDetails.companyName",
    companyAddress: {
      addressLine1: "businessDetails.companyAddress.addressLine1",
      addressLine2: "businessDetails.companyAddress.addressLine2",
      city: "businessDetails.companyAddress.city",
      state: "businessDetails.companyAddress.state",
      country: "businessDetails.companyAddress.country",
      pinCode: "businessDetails.companyAddress.pinCode",
    },
    companyEmail: "businessDetails.companyEmail",
    doingBusinessAs: "businessDetails.doingBusinessAs",
    website: "businessDetails.website",
    nameOfProduct: "businessDetails.nameOfProduct",
    productDesc: "businessDetails.productDesc",
    volume: "businessDetails.volume",
    exportPurposeCode: "businessDetails.exportPurposeCode",
    localCurrenciesNeeded: "businessDetails.localCurrenciesNeeded",
    businessRegDoc: {
      pan: "businessDetails.businessRegDoc.pan",
      tan: "businessDetails.businessRegDoc.tan",
      gst: "businessDetails.businessRegDoc.gst",
      memoArticleAssociation:
        "businessDetails.businessRegDoc.memoArticleAssociation",
      registrationCert: "businessDetails.businessRegDoc.registrationCert",
      iec: "businessDetails.businessRegDoc.iec",
    },
    addressProof: {
      rentalAgreement: "businessDetails.addressProof.rentalAgreement",
      utilityBills: "businessDetails.addressProof.utilityBills",
    },
    settlementAccountDetails: {
      accountNumber: "businessDetails.settlementAccountDetails.accountNumber",
      accountName: "businessDetails.settlementAccountDetails.accountName",
      confirmAccountName:
        "businessDetails.settlementAccountDetails.confirmAccountName",
      bankStatement: "businessDetails.settlementAccountDetails.bankStatement",
      cancelledCheque:
        "businessDetails.settlementAccountDetails.cancelledCheque",
      ifscCode: "businessDetails.settlementAccountDetails.ifscCode",
    },
  },
  directorDetails: {
    baseName: "directorDetails",
    nameOfPartner: "nameOfPartner",
    linkedinOfPartner: "linkedinOfPartner",
    aadhaarCardOfPartner: "aadhaarCardOfPartner",
    panOfPartner: "panOfPartner",
  },
};

import { Button, Modal } from "antd";
import React, { useState } from "react";
import { MdAddLink } from "react-icons/md";
import GenerateSubsPaymentLink from "./GenerateSubsPaymentLink";
import { getTimezone } from "../../../../utils/stateFunctions";
import dayjs from "../../../../utils/CustomDayjs";
import { Form } from "antd";
import { useCreateSubsPaymentLink } from "../hooks/useCreateSubsPaymentLink";
import { usePaymentLinksContext } from "../contexts/PaymentLinksContext";
import { copyToClipboard } from "./../../../common/utils/copyToClipboard";
import { GeneratedLinkInfo } from "./GeneratedLinkInfo";

const CreateSubscriptionLink = () => {
  const [open, setOpen] = useState(false);
  const { loading, createSubsPaymentLink } = useCreateSubsPaymentLink();
  const { newSubsLink, setNewSubsLink } = usePaymentLinksContext();
  const [linkInfo, setLinkInfo] = useState(null);

  const createPaymentLinkData = {
    name: "",
    email: "",
    description: "",
    amount: null,
    expiryDate: dayjs().tz(getTimezone()).add(1, "month").startOf("day"),
    receiptId: "",
    interval: null,
    intervalCount: null,
    cycleCount: null,
  };

  const [form] = Form.useForm();
  form.setFieldsValue(createPaymentLinkData);

  const showModal = () => {
    setOpen(true);
  };

  const handleOk = async () => {
    await form
      .validateFields()
      .then(async (values) => {
        await createSubsPaymentLink({ formValues: values });
        setLinkInfo(values);
      })
      .catch((errorInfo) => {
        console.log("Validation Failed");
      });
  };

  const handleCancel = () => {
    setOpen(false);
    setNewSubsLink(null);
  };

  return (
    <>
      <div className="absolute right-[0px] top-[-94px]">
        <Button
          type="primary"
          size="large"
          icon={<MdAddLink size={20} />}
          onClick={showModal}
        >
          New Subscription Link
        </Button>
      </div>
      <div id="paymentLink">
        <Modal
          title="Create New Subscription Link"
          open={open}
          onOk={() => {
            newSubsLink ? copyToClipboard(newSubsLink) : handleOk();
          }}
          okText={newSubsLink ? "Copy Link" : "Create Link"}
          cancelText={newSubsLink ? "Done" : "Cancel"}
          confirmLoading={loading}
          loading={loading}
          onCancel={handleCancel}
          wrapClassName={"backdrop-blur-sm"}
          className="pb-0 border border-gray-600 rounded-lg"
          width={600}
          footer={(_, { OkBtn, CancelBtn }) =>
            newSubsLink ? (
              <div className="flex items-center justify-between gap-4">
                <div
                  className="underline cursor-pointer"
                  onClick={() => {
                    setNewSubsLink(null);
                  }}
                >
                  Create New Subscription Link
                </div>
                <div className="flex gap-2">
                  <CancelBtn />
                  <OkBtn />
                </div>
              </div>
            ) : (
              <>
                <CancelBtn />
                <OkBtn />
              </>
            )
          }
        >
          {!newSubsLink ? (
            <GenerateSubsPaymentLink form={form} />
          ) : (
            <GeneratedLinkInfo linkInfo={linkInfo} link={newSubsLink} />
          )}
        </Modal>
      </div>
    </>
  );
};

export default CreateSubscriptionLink;

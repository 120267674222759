/* eslint-disable react-hooks/exhaustive-deps */
import BalancesTableFooter from "./BalancesTableFooter";
import { useBalanceContext } from "../../context/BalanceContext";
import { useGetAllPayouts } from "../../hooks/useGetAllPayouts";

import { FaCheck } from "react-icons/fa6";
import { RxCross2 } from "react-icons/rx";
import { FaRegClock } from "react-icons/fa";
import { HiOutlineExternalLink } from "react-icons/hi";

import { Table } from "antd";
import AntDesignContext from "../../../../common/contexts/AntDesignContext";
import ParseAmount from "../../../../common/components/UI/ParseAmount";
import ParseDateTimeTz from "./../../../../common/components/UI/ParseDateTimeTz";

const BalancesTable = () => {
  const { setShowDrawer, setPayoutId, pageSize, page, setPage, totalCount } =
    useBalanceContext();
  const { allPayouts, isLoading } = useGetAllPayouts();
  const handleShowDetails = (payoutId) => () => {
    setPayoutId(payoutId);
    setShowDrawer(true);
  };

  const columns = [
    {
      title: "Payout Id",
      dataIndex: "payoutId",
      key: "payoutId",
      align: "center",
      render: (intentId) => {
        const first4 = intentId.slice(0, 4);
        const last4 = intentId.slice(-4);

        const displayId = `${first4}....${last4}`;

        return (
          <div className="flex items-center justify-center gap-2">
            {displayId} <HiOutlineExternalLink size={16} />
          </div>
        );
      },
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
      align: "center",
      render: (amount) => {
        return <ParseAmount amount={amount} divideBy100={true} />;
      },
    },
    {
      title: "Initiated At",
      dataIndex: "initiatedAt",
      key: "initiatedAt",
      align: "center",

      render: (dateTime) => {
        return <ParseDateTimeTz dateTime={dateTime} />;
      },
    },
    {
      title: "Payout Cutoff",
      dataIndex: "untilTime",
      key: "untilTime",
      align: "center",

      render: (dateTime) => {
        return <ParseDateTimeTz dateTime={dateTime} />;
      },
    },
    {
      title: "Status",
      key: "status",
      dataIndex: "status",
      align: "center",
      render: (status) => {
        return (
          <div
            className={`flex gap-1 items-center justify-center ${
              {
                SUCCESS: "text-[#20BD96]",
                FAILED: "text-[#e0343c]",
                INITIATED: "text-gray-400",
                PROCESSING: "text-blue-400",
              }[status] || ""
            }`}
          >
            {
              {
                SUCCESS: <FaCheck size={16} />,
                FAILED: <RxCross2 size={18} />,
                INITIATED: <FaRegClock size={15} />,
                PROCESSING: <FaRegClock size={15} />,
              }[status]
            }
            {status || "Unknown status"}
          </div>
        );
      },
    },
  ];

  return (
    <AntDesignContext>
      <Table
        onRow={(record) => ({
          onClick: () => handleShowDetails(record.payoutId)(),
        })}
        columns={columns}
        dataSource={allPayouts}
        rowClassName={() => "cursor-pointer"}
        pagination={false}
        loading={isLoading}
      />
      <BalancesTableFooter
        page={page}
        setPage={setPage}
        total={totalCount}
        pageSize={pageSize}
      />
    </AntDesignContext>
  );
};

export default BalancesTable;

import React from "react";
import OnboardingHeader from "./OnboardingHeader/OnboardingHeader.jsx";
import OnboardingBody from "../components/OnboardingBody/OnboardingBody.jsx";
import OnboardingFooter from "./OnboardingFooter/OnboardingFooter.jsx";
import OnboardingSteps from "./OnboardingSteps/OnboardingSteps.jsx";
import Greetings from "./Greetings/Greetings.jsx";
import { useParams } from "react-router-dom";
import { useOnboardingContext } from "../contexts/OnboardingContext";
import Loader from "../../../common/components/UI/Loader.jsx";

const Main = () => {
  const { greeting } = useParams();
  const { loading } = useOnboardingContext();

  return (
    <div>
      {loading && <Loader />}
      <OnboardingHeader />
      {greeting ? (
        <Greetings greeting={greeting} />
      ) : (
        <div className="flex ">
          <OnboardingSteps />
          <div className="flex flex-col w-full md:mr-2">
            <OnboardingBody />
            <OnboardingFooter />
          </div>
        </div>
      )}
    </div>
  );
};

export default Main;

import { useState } from "react";
import { axiosInstance } from "./../../../common/hooks/useAxios";
import captureAxiosError from "./../../../common/utils/captureAxiosError";
import { getStandardApiHeadersWithAuth, getTimezone } from "../../../../utils/stateFunctions";
import useGlobalStore from "./../../../common/store/GlobalStore";

export const useExportCSV = () => {
  const [loading, setLoading] = useState(false);
  const { mode } = useGlobalStore();

  const [exportData, setExportData] = useState(null);

  const handleDownload = (csv) => {
    const blob = new Blob([csv], { type: "text/csv" });
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "xpay_transactions.csv");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const exportCSV = async ({ startTime, endTime, statusFilter }) => {
    setLoading(true);
    try {
      const { data } = await axiosInstance.post(
        `/transaction-page/filtered-transactions-csv`,
        {
          mode: mode,
          txnStatuses: statusFilter,
          startTime: startTime,
          endTime: endTime,
          timeZone: getTimezone(),
        },
        {
          headers: getStandardApiHeadersWithAuth(),
        }
      );
      handleDownload(data);
      setExportData(data);
    } catch (err) {
      captureAxiosError(err);
    } finally {
      setLoading(false);
    }
  };

  return { loading, exportCSV, exportData, setExportData };
};

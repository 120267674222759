import React from "react";
import Header from "./components/Header";
import Filters from "./components/Filters";
import SubscriptionTable from "./components/SubscriptionTable";
import SubscriptionDrawer from "./components/SubscriptionDrawer";
import { SubscriptionProvider } from "./contexts/SubscriptionContext";

const Subscription = () => {
  return (
    <div className="flex flex-col gap-4">
      <SubscriptionProvider>
        <Header />
        <Filters />
        <SubscriptionTable />
        <SubscriptionDrawer />
      </SubscriptionProvider>
    </div>
  );
};

export default Subscription;

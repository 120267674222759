import { axiosInstance } from "../../../common/hooks/useAxios";
import { getUserId } from "../../../../utils/stateFunctions";
import captureAxiosError from "../../../common/utils/captureAxiosError";
import { toast } from "react-toastify";

const useVerifyEmail = () => {
  const VerifyEmail = async () => {
    try {
      const promise = axiosInstance.post(
        "/users/send/verify-email",
        {
          userId: getUserId(),
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      await toast.promise(promise, {
        pending: "Sending verification email...",
        success: "Verification Email has been sent to your email successfully!",
      });
    } catch (e) {
      captureAxiosError(e);
    }
  };
  return VerifyEmail;
};

export default useVerifyEmail;

import {
  getStandardApiHeadersWithAuth,
  getCurrentMerchantId,
} from "../../../../utils/stateFunctions";
import { axiosInstance } from "../../../common/hooks/useAxios";
import captureAxiosError from "../../../common/utils/captureAxiosError";
import { useGlobalContext } from "../../../common/contexts/GlobalContext";

const useTCversionRegister = () => {
  const { setOpen } = useGlobalContext();
  const handleRegister = async (tcversion) => {
    if (!tcversion) {
      return;
    }

    const merchantId = getCurrentMerchantId();
    if (!merchantId) {
      return;
    }

    try {
      await axiosInstance.post(
        `/merchant/${merchantId}/tnc`,
        {
          tncVersion: tcversion,
        },
        {
          headers: getStandardApiHeadersWithAuth(),
        }
      );
      setOpen(false);
    } catch (e) {
      captureAxiosError(e);
    }
  };

  return handleRegister;
};

export default useTCversionRegister;

import React, { useState, useEffect } from "react";
import { Select, Button, Modal, Tooltip } from "antd";
import AntDesignContext from "../../../../common/contexts/AntDesignContext";
import { MdOutlineSimCardDownload } from "react-icons/md";
import { AiOutlineInfoCircle } from "react-icons/ai";
import dayjs from "../../../../../utils/CustomDayjs";
import { getTimezone } from "../../../../../utils/stateFunctions";
import { useExportCSV } from "../../hooks/useExportCSV";
import { useGlobalContext } from "../../../../common/contexts/GlobalContext";
import ExportCSVFilter from "./ExportCSVFilters";

const ExportCSVButton = ({ statuses }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [statusFilter, setStatusFilter] = useState();
  const { loading, exportCSV, exportData, setExportData } = useExportCSV();
  const [startTime, setStartTime] = useState(
    dayjs().tz(getTimezone()).subtract(29, "day").startOf("day").valueOf()
  );
  const [endTime, setEndTime] = useState(
    dayjs().tz(getTimezone()).endOf("day").valueOf()
  );

  const { timezone } = useGlobalContext();

  useEffect(() => {
    setStartTime(
      dayjs().tz(getTimezone()).subtract(29, "day").startOf("day").valueOf()
    );
    setEndTime(dayjs().tz(getTimezone()).endOf("day").valueOf());
  }, [timezone]);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleDownload = (csv) => {
    const blob = new Blob([csv], { type: "text/csv" });
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "xpay_transactions.csv");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleOk = async () => {
    await exportCSV({ startTime, endTime, statusFilter });
  };

  const handleCancel = () => {
    setExportData(null);
    setStatusFilter(null);
    setIsModalOpen(false);
  };

  const onChange = (e) => {
    setStatusFilter(e);
  };

  return (
    <div className="">
      <Button
        type="primary"
        icon={<MdOutlineSimCardDownload />}
        onClick={showModal}
        className="bg-[#121212] border border-[#ffffff80] rounded-lg"
        size="large"
      >
        Export
      </Button>
      <AntDesignContext>
        <Modal
          title="Export Ledger"
          open={isModalOpen}
          onOk={() => {
            exportData ? handleDownload(exportData) : handleOk();
          }}
          onCancel={handleCancel}
          confirmLoading={loading}
          loading={loading}
          okText={exportData ? "Download" : "Export"}
          wrapClassName={"backdrop-blur-sm"}
          className="pb-0 border border-gray-600 rounded-lg"
          footer={(_, { OkBtn, CancelBtn }) =>
            !exportData ? (
              <div className="flex items-center justify-between gap-4">
                <div className="flex items-center gap-2">
                  Timezone: {getTimezone()}
                  <Tooltip title="Make sure you have selected the correct timezone for exporting the transaction. You can update timezone setting from the navbar above.">
                    <AiOutlineInfoCircle className="cursor-pointer" />
                  </Tooltip>{" "}
                </div>
                <div className="flex gap-2">
                  <CancelBtn />
                  <OkBtn />
                </div>
              </div>
            ) : (
              <>
                <CancelBtn />
                <OkBtn />
              </>
            )
          }
        >
          {!exportData ? (
            <>
              <div className="py-4">Select your Date Range:</div>
              <div className="pb-4">
                <ExportCSVFilter
                  setStartTime={setStartTime}
                  setEndTime={setEndTime}
                />
              </div>
              <div className="py-4">Select transactions status:</div>
              <div className="pb-4">
                <Select
                  showSearch
                  onChange={onChange}
                  options={statuses}
                  mode="multiple"
                  placeholder="Status: All"
                  allowClear
                  value={statusFilter?.length ? statusFilter : undefined}
                  size="middle"
                  popupClassName="z-[100000000] bg-[#1b1a1a]"
                  className="min-w-[125px] border-none"
                  prefix={<span className="text-white">Status</span>}
                />
              </div>
            </>
          ) : (
            <div className="py-4">
              Your export is ready. If you don't see your file, you can try
              downloading it again.
            </div>
          )}
        </Modal>
      </AntDesignContext>
    </div>
  );
};

export default ExportCSVButton;

import React from "react";
import { Button } from "antd";
import { useNavigate } from "react-router-dom";
import toast  from "react-hot-toast";
import { axiosInstance } from "./../../common/hooks/useAxios";
import { getAdminAuthToken } from "../../../utils/stateFunctions";
import captureAxiosError from "./../../common/utils/captureAxiosError";

function App() {
  const navigateTo = useNavigate();

  const handleAdminLogout = async () => {
    toast.loading("loging out admin...", { id: "logout" });
    try {
      await axiosInstance.post(`/internal-auth/logout`, null, {
        headers: {
          Authorization: `Internal ${getAdminAuthToken()}`,
        },
      });

      localStorage.removeItem("adminAuthToken");
      await new Promise((resolve) => setTimeout(resolve, 2000));
      toast.dismiss("logout");
      navigateTo("/admin/login");
    } catch (error) {
      toast.dismiss("logout");
      captureAxiosError(error);
    }
  };

  return (
    <div className="App">
      <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100">
        <div className="w-full max-w-md p-10 text-center bg-white rounded-lg shadow-lg">
          <h1 className="mb-6 text-3xl font-bold text-gray-800">
            Welcome to Admin Dashboard
          </h1>

          <p className="mb-8 text-gray-600">Select an option to continue:</p>

          <div className="space-y-4">
            <Button
              type="primary"
              className="w-full px-4 py-2 text-white bg-blue-500 rounded-md hover:bg-blue-600"
              onClick={() => navigateTo("/admin/onboarding-details")}
            >
              Onboarding Details
            </Button>

            <Button
              type="default"
              className="w-full px-4 py-2 bg-gray-200 rounded-md hover:bg-gray-300"
              onClick={() => navigateTo("/admin/merchant-details")}
            >
              Merchant Details
            </Button>
          </div>
        </div>

        {/* Logout button at the bottom */}
        <div className="mt-6">
          <Button
            type="danger"
            className="w-full px-4 py-2 text-white bg-red-500 rounded-md hover:bg-red-600"
            onClick={handleAdminLogout}
          >
            Logout
          </Button>
        </div>
      </div>
    </div>
  );
}

export default App;

import { create } from "zustand";
import { PGMode } from "../constants/entities";
import { persist } from "zustand/middleware";

const initialState = {
  mode: PGMode.TEST,
  merchantActivated: false,
  isWelcomed: false,
};

const useGlobalStore = create(
  persist(
    (set, get) => ({
      ...initialState,

      setMode: (mode) => set({ mode }),

      setMerchantActivated: (merchantActivated) => set({ merchantActivated }),

      setIsWelcomed: (isWelcomed) => set({ isWelcomed }),
    }),
    {
      name: "global-store",
    }
  )
);

export default useGlobalStore;

/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";
import FilterBox from "./FilterBox";
import StatusFilters from "./StatusFilters";
import { useGetTransactionFilters } from "../../hooks/useGetTransactionsFilters";
import { useTransactionContext } from "../../context/TransactionContext";

const Filters = () => {
  const { setSearchByIdText } = useTransactionContext();
  const { statusFilters } = useGetTransactionFilters();

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const searchByIds = searchParams.get("search-by-ids");
    setSearchByIdText(JSON.parse(decodeURIComponent(searchByIds)));
  }, []);

  return (
    <div>
      <FilterBox />
      <StatusFilters statuses={statusFilters} />
    </div>
  );
};

export default Filters;

import React from "react";
import { Table } from "antd";
import AntDesignContext from "../../../common/contexts/AntDesignContext";
import ParseAmount from "../../../common/components/UI/ParseAmount";
import ParseDateTimeTz from "./../../../common/components/UI/ParseDateTimeTz";

import { FaCheck } from "react-icons/fa6";
import { RxCross2 } from "react-icons/rx";
import { FaRegClock } from "react-icons/fa";
import { HiOutlineExternalLink } from "react-icons/hi";
import { useSubscriptionContext } from "../contexts/SubscriptionContext";
import { useGetAllSubscription } from "../hooks/useGetAllSubscription";
import SubscriptionTableFooter from "./SubscriptionTableFooter";

const SubscriptionTable = () => {
  const {
    setShowDrawer,
    setSubscriptionId,
    pageSize,
    page,
    setPage,
    totalCount,
  } = useSubscriptionContext();
  const { allSubscriptions, isLoading } = useGetAllSubscription();

  const columns = [
    {
      title: "SubscriptionId",
      dataIndex: "subscriptionId",
      key: "subscriptionId",
      align: "center",
      render: (subscriptionId) => {
        const first4 = subscriptionId.slice(0, 4);
        const last4 = subscriptionId.slice(-4);

        const displayId = `${first4}....${last4}`;

        return (
          <div className="flex items-center justify-center gap-2">
            {displayId} <HiOutlineExternalLink size={16} />
          </div>
        );
      },
    },
    {
      title: "Customer",
      dataIndex: "customerDetails",
      key: "customerDetails",
      align: "center",
      render: (customerDetails) => {
        return (
          <div className="flex flex-col items-center justify-center">
            <div>{customerDetails.name}</div>
            <div className="opacity-50">{customerDetails.email}</div>
          </div>
        );
      },
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
      align: "center",
      render: (amount) => {
        return <ParseAmount amount={amount} divideBy100={true} />;
      },
    },
    {
      title: "Created At",
      dataIndex: "createdAt",
      key: "createdAt",
      align: "center",

      render: (createdAt) => {
        return (
          <ParseDateTimeTz
            dateTime={createdAt}
            onlyDate={false}
            singleLine={false}
          />
        );
      },
    },
    {
      title: "Frequency",
      dataIndex: "interval",
      key: "interval",
      align: "center",

      render: (interval, record) => {
        return (
          <div className="flex flex-col items-center justify-center">
            Every {record?.intervalCount} {interval.toLowerCase()}
            {record?.intervalCount > 1 ? "s" : ""}
            <span className="opacity-50">for {record?.cycleCount} cycles</span>
          </div>
        );
      },
    },
    {
      title: "Status",
      key: "status",
      dataIndex: "status",
      align: "center",
      render: (status) => {
        return (
          <div
            className={`flex gap-1 items-center justify-center ${
              {
                ACTIVE: "text-[#20BD96]",
                INACTIVE: "text-[#e0343c]",
                INCOMPLETED: "text-gray-400",
                CANCELLED: "text-gray-400",
                CREATED: "text-blue-400",
              }[status] || ""
            }`}
          >
            {
              {
                ACTIVE: <FaCheck size={16} />,
                INACTIVE: <RxCross2 size={18} />,
                INCOMPLETED: <FaRegClock size={15} />,
                CANCELLED: <RxCross2 size={18} />,
                CREATED: <FaRegClock size={15} />,
              }[status]
            }
            {status || "Unknown status"}
          </div>
        );
      },
    },
    {
      title: "Next Payment",
      dataIndex: "nextPaymentDate",
      key: "nextPaymentDate",
      align: "center",

      render: (nextPaymentDate) => {
        return nextPaymentDate ? (
          <ParseDateTimeTz dateTime={nextPaymentDate} onlyDate />
        ) : (
          "-"
        );
      },
    },
    {
      title: "Remaining Cycles",
      dataIndex: "remainingCycleCount",
      key: "remainingCycleCount",
      align: "center",

      render: (remainingCycleCount) => {
        return remainingCycleCount ? <div>{remainingCycleCount}</div> : "-";
      },
    },
  ];

  const handleShowDetails = (subscriptionId) => () => {
    setSubscriptionId(subscriptionId);
    setShowDrawer(true);
  };

  return (
    <AntDesignContext>
      <Table
        onRow={(record) => {
          return {
            onClick: (event) => {
              handleShowDetails(record.subscriptionId)();
            },
          };
        }}
        columns={columns}
        dataSource={allSubscriptions}
        rowClassName={() => "cursor-pointer"}
        pagination={false}
        loading={isLoading}
      />
      <SubscriptionTableFooter
        page={page}
        setPage={setPage}
        total={totalCount}
        pageSize={pageSize}
      />
    </AntDesignContext>
  );
};

export default SubscriptionTable;

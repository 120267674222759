import dayjs from "../../../../utils/CustomDayjs";
import { isEmpty } from "lodash";

const NULLABLE_FIELDS = new Set(["phone", "isSubscription", "addressLine2", "description", "receiptId"]);

const validateForm = (metadata, setError) => {
  let isValid = true;
  Object.keys(metadata).forEach((key) => {
    if (isEmpty(metadata[key]) && !NULLABLE_FIELDS.has(key)) {
      isValid = false;
      setError(key, "This field is required");
    }
  });
  return isValid;
};

const convertDayJsToEpoch = (date) => {
  if (!dayjs.isDayjs(date)) return null;
  return date.valueOf();
};

export { validateForm, convertDayJsToEpoch };

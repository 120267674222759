import React, { useState, useRef, useEffect } from "react";
import AntDesignContext from "../../../../common/contexts/AntDesignContext";
import { Button, Divider, Input, Select, Space, Form } from "antd";
import { useOnboardingContext } from "../../contexts/OnboardingContext";
import { getValidationRules } from "../../services/validation";

let index = 0;

const MultipleSelectField = ({
  label,
  placeholder,
  options,
  required,
  name,
  helper,
  label2,
  helper2,
}) => {
  const [items, setItems] = useState(options);
  const [name2, setName2] = useState("");
  const inputRef = useRef(null);
  const { onboardingFields, setOnboardingFields } = useOnboardingContext();

  useEffect(() => {
    if(!onboardingFields[name] || onboardingFields[name].length === 0) {
      setOnboardingFields({
        ...onboardingFields,
        [name]: "United States (USD)",
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onChange = (value) => {
    setOnboardingFields({
      ...onboardingFields,
      [name]: value,
    });
  };

  const onNameChange = (event) => {
    setName2(event.target.value);
  };

  const addItem = (e) => {
    e.preventDefault();
    const newItems = [...items];
    let groupIndex = newItems.findIndex((group) => group.label === "Others");
    if (groupIndex === -1) {
      newItems.push({
        label: "Others",
        options: [],
      });
      groupIndex = newItems.length - 1;
    }
    const newOption = {
      label: name2 || `New Country ${index++}`,
      value: name2 || `New Country ${index++}`,
    };
    newItems[groupIndex].options.push(newOption);
    setItems(newItems);
    const newValues = [...onboardingFields[name], newOption.value];
    setOnboardingFields({
      ...onboardingFields,
      [name]: newValues,
    });
    setName2("");
    setTimeout(() => {
      inputRef.current?.focus();
    }, 0);
  };

  return (
    <AntDesignContext>
      <Form.Item
        hasFeedback
        label={
          <div className="pb-2 text-lg text-white">
            {label}{" "}
            <span className="text-sm font-normal opacity-50">{helper} </span>{" "}
            {label2}
            <span className="text-sm font-normal opacity-50">{helper2} </span>
          </div>
        }
        name={name}
        validateFirst
        rules={getValidationRules(name, required, label)}
      >
        <Select
          mode="tags"
          size="large"
          placeholder={placeholder}
          style={{ width: "100%" }}
          popupClassName="bg-[#121212]"
          required={required}
          options={items}
          onChange={onChange}
          value={onboardingFields[name]}
          dropdownRender={(menu) => (
            <>
              {menu}
              <Divider
                style={{
                  margin: "8px 0",
                }}
              />
              <Space
                style={{
                  padding: "0 8px 4px",
                }}
              >
                <Input
                  placeholder="Any other country?"
                  ref={inputRef}
                  value={name2}
                  onChange={onNameChange}
                  onKeyDown={(e) => e.stopPropagation()}
                  className="w-fit"
                  required={true}
                />
                <Button onClick={addItem} className="text-white bg-transparent" disabled={!name2}>
                  Add Country
                </Button>
              </Space>
            </>
          )}
        />
      </Form.Item>
    </AntDesignContext>
  );
};

export default MultipleSelectField;

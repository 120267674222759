import { Button } from "antd";
import React from "react";
import { useNavigate } from "react-router-dom";
import { content } from "../../constants/greeting";

const GreetingContent = ({ greeting }) => {
  const contentRender =
    greeting === "thankyou" ? content.thankyou : content.welcome;

  const navigate = useNavigate();

  const next = () => {
    navigate(contentRender.link);
  };

  return (
    <div className="flex flex-col items-center justify-center">
      <div>
        <img src={contentRender.img} alt="thankyou" className="w-60"></img>
      </div>
      <div className="m-2 text-center md:w-1/2 py-14">
        <div className="text-xl">{contentRender.heading}</div>
        <div className="mt-4">{contentRender.subheading}</div>
      </div>
      <Button
        className="bg-white"
        onClick={next}
      >
        {contentRender.button}
      </Button>
    </div>
  );
};

export default GreetingContent;

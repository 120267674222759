import React from "react";
import dayjs from "../../../../utils/CustomDayjs";
import moment from "moment-timezone";
import { getTimezone } from "../../../../utils/stateFunctions";

const ParseDateTimeTz = ({ dateTime, onlyDate = false, singleLine = true }) => {
  return (
    <>
      <span>{dayjs(dateTime).tz(getTimezone()).format("DD MMM, YYYY")}</span>
      {!singleLine && <br />}
      {!onlyDate && (
        <span className={`${singleLine ? "ml-1" : ""} opacity-50`}>
          {moment(dateTime).tz(getTimezone()).format("HH:mm:ss (z)")}
        </span>
      )}
    </>
  );
};

export default ParseDateTimeTz;

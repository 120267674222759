/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { axiosInstance } from "./../../../common/hooks/useAxios";
import { getStandardApiHeadersWithAuth } from "./../../../../utils/stateFunctions";
import captureAxiosError from "./../../../common/utils/captureAxiosError";
import useGlobalStore from "./../../../common/store/GlobalStore";
import { getCurrentMerchantId } from "./../../../../utils/stateFunctions";

export const useGetSubscription = ({ subscriptionId }) => {
  const [subscriptionDetails, setSubscriptionDetails] = useState();
  const [subsTrans, setSubsTrans] = useState([]);
  const [isLoadingTxn, setIsLoadingTxn] = useState();
  const { mode } = useGlobalStore();

  useEffect(() => {
    const getSubscription = async () => {
      setIsLoadingTxn(true);
      try {
        const { data } = await axiosInstance.get(
          `/subscription/dashboard/get-subscription/${subscriptionId}`,
          {
            headers: getStandardApiHeadersWithAuth(),
          }
        );
        setSubscriptionDetails(data);

        if (data?.intentIds?.length > 0) {
          const { data: transData } = await axiosInstance.post(
            `/transaction-page/merchant/${getCurrentMerchantId()}/search-by-ids`,
            {
              searchText: data?.intentIds,
              mode: mode,
              startIndex: 0,
              pageSize: 50,
            },
            {
              headers: getStandardApiHeadersWithAuth(),
            }
          );
          setSubsTrans(transData?.transactions);
        } else {
          setSubsTrans([]);
        }
      } catch (error) {
        captureAxiosError(error);
      } finally {
        setIsLoadingTxn(false);
      }
    };

    if (subscriptionId) {
      getSubscription();
    }
  }, [subscriptionId]);

  return {
    subscriptionDetails,
    setSubscriptionDetails,
    isLoadingTxn,
    subsTrans,
  };
};

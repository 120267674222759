/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { axiosInstance } from "./../../../common/hooks/useAxios";
import { getStandardApiHeadersWithAuth } from "./../../../../utils/stateFunctions";
import captureAxiosError from "./../../../common/utils/captureAxiosError";
import useGlobalStore from "../../../common/store/GlobalStore";
import { useBalanceContext } from "../context/BalanceContext";

export const useGetAllPayouts = () => {
  const [allPayouts, setAllPayouts] = useState();
  const [isLoading, setIsLoading] = useState();
  const { mode } = useGlobalStore();
  const { setTotalCount, pageSize, page } = useBalanceContext();

  useEffect(() => {
    const getAllPayouts = async () => {
      setIsLoading(true);

      try {
        const { data } = await axiosInstance.post(
          `/balance/get-filtered-payouts`,
          {
            mode: mode,
            pageSize: pageSize,
            startIndex: (page - 1) * pageSize,
          },
          {
            headers: getStandardApiHeadersWithAuth(),
          }
        );

        setAllPayouts(data?.entries);
        setTotalCount(data?.totalCount);
      } catch (error) {
        captureAxiosError(error);
      } finally {
        setIsLoading(false);
      }
    };

    getAllPayouts();
  }, [mode, page]);

  return { allPayouts, isLoading };
};

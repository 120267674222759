/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import {
  Input,
  Button,
  Form,
  Space,
  Switch,
  Typography,
  Select,
  Row,
  Col,
  Tag,
  InputNumber,
  Upload,
  Popconfirm,
} from "antd";
import { FaEdit, FaSave, FaTimes, FaUpload } from "react-icons/fa";
import captureAxiosError from "../../common/utils/captureAxiosError";
import { axiosInstance } from "../../common/hooks/useAxios";
import { getAdminAuthToken } from "../../../utils/stateFunctions";
import toast from "react-hot-toast";

const { Title, Text } = Typography;

const MerchantDetails = ({ merchantData, setMerchantData }) => {
  const [editing, setEditing] = useState(false);
  const [form] = Form.useForm();
  const [save, setSave] = useState(false);

  useEffect(() => {
    form.setFieldsValue(merchantData);
  }, [merchantData]);

  const handleEdit = () => setEditing(true);

  const handleSave = async () => {
    form
      .validateFields()
      .then((values) => {
        setMerchantData({ ...merchantData, ...values });
        setSave(true);
        setEditing(false);
      })
      .catch((info) => {
        console.log("Validate Failed:", info);
      });
  };

  useEffect(() => {
    if (save) {
      updateMerchantDetail();
    }
  }, [merchantData]);

  const updateMerchantDetail = async () => {
    toast.loading("Updating merchant details...", {
      id: "updateMerchantDetails",
    });
    try {
      const { data } = await axiosInstance.post(
        `/admin/updateMerchantDetails`,
        merchantData,
        {
          headers: {
            Authorization: `Internal ${getAdminAuthToken()}`,
          },
        }
      );

      setMerchantData(data);
      toast.success("Merchant details updated", {
        id: "updateMerchantDetails",
      });
    } catch (error) {
      toast.dismiss("updateMerchantDetails");
      captureAxiosError(error);
    } finally {
      setSave(false);
    }
  };

  const handleCancel = () => {
    form.resetFields();
    setEditing(false);
  };

  const handleLogoUpload = (info) => {
    if (info.file.status === "done") {
      toast.success(`${info.file.name} file uploaded successfully`);
      // Update merchantData with the new logo URL
      setMerchantData({
        ...merchantData,
        merchantLogo: info.file.response.url,
      });
    } else if (info.file.status === "error") {
      toast.error(`${info.file.name} file upload failed.`);
    }
  };

  const paymentMethodOptions = [
    { value: "CARD", label: "CARD" },
    { value: "AMAZON_PAY", label: "AMAZON_PAY" },
    { value: "CASH_APP", label: "CASH_APP" },
  ];

  const allowedPGOptions = [
    { value: "STRIPE", label: "STRIPE" },
    { value: "AUTHNET", label: "AUTHNET" },
  ];

  return (
    <div
      className="flex items-center justify-center min-h-screen p-6 bg-gray-100"
      id="admin-paymentMethods"
    >
      <div className="w-full max-w-4xl p-10 bg-white rounded-lg shadow-lg">
        <div className="flex items-center justify-between mb-6">
          <Title level={3} className="mb-0">
            Merchant Details
          </Title>
          {!editing ? (
            <Button
              type="primary"
              onClick={handleEdit}
              className="flex items-center text-white bg-blue-500 hover:bg-blue-600"
            >
              <FaEdit className="mr-2" /> Edit
            </Button>
          ) : (
            <Space>
              <Popconfirm
                title="Save Changes?"
                description="Are you sure to save this changes?"
                onConfirm={handleSave}
                okText="Yes"
                cancelText="No"
              >
                <Button
                  type="primary"
                  className="flex items-center text-white bg-green-500 hover:bg-green-600"
                >
                  <FaSave className="mr-2" /> Save
                </Button>
              </Popconfirm>
              <Button
                danger
                onClick={handleCancel}
                className="flex items-center"
              >
                <FaTimes className="mr-2" /> Cancel
              </Button>
            </Space>
          )}
        </div>

        <Form
          layout="vertical"
          form={form}
          initialValues={merchantData}
          onFinish={handleSave}
        >
          <Row gutter={24}>
            <Col span={12}>
              <Form.Item label={<Text strong>ID</Text>} name="id">
                <div className="text-sm">{merchantData?.merchantId}</div>
              </Form.Item>

              <Form.Item label={<Text strong>Login Emails:</Text>} name="id">
                {merchantData?.loginEmails.map((email, index) => (
                  <div className="text-sm" key={index}>
                    {index + 1}) {email}
                  </div>
                ))}
              </Form.Item>

              <Form.Item
                label={<Text strong>Created At</Text>}
                name="createdAt"
              >
                <div className="text-sm">
                  {new Date(merchantData?.createdAt).toLocaleString()}
                </div>
              </Form.Item>

              <Form.Item label={<Text strong>Merchant Name</Text>} name="name">
                {editing ? <Input /> : <Text>{merchantData?.name}</Text>}
              </Form.Item>

              <Form.Item
                label={
                  <>
                    <Text strong>Merchant Logo</Text>{" "}
                    <div> (Selecting logo will directly get uploaded)</div>
                  </>
                }
                name="merchantLogo"
              >
                <Space
                  direction="vertical"
                  align="center"
                  style={{ width: "100%" }}
                >
                  {merchantData?.merchantLogo && (
                    <img
                      src={merchantData.merchantLogo}
                      alt="Merchant Logo"
                      className="object-cover mb-4"
                    />
                  )}
                  <Upload
                    listType="picture-card"
                    className="avatar-uploader"
                    customRequest={({
                      onSuccess,
                      onError,
                      file,
                      onProgress,
                    }) => {
                      const formData = new FormData();
                      formData.append("file", file);
                      formData.append("merchantId", merchantData?.merchantId);
                      axiosInstance
                        .post(`/admin/upload-merchant-logo`, formData, {
                          headers: {
                            Authorization: `Internal ${getAdminAuthToken()}`,
                          },
                          onUploadProgress: ({ total, loaded }) =>
                            onProgress({
                              percent: Math.round((loaded / total) * 100),
                            }),
                        })
                        .then((res) => {
                          onSuccess(res.data);
                        })
                        .catch((err) => {
                          onError({ err });
                        });
                    }}
                    onChange={handleLogoUpload}
                    disabled={!editing}
                  >
                    {editing ? (
                      <div>
                        <FaUpload />
                        <div style={{ marginTop: 8 }}>
                          {merchantData?.merchantLogo
                            ? "Change Logo"
                            : "Upload Logo"}
                        </div>
                      </div>
                    ) : merchantData?.merchantLogo ? null : (
                      <Text className="text-lg">No logo uploaded</Text>
                    )}
                  </Upload>
                </Space>
              </Form.Item>
              <div className="grid grid-cols-2 grid-rows-3 gap-4">
                <Form.Item
                  label={<Text strong>Activated</Text>}
                  name="activated"
                  valuePropName="checked"
                >
                  {editing ? (
                    <Switch />
                  ) : (
                    <Text className="">
                      {merchantData?.activated ? "Yes" : "No"}
                    </Text>
                  )}
                </Form.Item>

                <Form.Item
                  label={<Text strong>Welcomed</Text>}
                  name="welcomed"
                  valuePropName="checked"
                >
                  {editing ? (
                    <Switch />
                  ) : (
                    <Text className="">
                      {merchantData?.welcomed ? "Yes" : "No"}
                    </Text>
                  )}
                </Form.Item>

                <Form.Item
                  label={<Text strong>Send Email for Links</Text>}
                  name="sendEmailForLinks"
                  valuePropName="checked"
                >
                  {editing ? (
                    <Switch />
                  ) : (
                    <Text className="">
                      {merchantData?.sendEmailForLinks ? "Yes" : "No"}
                    </Text>
                  )}
                </Form.Item>
                <Form.Item
                  label={
                    <Text strong>Send Email for Payout Initialization</Text>
                  }
                  name="emailPayoutInit"
                  valuePropName="checked"
                >
                  {editing ? (
                    <Switch />
                  ) : (
                    <Text className="">
                      {merchantData?.emailPayoutInit ? "Yes" : "No"}
                    </Text>
                  )}
                </Form.Item>
                <Form.Item
                  label={<Text strong>Send Email for Customer Refund</Text>}
                  name="emailCustomerRefund"
                  valuePropName="checked"
                >
                  {editing ? (
                    <Switch />
                  ) : (
                    <Text className="">
                      {merchantData?.emailCustomerRefund ? "Yes" : "No"}
                    </Text>
                  )}
                </Form.Item>

                <Form.Item
                  label={<Text strong>Send Email for Customer Success</Text>}
                  name="emailCustomerSuccess"
                  valuePropName="checked"
                >
                  {editing ? (
                    <Switch />
                  ) : (
                    <Text className="">
                      {merchantData?.emailCustomerSuccess ? "Yes" : "No"}
                    </Text>
                  )}
                </Form.Item>
                <Form.Item
                  label={<Text strong>Send Email for Merchant Refund</Text>}
                  name="emailMerchantRefund"
                  valuePropName="checked"
                >
                  {editing ? (
                    <Switch />
                  ) : (
                    <Text className="">
                      {merchantData?.emailMerchantRefund ? "Yes" : "No"}
                    </Text>
                  )}
                </Form.Item>
                <Form.Item
                  label={<Text strong>Send Email for Merchant Success</Text>}
                  name="emailMerchantSuccess"
                  valuePropName="checked"
                >
                  {editing ? (
                    <Switch />
                  ) : (
                    <Text className="">
                      {merchantData?.emailMerchantSuccess ? "Yes" : "No"}
                    </Text>
                  )}
                </Form.Item>

                <Form.Item
                  label={<Text strong>ThreeDS</Text>}
                  name="threeDS"
                  valuePropName="checked"
                >
                  {editing ? (
                    <Switch />
                  ) : (
                    <Text className="">
                      {merchantData?.threeDS ? "Yes" : "No"}
                    </Text>
                  )}
                </Form.Item>
              </div>
            </Col>

            <Col span={12}>
              <Form.Item
                label={<Text strong>xFlowPay Account Id</Text>}
                name="xfpAccountId"
              >
                {editing ? (
                  <Input />
                ) : (
                  <Text>{merchantData?.xfpAccountId}</Text>
                )}
              </Form.Item>
              <Form.Item
                label={<Text strong>Payment Methods</Text>}
                name="paymentMethods"
              >
                {editing ? (
                  <Select
                    mode="tags"
                    style={{ width: "100%" }}
                    placeholder="Select payment methods"
                    tokenSeparators={[","]}
                    options={paymentMethodOptions}
                  />
                ) : (
                  <Space wrap>
                    {merchantData?.paymentMethods?.map((method) => (
                      <Tag
                        key={method}
                        color="blue"
                        className="px-2 py-1 text-base"
                      >
                        {method}
                      </Tag>
                    ))}
                  </Space>
                )}
              </Form.Item>

              <Form.Item
                label={<Text strong>Allowed PGs</Text>}
                name="allowedPGs"
              >
                {editing ? (
                  <Select
                    mode="tags"
                    style={{ width: "100%" }}
                    placeholder="Enter allowed PGs"
                    tokenSeparators={[","]}
                    options={allowedPGOptions}
                  />
                ) : (
                  <Space wrap>
                    {merchantData?.allowedPGs?.map((pg) => (
                      <Tag
                        key={pg}
                        color="green"
                        className="px-2 py-1 text-base"
                      >
                        {pg}
                      </Tag>
                    ))}
                  </Space>
                )}
              </Form.Item>
              <Form.Item
                label={<Text strong>TNC Version</Text>}
                name="tncVersion"
              >
                {editing ? (
                  <Input />
                ) : (
                  <Text className="">{merchantData?.tncVersion}</Text>
                )}
              </Form.Item>

              <Form.Item
                label={<Text strong>Percentage Fee in BPS</Text>}
                name="percentageFeeInBPS"
              >
                {editing ? (
                  <InputNumber min={0} />
                ) : (
                  <Text className="">
                    {merchantData?.percentageFeeInBPS} BPS
                  </Text>
                )}
              </Form.Item>

              <Form.Item
                label={<Text strong>Fixed Fee in Cents</Text>}
                name="fixedFeeInCents"
              >
                {editing ? (
                  <InputNumber min={0} />
                ) : (
                  <Text className="">
                    {merchantData?.fixedFeeInCents} Cents
                  </Text>
                )}
              </Form.Item>

              <Form.Item
                label={<Text strong>Dispute Fee in Cents</Text>}
                name="disputeFeeInCents"
              >
                {editing ? (
                  <InputNumber min={0} />
                ) : (
                  <Text className="">
                    {merchantData?.disputeFeeInCents} Cents
                  </Text>
                )}
              </Form.Item>

              <Form.Item
                label={<Text strong>DCC Markup in BPS</Text>}
                name="dccMarkupInBps"
              >
                {editing ? (
                  <InputNumber min={0} />
                ) : (
                  <Text className="">{merchantData?.dccMarkupInBps} BPS</Text>
                )}
              </Form.Item>

              <Form.Item
                label={<Text strong>Daily Transaction Refresh Amount</Text>}
                name="dailyTxnRefreshAmount"
              >
                {editing ? (
                  <InputNumber />
                ) : (
                  <Text className="">
                    {merchantData?.dailyTxnRefreshAmount}
                  </Text>
                )}
              </Form.Item>

              <Form.Item
                label={<Text strong>Daily Transaction Remaining Budget</Text>}
                name="dailyTxnRemainingBudget"
              >
                {editing ? (
                  <InputNumber />
                ) : (
                  <Text className="">
                    {merchantData?.dailyTxnRemainingBudget}
                  </Text>
                )}
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </div>
    </div>
  );
};

export default MerchantDetails;

import React from "react";
import { MdOutlineAccountBalanceWallet } from "react-icons/md";
import RightSideMetricsHeader from "./RightSideMetricsHeader";
import { useLandingPageContext } from "../../contexts/LandingPageContext";
import ParseAmount from "./../../../../common/components/UI/ParseAmount";

const BalancesMetric = () => {
  const { balanceAvailable, balanceAvailableSoon } = useLandingPageContext();

  return (
    <div className="bg-[#121212] rounded-lg p-4">
      <RightSideMetricsHeader
        title="Balances"
        link="View"
        route="/balances"
        icon={<MdOutlineAccountBalanceWallet />}
      />
      <div className="flex flex-col items-start justify-start gap-2 pl-2 m-2">
        <div className="pb-2 text-xl font-medium">
          <ParseAmount
            amount={Number(balanceAvailable) + Number(balanceAvailableSoon)}
            currencySize="sm"
          />
        </div>
        <div className="flex gap-2 text-sm">
          <span className="opacity-50">Balance available:</span>{" "}
          <div>
            <ParseAmount amount={balanceAvailable} />
          </div>
        </div>
        <div className="flex gap-2 text-sm">
          <span className="opacity-50"> Balance available soon:</span>{" "}
          <div>
            <ParseAmount amount={balanceAvailableSoon} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default BalancesMetric;

import React from "react";
import { InputNumber, Form } from "antd";
import AntDesignContext from "../../../../common/contexts/AntDesignContext";
import { getValidationRules } from "../../utils/validation";

const InputNumberField = ({
  label,
  placeholder,
  prefix,
  suffix,
  addonAfter,
  addonBefore,
  name,
  required,
  controls = true,
  changeOnWheel = false,
}) => {
  return (
    <AntDesignContext>
      <Form.Item
        hasFeedback
        label={label}
        name={name}
        validateFirst
        rules={getValidationRules(name, required, label)}
        className="w-full"
      >
        <InputNumber
          size="middle"
          placeholder={placeholder}
          prefix={prefix}
          suffix={suffix}
          addonAfter={addonAfter}
          addonBefore={addonBefore}
          controls={controls}
          formatter={(value) =>
            `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
          }
          className="w-full rounded-lg bg-[#121212]"
          required={required}
          changeOnWheel={changeOnWheel}
          min={0}
        />
      </Form.Item>
    </AntDesignContext>
  );
};

export default InputNumberField;

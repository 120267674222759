import React, { useState } from "react";
import { Button, Modal } from "antd";
import { useOnboardingContext } from "../../contexts/OnboardingContext";
import useSaveOnboardingDetails from "../../hooks/useSaveOnboardingDetails";
import useSubmitOnboardingDetails from "../../hooks/useSubmitOnboardingDetails.js";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import { FaChevronRight } from "react-icons/fa";
import { FaAngleLeft } from "react-icons/fa6";
import { useGlobalContext } from "../../../../common/contexts/GlobalContext.jsx";
import AntDesignContext from "../../../../common/contexts/AntDesignContext.jsx";
import useLogout from "../../../authentication/hooks/useLogout.js";

const FooterButtons = () => {
  const {
    currentPage,
    setCurrentPage,
    currentBDPage,
    setCurrentBDPage,
    onboardingSteps,
    onboardingBDSteps,
    form,
  } = useOnboardingContext();

  const [showModal, setShowModal] = useState(false);
  const [modalLoading, setModalLoading] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);

  const saveOnboardingDetails = useSaveOnboardingDetails();
  const submitOnboardingDetails = useSubmitOnboardingDetails();
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);
  const { isMobile } = useGlobalContext();
  const handleLogout = useLogout();

  const handleContinue = async () => {
    await form
      .validateFields()
      .then((values) => {
        if (currentPage < onboardingSteps.length - 1) {
          nextPage();
        } else {
          savingDetails(values);
        }
      })
      .catch((errorInfo) => {
        const fieldElement = document.querySelector(
          `[name="${errorInfo.errorFields[0].name}"]`
        );
        if (fieldElement) {
          fieldElement.scrollIntoView({
            behavior: "smooth",
            block: "center",
            inline: "nearest",
            marginTop: "-100px",
          });
        }
      });
  };

  const nextPage = async () => {
    setIsLoading(true);
    toast.loading("Saving...", {
      id: "onboarding",
      position: isMobile ? "top-center" : "bottom-center",
      duration: Infinity,
    });
    try {
      await saveOnboardingDetails();
      if (currentPage === 0) {
        setCurrentBDPage(0);
      }

      if (currentPage !== 1) {
        setCurrentPage(currentPage + 1);
      } else if (
        currentPage === 1 &&
        currentBDPage === onboardingBDSteps.length - 1
      ) {
        setCurrentPage(currentPage + 1);
      } else {
        setCurrentBDPage(currentBDPage + 1);
      }
      setIsLoading(false);
      toast.success("Data saved successfully", {
        id: "onboarding",
        position: isMobile ? "top-center" : "bottom-center",
        duration: 3000,
      });
    } catch (error) {
      toast.error(
        error?.response?.data?.errorDescription || "Something went wrong",
        {
          id: "onboarding",
          position: isMobile ? "top-center" : "bottom-center",
          duration: 3000,
        }
      );
      if (error?.response?.status === 401) {
        handleLogout();
      }
      setIsLoading(false);
    }
  };

  const prevPage = () => {
    setIsLoading(true);
    if (currentPage !== 1) {
      setCurrentPage(currentPage - 1);
    } else if (currentPage === 1 && currentBDPage === 0) {
      setCurrentPage(currentPage - 1);
    } else {
      setCurrentBDPage(currentBDPage - 1);
    }

    setIsLoading(false);
  };

  const savingDetails = async () => {
    setIsLoading(true);
    setModalLoading(true);
    setShowModal(true);
    toast.loading("Saving...", {
      id: "onboarding",
      position: isMobile ? "top-center" : "bottom-center",
      duration: Infinity,
    });
    try {
      setIsLoading(false);
      await saveOnboardingDetails();

      setIsLoading(false);

      toast.success("Data saved successfully", {
        id: "onboarding",
        position: isMobile ? "top-center" : "bottom-center",
        duration: 3000,
      });

      setModalLoading(false);
    } catch (error) {
      toast.error(
        error?.response?.data?.errorDescription || "Something went wrong",
        {
          id: "onboarding",
          position: isMobile ? "top-center" : "bottom-center",
          duration: 3000,
        }
      );
      if (error?.response?.status === 401) {
        handleLogout();
      }
      setIsLoading(false);
      setModalLoading(false);
      setShowModal(false);
    }
  };

  const submitDetails = async () => {
    setConfirmLoading(true);
    toast.loading("Submitting...", {
      id: "onboarding",
      position: isMobile ? "top-center" : "bottom-center",
      duration: Infinity,
    });
    try {
      await submitOnboardingDetails();
      setConfirmLoading(false);

      await new Promise((resolve) => setTimeout(resolve, 1000));

      toast.success("Data submitted successfully", {
        id: "onboarding",
        position: isMobile ? "top-center" : "bottom-center",
        duration: 3000,
      });

      navigate("/onboarding/thankyou");
    } catch (error) {
      toast.error(
        error?.response?.data?.errorDescription || "Something went wrong",
        {
          id: "onboarding",
          position: isMobile ? "top-center" : "bottom-center",
          duration: 3000,
        }
      );
      if (error?.response?.status === 401) {
        handleLogout();
      }
      setConfirmLoading(false);
    }
  };

  return (
    <>
      <div className="hidden gap-6 md:flex">
        {currentPage > 0 && (
          <Button
            onClick={prevPage}
            className="text-white border border-white hover:opacity-50"
            type="ghost"
          >
            Back
          </Button>
        )}
        <Button
          type="ghost"
          className="px-8 text-black bg-white hover:opacity-50 drop-shadow-xl"
          onClick={handleContinue}
          loading={isLoading}
        >
          {currentPage === onboardingSteps.length - 1
            ? "Save & Submit"
            : "Save & Continue"}
        </Button>
      </div>

      {/* Mobile Buttons */}
      <div className="flex items-center gap-2 md:hidden justify-normal">
        {currentPage > 0 && (
          <Button
            onClick={prevPage}
            className="flex items-center justify-center gap-1 text-white border-white border-none"
            type="ghost"
          >
            <FaAngleLeft className="w-2" />
            Back
          </Button>
        )}
        <Button
          type="ghost"
          size="large"
          shape="circle"
          className="bg-white drop-shadow-xl"
          onClick={handleContinue}
          loading={isLoading}
          icon={<FaChevronRight />}
        ></Button>
      </div>
      <AntDesignContext>
        <Modal
          title="Confirm Submission"
          centered
          open={showModal}
          onOk={submitDetails}
          onCancel={() => setShowModal(false)}
          confirmLoading={confirmLoading}
          loading={modalLoading}
        >
          <p>Are you sure you want to submit the KYB onboarding details?</p>
        </Modal>
      </AntDesignContext>
    </>
  );
};

export default FooterButtons;
